import React from "react";

export default function Error() {
  
  return (
    <div>
     <h2>Upps... Sie müssen angemeldet sein, um die Inhalte sehen zu können.</h2>
     <p></p>
    </div>
  );
}