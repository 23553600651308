import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import ElasticSearchService from "../../services/elasticSearch.service";
import StorageService from "../../services/storage.service";
import AuthService from "../../services/auth.service";
import ACService from "../../helpers/accessValidation";
import * as Constants from "../../components/constants";

export default class Choices extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			currentUser: undefined,
			choices:  {},
			isLoading: true,
		    selectedChoice: "default",
			choiceKeys: [],
			readOnly: true,
			changeOrCancel: "Einträge Ändern",
			changeOrCancelValue: 0,
			deleteOrCancel: "Einträge Löschen",
			deleteOrCancelValue: 0,
			message: "",
			successful: "",
			changedChoices: {},
			newChoiceFlag: false,
			readOnlySave: true,
			readOnlyChange: false,
			newChoice: { id: "", type: "", value:"", comment: ""},
			readOnlyNew: false,
			readOnlyDelete: true,
			readOnlyActivateDelete: false
			
		}
		
		this.handleChangeChoice = this.handleChangeChoice.bind(this);
		this.saveChangeChoice = this.saveChangeChoice.bind(this);
		this.addChangeChoice = this.addChangeChoice.bind(this);
		this.deleteChoice = this.deleteChoice.bind(this);
		this.enableNewChoice = this.enableNewChoice.bind(this);
		this.cancelNewChoice = this.cancelNewChoice.bind(this);
		this.changeNewChoiceValue = this.changeNewChoiceValue.bind(this);
		this.changeNewChoiceComment = this.changeNewChoiceComment.bind(this);
		this.addChangeChoiceComment = this.addChangeChoiceComment.bind(this);
		this.enableDeleteChoice = this.enableDeleteChoice.bind(this);
	}
	

	enableDeleteChoice(e){
		let value = e.target.value;
		if(value === "0"){
			this.setState({
				readOnly: true,
				deleteOrCancel: "Löschen Beenden",
				message: "",
				successful: "",
				readOnlySave: true,
				readOnlyNew: true,
				readOnlyChange: true,
				readOnlyDelete: false,
				deleteOrCancelValue: 1
			});
		}
		
		if(value === "1"){
			this.setState({
				readOnly: true,
				deleteOrCancel: "Einträge Löschen",
				message: "",
				successful: "",
				readOnlySave: true,
				readOnlyNew: false,
				readOnlyChange: false,
				readOnlyDelete: true,
				deleteOrCancelValue: 0
			});
		}
		
	}
	

	
	enableNewChoice(e){
		this.setState({
					newChoiceFlag: true,
					readOnlySave: false,
					readOnlyChange: true,
					readOnlyDelete: true,
					readOnlyActivateDelete: true,
					readOnlyNew: true,
					message: "",
					successful: "",
			});
	}
	
	cancelNewChoice(e){
		
		this.setState({
					newChoiceFlag: false,
					readOnlySave: true,
					newChoice: { id: "", type: "", value:"", comment: ""},
					readOnlyChange: false,
					readOnlyDelete: true,
					readOnlyActivateDelete: false,
					readOnlyNew: false,
					message: "",
					successful: "",
			});
	}
	
	changeNewChoiceValue(e, type){
		let newChoice = this.state.newChoice;
		newChoice.value = e.target.value
		newChoice.type = type;
		// replace all spaces from id field
		newChoice.id = type+"_"+newChoice.value.replace(/ /g, "")
		this.setState({
			newChoice: newChoice,
			message: "",
		});
	}
	
	changeNewChoiceComment(e){
		let newChoice = this.state.newChoice;
		newChoice.comment = e.target.value
		this.setState({
			newChoice: newChoice
		});
	}
	
	addChangeChoice(e, key, idx){
			let copy = this.state.choices;
			copy[key][idx].value = e.target.value
	
			let changedList = {}
			changedList[key] = copy[key]
			this.setState({
					choices: copy,
					changedChoices: changedList,
			});
	}
	
	addChangeChoiceComment(e, key, idx){
			let copy = this.state.choices;
			copy[key][idx].comment = e.target.value
				
			let changedList = {}
			changedList[key] = copy[key]
			this.setState({
					choices: copy,
					changedChoices: changedList,
			});
	}
	
	deleteChoice( key, idx){
		if(window.confirm("Soll der Eintrag hier entfernt werden?")){
		
			let copyChoices = this.state.choices;
			let toDelete = copyChoices[key][idx];
			
			ElasticSearchService.deleteChoice(toDelete).then(
			response => {	
				if(response.status === 202){
					this.setState({
						message: response.data.message,	
						successful: false,	
						choices: copyChoices,
					});
				}
				
				if(response.status === 200){
						let array = copyChoices[key]
						delete array[idx];
						copyChoices[key] = array;				
						this.setState({
							message: response.data.message,
							successful: true,
							choices: copyChoices,
							readOnlyDelete: false,
						});
				}
			},
				error => {
					this.setState({
					error:	(error.response && error.response.data) ||	error.message || error.toString(),
					});
				}
			);
		}
	}
	

		
	saveChangeChoice(e, key){
		
		let toSave = this.state.changedChoices;
		
		if(this.state.newChoiceFlag){
			if(this.state.newChoice.value.length === 0 ){
				this.setState({
					message: "Bitte geben Sie einen Wert ein...",
					successful: false,
				});
				return;
			} 
			else{
				let newChoice = this.state.newChoice;
				let choices = this.state.choices;
				choices[key].unshift(newChoice);
				this.setState({
					choices: choices,
					changedChoices: choices,
					newChoice: { id: "", type: "", value:"", comment: ""}
					
				});
				toSave[key] = choices[key]
			}
		}

		ElasticSearchService.saveChoices(toSave).then(
			response => {	
				this.setState({
					message: response.data.message,
					successful: true,
					readOnly: true,
					changeOrCancel: "Ändern",
					readOnlySave: true,
					newChoiceFlag: false,
					readOnlyChange: false,
					changedChoices: {}, 
					newChoice: { id: "", type: "", value:"", comment: ""},
					readOnlyNew: false,
					readOnlyDelete: true,
					readOnlyActivateDelete: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);
		
		let selectedChoice = StorageService.handleStorage("selectedChoice");
	
		if(selectedChoice === ""){
			selectedChoice = "default"
			
		}
		this.setState({
				selectedChoice: selectedChoice,
		});
		
		
		
	}
	
	enableChangeChoice(e){
		let value = e.target.value;
		
		if(value === "0"){
			this.setState({
				readOnly: false,
				changeOrCancel: "Ändern Abbrechen",
				message: "",
				successful: "",
				readOnlySave: false,
				readOnlyNew: true,
				readOnlyDelete: true,
				readOnlyActivateDelete: true,
				changeOrCancelValue: 1
				
			});
		}
		if(value === "1"){
			this.setState({
				readOnly: true,
				changeOrCancel: "Einträge Ändern",
				message: "",
				successful: "",
				readOnlySave: true,
				readOnlyNew: false,
				readOnlyDelete: false,
				readOnlyActivateDelete: false,
				changeOrCancelValue: 0
			});
		}

	}
	
	handleChangeChoice(e){
		let selectedChoice = e.target.value
		localStorage.setItem("selectedChoice", JSON.stringify(selectedChoice));		
		this.setState({
			selectedChoice: selectedChoice,
			message: "",
			successful: ""
			
		});
	}
	
	componentDidMount() {
		
		const user = AuthService.getCurrentUser();
		
		if (user) {
			this.setState({
				currentUser: user,
			});
		}
		
		let selectedChoice = StorageService.handleStorage("selectedChoice");
	
		if(selectedChoice === null || selectedChoice.length === 0 ){
		
			selectedChoice = "default"	
		}
		
		this.setState({
				selectedChoice: selectedChoice,
		});
		
		ElasticSearchService.loadChoices().then(
			response => {	
			
				this.setState({
					choices:  response.data,
					choiceKeys: Object.keys(response.data),
					isLoading: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);
				
	}
	
	render() {
		const { currentUser ,isLoading, choiceKeys, selectedChoice, readOnly, changeOrCancel, choices, newChoiceFlag, readOnlySave, newChoice, readOnlyChange, readOnlyNew, readOnlyDelete, readOnlyActivateDelete, deleteOrCancel, deleteOrCancelValue, changeOrCancelValue } = this.state;

		return (
			<Container fluid="xxl">			
			 {!isLoading 
				?
				<div> 
					<Row>
					<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Management</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Auswahllisten</Breadcrumb.Item>
					</Breadcrumb>
					</Col>
				</Row>
					<Row>
						<Col>
						<h3>Auswahllisten</h3>
						</Col>
					</Row>
					<Row >
					<Col>
					<Form.Group as={Col} controlId="choices">
						<Form.Text className="text-muted">Bitte klicken Sie auf eine Liste...</Form.Text>
      					<Form.Control as="select" defaultValue={selectedChoice} onChange={(e) => this.handleChangeChoice(e)}>
								<option value="default">...</option>			
        						{choiceKeys.map((key, id) => (<option key={id} value={key}>{key}</option>))}
      					</Form.Control>
					</Form.Group>				
					</Col>
					</Row>
					{selectedChoice !== "default" 
						?
							<div>
							{this.state.message && (
						<div className="form-group">
							<div
								className={
									this.state.successful
										? "alert alert-success"
										: "alert alert-danger"
								}
								role="alert"
							>
								{this.state.message}
							</div>
						</div>
					)}
							
							
							 <Form onSubmit={(e) => this.saveChangeChoice(e, selectedChoice)} ref={c => {this.form = c; }}>
								{ACService.accessValidation(currentUser, Constants.AC_EDIT_SELECTIONS) && (
									<Row>
										<Col>
											<Button variant="Link" onClick={(e) => this.enableNewChoice(e)}  disabled={readOnlyNew}>Neuer Eintrag</Button>
											<Button variant="Link" value={changeOrCancelValue} onClick={(e) => this.enableChangeChoice(e)} disabled={readOnlyChange} >{changeOrCancel}</Button>
											<Button variant="Link" value={deleteOrCancelValue} onClick={(e) => this.enableDeleteChoice(e)} disabled={readOnlyActivateDelete}>{deleteOrCancel}</Button>
											<Button variant="Link" type="submit" disabled={readOnlySave} className="float-right">Speichern</Button>
										</Col>
									</Row>
								)}
								{newChoiceFlag 
								? 
								<div className="rounded mb-0 border border-dark">
								<Row>
								
								<Col>
					              <Form.Group as={Col} >
											<Form.Text className="text-muted">{selectedChoice}</Form.Text>
					      					<Form.Control as="textarea" rows={newChoice.value.length>50?2:1} key="value" value={newChoice.value} onChange={(e) => this.changeNewChoiceValue(e, selectedChoice)}/>
									</Form.Group>
					            </Col>
								<Col>
					              <Form.Group as={Col} >
											<Form.Text className="text-muted">Kommentar</Form.Text>
					      					<Form.Control as="textarea" rows={newChoice.comment.length>50?4:1}  value={newChoice.comment} onChange={(e) => this.changeNewChoiceComment(e)}/>
									</Form.Group>
					            </Col>
	</Row>	
<Row>
								<Col>
									<Button variant="Link" className="float-left" onClick={(e) => this.cancelNewChoice(e)}>Abbrechen</Button>
									<Button variant="Link" type="submit" disabled={readOnlySave} className="float-right">Speichern</Button>
					            </Col>
									
								
								</Row>	
								</div>
								: ""
								}
						
						{choices && choices[selectedChoice].map((choice, id) =>(	
							
								<Row key={id}>
								<Col >
					              <Form.Group as={Col} >
											<Form.Text className="text-muted">{selectedChoice}</Form.Text>
					      					<Form.Control key={id} as="textarea" rows={choice.value.length>70?2:1} value={choice.value} readOnly={readOnly} onChange={(e) => this.addChangeChoice(e, selectedChoice, id)}/>
									</Form.Group>
					            </Col>
								<Col xs={4}>
					              <Form.Group as={Col} >
											<Form.Text className="text-muted">{selectedChoice==="Fotograf" || selectedChoice==="Urheberrechte und Lizenzen" ?"Rechtelizenz":"Kommentar"}</Form.Text>
					      					<Form.Control key={id} as="textarea" rows={choice.comment.length>50?4:1}  value={choice.comment === null ? "": choice.comment} readOnly={readOnly} onChange={(e) => this.addChangeChoiceComment(e, selectedChoice, id)}/>
									</Form.Group>
					            </Col>
								<Col xs={1}>
									<Button variant="Link" onClick={() => this.deleteChoice(selectedChoice, id)} disabled={readOnlyDelete}>Löschen</Button>
									
					            </Col>
								</Row>	
							))} 
						
						
								
							</Form>
							</div>
						: "Keine Liste ausgewählt"
					}
				</div>
				: 
				<Row><Col>Loading...</Col></Row>
			 } 
		
			</Container>
		);
	}
}
