import React, { useState, useEffect } from "react";

import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import * as Constants from "../../components/constants";

import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";
import ACService from "../../helpers/accessValidation.js";
import SchedulerService from "../../services/scheduler.service";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { handleStorage, setStorage } from '../../helpers/jfb';

import ProgressIndicator from "../standard/jfbProgressIndicator";

import PDF from "../../helpers/pdf.js";

export default function CementryReport(props) {
	
	const [user] = useState(AuthService.getCurrentUser());
	
	const STORAGE_CEMENTRY_KEY = "conservation-cementry";
	
	const [isLoadingCementries, setLoadingCementries] = useState(true);

	const [cementrySelection, setCementrySelection] = useState(null);
	const [cementries, setCementries] = useState([]);
	
	const [progressIndicatorTimerId, setProgressIndicatorTimerId] = useState(null);
	
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	function handleChangeCementrySelection(newValue) {
		
		let cementryId = (newValue !== null && newValue !== "") ? newValue.id : null;
		
		let cementryOption = getCementryOption(cementries, cementryId);
		
		clearProgressIndicatorUpdateInterval();
		
		setCementrySelection(cementryOption);

		setStorage(STORAGE_CEMENTRY_KEY, cementryId);
	}
	
	function getCementryOption(cementryOptions, cementryId) {
					
		let option = null;
		
		let index = cementryOptions.findIndex(cementry => cementry.id === cementryId);
		if (index > -1) {
			
			option = cementryOptions[index];
		}
		
		return option;
	}
	

	
	function getJobStatus() {
		
		return SchedulerService.getJobStatusCementryPicturesReportPdf(cementrySelection.id);
	}
	
	function triggerGenerationJob() {
		
		return SchedulerService.createJobForCementryPicturesReportPdfGeneration(cementrySelection.id);
	}
	
	function triggerDownload() {
		
		return PDF.downloadCementryPicturesReportPdf(cementrySelection.id);
	}
	
	function updateProgressIndicatorTimerId(timerId) {
		
		setProgressIndicatorTimerId(timerId);
	}
	
	function clearProgressIndicatorUpdateInterval() {
		
		console.info(progressIndicatorTimerId);
		
		if (progressIndicatorTimerId !== null) {
			
			clearInterval(progressIndicatorTimerId);
			
			setProgressIndicatorTimerId(null);
		}		
	}
	
	useEffect(() => {
		
		if (isLoadingCementries) {
			ElasticSearchService.loadCementriesForAutoComplete(0).then(
				response => {
					
					setCementries(response.data);
					setLoadingCementries(false);
					setSuccessful(true);
					
					let cementryIdInStorage = handleStorage(STORAGE_CEMENTRY_KEY, null); //localStorage.getItem("cementery-assessment");
					let selectedCementryOption = getCementryOption(response.data, cementryIdInStorage);
					
					setCementrySelection(selectedCementryOption);
					
				},
				error => {
					const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
		
					setMessage(resMessage);
					setSuccessful(false);
					setLoadingCementries(true);
				}
			);
		} else {
			setCementrySelection(null);
		} 
		
	}, [isLoadingCementries]);
	
	
	
	return (
		<Container>
			<Row md={12}>
				<Col  md={12} >
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Kontaktabzug</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row md={12}>
				<Col  md={12} ><h3>Kontaktabzug</h3></Col>
			</Row>
			<Row md={12}>
				<Col  md={12}>Erstellen Sie hier eine PDF Datei mit allen Standardfotos eines Friedhofes. Wählen Sie dazu einen Friedhof aus und klicken Sie auf "Kontaktabzug erstellen"</Col>
			</Row>
			<Row md={12}>
				<Col  md={12} ><strong>Friedhof</strong></Col>
			</Row>
			{ACService.accessValidation(user, Constants.AC_REPORT_CEMENTRY_PICTURES) && (
			<>
				<Row md={12}>	
					<Col  md={12} >
						<Autocomplete
					  		disablePortal
					  		id="combo-box-cementries"
					  		options={cementries}
					  		value={cementrySelection}
					  		isOptionEqualToValue={(option, value) => (value != null && option.id === value.id)}
					  		onChange={(event, cementry) => handleChangeCementrySelection(cementry)}
					  		renderInput={(params) => <TextField {...params} label="Bitte wählen Sie den gewünschten Friedhof aus" />} 
						/>
					</Col>
				</Row>
				<Row md={12}>
					<Col md={12}>
						{cementrySelection !== null 
							&& <ProgressIndicator 
									cementryId={cementrySelection.id}
									labelGenerationButton="Kontaktabzug erstellen" 
									labelGenerationFinishedButton="Kontaktabzug erstellt" 
									labelDownloadButton="bereits generierten Kontaktabzug herunterladen" 
									getStatus={getJobStatus} 
									onGenerate={triggerGenerationJob}
									onDownload={triggerDownload} 
									registerTimerId={updateProgressIndicatorTimerId}
								/>
						}
					</Col>
				</Row>
			</>)}
			{message && (
				<Row className="form-group">
					<Col className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</Col>
				</Row>
			)}
			
		</Container>
		
	);
}