import React, { Fragment, useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";

import HoverRating from "../../standard/hoverRating";

import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Stack from '@mui/material/Stack';

import AssessmentDetails from "./assessmentDetails";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { getStringDate } from "../../../helpers/util";

export default function AssessmentItem(props) {
	
	const { readOnly, assessment, index, onChange, onDelete } = props;
	
	const tombstoneConditions = {
		1: 'Fragment',
		2: 'stark gefährdet',
		3: 'gefährdet',
		4: 'gut'
	};
	
	const inscriptionConditions = {
		1: 'abhängig',
		2: 'überwiegend verwittert',
		3: 'überwiegend lesbar',
		4: 'gut'
	};

	let details = assessment.details !== undefined && assessment.details !== null ? assessment.details : [];
	const [assessmentDetails, setAssessmentDetails] = useState([...details]);
	
	// JavaScript date format: YYYY-MM-DDTHH:mm:ss.sssZ
	let date = assessment.date !== undefined ? new Date(assessment.date) : new Date();
	const [assessementDate, setAssessementDate] = useState(date);
	
	let classification1 = assessment.classificationTombstone !== undefined ? assessment.classificationTombstone : "";
	const [classificationTombstone, setClassificationTombstone] = useState(classification1);
	
	let classification2 = assessment.classificationInscription !== undefined ? assessment.classificationInscription : "";
	const [classificationInscription, setClassificationInscription] = useState(classification2);
	
	let currentComment = assessment.comment !== undefined ? assessment.comment : "";
	const [comment, setComment] = useState(currentComment);
	
	const createAssessment = (details, date, classTombstone, classInscription, assessmentComment) => {
		
		let currentAssessment = {};
     	currentAssessment.details = details;
		currentAssessment.date = getStringDate(date);
		currentAssessment.classificationTombstone = classTombstone;
		currentAssessment.classificationInscription = classInscription;
		currentAssessment.comment = assessmentComment;
		
		return currentAssessment;
	}
	
	const handleDateChange = (newValue) => {
		
		setAssessementDate(newValue);
		
		let currentAssessment = createAssessment(assessmentDetails, newValue, 
									classificationTombstone, classificationInscription, comment);
     	
     	onChange(index, currentAssessment);
	};
	
	const handleClassificationTombstone = (newValue) => {
		
		setClassificationTombstone(newValue);
		
		let currentAssessment = createAssessment(assessmentDetails, assessementDate, 
									newValue, classificationInscription, comment);
     	
     	onChange(index, currentAssessment);
	};
	
	const handleClassificationInscription = (newValue) => {
		
		setClassificationInscription(newValue);
		
		let currentAssessment = createAssessment(assessmentDetails, assessementDate, 
									classificationTombstone, newValue, comment);
     	
     	onChange(index, currentAssessment);
	};
	

	
	function hanldeDeleteAssessment() {
		
		onDelete(index);
	};
	
	function handleAddNewAssessmentDetails() {
		
		addNewAssessmentDetails({
			component: "Schäden am Stein",  
			damagePatterns:[],
			comment: ""
     	});
	}
	
	function addNewAssessmentDetails(details) {

		let newAssessmentsDetails = [...assessmentDetails];
		
		newAssessmentsDetails.push(details);
     	
     	setAssessmentDetails(newAssessmentsDetails);
     	
     	let currentAssessment = createAssessment(newAssessmentsDetails, assessementDate, 
									classificationTombstone, classificationInscription, comment);
     	
     	onChange(index, currentAssessment);
	}
	
	function deleteAssessmentDetails(detailsIndex) {
		
		let newAssessmentsDetails = [...assessmentDetails];
		
		newAssessmentsDetails.splice(detailsIndex, 1);
		
		setAssessmentDetails(newAssessmentsDetails);
		
		let currentAssessment = createAssessment(newAssessmentsDetails, assessementDate, 
									classificationTombstone, classificationInscription, comment);
     	
     	onChange(index, currentAssessment);
	}
	
	function handleDetailsChange(detailsIndex, detailsItem) {
		
		//let newAssessmentsDetails = [...assessmentDetails];
		
		assessmentDetails[detailsIndex] = detailsItem;
		
		//setAssessmentDetails(newAssessmentsDetails);
		
		let currentAssessment = createAssessment(assessmentDetails, assessementDate, 
									classificationTombstone, classificationInscription, comment);
     	
     	onChange(index, currentAssessment);
	};
	
	useEffect(() => {
	
		let details = assessment.details !== undefined && assessment.details !== null ? assessment.details : [];
		setAssessmentDetails([...details]);
		
		// JavaScript date format: YYYY-MM-DDTHH:mm:ss.sssZ
		let date = assessment.date !== undefined ? new Date(assessment.date) : new Date();
		setAssessementDate(date);
		
		let classification1 = assessment.classificationTombstone !== undefined ? assessment.classificationTombstone : "";
		setClassificationTombstone(classification1);
		
		let classification2 = assessment.classificationInscription !== undefined ? assessment.classificationInscription : "";
		setClassificationInscription(classification2);
		
		let currentComment = assessment.comment !== undefined ? assessment.comment : "";
		setComment(currentComment);
	
		console.info("assessmentItem rendered");
		 
	}, [assessment]);
	
	return (
		<Container>
			<>
				<Row md={12}>
					<Col md={12}>
						<Box sx={{ boxShadow: 3 }} >
							<Row>
								<Col></Col>
							</Row>
							<Row md={12} >
								
								<Col md={1}></Col>
								<Col  md={3} className="form-group">
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DesktopDatePicker 
								          label="Datum"
								          inputFormat="dd.MM.yyyy"
								          value={assessementDate}
								          onChange={handleDateChange}
								          renderInput={(params) => <TextField fullWidth {...params} disabled={readOnly} />}  
								          disabled={readOnly} 
								        />
									</LocalizationProvider>									     
								</Col>
								<Col md={1}></Col>
								<Col md={3}>
									<Box>Zustand des Grabmals</Box>
									<HoverRating 
										readOnly={readOnly}
										labels={tombstoneConditions}
										labelSelected={classificationTombstone} 
										onChange={handleClassificationTombstone} 
									/>
								</Col>
								<Col md={4}>
									<Box>Zustand der Inschrift</Box>
									<HoverRating 
										readOnly={readOnly} 
										labels={inscriptionConditions} 
										labelSelected={classificationInscription} 
										onChange={handleClassificationInscription} 
									/>
								</Col>
								<Col md={1}></Col>
							</Row>
							<Row md={12}>
								<Col md={1}></Col>
								<Col  md={10} className="form-group">
									{comment?"Bewertung: "+ comment:""}
								</Col>
								<Col md={1}></Col>
							</Row>
							<Row>
								<Col><br/></Col>
							</Row>
							<Row md={12}>
								<Col md={1}></Col>
								<Col  md={10} className="form-group">
									<Box><strong>Details zur Bewertung</strong></Box>
								</Col>
								<Col md={1}></Col>
							</Row>
							
							{assessmentDetails.length > 0 ? 
							
							assessmentDetails.map((assessmentDetails, detailsIndex) => (
								<Fragment key={detailsIndex}>
									<Row md={12}>
										<Col md={1}></Col>
										<Col  md={10} className="form-group">
											<AssessmentDetails 
												readOnly={readOnly}
												index={detailsIndex} 
												details={assessmentDetails} 
												onDelete={deleteAssessmentDetails} 
												onAddNew={addNewAssessmentDetails} 
												onChange={handleDetailsChange}
											 />
										</Col>
										<Col md={1}></Col>
									</Row>
								</Fragment>
							)) :
							(
								<Fragment key={-1}>
									<Row md={12}>
										<Col md={12}>
											<Stack direction="row" justifyContent="center" alignItems="center">
												<Box><strong>Keine Details zur Bewertung</strong></Box>
											</Stack>
										</Col>
									</Row>
									{!readOnly && (
									<Row md={12}>
										<Col md={1}></Col>
										<Col  md={10} className="form-group">
											<Stack direction="row" justifyContent="end" alignItems="center">
												<Tooltip title={"Bewertungsdetails hinzufügen"} arrow placement="left">
													<span>
														<IconButton onClick={handleAddNewAssessmentDetails} ><AddCircleIcon /></IconButton>
													</span>
												</Tooltip>
											</Stack>
										</Col>
										<Col md={1}></Col>
									</Row>
									)}
								</Fragment>
							)}
							{!readOnly && (							
							<Row md={12}>
								<Col md={12}>
									<Stack direction="row" justifyContent="start">
										<Tooltip title="Bewertung löschen" arrow placement="right">
											<span>
												<IconButton onClick={() => hanldeDeleteAssessment()} ><DeleteIcon /></IconButton>
											</span>
										</Tooltip>
									</Stack>
								</Col>
							</Row>
							)}
						</Box>
					</Col>
				</Row>
			</>

		</Container>

	);
}