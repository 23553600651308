import React from 'react'
import { Row, Col, Button } from "react-bootstrap";
//import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser} from '@fortawesome/free-solid-svg-icons';
import { personToString } from '../helpers/util'

export default class DetailElementPersonWithLink extends React.Component {
	
  	render() {
    	return (<div>
	        {this.props.value ? 
      			<Row >
					<Col md={3} style={{backgroundColor: '#ffffff'}}>
						<strong>{this.props.name}</strong>
       				</Col>
					<Col  md="auto" style={{backgroundColor: '#ffffff'}}></Col>
					<Col md={{ span: 11, offset: 1 }} style={{backgroundColor: '#fbfbfb'}}>
					{personToString(this.props.value)}
					<Button variant="Link" href={"/personDetails/"+this.props.value.cementry+"/"+this.props.value.id}><FontAwesomeIcon icon={faUser} /></Button>			
					{this.props.value.grabsteinnummer?
					<a href={"/tombstoneDetails/"+this.props.value.cementry+"/"+this.props.value.grabsteinnummer}>
						<img alt="" src="/home.png" width="17" height="20" />
					</a>
					:""
					}
					<br/>
			
					{this.props.secondPerson!=null ? <div>{personToString(this.props.secondPerson)}
					<Button variant="Link" href={"/personDetails/"+this.props.secondPerson.cementry+"/"+this.props.secondPerson.id}><FontAwesomeIcon icon={faUser} /></Button>			
					{this.props.secondPerson.grabsteinnummer?
					<a href={"/tombstoneDetails/"+this.props.secondPerson.cementry+"/"+this.props.secondPerson.grabsteinnummer}>
						<img alt="" src="/home.png" width="17" height="20" />
					</a>
					:""
					}
					</div>:""}
					
					</Col>
      		</Row>
			: ""}</div>
			
    )
  }
}

