import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Badge, Row, Col, Breadcrumb } from "react-bootstrap";

import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";
import ACService from "../../helpers/accessValidation.js";
import * as Constants from "../../components/constants";
import TombstoneGalery from "../../components/tombstoneGalery";
import TombstoneMapDetailElement from "../public/details/tombstoneMapDetailElement2";
import TombstoneConditionAssessment from "./assessment/tombstoneConditionAssessment2/tombstoneConditionAssessment2";
import ActionsBar from "../standard/actionsBar";
import JfbSlider from "../standard/jfbSlider";
import ConservationPlan from "./conservation/v2/conservationPlan2";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import styled from "styled-components";

import AutocompleteHelper from "../../helpers/autocompleteHelper";

import PDF from "../../helpers/pdf.js";

const PdfAnchor = styled.a`
		cursor: pointer;
`;	
	
export default function Conservation() {
	const [user] = useState(AuthService.getCurrentUser());

	const { cementryId, tombstoneId } = useParams();
	
	const [isLoadingTombstone, setLoadingTombstone] = useState(true);
	const [tombstone, setTombstone] = useState({});
	
	const [isLoadingTombstones, setLoadingTombstones] = useState(true);
	const [tombstones, setTombstones] = useState([]);
	
	const [isLoadingCementry, setLoadingCementry] = useState(true);
	const [cementry, setCementry] = useState({});
	
	const [isLoadingChoices, setLoadingChoices] = useState(true);
	const [isLoadingMakers, setLoadingMakers] = useState(true);
	
	const [edit, setEdit] = useState(false);
	
	const [successful, setSuccessful] = useState(false);	
	const [message, setMessage] = useState("");
	
	const [dateOfDeath, setDateOfDeath] = useState(null);
	const [tombstoneType, setTombstoneType] = useState(null);
	const [tombstoneManufacturer, setTombstoneManufacturer] = useState(null);
	
	const [tombstoneSteleLabels, setTombstoneSteleLabels] = useState([]);
	const [tombstoneInscriptionLabels, setTombstoneInscriptionLabels] = useState([]);
	const [tombstonePedestalLabels, setTombstonePedestalLabels] = useState([]);
	const [tombstoneSocketLabels, setTombstoneSocketLabels] = useState([]);
	
	const [tombstoneTypeOptions, setTombstoneTypeOptions] = useState([]);
	const [tombstoneManufacturerOptions, setTombstoneManufacturerOptions] = useState([]);
	const [materialOptions, setMaterialOptions] = useState([]);
	
	const [riscDescription, setRiscDescription] = useState("");
	
	function handleChangeEdit(value) {
			
		setEdit(value);
	}
	
	function handleSaveTombstone() {
		
		if (tombstone.persons !== undefined && tombstone.persons.length>0) {
		
			tombstone.persons[0].sterbedatum_tag = dateOfDeath.getDate();
			tombstone.persons[0].sterbedatum_monat = dateOfDeath.getMonth() + 1;
			tombstone.persons[0].sterbedatum_jahr = dateOfDeath.getFullYear();
		}
		
		if (tombstoneType != null) {
			tombstone.grabsteintypChoice = tombstoneType;
		}
		
		if (tombstoneManufacturer != null) {
			if (tombstone.herstellerMaker !== undefined && tombstone.herstellerMaker !== null) {
				tombstone.herstellerMaker.displayName = tombstoneManufacturer.label;
			} else {
				tombstone.herstellerMaker = {
					id: tombstoneManufacturer.id,
					displayName: tombstoneManufacturer.label	
				}
			}
		}
		
		tombstone.material.steleChoice = tombstoneSteleLabels;
		tombstone.material.inschrifttafelChoice = tombstoneInscriptionLabels;
		tombstone.material.postamentChoice = tombstonePedestalLabels;
		tombstone.material.sockelChoice = tombstoneSocketLabels;
		
		tombstone.riscDescription = riscDescription;
		
		tombstone.conservations.forEach((conservation) => {
			
			let assignedTombstones = conservation.assignedTombstones;
			
			if (assignedTombstones !== null && typeof assignedTombstones !== 'undefined' && assignedTombstones.length > 0) {
				
				ElasticSearchService.assignConservationToTombstones(cementryId, conservation, assignedTombstones).then(
					response => {
						
						setSuccessful(true);
						setMessage(response.data.message);
						
					},
					error => {
						const resMessage =
								(error.response &&
									error.response.data &&
									error.response.data.message) ||
								error.message ||
								error.toString();
				
						setMessage(resMessage);
						setSuccessful(false);
						setLoadingTombstone(false);
					}
				);
			} 
		});
						
		ElasticSearchService.saveTombstone(tombstone).then(
			response => {	
				
				setSuccessful(true);
				setMessage(response.data.message);
				
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
		
				setMessage(resMessage);
				setSuccessful(false);
				setLoadingTombstone(false);
			}
		);
	}
	
	function handleUpdateAssessments(updatedAssessments) {
		
		let updatedList = [...updatedAssessments];
		
		tombstone.conditions = updatedList;
				
		setTombstone(tombstone);
	}
	
	const handleDateChange = (newValue) => {
		
		setDateOfDeath(newValue);
	};
	
	function handleChangeTombstoneType(newValue) {
				
    	setTombstoneType(newValue);
	}
	
	function handleChangeManufacturer(newValue) {
				
    	setTombstoneManufacturer(newValue);
	}
	
	function handleChangeMaterialStele(newValue) {
		
		let currentTombstoneSteleLabels = [...newValue];
		
		setTombstoneSteleLabels(currentTombstoneSteleLabels);
	}
	
	function handleChangeMaterialInscription(newValue) {
		
		let currentTombstoneInscriptionLabels = [...newValue];
		
		setTombstoneInscriptionLabels(currentTombstoneInscriptionLabels);
	}
	
	function handleChangeMaterialPedestal(newValue) {
		
		let currentTombstonePedestalLabels = [...newValue];
		
		setTombstonePedestalLabels(currentTombstonePedestalLabels);
	}
	
	function handleChangeMaterialSocket(newValue) {
		
		let currentTombstoneSocketLabels = [...newValue];
		
		setTombstoneSocketLabels(currentTombstoneSocketLabels);
	}
		
	function handleChangeRiscDescription(newValue) {
		
		setRiscDescription(newValue);
	}
	
	function handleUpdateConservations(updatedConservations) {
		
		let updatedList = [...updatedConservations];
		
		tombstone.conservations = updatedList;
				
		setTombstone(tombstone);
	}
	
	function handleChangeAssignedTombstones(conservationIndex, assignedTombstones) {
		
		tombstone.conservations[conservationIndex].assignedTombstones = assignedTombstones;
				
		setTombstone(tombstone);
	}
	
	
	function getMaterialOptionsByChoice(tombstone, materialChoiceFieldName) {
		
		return AutocompleteHelper.getMaterialOptionsByChoice(tombstone, materialChoiceFieldName);
	}

	useEffect(() => {
         
        if (isLoadingTombstone) {
	     	
	     	ElasticSearchService.loadTombstone(cementryId, tombstoneId).then(
			response => {
				
				setTombstone(response.data);
				
				let currentTombstone = response.data;
				
				if (currentTombstone.persons !== undefined && currentTombstone.persons.length > 0) {
					setDateOfDeath(new Date(currentTombstone.persons[0].sterbedatum_jahr, 
										currentTombstone.persons[0].sterbedatum_monat-1, 
										currentTombstone.persons[0].sterbedatum_tag));
				}
				
				let tombstoneTypeChoice = currentTombstone.grabsteintypChoice;
				if (tombstoneTypeChoice !== null && tombstoneTypeChoice !== undefined) {
					
					setTombstoneType({
						id: tombstoneTypeChoice.id, 
						label: tombstoneTypeChoice.value
					});
				}
				
				let manufacturer = currentTombstone.herstellerMaker && currentTombstone.herstellerMaker.displayName ? {
					id: currentTombstone.herstellerMaker.id,
					label: currentTombstone.herstellerMaker.displayName 
				} : null;
				setTombstoneManufacturer(manufacturer);
				
				let currentTombstoneSteleLabels = getMaterialOptionsByChoice(currentTombstone, "steleChoice");
				setTombstoneSteleLabels(currentTombstoneSteleLabels);
				
				let currentTombstoneInscriptionLabels = getMaterialOptionsByChoice(currentTombstone, "inschrifttafelChoice");
				setTombstoneInscriptionLabels(currentTombstoneInscriptionLabels);
				
				let currentTombstonePedestalLabels = getMaterialOptionsByChoice(currentTombstone, "postamentChoice");
				setTombstonePedestalLabels(currentTombstonePedestalLabels);
	
				let currentTombstoneSocketLabels = getMaterialOptionsByChoice(currentTombstone, "sockelChoice");
				setTombstoneSocketLabels(currentTombstoneSocketLabels);
				
				if (currentTombstone.riscDescription !== null && currentTombstone.riscDescription !== undefined) {
					setRiscDescription(currentTombstone.riscDescription);
				}
						
				setLoadingTombstone(false);
				setSuccessful(true);
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
				setMessage(resMessage);
				setSuccessful(false);
				setLoadingTombstone(false);
			});
		}
		
		if (isLoadingTombstones) {
			ElasticSearchService.loadTombstones(cementryId).then(
			response => {
				setTombstones(response.data.tombstones);
				
				setLoadingTombstones(false);
				setSuccessful(true);
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
				setMessage(resMessage);
				setSuccessful(false);
				setLoadingTombstones(false);
			});
		}
		
		if (isLoadingCementry) {
			ElasticSearchService.getCementry(cementryId).then(
			response => {
				setCementry(response.data);
				
				setLoadingCementry(false);
				setSuccessful(true);
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
				setMessage(resMessage);
				setSuccessful(false);
				setLoadingTombstones(false);
			});
		}
		
		if (isLoadingChoices) {

			ElasticSearchService.loadChoices().then(
				response => {
					
					let data = response.data;
					
					let options = data.Grabsteintyp.map((option) => {
						
						return {
							id: option.id,
							label: option.value
						}
					});
					
					setTombstoneTypeOptions(options);
					
					options = data.Material.map((option) => {
						
						return {
							id: option.id,
							label: option.value
						}
					});
					
					setMaterialOptions(options);
				},
				error => {
					const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
		
					setMessage(resMessage);
					setSuccessful(false);
					setLoadingChoices(false);
				}
			);
		}
		
		if (isLoadingMakers) {
			ElasticSearchService.loadMakers().then(
				response => {	
					
					let makers = response.data;
					
					let options = makers.map((option) => {
							
						return {
							id: option.id,
							label: option.displayName
						}
					});
						
					setTombstoneManufacturerOptions(options);

				},
				error => {
					const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
		
					setMessage(resMessage);
					setSuccessful(false);
					setLoadingMakers(false);
				}
			);
		}

	}, [cementryId, tombstoneId]);

	return (
		<Container>
			<Row>
				<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item className="breadcrumb-item-style" href="/dashboard">Management</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" href="/conservation-projects" >Konservierungsprojekte</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Maßnahmenplan</Breadcrumb.Item>
					</Breadcrumb>
	
				</Col>
			</Row>
			<Row>
				<Col><h3><Badge className="badge-primary" bg="">Maßnahmenplan</Badge></h3></Col>
			</Row>
		
		   {(!isLoadingTombstone && !isLoadingCementry) && (
			<>
		    	{ACService.accessValidation(user, Constants.AC_EDIT_ASSESSMENTS, cementry.name) && (
				<Row md={12}>
					<Col md={12} >
						<ActionsBar 
							isEditable={edit} 
							onSave={handleSaveTombstone} 
							onChange={handleChangeEdit} 
						/>
					</Col>
				</Row>)}
		    	<Row>
					<Col md={11}>
						<h1>
						Friedhof: {cementry.name}
						</h1>
					</Col>
					<Col md={1}>
						<PdfAnchor onClick={() => PDF.createConservationsPdf(tombstone.cementry, tombstone.grabstein.grabsteinnummer, setMessage)} >PDF</PdfAnchor>
					</Col>
				</Row>
				<Row>
					<Col>
						<h1>
						Grabstein: {tombstone.grabstein.grabsteinnummer}
						</h1>
					</Col>
				</Row>
				
				{tombstone.bilder && (
				<>
				<TombstoneGalery photos={tombstone.bilder}/>
	            </>)}

				{message && (
					<div className="form-group">
						<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
					</div>
				)}
		   
		   		<div className="areaBorder">
				   	<Row>
						<Col><h2><b>Basisinformationen</b></h2></Col>
					</Row>
					
					<Row>
						<Col>
							<hr/>
						</Col>
					</Row>
					<Row>
						<Col>
							Name des Verstorbenen
						</Col>
						<Col>
							<h3>{tombstone.persons.length > 0 ? tombstone.persons[0].buergerlicher_vorname : ""} {tombstone.persons.length > 0 ? tombstone.persons[0].buergerlicher_name : ""}</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							Sterbedatum
						</Col>
						<Col>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DesktopDatePicker 
						          label="Datum"
						          inputFormat="dd.MM.yyyy" 
						          minDate={new Date(1600, 0)}
						          value={dateOfDeath}
						          onChange={handleDateChange}
						          renderInput={(params) => <TextField fullWidth {...params} disabled={!edit} />}  
						          disabled={!edit} 
						        />
							</LocalizationProvider>	
						</Col>
					</Row>
					<Row>
						<Col>
							Grabsteintyp
						</Col>
						<Col>
							<Autocomplete
								  disablePortal
								  id="damage-area"
								  options={tombstoneTypeOptions}
								  value={tombstoneType} 
								  filterSelectedOptions 
								  isOptionEqualToValue={(option, value) => (value !== null && value !== "" && option.id === value.id)}
								  onChange={(event, newValue) => handleChangeTombstoneType(newValue)}
								  renderInput={(params) => <TextField {...params} label="Wählen Sie den Grabsteinstyp" />} 
								  disabled={!edit} 
						  	/>
					  	</Col>
					</Row>
					<Row>
						<Col>
							Hersteller
						</Col>
						<Col>
							<Autocomplete
								  disablePortal
								  id="damage-area"
								  options={tombstoneManufacturerOptions}
								  value={tombstoneManufacturer}
								  filterSelectedOptions 
								  isOptionEqualToValue={(option, value) => (value !== null && option.label === value.label)}
								  onChange={(event, newValue) => handleChangeManufacturer(newValue)}
								  renderInput={(params) => <TextField {...params} label="Wählen Sie den Hersteller" />} 
								  disabled={!edit} 
						  	/>
					  	</Col>
					</Row>
					<Row>
						<Col>
							Materialien
						</Col>
					</Row>
					<Row>
						<Col  md={1}></Col>
						<Col>
							Stele
						</Col>
						<Col>
							<Autocomplete
								  multiple
								  id="material-stele"
								  options={materialOptions}
								  value={tombstoneSteleLabels}
								  filterSelectedOptions 
								  isOptionEqualToValue={(option, value) => (value !== null && value !== "" && option.label === value.label)}
								  onChange={(event, newValue) => handleChangeMaterialStele(newValue)}
								  renderInput={(params) => <TextField {...params} label="Wählen Sie das Material der Stele" />} 
								  disabled={!edit} 
						  	/>
					  	</Col>
					</Row>
					<Row>
						<Col  md={1}></Col>
						<Col>
							Inschriftentafel
						</Col>
						<Col>
							<Autocomplete
								  multiple
								  id="material-inscription"
								  options={materialOptions}
								  value={tombstoneInscriptionLabels}
								  filterSelectedOptions 
								  isOptionEqualToValue={(option, value) => (value !== null && value !== "" && option.label === value.label)}
								  onChange={(event, newValue) => handleChangeMaterialInscription(newValue)}
								  renderInput={(params) => <TextField {...params} label="Wählen Sie das Material der Inschriftentafel" />} 
								  disabled={!edit} 
						  	/>
					  	</Col>
					</Row>
					<Row>
						<Col  md={1}></Col>
						<Col>
							Postament
						</Col>
						<Col>
							<Autocomplete
								  multiple
								  id="material-pedestal"
								  options={materialOptions}
								  value={tombstonePedestalLabels}
								  filterSelectedOptions 
								  isOptionEqualToValue={(option, value) => (value !== null && value !== "" && option.label === value.label)}
								  onChange={(event, newValue) => handleChangeMaterialPedestal(newValue)}
								  renderInput={(params) => <TextField {...params} label="Wählen Sie das Material des Postaments" />} 
								  disabled={!edit} 
						  	/>
					  	</Col>
					</Row>
					<Row>
						<Col  md={1}></Col>
						<Col>
							Sockel
						</Col>
						<Col>
							<Autocomplete
								  multiple
								  id="material-socket"
								  options={materialOptions}
								  value={tombstoneSocketLabels} 
								  filterSelectedOptions 
								  isOptionEqualToValue={(option, value) => (value !== null && value !== "" && option.label === value.label)}
								  onChange={(event, newValue) => handleChangeMaterialSocket(newValue)}
								  renderInput={(params) => <TextField {...params} label="Wählen Sie das Material des Sockels" />} 
								  disabled={!edit} 
						  	/>
					  	</Col>
					</Row>
					
					{!isLoadingCementry && !isLoadingTombstones && tombstone.weltkoordinaten && tombstone.weltkoordinaten.features && tombstone.weltkoordinaten.features.length > 0 ? (
					<Row>
						<Col>
							<TombstoneMapDetailElement cementery={cementry} tombstones={tombstones} zoom={19} tombstoneMarker={[tombstone]} />
						</Col>
					</Row>) : (
					<Row>
						<Col>
							Derzeit sind keine GEO Informationen verfügbar.
						</Col>
				  	</Row>)}
					
				</div>
		   
				<div className="areaBorder">
					<Row>
						<Col><h2><b>Zustandsberichte</b></h2></Col>
					</Row>
					
					<Row>
						<Col>
							<hr/>
						</Col>
					</Row>
					<Row md={12}>
						<Col  md={12} className="form-group">
							<TombstoneConditionAssessment 
								readOnly={!edit} 
								conditions={tombstone.conditions}
								tombstoneNumber={tombstone.grabstein.grabsteinnummer}
								canSelectTombstone={false} 
								showTombstoneName={false} 
								onUpdateAssessments={handleUpdateAssessments}
							/>
						</Col>
					</Row>
				</div>
		  
				<div className="areaBorder">
					<Row>
						<Col><h2><b>Risikobewertung</b></h2></Col>
					</Row>
					
					<Row>
						<Col>
							<hr/>
						</Col>
					</Row>
					<Row md={12}>
						<Col md={1}>
						</Col>
						<Col md={10}>
							<JfbSlider 
								readOnly={!edit} 
								labelSelected={riscDescription}
								onChange={handleChangeRiscDescription}
							/>
						</Col>
						<Col md={1}>
						</Col>
					</Row>
					<Row>
						<Col>
						</Col>
					</Row>
				</div>
		   
				<div className="areaBorder">
					<Row>
						<Col><h2><b>Maßnahmenkonzept & Kostenschätzung</b></h2></Col>
					</Row>
					
					<Row>
						<Col>
							<hr/>
						</Col>
					</Row>
					<Row md={12}>
						<Col md={12}>
							<ConservationPlan 
								readOnly={!edit} 
								cementryId={cementryId}
								tombstoneId={tombstoneId}
								conservations={tombstone.conservations} 
								onChange={handleUpdateConservations}
								onChangeAssignedTombstones={handleChangeAssignedTombstones}	
							 />
						</Col>
					</Row>
					<Row>
						<Col>
						</Col>
					</Row>
				</div>
			</>)}
		</Container>
	);
}