import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { isEmail } from "validator";
import { Container, Row, Col, Breadcrumb, Form as BSForm } from "react-bootstrap";

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import AuthService from "../../services/auth.service";
import ElasticSearchService from "../../services/elasticSearch.service";
import * as Constants from "../../components/constants";

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

export default function UserDetails() {
	
	let { userId } = useParams();

	const [isLoading, setLoading] = useState(true);

	const [id, setUserId] = useState(userId);
	const [isActive, setActive] = useState(false);
	const [isAdmin, setAdmin] = useState(false);
	const [enterPassword, setEnterPassword] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	
	const [cementries, setCementries] = useState([]);
	const [cementrySelection, setCementrySelection] = useState({id:"", label: Constants.CEMENTERY_ALL});
	
	const [cementrySelectedForRole, setCementrySelectedForRole] = useState("");
	const [allCementriesForAutocomplete, setAllCementriesForAutocomplete] = useState([]);
	//const [allCementries, setAllCementries] = useState([]);
	
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	const required = value => {
		if (!value) {
			return (
				<div className="alert alert-danger" role="alert">
					Pflichtfeld!
				</div>
			);
		}
	};
	
	const email = value => {
		if (!isEmail(value)) {
			return (
				<div className="alert alert-danger" role="alert">
					Das ist keine valide E-Mail.
				</div>
			);
		}
	};
	
	
	const vPasswordMatchConfirmation = (value, props, components) => {
		if (value !== components['password-confirmation'][0].value) { 
			return (
				<div className="alert alert-danger" role="alert">
					Passwort stimmt nicht mit der Passwortwiederholung überein.
				</div>
			);
		}
	};
	
	function onChangeEnterPassword(e) {
		
		setEnterPassword(e.target.checked);
	}
	
	function onChangeActiveStatus(e) {
		
		let isActive = false;
		
		if (e.target.checked) isActive = true;
		
		setActive(isActive);
	}
	
	function hasAdmin(currentCementries) {
		
		let result = currentCementries.findIndex((cementry) => cementry.name === Constants.CEMENTERY_ALL);

		if (result > -1) {
			
			let rolesForAll = [...currentCementries[result].roles];
		
			return (rolesForAll.length > 0 && rolesForAll.findIndex(role => role.name === Constants.ROLE_ADMIN) > -1);

		} else {
			return false;
		}
	}
	
	function toggleAdmin() {
		
		let currentCementries = [...cementries];
		let result = currentCementries.findIndex((cementry) => cementry.name === Constants.CEMENTERY_ALL);

		if (result > -1) {
			
			let rolesForAll = [...currentCementries[result].roles];
		
			if (rolesForAll.length > 0 && rolesForAll.findIndex(role => role.name === Constants.ROLE_ADMIN) === -1) {
				rolesForAll = [{name: Constants.ROLE_ADMIN}];
				
				currentCementries[result].roles = rolesForAll;
				setAdmin(true);
			} else {
				currentCementries.splice(result, 1);
				
				setAdmin(false);
				setCementrySelection({id:"0", label: Constants.CEMENTERY_ALL});
			}

		} else {
			currentCementries = [];
			currentCementries.push({id: "", name: Constants.CEMENTERY_ALL, roles: [{name: Constants.ROLE_ADMIN}]});
			setAdmin(true);
		}
		
		setCementries(currentCementries);
	}
	
	function onChangeAdministrator(e) {
		
		toggleAdmin();

	}
	
	function addCementry(cementry) {
		
		let currentCementries = [...cementries];
		let result = currentCementries.findIndex((cementryItem) => cementryItem.id === cementry.id);

		if (result === -1) {

			currentCementries.push({id: cementry.id, name: cementry.name, roles:[]});
		}
		
		return currentCementries;
	}
	
	function addSelectedCementry() {
		
		let currentCementries = [];
		
		if (Constants.CEMENTERY_ALL === cementrySelection.label) {
			
			if (!hasAdmin(cementries)) {
				let result = cementries.findIndex((cementry) => cementry.name === Constants.CEMENTERY_ALL);
				if (result === -1) {
					currentCementries.push({id: cementrySelection.id, name: cementrySelection.label, roles:[]});
					
					setCementries(currentCementries);
				}

				setCementrySelectedForRole(cementrySelection.label);
			}
			
		} else {
			currentCementries = addCementry({id: cementrySelection.id, name: cementrySelection.label, roles:[]});
			
			setCementries(currentCementries);
			setCementrySelectedForRole(cementrySelection.label);
		}
	}
	
	function removeCementry(cementryName) {
		
		let currentCementries = [...cementries];
		let index = currentCementries.findIndex((cementry) => cementry.name === cementryName);

		if (index > -1) {
			
			if (cementryName === cementrySelectedForRole) setCementrySelectedForRole(null);
			
			currentCementries.splice(index, 1);
			setCementries(currentCementries);
			
			setCementrySelection({id:"", label: Constants.CEMENTERY_ALL});
			
			setMessage("Alle Rollen für Friedhof " + cementryName + " wurden entfernt.");
			setSuccessful(true);
		} 
	}
	
	function removeSelectedCementry() {
		
		removeCementry(cementrySelectedForRole);
	}
	
	function onChangeCementrySelection(cementryInAutocomplete) {
		
		if (Constants.CEMENTERY_ALL === cementryInAutocomplete.label) {
			
			setCementrySelection({id:"0", label: Constants.CEMENTERY_ALL});
			
		} else {
		
			setCementrySelection(allCementriesForAutocomplete.find(cementry => cementry.id === cementryInAutocomplete.id));
		}
	}
	
	function loadRolesForCementry(cementeryName) {
		
		setCementrySelectedForRole(cementeryName);
	}
	
	function isAllSelected() {
		if (cementries.findIndex((cementry) => cementry.name === Constants.CEMENTERY_ALL) > -1) {
			return true;
		} else if (allCementriesForAutocomplete.length > 0)
			return cementries.length === allCementriesForAutocomplete.length;
		else return false;
	}

	function handleRegister(e) {
		e.preventDefault();

		setMessage("");
		setSuccessful(false);
		
		let enteredPassword = enterPassword ? password : null;
		
		if (id !== undefined) {

			AuthService.saveUser(
				id,
				username,
				enteredPassword,
				firstname,
				lastname,
				cementries,
				isActive
			).then(
				response => {
					setMessage(response.data.message);
					setSuccessful(true);
				},
				error => {
					let resMessage = "";
					
					if (error.response &&
							error.response.data &&
							error.response.data.errors) {
						
						resMessage = error.response.data.errors[0].field + " - " + error.response.data.errors[0].defaultMessage;
						
					} else {
						resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) || error.message || error.toString();
					}
					
	
					setMessage(resMessage);
					setSuccessful(false);
				}
			);
		} else {
			AuthService.register(
				username,
				enteredPassword,
				firstname,
				lastname,
				cementries,
				isActive
			).then(
				response => {
					setMessage(response.data.message);
					setSuccessful(true);
				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
					setMessage(resMessage);
					setSuccessful(false);
				}
			);
		}
	}
	
	useEffect(() => {
		if (isLoading) {
			
			if (id !== undefined) {
				AuthService.getUser(id).then(
					response => {
						setUserId(response.data.id)
						setUsername(response.data.username);
						setActive(response.data.active);
						setFirstname(response.data.firstName);
						setLastname(response.data.lastName);
						
						if (response.data.cementries.length > 0) {
							setCementrySelectedForRole(response.data.cementries[0].name);
						}
						setCementries(response.data.cementries);
						
						setAdmin(hasAdmin(response.data.cementries));
							
						setLoading(false);
					},
					error => {
						const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
		
						setMessage(resMessage);
						setSuccessful(false);
					}
				);
			} else {
				setUsername("");
				setActive(true);
				setFirstname("");
				setLastname("");
				setCementries([]);
				
				setAdmin(false);
				setEnterPassword(true);
					
				setLoading(false);
			}
			
			ElasticSearchService.loadCementriesForAutoComplete("0").then(
				response => {
					setAllCementriesForAutocomplete(response.data);
				},
				error => {
					const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
		
						setMessage(resMessage);
						setSuccessful(false);
						setLoading(false);
				}
			);
		}    
	}, [id, isLoading]);
	
	const RolesForCementry = ((props) => {
		
		let cementeryRolesIndex = cementries.findIndex((cementry) => cementry.name === cementrySelectedForRole);
		let cementeryRoles = []; 
		if (cementeryRolesIndex > -1) {
			cementeryRoles = cementries[cementeryRolesIndex].roles;
		}
		
		let managerRole = Constants.ROLES.roles.find((role) => role.name === Constants.ROLE_MANAGER);
		let isManagerRoleChecked = cementeryRoles.findIndex((currentRole) => currentRole.name === Constants.ROLE_MANAGER) > -1;
		
		function onManageRolesForCementry(e) {
		
			let currentCementries = [...cementries];
			let index = currentCementries.findIndex((cementry) => cementry.name === props.cementery);
			
			if (index > -1) {
				
				if (Constants.ROLE_MANAGER === e.target.name) {
					
					let roleIndex = currentCementries[index].roles.findIndex((role) => role.name === Constants.ROLE_MANAGER);
					if (roleIndex > -1) {
						currentCementries[index].roles = [];
					} else {
						currentCementries[index].roles = [{ name: Constants.ROLE_MANAGER }];
					}
					
				} else {
					
					let roleIndex = currentCementries[index].roles.findIndex((role) => role.name === e.target.name);
					if (roleIndex > -1) {
						currentCementries[index].roles.splice(roleIndex, 1);
					} else {
						currentCementries[index].roles = [...currentCementries[index].roles,
							{ name: e.target.name }
						];
					}
				}
				
				setCementries(currentCementries);
				
			} else {
				// FEHLER! Das darf gar nicht passieren!
			}
		}

		
		return (
			<Box sx={{ boxShadow: 3 }}>
				<Row>
					<Col><br/></Col>
				</Row>
				<Row md={12} key={managerRole.name}>
					<Col md={1}>
					</Col>
					<Col md={11}>
						<BSForm.Check label={managerRole.displayName} name={managerRole.name} onClick={onManageRolesForCementry} defaultChecked={isManagerRoleChecked} />
					</Col>
				</Row>
				{!isManagerRoleChecked && Constants.ROLES.roles.map((role) => {
					
					let exclude = role.name === Constants.ROLE_ADMIN || role.name === Constants.ROLE_MANAGER;
					let ident =  (role.name.startsWith(Constants.CEMENTERY_TOMBSTONE_PREFIX) && role.name !== Constants.CEMENTERY_TOMBSTONE_MANAGER_ROLE)
									|| (role.name.startsWith(Constants.CEMENTERY_PERSON_PREFIX) && role.name !== Constants.CEMENTERY_PERSON_MANAGER_ROLE) ? 3 : 2;
					
					if (!exclude) {
						
						let isRoleChecked = cementeryRoles.findIndex((currentRole) => currentRole.name === role.name) > -1;
					
						return (
						<Row md={12} key={role.name}>
							<Col md={ident}></Col>
							<Col md={12 - ident}>
								<BSForm.Check label={role.displayName} name={role.name} onClick={onManageRolesForCementry} defaultChecked={isRoleChecked} />
							</Col>
						</Row>
						);
					} else {
						return (<></>);
					}
				}
				
				)}
				<Row md={12}>
					<Col md={4}></Col>
					<Col  md={8} className="form-group">
						<Button variant="contained" onClick={removeSelectedCementry} color="error" >Friedhof samt Rollen Löschen</Button>
					</Col>
				</Row>
			</Box>
		)
	});
			
	return (
		<Container fluid="xxl">
			<Row>
				<Col>
					<Breadcrumb>
						<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					  	<Breadcrumb.Item href="/userManagement">Benutzerübersicht</Breadcrumb.Item>
					  	<Breadcrumb.Item active>Benutzerdetails</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col><h3>Benutzer Details</h3></Col>
			</Row>

			<Form onSubmit={handleRegister} >
				{!isLoading && (
					<div>
						<Row>
							<Col className="form-group">
								<label htmlFor="new_username"><strong>Email</strong></label>
								<Input
									type="text"
									className="form-control"
									name="new_username"
									value={username}
									onChange={e => setUsername(e.target.value)}
									validations={[required, email]}
								/>
							</Col>
						</Row>
						<Row>
							<Col className="form-group">
								<label htmlFor="firstname"><strong>Vorname</strong></label>
								<Input
									type="text"
									className="form-control"
									name="firstname"
									value={firstname}
									onChange={e => setFirstname(e.target.value)}
									validations={[required]}
								/>
							</Col>
						</Row>
						<Row>
							<Col className="form-group">
								<label htmlFor="lastname"><strong>Nachname</strong></label>
								<Input
									type="text"
									className="form-control"
									name="firstname"
									value={lastname}
									onChange={e => setLastname(e.target.value)}
									validations={[required]}
								/>
							</Col>
						</Row>
						<Row>
							<Col><br/></Col>
						</Row>
						<Row md={12}>
							<Col md={12}>
								<BSForm.Check label={ enterPassword ? "Passworteingabe ist aktiviert" : "Passworteingabe ist deaktiviert"} name="enter-password" onClick={onChangeEnterPassword} defaultChecked={enterPassword} disabled={id === undefined} />
							</Col>
						</Row>
						{enterPassword && 
						<Row>
							<Col className="form-group">
								<label htmlFor="password"><strong>Password</strong></label>
								<Input
									type="password"
									className="form-control"
									name="password"
									value={password}
									onChange={e => setPassword(e.target.value)}
									validations={[required, vPasswordMatchConfirmation]}
								/>
							</Col>
							<Col className="form-group">
								<label htmlFor="password-confirmation"><strong>Passwortwiederholung</strong></label>
								<Input
									type="password"
									className="form-control"
									name="password-confirmation"
									value={passwordConfirmation}
									onChange={e => setPasswordConfirmation(e.target.value)}
									validations={[required]}
								/>
							</Col>
						</Row>
						}
						<Row>
							<Col><br/></Col>
						</Row>
						<Row>
							<Col>
								<h4>Berechtigungen</h4>
							</Col>
						</Row>
						<Row>

							<Col md={1}>
								<Input
									type="checkbox"
									name="status-active"
									checked={isActive}
									onChange={onChangeActiveStatus}
								/>
							</Col>
							<Col>
								<label htmlFor="status-active"><strong>Benutzer darf sich anmelden.</strong> Deaktivieren Sie diese Option, wenn der Benutzer sich nicht anmelden darf.</label>
							</Col>
						</Row>
						<Row >
							<Col  md={1} >
								<input
									type="checkbox"
									name="is-admin"
									checked={isAdmin} 
									onChange={onChangeAdministrator}
								/>
							</Col>
							<Col>
								<label htmlFor="is-admin"><strong>Administrator</strong> - Wählen Sie diese Option, wenn der Nutzer alle Rechte für alle Friedhöfe bekommen soll.</label>
							</Col>
						</Row>
						<Row>
							<Col><br/></Col>
						</Row>
						{!isAdmin && (
						<Row>

								{!isAllSelected() ? (
								<div>
								<Row >
									<Col>
										<h4>Wählen Sie einen Friedhof aus und klicken Sie auf hinzufügen.</h4>
									</Col>
								</Row>
								
								<Row>
			
									<Col >
										
										<Autocomplete  
											value = {allCementriesForAutocomplete.find(cementry => cementry.id === (cementrySelection ? cementrySelection : {id: "0", label: Constants.CEMENTERY_ALL}))}
											onChange={(event, newValue) => onChangeCementrySelection(newValue)}
				      					    disableClearable = {true} 
				      						id="combo-box-cementries"
				      						options={allCementriesForAutocomplete}
				      						renderInput={(params) => {
										          return (<TextField {...params} label="Bitte wählen Sie den gewünschten Friedhof aus" />);
										    }}
				    					/>
				    					
									</Col>
								</Row>
								<Row >
									<Col className="col-auto me-auto"></Col>
									<Col className="col-auto">
										<Button variant="contained" onClick={addSelectedCementry}>Hinzufügen</Button>
									</Col>
								</Row>
								</div>) : (
								<Row >
									<Col ></Col>
									<Col className="form-group">Alle Friedhöfe wurden selektiert</Col>
								</Row>
								)}
								<Row>
									<Col><br/></Col>
								</Row>
								{cementries.length > 0 &&
								<Fragment>
								<Row md={12}>
									<Col md={1}></Col>
									<Col  md={4} className="form-group">
										<Container>
										{cementries.map((cementry) => (
											<Row md={12} key={cementry.id}>
												<Col  md={8} className="form-group">
													<BSForm.Check type="radio" label={cementry.name === Constants.CEMENTERY_ALL ? "Alle" : cementry.name} name="cementeries" onClick={() => loadRolesForCementry(cementry.name)} defaultChecked={cementry.name === cementrySelectedForRole} />
												</Col>
												<Col md={2}>
													<IconButton onClick={() => removeCementry(cementry.name)} ><DeleteIcon /></IconButton>
												</Col>
												<Col md={2}></Col>
											</Row>
										))}
										</Container>
									</Col>
									<Col md={7}>
										{cementrySelectedForRole && <RolesForCementry cementery={cementrySelectedForRole}/>}
									</Col>
								</Row>
								
								</Fragment>
								}
							
						</Row>
						)}
						<Row >
						<Col className="col-auto me-auto"></Col>
							<Col className="col-auto">
								<Tooltip title={(cementries.length === 0 && !isAdmin) ? "Bitte wählen Sie mindestens eine Rolle aus" : ""} arrow placement="right">
									<span>
										<Button type="submit" variant="contained" disabled={cementries.length === 0 && !isAdmin}><strong>Benutzer speichern</strong></Button>
									</span>
								</Tooltip>
							</Col>
						</Row>
					</div>
				)}

				{message && (
					<div className="form-group">
						<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
					</div>
				)}
			</Form>
		</Container>

	);
}