import React, { useState } from 'react'
import { Row, Col } from "react-bootstrap";
import { GoogleMap, LoadScript, Polygon, InfoWindow } from '@react-google-maps/api'
import * as Constants from "../../../components/constants";
import { personToString } from '../../../helpers/util'



export default function TombstoneMapDetailElement(props) {

	const [activeMarker, setActiveMarker] = useState(null);

	const handleActiveMarker = (marker) => {
		
		if (marker === activeMarker) {
			return;
		}
		setActiveMarker(marker);
	};



	const onLoad = polygon => {

	}

	const getCenterPosition = (cementery, tombstoneMarker) => {
		let center = cementery.coordinates.features[0].geometry.coordinates[0][0]

		try {
			let tombstoneCoordinates = tombstoneMarker[0].weltkoordinaten.features[0].geometry.coordinates[0];
			return tombstoneCoordinates[0];
		}
		catch (e) {
			return center;
		}
	}


	const getTombstoneMarker = (tombstone,id) => {
		
		if (tombstone.weltkoordinaten !== null && tombstone.weltkoordinaten.features !== null) {
			if (tombstone.weltkoordinaten.features.length > 0) {
				let tombstoneCoordinates = tombstone.weltkoordinaten.features[0].geometry.coordinates[0];

				let link = '/tombstoneDetails/' + tombstone.cementry + '/' + tombstone.id

				return (
					
					<Polygon key={tombstone.id+id}
					onMouseOver={() => { handleActiveMarker(tombstone.id) }}
					onMouseOut={() => { handleActiveMarker(null) }}
					onClick={() => { window.open(link, '_self') }}
					onLoad={onLoad}
					paths={tombstoneCoordinates}
					options={Constants.tombstoneMarkerOptions} />
				)
			}
		}
	}

	const getTombstonePolygon = tombstone => {

		if (tombstone.weltkoordinaten !== null && tombstone.weltkoordinaten.features !== null) {
			if (tombstone.weltkoordinaten.features.length > 0) {
				let tombstoneCoordinates = tombstone.weltkoordinaten.features[0].geometry.coordinates[0];
				let link = '/tombstoneDetails/' + tombstone.cementry + '/' + tombstone.id

				return (<div key={'P'+tombstone.id}><Polygon
					onMouseOver={() => { handleActiveMarker(tombstone.id) }}
					onMouseOut={() => { handleActiveMarker(null) }}
					onClick={() => { window.open(link, '_self') }}
					onLoad={onLoad}
					paths={tombstoneCoordinates}
					options={Constants.tombstoneOptions} />

					{activeMarker === tombstone.id ? (
						<InfoWindow onCloseClick={() => setActiveMarker(null)} position={tombstoneCoordinates[0]}>
							<div>
								<Row>
									<Col className="col-auto me-auto" >
										<img width={64} height={64} className="mr-3" src={tombstone.bilder && tombstone.bilder[0] && tombstone.bilder[0].tumbnailImagePath ? tombstone.bilder[0].tumbnailImagePath : ""} alt="kein Bild vorhanden" />
									</Col>
									<Col>
										<p><strong> <a href={"/tombstoneDetails/" + tombstone.cementry + "/" + tombstone.grabstein.grabsteinnummer}>Grabstein-ID: {tombstone.grabstein.grabsteinnummer}</a></strong></p>
										{tombstone.persons.map((person, id3) => (
											<div key={id3}>
												<a href={"/personDetails/" + tombstone.cementry + "/" + person.id} >{personToString(person)}</a>
											</div>
										))}
									</Col>
								</Row>
							</div>
						</InfoWindow>
					) : null}
				</div>)
			}
		}
	}

 	

	return (
		<div>
		
			{(props.cementery && props.cementery.coordinates !== null && props.cementery.coordinates.features !== undefined && props.cementery.coordinates.features !== null) ?
				<div>

					<Row>
						<Col>

							<LoadScript googleMapsApiKey={Constants.GOOGLE_API_KEY}>
								<GoogleMap
									mapContainerStyle={{ width: '100%', height: '600px' }}
									center={getCenterPosition(props.cementery, props.tombstoneMarker)}
									zoom={props.zoom}
									options={Constants.mapStyles}
									streetViewControl={false} 
									disableDefaultUI={false}>


									<Polygon
										onLoad={onLoad}
										path={props.cementery.coordinates.features[0].geometry.coordinates[0]}
										options={Constants.options}
									/>
									<Polygon
										onLoad={onLoad}
										path={props.cementery.coordinates.features[0].geometry.coordinates[1]}
										options={Constants.options}
									/>



									{props.tombstones.map(function(tombstone) {
										return getTombstonePolygon(tombstone);
									})
									}
									
									{props.tombstoneMarker && props.tombstoneMarker.map(function(tombstone,id) {
										
										return getTombstoneMarker(tombstone,id);
									})
									}
								</GoogleMap>
							</LoadScript>

						</Col>
					</Row>
				</div>
				:
				<Row>
					<Col>
						Es sind keine GEO Informationen verfügbar
					</Col>
				</Row>
			}
		</div>
	)
}







