
class StorageService {
	
	handleStorage(item){
		
		let itemValues = localStorage.getItem(item)
	
		if (itemValues === null && !itemValues) {
			
			itemValues = [];
			localStorage.setItem(item, JSON.stringify(itemValues));
			
		} else {
			
			itemValues = JSON.parse(localStorage.getItem(item));
		}
		return itemValues;
	}
	
}	
export default new StorageService();