import React, { useState, useEffect } from "react";

import { Container, Row, Col, Breadcrumb } from "react-bootstrap";

import ElasticSearchService from "../../services/elasticSearch.service";

export default function Faq() {
				
	const [questions, setQuestions] = useState([]);
	
	const [successful, setSuccessful] = useState(false);	
	const [message, setMessage] = useState("");
	
	const [loading, setLoading] = useState(false);	
	
	useEffect(() => {
		ElasticSearchService.loadChoices().then(
			response => {
				
				let data = response.data;
				
				let choices = data["Fragen und Antworten"];
								
				setQuestions(choices);
				
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
	
				setMessage(resMessage);
				setSuccessful(false);
				setLoading(false);
			}
		);
	}, []);
			
	return (
		<Container fluid="sm">
		
			<Row className="top-spacer">
				<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					  <Breadcrumb.Item active>Häufig gestellte Fragen</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			
			<Row className="text-spacer">
				<Col><h3>Häufig gestellte Fragen</h3></Col>
			</Row>
			
			{!loading && questions.map((question, index) => (
			<Row className="text-spacer" key={question.id} >
				<Col><strong>Frage: </strong> {question.value}<br/>
				<strong>Antwort: </strong> {question.comment}
				</Col>
			</Row>
			))}
			
			<Row className="text-spacer">
				<Col>Sie haben eine Frage und finden keine passende Antwort in unseren FAQs? Senden Sie uns einfach eine Nachricht.</Col> 
			</Row>

			{message && (
				<div className="form-group">
					<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
				</div>
			)}
		
		</Container>
	);
}

