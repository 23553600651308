import React, { Component } from 'react';
import { Container, Row, Col, Breadcrumb, Form, Button, ListGroup } from "react-bootstrap";
import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";
import * as Constants from "../../components/constants";
import ACService from "../../helpers/accessValidation.js"

import Autosuggest from 'react-autosuggest';
import { personToString, batOrBen, renderDate, renderDateFromObject, renderDateFromdateParts } from '../../helpers/util'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { getStringValue } from "../../helpers/util";
import FootnoteTextField from "./footnotes/footnoteTextField";
import ReferencesList from "./footnotes/referencesList";
import CopyRight from "./copyRight";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'

export default class Person extends Component {

	constructor(props) {
		super(props);

		this.state = {
			personIdValue: "",
			cementryId: "",
			cementryName: "",
			cementries: [],
			person: {
				"cementry": "",
				"personId": "",
				"grabsteinnummer": "",
				"buergerlicher_vorname": "",
				"buergerlicher_name": "",
				"hebraeischer_vorname": "",
				"hebraeischer_name": "",
				"geburtsname": "",
				"gattenname": "",
				"geschlecht": "",
				"geburtsdatum": "",
				"geburtsdatum_tag": "",
				"geburtsdatum_monat": "",
				"geburtsdatum_jahr": "",
				"herkunftsort": "",
				"herkunftsortChoice": { id: "", type: "", value: "", comment: "" },
				"sterbedatum": "",
				"sterbedatum_tag": "",
				"sterbedatum_monat": "",
				"sterbedatum_jahr": "",
				"sterbeort": "",
				"sterbeortChoice": { id: "", type: "", value: "", comment: "" },
				"wohnort": "",
				"wohnortChoice": { id: "", type: "", value: "", comment: "" },
				"beruf": "",
				"berufe": [],
				"berufChoices": [],
				"stellung": "",
				"stellungChoices": [],
				"kinder": [],
				"kinderPersons": [],
				"ehepartner": [],
				"ehepartnerPersons": [],
				"vater": "",
				"vaterPerson": {},
				"mutter": "",
				"mutterPerson": {},
				"quelle": [],
				"literatur": [],
				"offeneFragen": "",
				"allowAutoUpdate": "",
				"funeralDate": "",
				"funeral_day": "",
				"funeral_month": "",
				"funeral_year": "",

			},
			isPersonLoading: true,
			isCementryLoading: true,
			isPersonListLoading: true,
			readOnlySave: true,
			readOnly: true,
			cementryReadOnly: true,
			tombstoneIdReadOnly: false,

			changeOrCancelButton: Constants.PERSON_CHANGE_BUTTON_INIT,
			changeOrCancelValue: Constants.BUTTON_INACTIV,

			choices: {},
			choiceKeys: [],
			choice: { id: "", type: "", value: "", comment: "" },

			value: '',
			suggestions: [],
			wohnort: "",
			sterbeort: "",
			beruf: "",
			stellung: "",
			children: "",
			spouces: "",
			literatur: "",
			personList: {},
			tombstone: {},
			allReferences: [],
			biografieLicenseId: ""
		}

		this.getSuggestions = this.getSuggestions.bind(this);
		this.getSuggestionValue = this.getSuggestionValue.bind(this);
		this.savePerson = this.savePerson.bind(this);
		
		this.handleUpdateReferences = this.handleUpdateReferences.bind(this);
		this.handleFieldContentChanged = this.handleFieldContentChanged.bind(this);
		this.handleCopyrightChangeForBiography = this.handleCopyrightChangeForBiography.bind(this);
		this.removePerson = this.removePerson.bind(this);

	}
	
	removePerson(e, typ ){
		if (window.confirm("Soll die Person hier entfernt werden?")) {
			let person = this.state.person
			
			if(typ ==="vater"){
				person.vater="";
				person.vaterPerson=null;
			}
			
			if(typ ==="mutter"){
				person.mutter="";
				person.mutterPerson=null;
			}


			this.setState({
				person: person,
			});
		}
	}

	changeQuelle(e, id) {
		let person = this.state.person;
		person.quelle[id] = e.target.value;
		this.setState({
			person: person
		});
	}

	addQuellenLine(e) {
		e.preventDefault();
		let person = this.state.person;
		if (person.quelle === null) {
			person.quelle = []
		}

		person.quelle.push("");
		this.setState({
			person: person
		});
	}

	deleteQuellenLine(e, id) {
		let person = this.state.person;
		let quelle = person.quelle[id];

		if (quelle === "") {
			person.quelle.splice(id, 1);
			this.setState({
				person: person
			});
			return;
		}
		window.alert("Die Zeile ist nicht leer. Zum Löschen müssen alle Felder der Zeile leer sein.")
	}

	handleGenderSelect(e) {
		let person = this.state.person;
		person.geschlecht = e.target.value
		this.setState({
			person: person
		});
	}

	handleCementryChange(e) {
		let id = e.id;
		let person = this.state.person;
		let tombstoneIdReadOnly = false;
		person.cementry = id;

		if(id=== '0'){
			tombstoneIdReadOnly = false;
		}

		this.setState({
			person: person,
			tombstoneIdReadOnly: tombstoneIdReadOnly,
		});
	}

	removeSpouces(e, id) {
		e.preventDefault();
		if (window.confirm("Soll die Person hier entfernt werden?")) {
			let person = this.state.person
			let spouces = person.ehepartnerPersons;
			spouces.splice(id, 1);

			let ehepartner = person.ehepartner;
			ehepartner.splice(id, 1);
			this.setState({
				spouces: "",
				person: person
			});


		}
	}

	removeChildren(e, id) {
		e.preventDefault();

		if (window.confirm("Soll die Person hier entfernt werden?")) {
			let person = this.state.person
			let children = person.kinderPersons;
			children.splice(id, 1)
			this.setState({
				children: "",
				person: person
			});
		}
	}

	removeBeruf(e, id) {
		e.preventDefault();
		if (window.confirm("Soll der Beruf hier entfernt werden?")) {
			let person = this.state.person
			let berufChoices = person.berufChoices;
			berufChoices.splice(id, 1)
			this.setState({
				person: person
			});
		}
	}

	removeStellung(e, id) {
		e.preventDefault();
		if (window.confirm("Soll die Stellung in der Gemeinde hier entfernt werden?")) {
			let person = this.state.person
			let stellungChoices = person.stellungChoices;
			stellungChoices.splice(id, 1)
			this.setState({
				person: person
			});
		}
	}

	removeLiteratur(e, id) {
		e.preventDefault();
		if (window.confirm("Soll die Literaturangabe hier entfernt werden?")) {
			let person = this.state.person
			let literatur = person.literatur;
			literatur.splice(id, 1)
			this.setState({
				person: person
			});
		}
	}

	getSuggestionsForPersons(value) {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;
		const data = this.state.personList;
		if (inputLength === 0) {
			return []
		}
		else {
			let suggestionValue = data.filter(person => personToString(person).toLowerCase().search(inputValue.toLowerCase()) !== -1);
			return suggestionValue;
		}
	}

	getSuggestionValueForSinglePerson(suggestion, field) {
		let person = this.state.person;
		person[field] = suggestion;

		if (field === "mutterPerson") {
			person.mutter = personToString(suggestion);
		}

		if (field === "vaterPerson") {
			person.vater = personToString(suggestion);
		}

		this.setState({
			person: person
		});
		return personToString(suggestion);
	}

	getSuggestions(value, key) {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;
		const data = this.state.choices[key];

		if (inputLength === 0) {
			return []
		}
		else {
			let suggestionValue = data.filter(ort => ort.value.toLowerCase().search(inputValue.toLowerCase()) !== -1);
			return suggestionValue;
		}
	}


	getSuggestionValue(suggestion, field) {
		let person = this.state.person;
		person[field] = suggestion;

		this.setState({
			person: person
		});
		return suggestion.value;
	}

	getSuggestionValueFromChoices(suggestion, field) {

		let person = this.state.person;
		let choices = person[field];
		choices.push(suggestion)
		this.setState({
			person: person,
		});
		return suggestion.value;
	}

	getSuggestionValueFromLiteraturChoice(suggestion, field) {
		let person = this.state.person;
		let literaturList = person[field];
		literaturList.push({ "literaturEntry": suggestion, "literaturEntryId": suggestion.id, "page": "" })
		this.setState({
			person: person,
		});
		return suggestion.value;
	}


	getSuggestionValueFromList(suggestion, field) {
		let person = this.state.person;
		let personField = person[field];
		personField.push(suggestion)
		this.setState({
			person: person,
		});
		return personToString(suggestion);
	}

	getSuggestionValueFromListForSpouces(suggestion, field) {
		let person = this.state.person;
		let personField = person[field];
		personField.push(suggestion)
		
		let spouces = person.ehepartner;
		let sp = { id: suggestion.id, heiratsdatum_tag: "", heiratsdatum_monat: "", heiratsdatum_jahr: "" };
		spouces.push(sp);

		this.setState({
			person: person,
		});
		return personToString(suggestion);
	}

	onChangeHerkunftsOrt = (event, { newValue }) => {
		let person = this.state.person;
		person.herkunftsort = newValue;
		this.setState({
			person: person
		});
	};

	onChangeWohnort = (event, { newValue }) => {
		let person = this.state.person;
		person.wohnort = newValue;
		this.setState({
			person: person
		});
	};

	onChangeLiteratur = (event, { newValue }) => {
		this.setState({
			literatur: newValue,
		});
	};

	onChangeSterbeort = (event, { newValue }) => {
		let person = this.state.person;
		person.sterbeort = newValue;
		this.setState({
			person: person
		});
	};

	onChangeVater = (event, { newValue }) => {
		event.preventDefault();
		let person = this.state.person;
		person.vater = newValue;
		this.setState({
			person: person
		});
	};

	onChangeMutter = (event, { newValue }) => {
		let person = this.state.person;
		person.mutter = newValue;
		this.setState({
			person: person
		});
	};

	onChangeBeruf = (event, { newValue }) => {
		let person = this.state.person;
		person.beruf = newValue;
		this.setState({
			beruf: newValue,
			person: person
		});
	};

	onChangeStellung = (event, { newValue }) => {
		this.setState({
			stellung: newValue,
		});
	};

	onChangeChildren = (event, { newValue }) => {
		this.setState({
			children: newValue,
		});

	};

	onChangeSpouces = (event, { newValue }) => {
		
		this.setState({
			spouces: newValue,
		});
	};

	changeHeiratsDatum(e, id) {
	
		let name = e.target.name;
		let person = this.state.person;
		let spouce = person.ehepartnerPersons[id];

		let heiratsdatum_tag
		let heiratsdatum_monat;
		let heiratsdatum_jahr;

		if (person.ehepartner[id] != null) {
			heiratsdatum_tag = person.ehepartner[id].heiratsdatum_tag;
			heiratsdatum_monat = person.ehepartner[id].heiratsdatum_monat;
			heiratsdatum_jahr = person.ehepartner[id].heiratsdatum_jahr;
		}

		if (name === "heiratsdatum_tag") {
			heiratsdatum_tag = e.target.value;
		}
		if (name === "heiratsdatum_monat") {
			heiratsdatum_monat = e.target.value;
		}
		if (name === "heiratsdatum_jahr") {
			heiratsdatum_jahr = e.target.value;
		}


		person.ehepartner[id] = { id: spouce.id, heiratsdatum_tag: heiratsdatum_tag, heiratsdatum_monat: heiratsdatum_monat, heiratsdatum_jahr: heiratsdatum_jahr };

		this.setState({
			person: person
		});


	}

	changePage(e, id) {
		let person = this.state.person;
		let literatur = person.literatur[id];

		literatur.page = e.target.value;
		this.setState({
			person: person
		});
	}

	componentDidMount() {
		const user = AuthService.getCurrentUser();
		if (user) {
			this.setState({
				currentUser: user,
				showManagerLinks: user.roles.includes("ROLE_MANAGER"),
			});
		}
		let url = window.location.href.split('/')
		let cementryId = url[5];
		let personIdValue = url[6];
		let newFlag = "";
		if (url[7] !== null) {
			newFlag = url[7];
		}

		this.setState({
			personIdValue: personIdValue,
			cementryId: cementryId,

		});

		if (newFlag === undefined || newFlag !== "new") {

			ElasticSearchService.loadPerson(personIdValue).then(
				response => {
					let person = response.data;
					if (person.mutter) {
						let mutter = personToString(response.data.mutterPerson);
						person.mutter = mutter;
					}

					if (person.vater) {
						let vater = personToString(response.data.vaterPerson);
						person.vater = vater;
					}
					
					let currentBiografieLiceseId = "";
					if (person.rights !== undefined && person.rights.length > 0) {
						
						let license = person.rights.find(right => right.reference === "biografie");
						if (license !== undefined) currentBiografieLiceseId = license.id;
						else currentBiografieLiceseId = null;
					} 

					this.setState({
						person: person,
						allReferences: person.references,
						isPersonLoading: false,
						biografieLicenseId: currentBiografieLiceseId
					});

					if (person.cementry !== "" && person.grabsteinnummer !== "") {
						ElasticSearchService.loadTombstone(person.cementry, person.grabsteinnummer).then(
							response => {
								this.setState({
									tombstone: response.data,
									isTombstoneLoading: false,
									successfull: true,
								});

							},
							error => {
								this.setState({
									successfull: false,
									message: (error.response && error.response.data) || error.message || error.toString()
								});
							});
					}
				},
				error => {
					this.setState({
						error:
							(error.response && error.response.data) ||
							error.message ||
							error.toString()
					});
				}
			);
		}
		else {

			let person = this.state.person;
			person.id = personIdValue;
			person.cementry = cementryId
			this.setState({
				person: person,
				isPersonLoading: false,
				readOnly: false,
				readOnlySave: false,
				changeOrCancelButton: Constants.PERSON_CHANGE_BUTTON_ACTIV,
				changeOrCancelValue: Constants.BUTTON_ACTIV,
				cementryReadOnly: false,
			});

		}
		
		if(cementryId === '0'){
			this.setState({
				tombstoneIdReadOnly: false,
			});
		}
		else{
			this.setState({
				tombstoneIdReadOnly: true,
			});
		}




		ElasticSearchService.loadChoices().then(
			response => {
				this.setState({
					choices: response.data,
					choiceKeys: Object.keys(response.data)
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);

		ElasticSearchService.loadCementriesForAutoComplete(-1).then(
			response => {
				this.getCementryName(response.data);
				this.setState({
					cementries: response.data,
					isCementryLoading: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);

		ElasticSearchService.loadAllPersons().then(
			response => {
			
				this.setState({
					personList: response.data,
					isPersonListLoading: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);

		

	}

	changeField(e) {
		const value = e.target.value;
		const name = e.target.name;
		this.setState(prevState => ({
			person: { ...prevState.person, [name]: value }
		}))
	}

	changeCheckBox(e) {

		let person = this.state.person;

		if (Boolean(person.allowAutoUpdate)) {
			person.allowAutoUpdate = false;
		}
		else {
			person.allowAutoUpdate = true;
		}
		this.setState({
			person: person
		})

	}

	getCementryName(cementries) {
		let cementry = cementries.find(cementry => cementry.id === this.state.cementryId);
	
		this.setState({
			cementryName: cementry.name
		});
	}

	savePerson(e) {
		e.preventDefault();
		const toSave = this.state.person;
		

		if (toSave.cementry === undefined || toSave.cementry === null || toSave.cementry === "") {
			toSave.cementry = this.state.cementryId;
			this.setState({
				person: toSave
			});
		}

		ElasticSearchService.savePerson(toSave).then(
			response => {
				this.setState({
					message: response.data.message,
					successful: true,
					readOnly: true,
					readOnlySave: true,
					cementryReadOnly: true,

					person: toSave,
					changeOrCancelValue: Constants.BUTTON_INACTIV,
					changeOrCancelButton: Constants.PERSON_CHANGE_BUTTON_INIT,
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);


		//let url = window.location.href.split('/')
		//let newUrl = url[0] +"/"+url[1]+"/"+url[2]+"/"+url[3]+"/"+url[4]+"/"+url[5]
		//window.location.replace(newUrl);
	}

	enableChangePerson(e) {
		let value = e.target.value;
		if (value === "0") {
			this.setState({
				readOnly: false,
				readOnlySave: false,

				changeOrCancelButton: Constants.PERSON_CHANGE_BUTTON_ACTIV,
				changeOrCancelValue: Constants.BUTTON_ACTIV,

				message: "",
				successful: false,
			});
		}
		if (value === "1") {
			this.setState({
				readOnly: true,
				readOnlySave: true,

				changeOrCancelValue: Constants.BUTTON_INACTIV,
				changeOrCancelButton: Constants.PERSON_CHANGE_BUTTON_INIT,
				cementryReadOnly: true
			});
		}
	}
	
	handleUpdateReferences(list) {
		
		let person = this.state.person;
		let references = [...list];
		
		person.references = references;
		
		this.setState({
			person: person,
			allReferences: person.references
  		});
	}
	
	handleFieldContentChanged(fieldName, content) {
		
		let person = this.state.person;
		
		person[fieldName] = content;
		
  		this.setState({
			person: person
  		});
	}
	
	handleCopyrightChangeForBiography(rights) {
		
		let person = this.state.person;
		
		person.rights = rights;
			
		this.setState({
			person: person
	  	});
	}
	

	render() {
		const {
			isPersonLoading,
			isCementryLoading,
			tombstoneIdReadOnly,
			cementries,
			cementryName,
			cementryId,
			person,
			readOnlySave,
			readOnly,
			changeOrCancelValue,
			changeOrCancelButton,
			beruf,
			stellung,
			children,
			spouces,
			literatur,
			tombstone,
			suggestions,
			currentUser
		 } = this.state;

		return (
			<Container>
				<Form onSubmit={(e) => this.savePerson(e)}>
					<Row><Col>
					  <Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					  <Breadcrumb.Item >Management</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Person</Breadcrumb.Item>
					  <Breadcrumb.Item href="/search">Suche</Breadcrumb.Item>
					</Breadcrumb>
					</Col></Row>
					<Row>
						<Col>
							<h3>Personenerfassung</h3>
						</Col>
					</Row>
{!isPersonLoading && !isCementryLoading ? (
	<div>

					{ACService.accessValidation(currentUser, [Constants.AC_ADMIN_NAVIGATION, Constants.AC_MANAGER, Constants.AC_PERSON_MANAGER], cementries.find(cementery => cementery.id === cementryId).label)  && (
						<Row>
							<Col className="col-auto me-auto">
								<Button variant="Link" value={changeOrCancelValue} onClick={(e) => this.enableChangePerson(e)}>{changeOrCancelButton}</Button>
								
								{person.grabsteinnummer ?
									<Button variant="Link" href={"/management/tombstone/" + person.cementry + "/" + person.grabsteinnummer}>Grabstein bearbeiten</Button> : ""}
									<Button variant="Link" type="submit" disabled={readOnlySave} className="float-right">Speichern</Button>
							</Col>
							<Col className="col-auto">
								<Button variant="Link" href={"/personDetails/" + cementryId + "/" + person.id}>Personen Details</Button>
							</Col>
						</Row>
					)}
					


					
					{this.state.message && (
						<div className="form-group">
							<div className={this.state.successful ? "alert alert-success" : "alert alert-danger"} role="alert">
								{this.state.message}
							</div>
						</div>
					)}

							<div >
								<div className="areaBorder">
									<Row>
										<Col>
											<Autocomplete
											    disabled = {tombstoneIdReadOnly}
											    value = {cementries.find(cementery => cementery.id === cementryId)}
												onChange={(event, newValue) => { this.handleCementryChange(newValue); }}
												id="combo-box-cementries-dialog"
												options={cementries}
												disableClearable
												renderInput={(params) => <TextField {...params} label="Bitte wählen Sie einen Friedhof" />}
											/>

										</Col>
									</Row>
								</div>
								<div className="areaBorder">
									<Row>
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Personen ID</strong></Form.Text>
												<Form.Control as="input" value={person.personId ? person.personId : ""} name="personId" readOnly onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong><a href={"/tombstoneDetails/" + person.cementry + "/" + person.grabsteinnummer}>Grabstein-ID</a></strong></Form.Text>
												<Form.Control as="input" name="grabsteinnummer" value={person.grabsteinnummer ? person.grabsteinnummer : ""} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
									</Row>
								</div>
								<div className="areaBorder">

									<Row>
										<Col >
											<Form.Group as={Col} >
												<strong>Bürgerlicher Name: {person.buergerlicher_vorname ? person.buergerlicher_vorname : ""} {person.buergerlicher_name ? person.buergerlicher_name : ""}</strong>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Vorname</strong></Form.Text>
												<Form.Control as="input" value={person.buergerlicher_vorname ? person.buergerlicher_vorname : ""} name="buergerlicher_vorname" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Familienname</strong></Form.Text>
												<Form.Control as="input" value={person.buergerlicher_name ? person.buergerlicher_name : ""} name="buergerlicher_name" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col >
											<Form.Group as={Col} >
												<strong>Hebräischer Name: {person.hebraeischer_vorname ? person.hebraeischer_vorname : ""}{person.hebraeischer_vorname && person.hebraeischer_name ? batOrBen(person.geschlecht) : ""}{person.hebraeischer_name ? person.hebraeischer_name : ""}</strong>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>VornameHE</strong></Form.Text>
												<Form.Control as="input" value={person.hebraeischer_vorname ? person.hebraeischer_vorname : ""} name="hebraeischer_vorname" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Vatersname</strong></Form.Text>
												<Form.Control as="input" value={person.hebraeischer_name ? person.hebraeischer_name : ""} name="hebraeischer_name" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Geburtsname</strong></Form.Text>
												<Form.Control as="input" value={person.geburtsname ? person.geburtsname : ""} name="geburtsname" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Gattenname</strong></Form.Text>
												<Form.Control as="input" value={person.gattenname ? person.gattenname : ""} name="gattenname" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
									</Row>
								</div>
								<div className="areaBorder">
									<Row>
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Geschlecht</strong></Form.Text>
												<Form.Control disabled={readOnly} as="select" custom onChange={(e) => !readOnly && this.handleGenderSelect(e)} value={person.geschlecht ? person.geschlecht : ""}>
													<option>Auswahl</option>
													<option>männlich</option>
													<option>weiblich</option>
												</Form.Control>
											</Form.Group>
										</Col>
									</Row>
								</div>
								<div className="areaBorder">
									<Row>
										<Col xs={6} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Geburtsdatum: {renderDateFromdateParts(person.geburtsdatum_jahr, person.geburtsdatum_monat, person.geburtsdatum_tag)}</strong></Form.Text>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col xs={2} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Tag</strong></Form.Text>
												<Form.Control as="input" placeholder="Tag" value={person.geburtsdatum_tag ? person.geburtsdatum_tag : ""} name="geburtsdatum_tag" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col xs={2} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Monat</strong></Form.Text>
												<Form.Control as="input" placeholder="Monat" value={person.geburtsdatum_monat ? person.geburtsdatum_monat : ""} name="geburtsdatum_monat" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col xs={2} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Jahr</strong></Form.Text>
												<Form.Control as="input" placeholder="Jahr" value={person.geburtsdatum_jahr ? person.geburtsdatum_jahr : ""} name="geburtsdatum_jahr" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col xs={6} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Geburtsort</strong></Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Ort") }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [] }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValue(suggestion, "herkunftsortChoice")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
														inputProps={{ placeholder: 'Geburtsort auswählen', value: person.herkunftsort ? person.herkunftsort : "", onChange: this.onChangeHerkunftsOrt }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="herkunftsort" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>
								</div>
								<div className="areaBorder">
									<Row>
										<Col xs={6} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Sterbedatum: {renderDateFromdateParts(person.sterbedatum_jahr, person.sterbedatum_monat, person.sterbedatum_tag)}</strong></Form.Text>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col xs={2} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Tag</strong></Form.Text>
												<Form.Control as="input" placeholder="Tag" value={person.sterbedatum_tag ? person.sterbedatum_tag : ""} name="sterbedatum_tag" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col xs={2} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Monat</strong></Form.Text>
												<Form.Control as="input" placeholder="Monat" value={person.sterbedatum_monat ? person.sterbedatum_monat : ""} name="sterbedatum_monat" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col xs={2} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Jahr</strong></Form.Text>
												<Form.Control as="input" placeholder="Jahr" value={person.sterbedatum_jahr ? person.sterbedatum_jahr : ""} name="sterbedatum_jahr" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col xs={6} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Sterbeort</strong></Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Ort") }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [] }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValue(suggestion, "sterbeortChoice")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
														inputProps={{ placeholder: 'Sterbeort auswählen', value: person.sterbeort ? person.sterbeort : "", onChange: this.onChangeSterbeort }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="sterbeort" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>
								</div>
								<div className="areaBorder">
									<Row>
										<Col xs={6} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Begräbnisdatum: {person.funeralDate ? renderDate(person.funeralDate) : ""}</strong></Form.Text>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col xs={2} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Tag</strong></Form.Text>
												<Form.Control as="input" placeholder="Tag" value={person.funeral_day ? person.funeral_day : ""} name="funeral_day" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col xs={2} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Monat</strong></Form.Text>
												<Form.Control as="input" placeholder="Monat" value={person.funeral_month ? person.funeral_month : ""} name="funeral_month" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col xs={2} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Jahr</strong></Form.Text>
												<Form.Control as="input" placeholder="Jahr" value={person.funeral_year ? person.funeral_year : ""} name="funeral_year" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Wohnort</strong></Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Ort") }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [] }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValue(suggestion, "wohnortChoice")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
														inputProps={{ placeholder: 'Wohnort auswählen', value: person.wohnort ? person.wohnort : "", onChange: this.onChangeWohnort }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="wohnort" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>
								</div>


								<div className="areaBorder">
									<Row>
										<Col xs={6}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Ausgeübte Berufe</strong></Form.Text>
												<ListGroup variant="flush">
													{person.berufChoices ? person.berufChoices.map((beruf, id) => (
														<ListGroup.Item key={id} action onClick={(e) => !readOnly && this.removeBeruf(e, id)}>{beruf.value}</ListGroup.Item>
													)) : ""}
												</ListGroup>
											</Form.Group>
										</Col >
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Berufe</Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Beruf") }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [], beruf: "" }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValueFromChoices(suggestion, "berufChoices")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
														inputProps={{ placeholder: 'Beruf auswählen', value: beruf, onChange: this.onChangeBeruf }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="beruf" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>
								</div>
								<div className="areaBorder">
									<Row>
										<Col xs={6}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Stellung in der Gemeinde</strong></Form.Text>
												<ListGroup variant="flush">
													{person.stellungChoices ? person.stellungChoices.map((stellung, id) => (
														<ListGroup.Item key={id} action onClick={(e) => !readOnly && this.removeStellung(e, id)}>{stellung.value}</ListGroup.Item>
													)) : ""}
												</ListGroup>
											</Form.Group>
										</Col >
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Stellungen in der Gemeinde</Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Stellung") }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [], stellung: "" }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValueFromChoices(suggestion, "stellungChoices")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
														inputProps={{ placeholder: 'Stellung in der Gemeinde auswählen', value: stellung, onChange: this.onChangeStellung }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="stellung" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>
								</div>
								<div className="areaBorder">
									<Row>
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Vater</strong></Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestionsForPersons(value) }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [] }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValueForSinglePerson(suggestion, "vaterPerson")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{personToString(suggestion)}</ListGroup.Item>}
														inputProps={{ placeholder: 'Vater auswählen', value: person.vater ? person.vater : "", onChange: this.onChangeVater }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="vater" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
										<Col xs={1}>
										<Form.Group as={Col} >
											<br/>
											<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.removePerson(e,"vater")}><FontAwesomeIcon icon={faTrash} /></Button>
										</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Mutter</strong></Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestionsForPersons(value) }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [] }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValueForSinglePerson(suggestion, "mutterPerson")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{personToString(suggestion)}</ListGroup.Item>}
														inputProps={{ placeholder: 'Mutter auswählen', value: person.mutter ? person.mutter : "", onChange: this.onChangeMutter }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="mutter" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
										<Col xs={1}>
										<Form.Group as={Col} >
											<br/>
											<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.removePerson(e,"mutter")}><FontAwesomeIcon icon={faTrash} /></Button>
										</Form.Group>
										</Col>
									</Row>
								</div>
								<div className="areaBorder">
									<Row>
										<Col xs={6}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Ehepartner</strong></Form.Text>
												<ListGroup variant="flush">
													{person.ehepartnerPersons ? person.ehepartnerPersons.map((spouce, id) => (
														<ListGroup.Item key={id} as={Col} >
															<div onClick={(e) => !readOnly && this.removeSpouces(e, id)}>{personToString(spouce)}</div>
															<Row>
																<Col><strong>Hochzeitsdatum: {

																	person.ehepartner[id] ? renderDateFromObject(person.ehepartner[id]) : ""}</strong></Col>

															</Row>
															<Row>
																<Col>
																	<Form.Control as="input" placeholder="Tag" value={person.ehepartner[id] && person.ehepartner[id].heiratsdatum_tag ? person.ehepartner[id].heiratsdatum_tag : ""} name="heiratsdatum_tag" readOnly={readOnly} onChange={(e) => this.changeHeiratsDatum(e, id)} />
																</Col>
																<Col>
																	<Form.Control as="input" placeholder="Monat" value={person.ehepartner[id] && person.ehepartner[id].heiratsdatum_monat ? person.ehepartner[id].heiratsdatum_monat : ""} name="heiratsdatum_monat" readOnly={readOnly} onChange={(e) => this.changeHeiratsDatum(e, id)} />
																</Col>
																<Col>
																	<Form.Control as="input" placeholder="Jahr" value={person.ehepartner[id] && person.ehepartner[id].heiratsdatum_jahr ? person.ehepartner[id].heiratsdatum_jahr : ""} name="heiratsdatum_jahr" readOnly={readOnly} onChange={(e) => this.changeHeiratsDatum(e, id)} />
																</Col>
															</Row>
														</ListGroup.Item>

													)) : ""}
												</ListGroup>
											</Form.Group>
										</Col >
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Personenauswahl</Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestionsForPersons(value) }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [], spouces: "" }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValueFromListForSpouces(suggestion, "ehepartnerPersons")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{personToString(suggestion)}</ListGroup.Item>}
														inputProps={{ placeholder: 'Ehepartner auswählen', value: spouces, onChange: this.onChangeSpouces }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="spouces" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>
								</div>
								<div className="areaBorder">

									<Row>
										<Col xs={6}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Kinder</strong></Form.Text>
												<ListGroup variant="flush">
													{person.kinderPersons ? person.kinderPersons.map((kind, id) => (
														<ListGroup.Item key={id} action onClick={(e) => this.removeChildren(e, id)}>{personToString(kind)}</ListGroup.Item>
													)) : ""}
												</ListGroup>
											</Form.Group>
										</Col >
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Personenauswahl</Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestionsForPersons(value) }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [], children: "" }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValueFromList(suggestion, "kinderPersons")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{personToString(suggestion)}</ListGroup.Item>}
														inputProps={{ placeholder: 'Kinder auswählen', value: children, onChange: this.onChangeChildren }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="children" {...inputProps} />}

													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>
								</div>
								
								<div className="areaBorder top-spacer">
									<Row>
										<Col>
										<Form.Group as={Col} >
											<Form.Text className="text-muted"><strong>Biografie</strong></Form.Text>
										</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col>
											<FootnoteTextField 
													globalReferences={person.references} 
													footnoteLetter="P" 
													fieldName="biografie" 
													onUpdateReferences = {this.handleUpdateReferences}
													
													disabled={readOnly} 
													fullWidth 
													label=" " 
													name="bio-with-footnotes"
													defaultValue={getStringValue(person.biografie, "")} 
													onContentChanged={(fieldName, content) => this.handleFieldContentChanged(fieldName, content)}
													multiline
													rows={10} 
												/>
					        			</Col>
									</Row>
									<CopyRight 
										readOnly={readOnly} 
										fieldName="biografie"
										rights={person.rights}
										onChange={(rights) => this.handleCopyrightChangeForBiography(rights)} 
										/>
					

								</div>
								
								<div className="areaBorder top-spacer">
									<Row>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Quelle</strong></Form.Text>
											</Form.Group>
										</Col>
									</Row>
									{person.quelle ? person.quelle.map((quelle, id) => (
										<div key={id}>
											<Row>
												<Col>
													<Form.Group as={Col} >
														<Form.Control as="input" name="quellenZeile" value={quelle ? quelle : ""} readOnly={readOnly} onChange={(e) => this.changeQuelle(e, id)} />
													</Form.Group>
												</Col>
												<Col xs={1}>
													<Form.Group as={Col} >
														<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.deleteQuellenLine(e, id)}>Löschen</Button>
													</Form.Group>
												</Col>
											</Row>
										</div>)) : ""}
									<Row>
										<Col>
											<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.addQuellenLine(e)}>Neue Zeile hinzufügen</Button>
										</Col>
									</Row>
									<Row>
										{tombstone.quelle && tombstone.quelle.length > 0 ? tombstone.quelle.map((quelle1, id) => (


											<Col key={id}>
												<Form.Group as={Col} >
													<Form.Control as="input" value={quelle1 ? quelle1 : ""} readOnly={true} />
												</Form.Group>
											</Col>


										)) : ""}
									</Row>
								</div>
								<div className="areaBorder">
									<Row>
										<Col xs={6} >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Literatur</strong></Form.Text>
												<ListGroup variant="flush">
													{person.literatur ? person.literatur.map((entry, id) => (
														<ListGroup.Item key={id} >
															<div onClick={(e) => this.removeLiteratur(e, id)}>{entry.literaturEntry.value}</div>
															<Form.Control as="input" placeholder="Seite" value={entry.page ? entry.page : ""} name="literatur" readOnly={readOnly} onChange={(e) => this.changePage(e, id)} />
														</ListGroup.Item>

													)) : ""}
												</ListGroup>
											</Form.Group>
										</Col >
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Literaturauswahl</Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Literatur") }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [], literatur: "" }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValueFromLiteraturChoice(suggestion, "literatur")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
														inputProps={{ placeholder: 'Literaturangabe auswählen', value: literatur, onChange: this.onChangeLiteratur }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="literatur" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>

									<Row>
										{tombstone.literaturList && tombstone.literaturList.length > 0 ? tombstone.literaturList.map((literatur, id) => (


											<Col key={id}>
												<Form.Group as={Col} >
													<Form.Control as="input" value={literatur ? literatur : ""} readOnly={true} />
												</Form.Group>
											</Col>


										)) : ""}
									</Row>
								</div>
								<div className="areaBorder">
									<Row>
										<Col>
											<FootnoteTextField 
													globalReferences={person.references} 
													footnoteLetter="P" 
													fieldName="offeneFragen" 
													onUpdateReferences = {this.handleUpdateReferences}
													
													disabled={readOnly} 
													fullWidth 
													label="Offene Fragen" 
													name="open-questions-with-footnotes"
													defaultValue={getStringValue(person.offeneFragen, "")} 
													onContentChanged={(fieldName, content) => this.handleFieldContentChanged(fieldName, content)}
													multiline
													rows={5} 
												/>
										</Col>
									</Row>
								</div>
								<div className="areaBorder">

									<Row>
										<Col>
											{this.state.allReferences !== undefined && this.state.allReferences.length > 0 && 
												<ReferencesList 
													title="Einzelnachweise" 
													references={person.references}
													onUpdateReferences={this.handleUpdateReferences} 
													isReadOnly={readOnly}						 
												/>}
										</Col>
									</Row>
								</div>
								<Row>
									<Col>
										<Form.Group as={Col} controlId="allowAutoUpdate">
											<Form.Check type="checkbox" checked={Boolean(person.allowAutoUpdate) ? true : false} label="Automatische Updates erlauben." name="allowAutoUpdate" disabled={readOnly} onChange={(e) => this.changeCheckBox(e)} />
										</Form.Group>
									</Col>
								</Row>

								<Row><Col></Col></Row>
								<Row><Col></Col></Row>
								<Row><Col></Col></Row>
								<Row id="footer"><Col></Col></Row>

							</div>
							{ACService.accessValidation(currentUser, [Constants.AC_ADMIN_NAVIGATION, Constants.AC_MANAGER, Constants.AC_PERSON_MANAGER], cementries.find(cementery => cementery.id === cementryId).label)  && (
						<Row>
							<Col className="col-auto me-auto">
								<Button variant="Link" value={changeOrCancelValue} onClick={(e) => this.enableChangePerson(e)}>{changeOrCancelButton}</Button>
								
								{person.grabsteinnummer ?
									<Button variant="Link" href={"/management/tombstone/" + person.cementry + "/" + person.grabsteinnummer}>Grabstein bearbeiten</Button> : ""}
									<Button variant="Link" type="submit" disabled={readOnlySave} className="float-right">Speichern</Button>
							</Col>
							<Col className="col-auto">
								<Button variant="Link" href={"/personDetails/" + cementryId + "/" + person.id}>Personen Details</Button>
							</Col>
						</Row>
					)}
							{this.state.message && (
						<div className="form-group">
							<div className={this.state.successful ? "alert alert-success" : "alert alert-danger"} role="alert">
								{this.state.message}
							</div>
						</div>
					)}
						</div>
					) : (
						<Row>
							<Col>Loading...</Col>
						</Row>
					)}
				</Form>
			</Container>
		)
	}
}

