import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Breadcrumb, Button} from "react-bootstrap";
import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";
import * as Constants from "../../components/constants";
import { personToString, renderDateFromdateParts, personIds, personName } from '../../helpers/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser} from '@fortawesome/free-solid-svg-icons';
import FootnoteLabel from "../management/footnotes/footnoteLabel";
import ReferencesList from "../management/footnotes/referencesList";
import ACService from "../../helpers/accessValidation.js"

export default class PersonDetails extends Component {
	
	constructor(props) {
		super(props);
	
		 this.state = {
			personIdValue: "",
			cementryId: "",
			cementryName: "",
			cementries: [],
			person: {},
			isPersonLoading: true,
			isCementryLoading: true,
			isPersonListLoading: true,
			readOnlySave: true,
			readOnly: true,
			
			changeOrCancelButton: Constants.PERSON_CHANGE_BUTTON_INIT,
			changeOrCancelValue: Constants.BUTTON_INACTIV,
			
			choices: {},
			choiceKeys: [],
			choice: { id: "", type: "", value:"", comment: ""},
			
			value: '',
      		suggestions: [],
			wohnort: "",
			sterbeort: "",
			beruf: "",
			stellung: "",
			children: "",
			spouces: "",
			literatur: "",			
			personList: {},
			currentUser:""
		}
		
	
	
	}

	componentDidMount() {
		const user = AuthService.getCurrentUser();
		
		if (user) {
			this.setState({
				currentUser: user
			});
		}
		let url = window.location.href.split('/')
		let cementryId = url[4];
		let personeIdValue = url[5];
		
		this.setState( {
			personIdValue: personeIdValue,
			cementryId: cementryId,
		});
		
		ElasticSearchService.loadPerson(personeIdValue).then(
			response => {	
				let person = response.data;
				if(person.mutter){
					let mutter = personToString(response.data.mutterPerson);
					person.mutter = mutter;
				}
				
				if(person.vater){
					let vater = personToString(response.data.vaterPerson);
					person.vater = vater;
					
				}
							
				this.setState({
					person:  person,
					isPersonLoading: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		).then(
		
		
		
		ElasticSearchService.loadCementries().then(
			response => {	
				this.getCementryName(response.data);
				this.setState({
					cementries:  response.data,
					isCementryLoading: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		));
		
		
		
	}
	
	
	getCementryName(cementries){
		let cementry = cementries.find(cementry => cementry.id === this.state.cementryId);	
		this.setState( {
			cementryName: cementry.name
		});	
	}
	
	
	render() {
		const { 
			currentUser,
			isPersonLoading, 
			isCementryLoading,  
			cementryId,
			cementryName, 
			person
			} = this.state;		

		return (
			
			<Container>
				<Row className="top-spacer">
				<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					  <Breadcrumb.Item >Personen Details</Breadcrumb.Item>
					  <Breadcrumb.Item href={'/cemetery/'+cementryId}>{cementryName}</Breadcrumb.Item>					 
					  <Breadcrumb.Item active>Personen ID: {person.personId}</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col className="col-auto me-auto">
					<a href="/tombstones">Grabsteinliste</a>
				</Col>
				<Col className="col-auto">
					{(ACService.accessValidation(currentUser, Constants.AC_ADMIN_NAVIGATION) || ACService.accessValidation(currentUser, Constants.AC_MANAGER_NAVIGATION)) && (
						<a href={"/management/person/" +cementryId +"/"+ person.id} className="btn badge-flat" >Person bearbeiten</a>
					)}
				</Col>
			</Row>
				
				<Row>
					<Col>
						<h3>Personen Details</h3>
					</Col>
				</Row>
				

				{!isPersonLoading && !isCementryLoading ? (
					<div className="p-4">
					<Row>
					<Col>
						<strong>Grabstein-ID: {person.grabsteinnummer} | Personen-ID: {person.personId} </strong><br/>
						
					</Col>
				</Row>
				<Row>
					<Col >
						<h2>{personName(person)}</h2>
					</Col>
				</Row>
				<Row><Col></Col></Row>
					<Row>
						<Col><strong>Friedhof:</strong></Col>
						<Col>{cementryName}</Col>
					</Row>
					
					<Row  className="border-top">
						<Col><strong>Grabstein-ID:</strong></Col>
						{person.grabsteinnummer ? (
						<Col><a href={"/tombstoneDetails/"+ person.cementry+ "/" + person.grabsteinnummer}>{person.grabsteinnummer}</a></Col>
						):<Col>-</Col>}
					</Row>
					{person.buergerlicher_vorname ? (
						<Row  className="border-top">
						<Col><strong>Vorname:</strong></Col>
						<Col>{person.buergerlicher_vorname}</Col>
					
					</Row>
					):""}
					{person.buergerlicher_name ? (
						<Row className="border-top">
						<Col><strong>Familienname:</strong></Col>
						<Col>{person.buergerlicher_name}</Col>
					</Row>
					):""}
					{person.hebraeischer_vorname ? (
						<Row className="border-top">
						<Col><strong>Vorname Hebräisch:</strong></Col>
						<Col>{person.hebraeischer_vorname}</Col>
					</Row>
					):""}
					{person.hebraeischer_name ? (
						<Row className="border-top">
						<Col><strong>Vatersname:</strong></Col>
						<Col>{person.hebraeischer_name}</Col>
					</Row>
					):""}
					{person.geburtsname ? (
						<Row className="border-top">
						<Col><strong>Geburtsname:</strong></Col>
						<Col>{person.geburtsname}</Col>
					</Row>
					):""}
					{person.gattenname ? (
						<Row className="border-top">
						<Col><strong>Gattenname:</strong></Col>
						<Col>{person.gattenname}</Col>
					</Row>
					):""}
					{person.geburtsdatum_jahr || person.geburtsdatum_monat || person.geburtsdatum_tag ? (
						<Row className="border-top">
						<Col><strong>Geburtsdatum:</strong></Col>
						<Col>{renderDateFromdateParts(person.geburtsdatum_jahr,person.geburtsdatum_monat, person.geburtsdatum_tag)}</Col>
					</Row>
					):""}
					
					{person.herkunftsort ? (
						<Row className="border-top">
						<Col><strong>Geburtsort:</strong></Col>
						<Col>{person.herkunftsort}</Col>
					</Row>
					):""}
					{person.sterbedatum_jahr || person.sterbedatum_monat || person.sterbedatum_tag ? (
						<Row className="border-top">
						<Col><strong>Sterbedatum:</strong></Col>
						<Col>{renderDateFromdateParts(person.sterbedatum_jahr,person.sterbedatum_monat, person.sterbedatum_tag)}</Col>
					</Row>
					):""}
					{person.sterbeort ? (
						<Row className="border-top">
						<Col><strong>Sterbeort:</strong></Col>
						<Col>{person.sterbeort}</Col>
					</Row>
					):""}
					{person.funeral_year || person.funeral_month || person.funeral_day ? (
						<Row className="border-top">
						<Col><strong>Begräbnisdatum:</strong></Col>
						<Col>{renderDateFromdateParts(person.funeral_year,person.funeral_month, person.funeral_day)}</Col>
					</Row>
					):""}
					{person.wohnort ? (
						<Row className="border-top">
						<Col><strong>Wohnort:</strong></Col>
						<Col>{person.wohnort}</Col>
					</Row>
					):""}
					{person.berufChoices && person.berufChoices.length>0 ? (
						<Row className="border-top">
						<Col><strong>Ausgeübte Berufe:</strong></Col>
						<Col>
							{person.berufChoices ? person.berufChoices.map((beruf, id) =>(<div key={id}>{beruf.value}</div>)):""}
						</Col>
					</Row>
					):""}
					{person.stellungChoices && person.stellungChoices.length>0 ? (
						<Row className="border-top">
						<Col><strong>Stellungen in der Gemeinde:</strong></Col>
						<Col>
							{person.stellungChoices ? person.stellungChoices.map((stellung, id) =>(<div key={id}>{stellung.value}</div>)):""}
						</Col>
					</Row>
					):""}
					{person.vater ? (
						<Row className="border-top">
						<Col><strong>Vater:</strong></Col>
						<Col>
							{person.vater}
							<Button variant="Link" href={"/personDetails/"+person.vaterPerson.cementry+"/"+person.vaterPerson.id}><FontAwesomeIcon icon={faUser} /></Button>
							{person.vaterPerson.grabsteinnummer?
								<a href={"/tombstoneDetails/"+person.vaterPerson.cementry+"/"+person.vaterPerson.grabsteinnummer}>
									<img alt="" src="/home.png" width="17" height="20" />
								</a>
							:""
							}

						</Col>
					</Row>
					):""}
					{person.mutter ? (
						<Row className="border-top">
						<Col><strong>Mutter:</strong></Col>
						<Col>
						{person.mutter}
						<Button variant="Link" href={"/personDetails/"+person.mutterPerson.cementry+"/"+person.mutterPerson.id}><FontAwesomeIcon icon={faUser} /></Button>
						{person.mutterPerson.grabsteinnummer?
								<a href={"/tombstoneDetails/"+person.mutterPerson.cementry+"/"+person.mutterPerson.grabsteinnummer}>
									<img alt="" src="/home.png" width="17" height="20" />
								</a>
							:""
							}

						</Col>
					</Row>
					):""}
					{person.ehepartnerPersons&& person.ehepartnerPersons.length>0? (
						<Row className="border-top">
						<Col><strong>Ehepartner:</strong></Col>
						<Col>
							{person.ehepartnerPersons ? person.ehepartnerPersons.map((spouce, id) =>(<div key={id}>
								{personToString(spouce)}
								<Button variant="Link" href={"/personDetails/"+spouce.cementry+"/"+spouce.id}><FontAwesomeIcon icon={faUser} /></Button>
								{spouce.grabsteinnummer?
								<a href={"/tombstoneDetails/"+spouce.cementry+"/"+spouce.grabsteinnummer}>
									<img alt="" src="/home.png" width="17" height="20" />
								</a>
							:""
							}
							</div>)):""}
						</Col>
					</Row>
					):""}
					{person.kinderPersons && person.kinderPersons.length>0? (
						<Row className="border-top">
						<Col><strong>Kinder:</strong></Col>
						<Col>
							{person.kinderPersons ? person.kinderPersons.map((child, id) =>(<div key={id}>
								{personToString(child)}
								<Button variant="Link" href={"/personDetails/"+child.cementry+"/"+child.id}><FontAwesomeIcon icon={faUser} /></Button>
								{child.grabsteinnummer?
								<a href={"/tombstoneDetails/"+child.cementry+"/"+child.grabsteinnummer}>
									<img alt="" src="/home.png" width="17" height="20" />
								</a>
							:""
							}
							</div>)):""}
						</Col>
					</Row>
					):""}
					{person.biografie ? (
						<div>
						<Row className="border-top">
						<Col><strong>Zur Biografie:</strong></Col>
						<Col>
							<FootnoteLabel 
								globalReferences={person.references} 
								defaultValue={person.biografie} 
							/>
						</Col>
						</Row>
						<Row>
					<Col></Col>
					
					<Col>

						    	{person.rights? person.rights.map((entry, id)=>( entry.reference === "biografie"?<div key={id}>{entry.rightChoices.map((right)=>(<div key={entry.id}><strong>Autor:</strong>{right.value}{right.comment?" | "+right.comment:""}</div>))}</div>:"" )):""}
				    </Col>
					</Row>
					</div>
					):""}
					
					{person.quelle && person.quelle.length>0 ? (
						<Row className="border-top">
						<Col><strong>Quellenangaben:</strong></Col>
						<Col>
							{person.quelle ? person.quelle.map((entry, id) =>(<div key={id}>{entry}</div>)):""}
						</Col>
					</Row>
					):""}
					{person.literatur && person.literatur.length>0 ? (
						<Row className="border-top">
						<Col><strong>Literatur:</strong></Col>
						<Col>
							{person.literatur ? person.literatur.map((entry, id) =>(<div key={id}>
						
							{entry.page?entry.literaturEntry.value.substring(0,entry.literaturEntry.value.length-1 ) + entry.page:entry.literaturEntry.value}</div>)):""}
						</Col>
					</Row>
					):""}
					{person.references && person.references.length>0 ? (
						<Fragment>
						
							<Row className="border-top">
								<Col><strong>Einzelnachweise:</strong></Col>
								<Col></Col>
							</Row>
							<Row>
								<Col>
									<ReferencesList 
										references={person.references}					 
									/>
								</Col>
							</Row>
						</Fragment>
					):""}
					<Row> <Col><hr/></Col></Row>
					<Row>
						<Col className="col-auto me-auto">
							<a href="/tombstones">Grabsteinliste</a>
						</Col>
						<Col className="col-auto">
							{(ACService.accessValidation(currentUser, Constants.AC_ADMIN_NAVIGATION) || ACService.accessValidation(currentUser, Constants.AC_MANAGER_NAVIGATION)) && (
								<a href={"/management/person/" +cementryId +"/"+ person.id} className="btn badge-flat" >Person bearbeiten</a>
							)}
						</Col>
					</Row>

					</div>
				):( 
          		<Row>
					<Col>Loading...</Col>
		  		</Row>
        	)}
		
			</Container>
		)
	}
}