import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import { Container, Row, Col, Button, Breadcrumb, Form as BSForm } from "react-bootstrap";

import ElasticSearchService from "../../services/elasticSearch.service";

import { getStringValue } from '../../helpers/util';

const new_cementry_template = {"id": "", "abbreviation": "", "monumentNumber": "", "link": "", "name": "", "district": "", "localCommunity": "", "parcel": "",  "owner": "",
	"ownerAddress": {"city": "",	"postCode": "",	"street": "", "houseNumber": ""  },  
	"coordinates": {"utm": "" }, "description": "", "published": ""	
};

export default function CementryDetails() {
	
	const navigate = useNavigate();
	
	let { cementryId } = useParams();

	const [isLoading, setLoading] = useState(true);
	const [id, setId] = useState(cementryId);
	const [abbreviation, setAbbreviation] = useState("");
	const [monumentNumber, setMonumentNumber] = useState("");
	const [link, setLink] = useState("");
	const [gov, setGov] = useState("");
	const [name, setName] = useState("");
	const [district, setDistrict] = useState("");
	const [localCommunity, setLocalCommunity] = useState("");
	const [parcel, setParcel] = useState("");
	const [owner, setOwner] = useState("");
	const [ownerAddress, setOwnerAddress] = useState({"city": "",	"postCode": "",	"street": "", "houseNumber": "" });
	const [coordinates, setCoordinates] = useState({"utm": "" },);
	const [description, setDescription] = useState("");
	
	const [occupancy, setOccupancy] = useState("");
	const [numberOfTombstones, setNumberOfTombstones] = useState("");
	
	
	const [published, setPublished] = useState("");
	const [descriptionEntered, setDescriptionEntered] = useState(false);
	
	const [errors] = useState(null);
	const [isSuccessful, setSuccessful] = useState(false);
	const [message, setMessage] = useState(false);
	
	const required = value => {
		if (!value) {
			return (
				<div className="alert alert-danger" role="alert">
					Pflichtfeld!
				</div>
			);
		}
	};
	
	function hasCementeryId() {
		
		return id !== undefined;
	}
	
	useEffect(() => {
		if (isLoading) {
			if (hasCementeryId()) {
				ElasticSearchService.getCementry(id).then(
					response => {
						
						setId(id);
						setAbbreviation(getStringValue(response.data.abbreviation, ""));
						setMonumentNumber(getStringValue(response.data.monumentNumber, ""));
						setLink(getStringValue(response.data.link, ""));
						setName(getStringValue(response.data.name, ""));
						setDistrict(getStringValue(response.data.district, ""));
						setLocalCommunity(getStringValue(response.data.localCommunity, ""));
						setParcel(getStringValue(response.data.parcel, ""));
						setOwner(getStringValue(response.data.owner, ""));
						setGov(getStringValue(response.data.gov, ""));
						setOccupancy(getStringValue(response.data.occupancy, ""));
						setNumberOfTombstones(getStringValue(response.data.numberOfTombstones, ""));
					
						
						let ownerAddress = response.data.ownerAddress !== null ? {
							city: getStringValue(response.data.ownerAddress.city, ""),
							postCode: getStringValue(response.data.ownerAddress.postCode, ""),
							street: getStringValue(response.data.ownerAddress.street, ""),
							houseNumber: getStringValue(response.data.ownerAddress.houseNumber, "")
						} : {
							city: "",
							postCode: "",
							street: "",
							houseNumber: ""
						};
						setOwnerAddress(ownerAddress);
						
						setCoordinates(response.data.coordinates!==null?response.data.coordinates:{"coordinates": "" });
						setDescription(getStringValue(response.data.description, ""));
						setPublished(response.data.published);
						
						setDescriptionEntered(response.data.description != null && response.data.description.length > 0);
						
						setLoading(false);
						
					},
					error => {
						const resMessage =
								(error.response &&
									error.response.data &&
									error.response.data.message) ||
								error.message ||
								error.toString();
			
						setMessage(resMessage);
						setSuccessful(false);
					}
				);
			} else {
				
				let newCementry = new_cementry_template;
				newCementry.published = false;
						
				setId(null);
				setPublished(false);
				
				setDescriptionEntered(false);
				
				setLoading(false);
			
			}
			
		}    
	}, []);

	function handleChange(event) {

		let field = event.target.name;
		
		if( field.indexOf("ownerAddress") >= 0 ) {
			
			let subField = field.split(".")[1];
				
			ownerAddress[subField] = event.target.value;
			setOwnerAddress(ownerAddress);
			
		} else if(field.indexOf("coordinates") >= 0) {
			
			let subField = field.split(".")[1];
			
			coordinates[subField] = event.target.value;
			setCoordinates(coordinates);
			
		} 		
  	}
  	
  	function onDescriptionChanged(description) {
		
		setDescription(description);
		setDescriptionEntered(description.length > 0);
	}
	

	
	function saveCementery(e) {
	
		e.preventDefault();
	
		setMessage("");	
		setSuccessful(false);
		
		let cementery = {
			id: id,
			abbreviation: abbreviation,
			monumentNumber: monumentNumber,
			gov: gov,
			link: link,
			name: name,
			district: district,
			localCommunity: localCommunity,
			parcel: parcel,
			owner: owner,
			ownerAddress: ownerAddress,
			coordinates: coordinates,
			description: description,
			numberOfTombstones: numberOfTombstones,
			occupancy: occupancy,
			published: published,

			
		};
		
		let saveAndClose = (e.nativeEvent.submitter.name === "save-cementery-and-close");
		if (saveAndClose) {
			saveCementeryAndClose(cementery);
		} else {
			saveCementeryOnly(cementery);
		}
	}
  	
  	function saveCementeryAndClose(cementery) {
	
		ElasticSearchService.saveCementry(
			cementery
		).then(
			response => {
				
				setId(response.data.id);
			
				
				navigate("/cemeteryOverview");
			},
			error => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setMessage(resMessage);
				setSuccessful(false);
			}
		);
	}
	
	function saveCementeryOnly(cementery) {
	
		ElasticSearchService.saveCementry(
			cementery
		).then(
			response => {

				setId(response.data.id);
			
				
				navigate("/cemeteryDetails/" + response.data.id);
			},
			error => {
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

				setMessage(resMessage);
				setSuccessful(false);
			}
		);
	}
	
	return (
		<Container>
			<Row>
				<Col>
					<Breadcrumb>
					  <Breadcrumb.Item href="/cemeteryOverview">Friedhofs Management</Breadcrumb.Item>
					  <Breadcrumb.Item active>Friedhofsdetails: {name}</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col><h3>{name}</h3></Col>
			</Row>
			
			<Row>
				<Col>	
					<br/>
				</Col>
			</Row>

			{!isLoading ? (	
				<Form onSubmit={saveCementery}>
	          	<Row >
					<Col className="form-group">
						<label htmlFor="id">ID</label>
						<Input 
							readOnly 
							type="text"
								className="form-control"
								name="id"
								value={id} />
						<div className="text-muted">Die ID des Friedhofs wird automatisch festgelegt!</div>
					</Col>
				</Row>
				<Row >
					<Col className="form-group">
						<label htmlFor="abbreviation">Kürzel</label>
						<Input type="text"
								className="form-control"
								name="abbreviation"
								placeholder="Kürzel" 
								value={abbreviation}
								onChange={e => setAbbreviation(e.target.value)} 
								validations={[required]} />
						<div className="text-muted">Vergeben Sie ein Kürzel für den Friedhof.</div>
					</Col>
				</Row>
				<Row >
					<Col className="form-group">
						<label htmlFor="monumentNumber">Denkmalnummer</label>
						<Input type="text"
								className="form-control"
								name="monumentNumber" 
								placeholder="Denkmalnummer" 
								value={monumentNumber}
								onChange={e => setMonumentNumber(e.target.value)} />
						<div className="text-muted">Tragen Sie hier die offizielle Denkmalnummer des Friedhofs ein.</div>
					</Col>
				</Row>
				<Row >
					<Col className="form-group">
						<label htmlFor="link">Link zur Denkmalliste</label>
						<Input type="text"
								className="form-control"
								name="link" 
								placeholder="Link zur Denkmalliste" 
								value={link}
								onChange={e => setLink(e.target.value)} />
						<div className="text-muted">Geben Sie hier den Link zur Denkmalliste in der Form "https//www.xxxx.de/..." an.</div>
					</Col>
				</Row>			
				<Row >
					<Col className="form-group">
						<label htmlFor="name">Name</label>
						<Input type="text"
								className="form-control"
								name="name" 
								placeholder="Name des Friedhofs" 
								value={name}
								onChange={e => setName(e.target.value)} 
								validations={[required]} />
						<div className="text-muted">Geben Sie die Bezeichnung des Friedhofs ein.</div>
					</Col>
				</Row>	
				<Row >
					<Col className="form-group">
						<label htmlFor="gov">Regierungsbezirk</label>
						<Input type="text"
								className="form-control"
								name="gov" 
								placeholder="Regierungsbezirk des Friedhofs" 
								value={gov}
								onChange={e => setGov(e.target.value)} 
								/>
						<div className="text-muted">Geben Sie den Regierungsbezikr an, in dem sich der Friedhof befindet.</div>
					</Col>
				</Row>	
				<Row>
					<Col className="form-group">
						<label htmlFor="district">Landkreis</label>
						<Input type="text"
								className="form-control"
								name="district" 
								placeholder="Landkreis" 
								value={district}
								onChange={e => setDistrict(e.target.value)} />
						<div className="text-muted">Geben Sie den Landkreis an.</div>
					</Col>
				</Row>
				<Row>
					<Col className="form-group">
						<label htmlFor="localCommunity">Gemeinde</label>
						<Input type="text"
								className="form-control"
								name="localCommunity" 
								placeholder="Gemeinde" 
								value={localCommunity}
								onChange={e => setLocalCommunity(e.target.value)} />
						<div className="text-muted">Geben Sie den Namen der Gemeinde an.</div>
					</Col>
				</Row>
				<Row>
					<Col className="form-group">
						<label htmlFor="owner">Eigentümer</label>
						<Input type="text"
								className="form-control"
								name="owner" 
								placeholder="Eigentümer" 
								value={owner}
								onChange={e => setOwner(e.target.value)} />
						<div className="text-muted">Geben Sie den Namen des Eigentümers an.</div>
					</Col>
				</Row>
				<Row>
					<Col className="form-group">
						<label htmlFor="ownerAddress.city">Ort</label>
						<Input type="text"
								className="form-control"
								name="ownerAddress.city" 
								placeholder="Ort" 
								value={ownerAddress.city}
								onChange={handleChange} />
						<div className="text-muted">Geben Sie die Adresse (Ort) des Friedhofs an.</div>
					</Col>
				</Row>
				<Row>
					<Col className="form-group">
						<label htmlFor="ownerAddress.postCode">Postleitzahl</label>
						<Input type="text"
								className="form-control"
								name="ownerAddress.postCode" 
								placeholder="Postleitzahl" 
								value={ownerAddress.postCode}
								onChange={handleChange} />
						<div className="text-muted">Geben Sie die Adresse (Postleitzahl) des Friedhofs an.</div>
					</Col>
				</Row>
				<Row>
					<Col className="form-group">
						<label htmlFor="ownerAddress.street">Strasse</label>
						<Input type="text"
								className="form-control"
								name="ownerAddress.street" 
								placeholder="Strasse" 
								value={ownerAddress.street}
								onChange={handleChange} />
						<div className="text-muted">Geben Sie die Adresse (Strasse) des Friedhofs an.</div>
					</Col>
				</Row>
				<Row>
					<Col className="form-group">
						<label htmlFor="ownerAddress.houseNumber">Hausnummer</label>
						<Input type="text"
								className="form-control"
								name="ownerAddress.houseNumber" 
								placeholder="Hausnummer" 
								value={ownerAddress.houseNumber}
								onChange={handleChange} />
						<div className="text-muted">Geben Sie die Adresse (Hausnummer) des Friedhofs an.</div>
					</Col>
				</Row>
				<Row>
					<Col className="form-group">
						<label htmlFor="coordinates.utm">Geokoordinaten (UTM)</label>
						<Input type="text"
								className="form-control"
								name="coordinates.utm" 
								placeholder="Geokoordinaten (UTM) des Friedhofs" 
								value={coordinates.utm}
								onChange={handleChange} />
						<div className="text-muted">Geben Sie die UTM Geokoordinanten des Friedhofs an.</div>
					</Col>
				</Row>
				<Row>
					<Col className="form-group">
						<label htmlFor="occupancy">Belegung</label>
						<Input type="text"
								className="form-control"
								name="occupancy" 
								placeholder="Belegung des Friedhofs von-bis" 
								value={occupancy}
								onChange={e => setOccupancy(e.target.value)} />
						<div className="text-muted">Geben Sie an in welchem Zeitraum der Friedhof belegt wurde.</div>
					</Col>
				</Row>
				<Row>
					<Col className="form-group">
						<label htmlFor="numberOfTombstones">Anzahl der Grabsteine</label>
						<Input type="text"
								className="form-control"
								name="numberOfTombstones" 
								placeholder="Anzahl der Grabsteine auf dem Friefhof" 
								value={numberOfTombstones}
								onChange={e => setNumberOfTombstones(e.target.value)} />
						<div className="text-muted">Geben Sie die Anzahl der Grabsteine auf dem Friedhof an.</div>
					</Col>
				</Row>
				<Row>
					<Col>
						 <BSForm.Group controlId="description">
							<BSForm.Label>Beschreibung</BSForm.Label>
							<BSForm.Control 
									name="description"
									type="text" 
									as="textarea"
									rows={20} 
									placeholder="Beschreibung" 
									value={description}
									onChange={e => onDescriptionChanged(e.target.value)}
									/>
						    <BSForm.Text className="text-muted">
						      Beschreibung des Friedhofs für die Friedhofsstartseite
						    </BSForm.Text>
						 </BSForm.Group>
					</Col>
				</Row>
				<Row md={12}>
					<Col md={12}>
						<BSForm.Check label="Friedhof veröffentlichen" name="publish" onClick={(e) => setPublished(e.target.checked)} defaultChecked={published} disabled={!descriptionEntered} />
					</Col>
				</Row>
				<Row md={12}>
					<Col md={12}>Aktivieren Sie die Checkbox, um den Friedhof für die Öffentlichkeit zugänglich zu machen. Um einen Friedhof zu veröffentlichen muss mindestens das Feld "Beschreibung" gefüllt sein!</Col>
				</Row>
				<Row>
					<Col></Col>
				</Row>
				<Row>
					<Col className="col-auto me-auto">				
						<Button name="cancel" href="/cemeteryOverview">Abbrechen</Button>
					</Col>
					<Col  className="col-auto">				
					 <Button type="submit" name="save-cementery" className="btn badge-flat float-right">Speichern</Button>
					</Col>
					<Col  className="col-auto">				
					 <Button type="submit" name="save-cementery-and-close" className="btn badge-flat float-right">Speichern und Schließen</Button>
					</Col>
				</Row>
			</Form>
	        ) : (
	          <Row>
				<Col>Loading...</Col>
			  </Row>
	        )}

			{errors && (
	          <div className="form-group">
	            <div className="alert alert-danger" role="alert">
	              {errors}
	            </div>
	          </div>
	        )}

			{message && (
				<div className="form-group">
					<div className={
						isSuccessful
						? "alert alert-success"
						: "alert alert-danger"
						}
						role="alert">{message}
					</div>
				</div>
			)}
		</Container>
	);
}