import React, { useState, useEffect } from "react";

import { Row, Col } from "react-bootstrap";

import ElasticSearchService from "../../../services/elasticSearch.service";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Tooltip from '@mui/material/Tooltip';

import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Stack from '@mui/material/Stack';

export default function AssessmentDetails(props) {
	
	const { readOnly, details, index, onDelete, onAddNew, onChange } = props;
	
	const [setLoading] = useState(true);

	const [damageArea, setDamageArea] = useState(details.component);
	
	const [damageImageLabels, setDamageImageLabels] = useState([]);
	const [comment, setComment] = useState(details.comment);
	
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	const [damageImageOptions, setDamageImageOptions] = useState([]);
	
	const damageAreaOptions = [
		{ label: "Schäden am Stein" },
		{ label: "Konstruktive Schäden" }, 
		{ label: "Schäden im Kontext von Schändungen" }
	];
	
	const createAssessmentDetails = (damageArea, damageImageLabels, detailsComment) => {
		
		let currentAssessmentDetails = {};
     	currentAssessmentDetails.component = damageArea;
		currentAssessmentDetails.damagePatterns = damageImageLabels.map((pattern) => {
				
			return {
				id: pattern.id,
				value: pattern.label
			};
		});;
		currentAssessmentDetails.comment = detailsComment;
		
		return currentAssessmentDetails;
	}
	
	function hanldeDeleteAssessmentDetails() {
		
		onDelete(index);
	}
	
	function handleAddNewAssessmentDetails() {
		
		onAddNew({
			component: "",  
			damagePatterns:[],
			comment: ""
     	});
	}
	
	function handleChangeAssessmentDetailsDamageArea(newValue) {
		
		setDamageArea(newValue);
		
		let currentAssessmentDetails = createAssessmentDetails(newValue, damageImageLabels, comment);
		
		onChange(index, currentAssessmentDetails);
	}
	
	function handleChangeAssessmentDetailsDamageImage(newValue) {
		
		let currentDamageImageLabels = [...newValue];
		
		setDamageImageLabels(currentDamageImageLabels);
		
		let currentAssessmentDetails = createAssessmentDetails(damageArea, currentDamageImageLabels, comment);
		
		onChange(index, currentAssessmentDetails);
	}
	
	function handleChangeAssessmentDetailsComment(newValue) {
		
		setComment(newValue);
		
		let currentAssessmentDetails = createAssessmentDetails(damageArea, damageImageLabels, newValue);
		
		onChange(index, currentAssessmentDetails);
	}
	
	useEffect(() => {

		setDamageArea(details.component);
		setComment(details.comment);

		let currentDamageImageLabels = [];
		if (details.damagePatterns !== undefined && details.damagePatterns.length > 0) {
			currentDamageImageLabels = details.damagePatterns.map((pattern) => {
				
				return {
					id: pattern.id,
					label: pattern.value
				};
			});
		}
		setDamageImageLabels(currentDamageImageLabels);
	
		ElasticSearchService.loadChoices().then(
			response => {	
				let data = response.data;
				
				let options = data.Schadensbild.map((option) => {
					
					return {
						id: option.id,
						label: option.value
					}
				});
				
				setDamageImageOptions(options);
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
					setMessage(resMessage);
					setSuccessful(false);
					setLoading(false);
			}
		);
		 
	}, [details]);
			
	return (
		
		<Box sx={{ boxShadow: 3 }} >
			<Row>
				<Col></Col>
			</Row>
			<Row md={12} >
				
				<Col md={1}></Col>
				<Col md={5}>
					<Autocomplete
					  disablePortal
					  id="damage-area"
					  options={damageAreaOptions}
					  value={damageArea}
					  isOptionEqualToValue={(option, value) => (value != null && option.label === value)}
					  onChange={(event, newValue) => handleChangeAssessmentDetailsDamageArea(newValue.label)}
					  renderInput={(params) => <TextField {...params} label="Wählen Sie den Schadensbereich" />} 
					  disabled={readOnly}
					/>
					
				</Col>
				<Col md={5}>
					<Autocomplete 
						multiple
						id="damage-image"
						options={damageImageOptions}
						value={damageImageLabels} 
						isOptionEqualToValue={(option, value) => (value != null && option.id === value.id)} 
						onChange={(event, newValue) => handleChangeAssessmentDetailsDamageImage(newValue)}
						renderInput={(params) => <TextField {...params} label="Wählen Sie das Schadensbild" />} 
						disabled={readOnly}
					/>
					
				</Col>
				<Col md={1}></Col>
			</Row>
			<Row md={12}>
				<Col md={1}></Col>
				<Col  md={10} className="form-group">
					<TextField 
						fullWidth 
						label="Bewertung" 
						name="cementry-tombstone-assessment-description"
						value={comment} 
						onChange={(e) => handleChangeAssessmentDetailsComment(e.target.value)} 
						multiline
						rows={4} 
						disabled={readOnly} 
						/>
				</Col>
				<Col md={1}></Col>
			</Row>
			{!readOnly && (
			<Row md={12}>
				<Col md={1}></Col>
				<Col  md={10} className="form-group">
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Tooltip title={"Bewertungsdetails entfernen"} arrow placement="right">
							<span>
								<IconButton onClick={hanldeDeleteAssessmentDetails} ><DeleteIcon /></IconButton>
							</span>
						</Tooltip>
					
						<Tooltip title={"Bewertungsdetails hinzufügen"} arrow placement="left">
							<span>
								<IconButton onClick={handleAddNewAssessmentDetails} ><AddCircleIcon /></IconButton>
							</span>
						</Tooltip>
					</Stack>
				</Col>
				<Col md={1}></Col>
			</Row>
			)}
			
			{message && (
					<div className="form-group">
						<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
					</div>
			)}
		</Box>
	);
}