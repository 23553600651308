import React from 'react'
import { Row, Col, Button } from "react-bootstrap";
import { personToString} from '../helpers/util'
//import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser} from '@fortawesome/free-solid-svg-icons';

export default class DetailElementChildren extends React.Component {

  	render() {
    	return (<div>

	        {this.props.value && this.props.value.length >0 &&  this.props.value[0] !== ""? 
      			<Row >
					<Col md={3} style={{backgroundColor: '#ffffff'}}>
						<strong>{this.props.name}</strong>
       				</Col>
					<Col  md="auto" style={{backgroundColor: '#ffffff'}}></Col>
					<Col md={{ span: 11, offset: 1 }} style={{backgroundColor: '#fbfbfb'}}>
					
						{this.props.value.map((person, id) =>(	<div key={id}>
							{personToString(person)}
							<Button variant="Link" href={"/personDetails/"+person.cementry+"/"+person.id}><FontAwesomeIcon icon={faUser} /></Button>
							{person.grabsteinnummer?
								<a href={"/tombstoneDetails/"+person.cementry+"/"+person.grabsteinnummer}>
									<img alt="" src="/home.png" width="17" height="20" />
								</a>
							:""
							}
						    
							
						</div>))}
       				</Col>
      		</Row>
			: ""}</div>
			
    )
  }
}

