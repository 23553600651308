import React, { useState, useEffect } from "react";

import { Row, Col } from "react-bootstrap";

import AuthService from "../../../../services/auth.service";

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Stack from '@mui/material/Stack';

import ConservationPlanItem from "./conservationPlanItem2";

import { getStringDate } from "../../../../helpers/util";
import AutocompleteHelper from "../../../../helpers/autocompleteHelper";

export default function ConservationPlan(props) {
	
	const { readOnly, cementryId, tombstoneId, conservations, onChange, onChangeAssignedTombstones } = props;
	
	let list = conservations !== undefined ? conservations : [];
	const [conservationsList, setConservationsList] = useState([...list]);
	
	const [userDisplayName, setUserDisplayName] = useState("");
	
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	const createConservation = (actions, mappings, inspections, costEstimate, status, executionDate, documentation, pdfDocumentations) => {
		
		let currentConservation = {};
		
     	currentConservation.planningDate = (new Date()).getTime();
		currentConservation.planner = userDisplayName;
		
		currentConservation.actionsChoice = AutocompleteHelper.getChoicesFromOptions(actions);
		currentConservation.mappingsChoice = AutocompleteHelper.getChoicesFromOptions(mappings);
		currentConservation.inspectionsChoice = AutocompleteHelper.getChoicesFromOptions(inspections);
		currentConservation.costEstimate = costEstimate;
		
		currentConservation.status = status !== undefined ? status : null;
		currentConservation.executionDate = getStringDate(executionDate);
		currentConservation.documentation = documentation;
		currentConservation.pdfDocumentations = pdfDocumentations;
		
		return currentConservation;
	}
	
	function handleAddNewConservation() {
		
		let newConservation = createConservation([], [], [], "", "", new Date(), "", []);
		
		addNewConservation(newConservation);
	}
	
	function addNewConservation(conservation) {

		let newConservationsList = [...conservationsList];
		
		newConservationsList.push(conservation);
     	
     	setConservationsList(newConservationsList);
     	
     	onChange(newConservationsList);
	}
	
	function handleDeleteConservation(conservationIndex) {

	
			let newConservationsList = [...conservationsList];
			newConservationsList.splice(conservationIndex, 1);
			setConservationsList(newConservationsList);
			onChange(newConservationsList);


	}
	
	function handleConservationChange(conservationIndex, conservation) {
		
		let newConservationsList = [...conservationsList];
		
		newConservationsList[conservationIndex] = conservation;
		
		setConservationsList(newConservationsList);
		
		onChange(newConservationsList);
	};
	
	function handleChangeAssignedTombstones(conservationIndex, assignedTombstones) {
				
		onChangeAssignedTombstones(conservationIndex, assignedTombstones);
	};
	
	
	
	useEffect(() => {

		let user = AuthService.getCurrentUser();
		
		setUserDisplayName(user.firstName + " " + user.lastName);
		 
	}, []);
	
	return (
		<>
			{conservationsList.length > 0 ? 
							
				conservationsList.map((conservation, conservationIndex) => (
					<Box sx={{ boxShadow: 3 }} key={conservationIndex}>
						<Row>
							<Col md={12}></Col>
						</Row>
						<Row md={12}>
							<Col md={1}>
							</Col>
							<Col md={10}>
								<ConservationPlanItem 
									readOnly={readOnly}
									index={conservationIndex} 
									cementryId={cementryId} 
									tombstoneId={tombstoneId} 
									conservation={conservation} 
									onDelete={handleDeleteConservation} 
									onAddNew={addNewConservation} 
									onChange={handleConservationChange}
									onChangeAssignedTombstones={handleChangeAssignedTombstones}
								 />
							</Col>
							<Col md={1}>
							</Col>
						</Row>
						<Row>
							<Col></Col>
						</Row>
					</Box>
				)) :
				(
					<Box sx={{ boxShadow: 3 }} key={-1}>
						<Row>
							<Col md={12}></Col>
						</Row>
						<Row md={12}>
							<Col md={12}>
								<Stack direction="row" justifyContent="center" alignItems="center">
									<Box><strong>Keine Maßnahmen</strong></Box>
								</Stack>
							</Col>
						</Row>
						{!readOnly && (
						<Row md={12}>
							<Col md={1}></Col>
							<Col  md={10} className="form-group">
								<Stack direction="row" justifyContent="end" alignItems="center">
									<Tooltip title={"Maßnahme hinzufügen"} arrow placement="left">
										<span>
											<IconButton onClick={handleAddNewConservation} ><AddCircleIcon /></IconButton>
										</span>
									</Tooltip>
								</Stack>
							</Col>
							<Col md={1}></Col>
						</Row>
						)}
					</Box>
				)}
		   	<Row>
				<Col md={12}></Col>
			</Row>
			{message && (
				<div className="form-group">
					<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
				</div>
			)}
		</>
	);
}