import React, { useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import ContentCopy from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import Divider from '@mui/material/Divider';

import copyToClipboard from 'copy-to-clipboard';

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}));

export default function DashboardLink(props) {
	
	const { AvatarIcon, title, subheader, linkDescription, linkPath, onClick } = props;
	
	const [expanded, setExpanded] = useState(false);
	
	const [copiedToClipboard, setCopiedToClipboard] = useState(false);

	const handleCopyToClipboard = () => {
		
		let url = getAbsoluteUrl(linkPath);
		copyToClipboard(url);
		
		setCopiedToClipboard(true);
		
		setTimeout(() => {
			setCopiedToClipboard(false);
		}, 3000);
	}
	
	const handleExpandClick = () => {
    	setExpanded(!expanded);
  	};
  	
  	const getAbsoluteUrl = (queryPath) => {
    	
    	return window.location.protocol + "//" + window.location.host + queryPath;
  	};
  	
  	useEffect(() => {

		

	}, []);
	
	return (
		<Card sx={{ maxWidth: 300, minWidth: 300 }}>
			<CardHeader
				avatar={<IconButton {...(onClick ? {onClick: onClick} : {component: Link, to: linkPath})}>
		          			<AvatarIcon fontSize="medium" sx={{ 
							    color: "black"
							  }} />
		        		</IconButton>}
        		title={title}
        		subheader={subheader}
        		action={
					<>
						<IconButton {...(onClick ? {onClick: onClick} : {component: Link, to: linkPath})}>
		          			<KeyboardDoubleArrowRightIcon fontSize="large" sx={{ 
							    color: "black"
							  }} />
		        		</IconButton>
					</>
				}
      		/>
      		
      		<Divider variant="middle" />
      		
      		<Collapse in={expanded} timeout="auto" unmountOnExit>
        		<CardContent>
          			<Typography variant="body2" color="text.secondary">{linkDescription}</Typography>
        		</CardContent>
      		</Collapse>
      		
      		<CardActions disableSpacing>
        		{!copiedToClipboard && (<IconButton aria-label="share" onClick={handleCopyToClipboard} >
          			<ContentCopy fontSize="small" />
        		</IconButton>)}
        		{copiedToClipboard && (<Typography variant="body2" color="text.secondary">Link kopiert</Typography>)}
        		<ExpandMore
          			expand={expanded}
          			onClick={handleExpandClick}
          			aria-expanded={expanded}
          			aria-label="show more"
        		>
          			<ExpandMoreIcon fontSize="small" />
        		</ExpandMore>
      		</CardActions>
      		
    	</Card>
	);
}