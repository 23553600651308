import React from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'

class Contact extends React.Component {
    render() {
        return <Container fluid="xxl">
		<Row>
			<Col>
				  <Breadcrumb className="breadcrumb-style">
				  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
				  <Breadcrumb.Item className="breadcrumb-item-style" active>Kontakt</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
		</Row>
        <Row>
          <Col><h3>Kontakt</h3></Col>
        </Row>
        <Row>
          <Col>
        	<strong>Bayerisches Landesamt für Denkmalpflege</strong><br/>
Dienststelle Bamberg<br/>
Schloss Seehof · 96117 Memmelsdorf<br/>
Tel.: 0951 4095-0 · Fax: 0951 4095-30<br/>
info@bet-olam-bayern.de<br/>
<br/>
www.blfd.bayern.de <br/>
Instagram · Facebook <br/>
@denkmaelerbayern<br/>
<br/>
Ansprechpartner: <br/>
<strong>Susanne Klemm M.A.</strong><br/>
Projekt: Erfassung jüdischer Grabmäler in Bayern<br/>
Z II · Denkmalforschung, Städtebauliches Erbe und Welterbe (Bau)<br/>
<br/>
<strong>Bayerisches Landesamt für Denkmalpflege</strong><br/>
Schloss Seehof · 96117 Memmelsdorf<br/>
Tel.: 0951 4095-16 · Fax: 0951 4095-30<br/>
Susanne.Klemm@blfd.bayern.de<br/>
<br/>
<strong>Elisabeth Singer-Brehm, M.A.</strong><br/>
Projekt: Erfassung jüdischer Grabmäler in Bayern / Net Olam<br/>
Z II · Denkmalforschung, Städtebauliches Erbe und Welterbe (Bau)<br/>
<br/>
<strong>Bayerisches Landesamt für Denkmalpflege</strong><br/>
Schloss Seehof · 96117 Memmelsdorf<br/>
Tel.: 0951 4095-23 · Fax: 0951 4095-30<br/>
Elisabeth.Singer-Brehm@blfd.bayern.de

          </Col>
        </Row>
        </Container>
    }
}
export default Contact
