import React from 'react'
import { Row, Col } from "react-bootstrap";

export default class DetailElementMaterial extends React.Component {
	
	
  	render() {
    	return (<div>
	        {(this.props.value.inschrifttafelChoice.length >0 || this.props.value.postamentChoice.length >0 || this.props.value.steleChoice.length >0 || this.props.value.sockelChoice.length >0) ? 
      			<Row >
					<Col md={3} style={{backgroundColor: '#ffffff'}}>
						<strong>{this.props.name}</strong>
       				</Col>
					<Col  md="auto" style={{backgroundColor: '#ffffff'}}></Col>
					<Col md={{ span: 10, offset: 1 }} style={{backgroundColor: '#fbfbfb'}}>
						{this.props.value.inschrifttafelChoice ? this.props.value.inschrifttafelChoice.map((name, id) =>(	<div key={id}>{name.value} (Inschriftentafel)</div>)) : ""}
						{this.props.value.steleChoice ? this.props.value.steleChoice.map((name, id) =>(	<div key={id}>{name.value} (Stele)</div>)) : ""}
						{this.props.value.postamentChoice ? this.props.value.postamentChoice.map((name, id) =>(	<div key={id}>{name.value} (Postament)</div>)) : ""}
						{this.props.value.sockelChoice ? this.props.value.sockelChoice.map((name, id) =>(	<div key={id}>{name.value} (Sockel)</div>)) : ""}
       				</Col>
      		</Row>
			: ""}</div>
			
    )
  }
}

