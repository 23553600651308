import React, { useState, useEffect } from "react";
import { Container, Row, Col, Breadcrumb, Table, Button } from "react-bootstrap";
import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";
import * as Constants from "../../components/constants";
import ACService from "../../helpers/accessValidation.js"

export default function Reports() {
	const [user, setUser] = useState(AuthService.getCurrentUser());
	
	const [isReportsLoading, setReportsLoading] = useState(true);
	const [reports, setReports] = useState([]);
	const [successful, setSuccessful] = useState(false);	
	const [message, setMessage] = useState("");
	
	
	
	function deleteReport(report){
		setMessage("");
		ElasticSearchService.deleteReport(report.id).then(
			response => {
				setReports(response.data);
				setReportsLoading(false);
				setSuccessful(true);
			
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
					setMessage(resMessage);
					setSuccessful(false);
					setReportsLoading(false);
				}
			);
	}
	
	useEffect(() => {
		
		setUser(AuthService.getCurrentUser());
			         
         if(isReportsLoading){
	     	ElasticSearchService.loadReports().then(
			response => {
				setReports(response.data);
				setReportsLoading(false);
				setSuccessful(true);
			
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
					setMessage(resMessage);
					setSuccessful(false);
					setReportsLoading(false);
				}
			);
		}
		


	}, [isReportsLoading]);
	

	
	return (
		<Container>
			<Row>
				<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>		
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Management</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Reportingverwaltung</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col><h3>Reportverwaltung</h3></Col>
			</Row>
		{successful && (
			
			<div>
			<Row>
				<Col><b>Reports</b></Col>
			</Row>
			<Table striped bordered hover>
			  <thead>
			    <tr>
			    	<th>Name des Reports</th>
			    	<th>Ersteller</th>
			    	<th>Datum der Erstellung</th>
			    	<th>Aktionen</th>
			    </tr>
			  </thead>
			  <tbody>
			    {reports.map((report) =>(					    

							<tr key={report.id}>
								<td>{report.id}</td>
								<td>{report.userName}</td>
								<td>{report.createdAt}</td>
			      				<td>
			      				
			      					{((ACService.accessValidation(user, Constants.AC_REPORTING)|| report.userName === user.userName) &&
										<Button variant="secondary" onClick={()=> deleteReport(report)}>Löschen</Button>
									)}
			         				
			         			
			      				</td>
			      			</tr>
					
				))}
			    
			  </tbody>
			</Table>
			</div>

		)}
		
		{message && (
					<div className="form-group">
						<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
					</div>
		)}
		</Container>
	)
}