import React from 'react'
import { Row, Col } from "react-bootstrap";

export default class DetailElementListValues extends React.Component {

  	render() {
    	return (<div>
	        {this.props.value && this.props.value.length >0 &&  this.props.value[0] !== ""? 
      			<Row >
					<Col md={3} style={{backgroundColor: '#ffffff'}}>
						<strong>{this.props.name}</strong>
       				</Col>
					<Col  md="auto" style={{backgroundColor: '#ffffff'}}></Col>
					<Col md={{ span: 11, offset: 1 }} style={{backgroundColor: '#fbfbfb'}}>
						{this.props.value.map((name, id) =>(	<div key={id}>{name}</div>))}
       				</Col>
      		</Row>
			: ""}</div>
			
    )
  }
}

