import React from 'react'
import { Row, Col } from "react-bootstrap";
import { checkDataExist } from '../../../helpers/util'


export default class CementeryListDetailElement extends React.Component {
	
  	render() {
    	return (
			<div className="text-spacer">
	        {this.props.cementeries && this.props.published ? 
	        	<div>
      				{this.props.cementeries.map((cementry, id) =>(
						
						<div key={id} className="cementeries-list">
						{cementry.published ? 
						<div>
							<Row>
								<Col sm={11}>{cementry.name} {checkDataExist(cementry.ownerAddress)?'':''}</Col>
								<Col>{cementry.published?<a href={'/cemetery/'+cementry.id}><img className="img-fluid"  src={'jfb-ic-arrow-right.png'} alt="Logo" /></a>:""}</Col>
							</Row>
							<Row><Col><hr/></Col></Row>
							</div>
						: 
						""
						}
				</div>	
				))}
				</div>			
			: 
			<div>
      				{this.props.cementeries.map((cementry, id) =>(
						
						<div key={id} className="cementeries-list">
		
						
							<Row>
								<Col className="col-auto me-auto">
									{cementry.name} {checkDataExist(cementry.ownerAddress)?'':''}
								</Col>
								<Col className="col-auto">
									{cementry.published?<a href={'/cemetery/'+cementry.id}><img className="img-fluid"  src={'jfb-ic-arrow-right.png'} alt="Logo" /></a>:""}
								</Col>
							</Row>
							<Row><Col><hr/></Col></Row>
						
	
						</div>	
					))}
			</div>}
			</div>
    )
  }
  
}





