import React, { Fragment, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { Container, Breadcrumb, Row, Col, Form, Button, Accordion, Card, useAccordionButton, Form as BSForm } from "react-bootstrap";
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ElasticSearchService from "../../services/elasticSearch.service";
import { isEmpty, getStringValue } from "../../helpers/util";
import { getPolygonCoordinatesFromCementery } from "../../helpers/geoUtils";
import * as Constants from "../../components/constants";
import ACService from "../../helpers/accessValidation.js"
import AuthService from "../../services/auth.service";


export default function CementryOverview() {
	const [user] = useState(AuthService.getCurrentUser());

	const navigate = useNavigate();
	
	const [isLoading, setLoading] = useState(true);

	const [cementries, setCementries] = useState([]);
	
	const [isSuccessful, setSuccessful] = useState(false);
	const [message, setMessage] = useState(false);
	
	useEffect(() => {
		if (isLoading) {
			
			ElasticSearchService.loadCementries().then(
				response => {
					
					let cementries = [];
					
					response.data.forEach(cementry => {
						
						cementry.id = getStringValue(cementry.id, "");
						cementry.abbreviation = getStringValue(cementry.abbreviation, "");
						cementry.parcel = getStringValue(cementry.parcel, "");
						cementry.description = getStringValue(cementry.description, "");
						cementry.district = getStringValue(cementry.district, "");
						cementry.link = getStringValue(cementry.link, "");
						cementry.localCommunity = getStringValue(cementry.localCommunity, "");
						cementry.monumentNumber = getStringValue(cementry.monumentNumber, "");
						cementry.name = getStringValue(cementry.name, "");
						cementry.owner = getStringValue(cementry.owner, "");
						
						cementry.ownerAddress = cementry.ownerAddress !== null ? {
							city: getStringValue(cementry.ownerAddress.city, ""),
							postCode: getStringValue(cementry.ownerAddress.postCode, ""),
							street: getStringValue(cementry.ownerAddress.street, ""),
							houseNumber: getStringValue(cementry.ownerAddress.houseNumber, "")
						} : {
							city: "",
							postCode: "",
							street: "",
							houseNumber: ""
						};
						
						cementry.published =  getStringValue(cementry.published, false);
						
						cementries.push(cementry);
						
					});
					setCementries(cementries);
					
					setLoading(false);
				},
				error => {
					const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
		
					setMessage(resMessage);
					setSuccessful(false);
				}
			);
		}    
	}, [isLoading]);
		
	
  	
  	function onEditCementry(e, cementryId) {
		navigate("/cemeteryDetails/" + cementryId);
	}
  	
  	const CementryAccordionCard = ((props) => {
	
		const [expandedKey, setExpandedKey] = useState("");
		
		const CustomToggle = (({ children, eventKey, isExpanded }) => {
			
			const decoratedOnClick = useAccordionButton(eventKey, () => {
				
				if (isExpanded) setExpandedKey("");
				else setExpandedKey(eventKey);
			});
			
			return (
				<Button className="btn btn-secondary" onClick={decoratedOnClick} style={{cursor: "pointer"}} >{!isExpanded ? <ExpandMore /> : <ExpandLess />}</Button>
			);
		});
		
		const polygonCoordinatesFromCementery = getPolygonCoordinatesFromCementery(props.cementry);
		
		const coordinatesAsString = () => {
		
			let coordinatesAsString = "";
			
			polygonCoordinatesFromCementery.forEach(coordinate => {
				
				coordinatesAsString += " (" + coordinate.lat + ", " + coordinate.lng + ")";
				
			});
			
			return coordinatesAsString;
		};
		
		function getMapUrl(polygonCoordinatesFromCementery) {
			
			let url = "";
			
			if (!isEmpty(polygonCoordinatesFromCementery)) {
				url = "https://www.google.com/maps/@?api=1&map_action=map&center=" + polygonCoordinatesFromCementery[0].lat + "%2C" + polygonCoordinatesFromCementery[0].lng + "&zoom=18&basemap=satellite";
			}
			
			return url;
		};
		
		const mapUrl = getMapUrl(polygonCoordinatesFromCementery);

	return (
		<div className="top-spacer">
		<Form>
		<Card>
	        <Card.Header>
	          	<Row>
					<Col>
						<strong>{props.cementry.name}</strong>
						{mapUrl !== "" && <a href={mapUrl} target="_blank" rel="noopener noreferrer"> Auf der Karte ansehen</a>}
					</Col>
					<Col className="col-auto me-auto">
					{ACService.accessValidation(user, Constants.AC_EDIT_CEMENTERY, props.cementry.name)?(
						<Button type="button" className="btn btn-secondary" onClick={(e) => onEditCementry(e, props.cementry.id)}>Bearbeiten</Button>
						):('')}
					</Col>
					<Col className="col-auto">
						<CustomToggle eventKey={props.cementry.id} isExpanded={expandedKey === props.cementry.id}/>
					</Col>
				</Row>
	        </Card.Header>
	        <Accordion.Collapse eventKey={props.cementry.id}>
	          <Card.Body>
	          	<Row >
					<Col>
						 <Form.Group controlId="id">
							<Form.Label>ID</Form.Label>
							<Form.Control 
		                            readOnly 
									name="id"
									type="input" 
									placeholder="ID des Friedhofs" 
									value={props.cementry.id} 
									/>
						    <Form.Text className="text-muted">
						      Die ID des Friedhofs
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>
				<Row >
					<Col>
						 <Form.Group controlId="name">
							<Form.Label>Kürzel</Form.Label>
							<Form.Control 
									readOnly
									name="abbreviation"
									type="input" 
									placeholder="Kürzel" 
									value={props.cementry.abbreviation} 
									/>
						    <Form.Text className="text-muted">
						      Ein Kürzel für den Friedhof.
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>
				<Row >
					<Col>
						 <Form.Group controlId="name">
							<Form.Label>Denkmalnummer</Form.Label>
							<Form.Control 
									readOnly
									name="monumentNumber"
									type="input" 
									placeholder="Denkmalnummer" 
									value={props.cementry.monumentNumber} 
									/>
						    <Form.Text className="text-muted">
						      Die offizielle Denkmalnummer des Friedhofs.
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>
				<Row >
					<Col>
						 <Form.Group controlId="name">
							<Form.Label>Link zur Denkmalliste</Form.Label>
							<Form.Control 
									readOnly
									name="link"
									type="input" 
									placeholder="Link zur Denkmalliste" 
									value={props.cementry.link} 
									/>
						    <Form.Text className="text-muted">
						      Link zur Denkmalliste.
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>			
				<Row >
					<Col>
						 <Form.Group controlId="name">
							<Form.Label>Name</Form.Label>
							<Form.Control 
									readOnly
									name="name"
									type="input" 
									placeholder="Name des Friedhofs" 
									value={props.cementry.name} 
									/>
						    <Form.Text className="text-muted">
						      Die Bezeichnung des Friedhofs.
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>	
				<Row>
					<Col>
						 <Form.Group controlId="district">
							<Form.Label>Landkreis</Form.Label>
							<Form.Control 
									readOnly
									name="district"
									type="input" 
									placeholder="Landkreis" 
									value={props.cementry.district} 
									/>
						    <Form.Text className="text-muted">
						      Landkreis
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						 <Form.Group controlId="localCommunity">
							<Form.Label>Gemeinde</Form.Label>
							<Form.Control 
									readOnly
									name="localCommunity"
									type="input" 
									placeholder="Gemeinde" 
									value={props.cementry.localCommunity} 
									/>
						    <Form.Text className="text-muted">
						      Der Name der Gemeinde.
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						 <Form.Group controlId="owner">
							<Form.Label>Eigentümer</Form.Label>
							<Form.Control 
									readOnly
									name="owner"
									type="input" 
									placeholder="Eigentümer" 
									value={props.cementry.owner} 
									/>
						    <Form.Text className="text-muted">
						      Der Name des Eigentümers
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						 <Form.Group controlId="ownerAddress.city">
							<Form.Label>Ort</Form.Label>
							<Form.Control 
									readOnly
									name="ownerAddress.city"
									type="input" 
									placeholder="Ort des Eigentümers" 
									value={props.cementry.ownerAddress && props.cementry.ownerAddress.city ? props.cementry.ownerAddress.city : ""} 
									/>
						    <Form.Text className="text-muted">
						      Die Adresse (Ort) des Eigentümers.
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						 <Form.Group controlId="ownerAddress.postCode">
							<Form.Label>Postleitzahl</Form.Label>
							<Form.Control 
									readOnly
									name="ownerAddress.postCode"
									type="input" 
									placeholder="Postleitzahl des Eigentümers" 
									value={props.cementry.ownerAddress && props.cementry.ownerAddress.postCode ? props.cementry.ownerAddress.postCode : ""} 
									/>
						    <Form.Text className="text-muted">
						      Die Adresse (Postleitzahl) des Eigentümers
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						 <Form.Group controlId="ownerAddress.street">
							<Form.Label>Strasse</Form.Label>
							<Form.Control 
									readOnly
									name="ownerAddress.street"
									type="input" 
									placeholder="Straße des Eigentümers" 
									value={props.cementry.ownerAddress && props.cementry.ownerAddress.street ? props.cementry.ownerAddress.street : ""} 
									/>
						    <Form.Text className="text-muted">
						      Die Adresse (Strasse) des Eigentümers
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						 <Form.Group controlId="ownerAddress.houseNumber">
							<Form.Label>Hausnummer</Form.Label>
							<Form.Control 
									readOnly
									name="ownerAddress.houseNumber"
									type="input" 
									placeholder="Hausnummer des Eigentümers" 
									value={props.cementry.ownerAddress && props.cementry.ownerAddress.houseNumber ? props.cementry.ownerAddress.houseNumber : ""} 
									/>
						    <Form.Text className="text-muted">
						      Die Adresse (Hausnummer) des Eigentümers
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						 <Form.Group controlId="coordinates.utm">
							<Form.Label>Geokoordinaten</Form.Label>
							<Form.Control 
									readOnly
									name="coordinates"
									type="input" 
									placeholder="Geokoordinaten des Friedhofs" 
									value={coordinatesAsString()} 
									/>
						    <Form.Text className="text-muted">
						      Die UTM Geokoordinanten des Friedhofs
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						 <Form.Group controlId="coordinates.utm">
							<Form.Label>Beschreibung</Form.Label>
							<Form.Control 
									readOnly
									name="description"
									type="text" 
									as="textarea"
									rows={4} 
									placeholder="Beschreibung" 
									value={props.cementry.description} 
									/>
						    <Form.Text className="text-muted">
						      Beschreibung des Friedhofs für die Friedhofsstartseite
						    </Form.Text>
						 </Form.Group>
					</Col>
				</Row>
				<Row md={12}>
					<Col md={12}>
						<BSForm.Check label="Friedhof veröffentlichen" name="publish" defaultChecked={props.cementry.published} disabled={true} />
					</Col>
				</Row>
	          </Card.Body>
	        </Accordion.Collapse>
		</Card>
		</Form>
		</div>
		)
	});
	
	return (
		<Container fluid="xxl" >
			<Row>
				<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Friedhofsverwaltung</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col><h3>Friedhofsverwaltung</h3></Col>
			</Row>
			<Row>
				<Col>	
					<br/>
				</Col>
			</Row>

			{!isLoading ? (	
			<Fragment>

				<Accordion defaultActiveKey="0">  
				{cementries.map((cementry, index) => (
					<CementryAccordionCard 
						key={index} 
						index={index} 
						cementry={cementry} 
						isNew={false} />
       			))}
				</Accordion>
			</Fragment>		
	        ) : (
	          <Row>
				<Col>Loading...</Col>
			  </Row>
	        )}

			{message && (
				<div className="form-group">
					<div className={
						isSuccessful
						? "alert alert-success"
						: "alert alert-danger"
						}
						role="alert">{message}
					</div>
				</div>
			)}
		</Container>
	);
}
