import React from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'

class BetOlam extends React.Component {

	render() {
		return <Container fluid="xxl">
			<Row className="top-spacer">
				<Col>
					<Breadcrumb className="breadcrumb-style">
						<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
						<Breadcrumb.Item active>Bet Olam Bayern</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col>

					<h4>Unsere Arbeit, Kooperationspartner</h4>
					Ein groß angelegtes Projekt, wie die Erfassung jüdischer Grabmäler in Bayern kann nur mit Beteiligung von Kooperationspartnern erfolgreich arbeiten.<br/><br/>
					Das Landesamt für Denkmalpflege dankt insbesondere seinen Kooperationspartnern, die das Projekt auf verschiedenen Ebenen unterstützen und beraten, dem Beauftragten der Bayerischen Staatsregierung für jüdisches Leben und gegen Antisemitismus, für Erinnerungsarbeit und geschichtliches Erbe, Herrn Dr. Ludwig Spaenle, dem Landesverband Israelitischer Kultusgemeinden in Bayern, Professur für Judaistik der Universität Bamberg, Frau Prof. Dr. Susanne Talabardon, Herrn Prof. Dr. Dr. Peter Kuhn und dem Salomon-Ludwig-Steinheim-Institut in Essen.<br/><br/>
					Die Datenbank bet-olam-bayern.de veröffentlicht nicht nur eigene Dokumentationen und Erkenntnisse, sondern sie ist gleichzeitig ein Portal für Friedhofserfassungen, die in den vergangenen Jahren durch andere Projektträger (Kommunen, Vereine, Privatpersonen) veranlasst wurden. Durch die großzügige Bereitstellung von Vermessungsplänen, Bearbeitungen der Grabsteininschriften, Fotoserien und Informationen zur Geschichte der Friedhöfe unterstützen sie das bayernweite Dokumentationsprojekt des Landesamts für Denkmalpflege. Historiker erhoben in ehrenamtlicher Arbeit in großem Umfang biografische Daten und übermittelten sie dem Landesamt für Denkmalpflege.<br/><br/>
					Allen Beteiligten wird herzlich für ihr Engagement, für die Überlassung von Forschungsergebnissen und für die Einräumung von Nutzungsrechten gedankt.<br/><br/>
					Bet Olam, Verein zur Erforschung und Dokumentation des jüdischen Friedhofs Schopfloch e.V., Alemannia judaica. Arbeitsgemeinschaft für die Erforschung der Geschichte der Juden im süddeutschen und angrenzenden Raum, Johanna-Stahl-Zentrum Würzburg, Schwäbische Forschungsgemeinschaft, Förderverein Ehemalige Synagoge Kitzingen, Markt Bechhofen, Stadt Pappenheim, Stadt Sulzbach-Rosenberg, Landratsamt Würzburg, Frankenbund Ansbach, Gemeinde Mühlhausen, Stadt Bad Kissingen, Stadt Memmingen, Markt Willanzheim, Stadt Hammelburg, Gemeinde Riedbach, Markt Buttenheim, Gemeinde Schwanfeld, Markt Höchberg, Gemeinde Georgensgmünd, Stadtarchiv Ingolstadt, Historischer Verein Memmingen.<br/><br/>
					Reinhold Albert, Dr. Andreas Angerstorfer, Dr. Riccardo Altieri, Gerfrid Arnold, Christoph Bender, Dr. Monika Berwanger, Dr. Gisela Naomi Blume, Elisabeth Böhrer, Angelika Brosig, Klaus Bub, Angela Bungert, Dr. Rebekka Denz, Claudia Dommel, Dr. Florian Duschl, Dr. Katja Fichtel, Peter Friedmann, Dr. Joachim Först, Christoph Engelhard, Rolf Hofmann, Prof. Dr. Heide Invetheen, Elisabeth Gröbel, Dr. Gerhard Gronauer, Dr. Joachim Hahn, Birgit Hähnlein-Häberlein, Robert Hollenbacher, Dr. Ulrich Hornsteiner, Nathanja Hüttenmeister, Mario Jacoby, Angelika Jeckel, Dr. Uta Karrer, Prof. Dr. Roman Koch, Richard Köth, Birgit Kroder-Gumann, Klaus Krumscheid, Thomas Künzl, Dr. Gerhard Lehrberger, Margret Löther, Dr. Claudia Madel-Böhringer, Andreas Maul, Cornelia Mence, Patrick Menth, Dr. Johannes Mordstein, Detlef Müller, Judith Orschler, Carolin Pfeuffer, Matthias Poppe, Ellen Presser, Renate Prusakow, Gunter Reese, Dr. Rotraut Ries, Tim Riedmüller, Prof. Dr. Stefan Rohrbacher, Dr. Tanja Roppelt, Joachim Ruck, Shoshana Sauerbier-Tietz, Annette Schäfer, Dr. Leonhard Scherg, Peter Schleifheimer, Günter Schmidt, Gaby Schuller, Dietmar Stadter, Dorina Stupp, Dr. Ulrike Stutschka, Lutz Tietmann, Thomas Voigtländer, Friedrich Wollmershäuser, Oded Zingher.
				</Col>
			</Row>
		</Container>

	}
}
export default BetOlam
