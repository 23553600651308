import React from 'react'
import ElasticSearchService from "../../services/elasticSearch.service";
import StorageService from "../../services/storage.service";
import { Container, Row, Col, FormControl, Form, Button, Breadcrumb } from 'react-bootstrap'
import Highlight from "../../components/highlight"
import AuthService from "../../services/auth.service";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';


class SearchResults extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			cementries: [],
			isLoading: true,
			errors: null,
			successful: false,
			message: "",
			selectedCementry: "",
			currentCementry: "",
			currentUser: undefined,
			query: "",
			result: { hits: 0 },
			timeout: 0,
			isSearching: false,
			from: 0,
			size: 25,
			resultSize: 0,
			radioValue: 0,
		};

		this.handleCementryChange = this.handleCementryChange.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);

	}


	handleRadioChange(e){
		
		let value = e.target.value;
		this.setState({
			radioValue: value,
		});
	}

	handleCementryChange(e) {
	
		let id = e.id;
		this.setState({
			selectedCementry: id,
			result: {},
			query: "",
			message: "",
			isSearching: false,
			resultSize: 0
		});

		localStorage.setItem("cementery", JSON.stringify(id));

	}

	handleSearchChange(e) {
		let queryString = e.target.value;

		this.setState({
			query: queryString,
			size: 25,
			message: ""
		});

		localStorage.setItem("query", JSON.stringify(queryString));



	}

	allResults() {
		let newSize = this.state.result.hits
		this.setState({
			size: newSize,
		});
		this.handleSearch(newSize)
	}



	handleSearch(size) {

		if (this.state.query !== "" && this.state.query.length > 2) {
			this.setState({
				result: {},
				isSearching: true
			});

			ElasticSearchService.search(this.state.selectedCementry, this.state.query, this.state.from, size, this.state.radioValue).then(
				response => {

					let resultSize = 0;
					if (response.data.hits < size) {
						resultSize = response.data.hits;
					}
					else {
						resultSize = size
					}

					this.setState({
						result: response.data,
						isSearching: false,
						resultSize: resultSize
					});

					if (response.data.hits === "0") {
						this.setState({
							message: "Die Suche ergab keinen Treffer...",
							isSearching: false,
						});
					}
				},
				error => {
					this.setState({
						error:
							(error.response && error.response.data) ||
							error.message ||
							error.toString()
					});
				}
			);
		}
		else {
			this.setState({
				result: {},
				message: "Bitte geben Sie mindestens 3 Zeichen ein..."

			});
		}


	}


	componentDidMount() {

		const user = AuthService.getCurrentUser();
		if (user) {
			this.setState({
				currentUser: user,
				showManagerLinks: true,
			});
		}

		let query = StorageService.handleStorage("query");
		let selectedCementry = StorageService.handleStorage("cementery");

		this.setState({
			query: query,
			selectedCementry: selectedCementry
		});

		let onlyPublished = user ? "0" : "-2";

		ElasticSearchService.loadCementriesForAutoComplete(onlyPublished).then(
			response => {
				this.setState({
					cementries: response.data,
					isLoading: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);



		if (query !== "" && query.length > 2) {
						
			ElasticSearchService.search(this.state.selectedCementry, query, 0, 25).then(
				response => {
					let resultSize = 0;
					if (response.data.hits < this.state.size) {
						resultSize = response.data.hits;
					}
					else {
						resultSize = this.state.size
					}
					this.setState({
						result: response.data,
						isSearching: false,
						resultSize: resultSize
					});
				},
				error => {
					this.setState({
						error:
							(error.response && error.response.data) ||
							error.message ||
							error.toString()
					});
				}
			);
		}
	}

	render() {
		const { cementries, isLoading, isSearching, query, result, resultSize, size, showManagerLinks, radioValue } = this.state;
		return <div id="search-results" style={{ width: "90%", marginLeft: "5%" }}>
			<Container fluid="xxl">
				<Row className="top-spacer">
					<Col>
						<Breadcrumb className="breadcrumb-style">
							<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
							<Breadcrumb.Item className="breadcrumb-item-style" active>Suche</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row>
				<Row>
					<Col><h3>Suche</h3></Col>
				</Row>
				<Row>
					<Col>
						Für die Suche wählen Sie den gewünschten Friedhof oder den Eintrag "--- alle Friedhöfe ---" aus und geben Sie bitte mindestens 3 Zeichen in das Feld für die Suchbegriffe ein.
						Anschließend starten Sie die Suche mit der "Enter" Taste.
						Möchten Sie die Suche auf Grabsteine oder Personen einschränken, aktivieren Sie bitte die entsprechende Checkbox.
						<br /><br />
					</Col>
				</Row>

				{!isLoading ? (
					<div>

						<Row>
							<Col>
								<Form.Group as={Col} controlId="CementryChoice">
									<Autocomplete
										value={cementries.find(cementery => cementery.id === this.state.selectedCementry)}
										onChange={(event, newValue) => { this.handleCementryChange(newValue); }}
										disableClearable={true}
										id="combo-box-cementries"
										options={cementries}
										renderInput={(params) => <TextField {...params} label="Bitte wählen Sie einen Friedhof" />}
									/>
								</Form.Group>

							</Col>
						</Row>

						<Row>
							<Col>
						
									<FormLabel id="demo-controlled-radio-buttons-group">Einschränkungen für die Suche</FormLabel>
									<RadioGroup
									    row
										aria-labelledby="demo-controlled-radio-buttons-group"
										name="controlled-radio-buttons-group"
										value={radioValue}
										onChange={(e) =>this.handleRadioChange(e)}
									>
										<FormControlLabel value="0" control={<Radio />} label="Suche ohne Einschränkungen" />
										<FormControlLabel value="1" control={<Radio />} label="Nur in Grabsteinen suchen" />
										<FormControlLabel value="2" control={<Radio />} label="Nur in Personen suchen" />
									</RadioGroup>
						
							</Col>
						</Row>


						
						<Row>
							<Col>
								<Form.Group as={Col} controlId="SearchGroup">
									<Form.Text className="text-muted">Geben Sie einen Suchbegriff ein...</Form.Text>
									<FormControl placeholder="Suchbegriffe eingeben" value={query} onChange={(e) => this.handleSearchChange(e)}
										onKeyPress={event => {
											if (event.key === 'Enter') {
												this.handleSearch(size)
											}
										}} />
								</Form.Group>
							</Col>

						</Row>
						{!isSearching ? (
							<div>
								<Row>
									{result.hits && result.hits > 0
										? <Col> <span> <Button variant="Link" disabled>Treffer: {result.hits}  - Es werden {resultSize} Ergebnisse angezeigt</Button> <Button variant="Link" className="float-right" onClick={(e) => this.allResults(e)}>(alle Ergebnisse anzeigen)</Button></span></Col>
										: <Col> Treffer: 0</Col>
									}
								</Row>

								<Highlight searchResult={result} managerLinks={showManagerLinks} />

								<Row>
									{result && result.hits && result.hits > 0
										? <Col> <span> <Button variant="Link" disabled>Treffer: {result.hits}  - Es werden {resultSize} Ergebnisse angezeigt </Button><Button variant="Link" className="float-right" onClick={(e) => this.allResults(e)}>(alle Ergebnisse anzeigen)</Button></span></Col>
										: <Col> </Col>
									}
								</Row>
							</div>
						) : (
							<Row>
								<Col>Searching...</Col>
							</Row>
						)}
					</div>



				) : (
					<Row>
						<Col>Loading...</Col>

					</Row>
				)}

				{this.state.message && (
					<div className="form-group">
						<div
							className={
								this.state.successful
									? "alert alert-success"
									: "alert alert-danger"
							}
							role="alert"
						>
							{this.state.message}
						</div>
					</div>
				)}
			</Container>
		</div>
	}
}
export default SearchResults
