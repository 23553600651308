import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Breadcrumb, Figure } from "react-bootstrap";
import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";
import ACService from "../../helpers/accessValidation";
import * as Constants from "../../components/constants";

export default class MakerDetails extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isError: { name: "" },
			showManagerLinks: false,
			currentUser: undefined,
			maker: {},
			newWork: { "id": "", "type": "", "beschreibung": "" },
			newMaker: {},
			isMakersLoading: true,
			readOnly: true,
			changeOrCancel: "Hersteller Bearbeiten",
			changeOrCancelValue: 0,
			makerId: "",
			newWorkFlag: false,
			newWorkDisabled: true,
			newWorkOrCancel: "Werk Hinzufügen",
			readOnlySave: true,
			message: "",
			successful: true,
			makerTemplate: {
				"id": "",
				"name": "",
				"vorname": "",
				"ort": "",
				"land": "",
				"adresse": "",
				"geboren_jahr": "",
				"geboren_monat": "",
				"geboren_tag": "",
				"geburtsdatum": "..",
				"gestorben_jahr": "",
				"gestorben_monat": "",
				"gestorben_tag": "",
				"todesdatum": "..",
				"biografie": "",
				"werke": [],
				"kommentar": "",
				"rechte": ""
			},
		}
		this.enableNewMaker = this.enableNewMaker.bind(this);

	}

	enableNewMaker(e) {
		this.setState({
			maker: this.state.makerTemplate,
			readOnlySave: false,
			readOnly: false,
			changeOrCancel: "Bearbeitung Abbrechen",
			changeOrCancelValue: "1",
			newWorkDisabled: false,
			message: "",
			successful: false,
		});
	}



	saveMaker(e) {
		e.preventDefault();



		const toSave = this.state.maker;

		if (toSave.name === "" && toSave.name.length < 3) {
			let isError = this.state.isError
			isError.name = "Geben Sie einen Namen für den Hersteller an! (mindestens 3 Buchstaben)"
			this.setState({
				isError: isError
			});
			e.stopPropagation();
			return;

		}

		if (this.state.newWorkFlag) {
			const werke = toSave["werke"];
			werke.push(this.state.newWork)
			toSave["werke"] = werke
		}

		ElasticSearchService.saveMaker(toSave).then(
			response => {
				this.setState({
					message: response.data.message,
					successful: true,
					readOnly: true,
					changeOrCancel: "Hersteller Bearbeiten",
					readOnlySave: true,
					newWorkFlag: false,
					newWork: { "id": "", "type": "", "beschreibung": "" },
					maker: toSave,
					newWorkDisabled: true,
					newWorkOrCancel: "Werk Hinzufügen",
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);
	}

	enableNewWork() {
		this.setState({
			newWorkFlag: true,
		})
	}

	cancelNewWork() {
		this.setState({
			newWorkFlag: false,
		})
	}

	changeNewWork(e) {
		const value = e.target.value;
		const name = e.target.name;

		this.setState(prevState => ({
			newWork: { ...prevState.newWork, [name]: value }
		}))
	}

	removeWork(e, id) {
		let maker = this.state.maker;
		let werke = maker.werke;
		werke.splice(id, 1);
		maker.werke = werke;
		this.setState({
			maker: maker
		})
	}

	changeField(e) {
		const value = e.target.value;
		const name = e.target.name;
		this.setState(prevState => ({
			maker: { ...prevState.maker, [name]: value },
			isError: { name: "" },
		}))
	}

	changeInnerField(e, id) {
		let value = e.target.value;
		let name = e.target.name;
		let werk = this.state.maker["werke"][id];
		let maker = this.state.maker
		werk[name] = value;
		maker["werke"][id] = werk

		this.setState({
			maker: maker
		})
	}

	enableChangeMaker(e) {
		let value = e.target.value;
		if (value === "0") {
			this.setState({
				readOnly: false,
				changeOrCancel: "Bearbeitung Abbrechen",
				changeOrCancelValue: "1",
				newWorkDisabled: false,
				readOnlySave: false,
				message: "",
				successful: false,
			});
		}
		if (value === "1") {
			ElasticSearchService.loadMaker(this.state.makerId).then(
				response => {
					this.setState({
						maker: response.data,
						isMakersLoading: false
					});
				},
				error => {
					this.setState({
						error:
							(error.response && error.response.data) ||
							error.message ||
							error.toString()
					});
				}
			);

			this.setState({
				readOnly: true,
				changeOrCancelValue: "0",
				changeOrCancel: "Hersteller Bearbeiten",
				newWorkDisabled: true,
				newWorkFlag: false,
				readOnlySave: true,
			});
		}

	}



	componentDidMount() {
		const user = AuthService.getCurrentUser();

		if (user) {
			this.setState({
				currentUser: user,
			});
		}

		let url = window.location.href.split('/')
		let makerId = url[4];

		if (makerId === "new") {
		
			this.enableNewMaker();
			this.setState({
				isMakersLoading: false
			});
			return;
		}

		this.setState({
			makerId: makerId,
		});

		ElasticSearchService.loadMaker(makerId).then(
			response => {
				
				this.setState({
					maker: response.data,
					isMakersLoading: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);
	}

	render() {
		const { currentUser, isMakersLoading, maker, readOnly, changeOrCancelValue, changeOrCancel, readOnlySave, isError } = this.state;
		return (
			<Container fluid="xxl">
				<Form onSubmit={(e) => this.saveMaker(e)}>
					<Row>
						<Col>
							<Breadcrumb className="breadcrumb-style">
								<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
								<Breadcrumb.Item>Management</Breadcrumb.Item>
								<Breadcrumb.Item href="/makers">Herstellerliste</Breadcrumb.Item>
								<Breadcrumb.Item active>Hersteller</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col>
							<h3>Hersteller Details</h3>
						</Col>
					</Row>
					{ACService.accessValidation(currentUser, Constants.AC_EDIT_MAKER) && (
						<Row>
							<Col>
								<Button variant="Link" size="sm" href="/makers" >Herstellerliste</Button>{' '}
								<Button variant="Link" size="sm" value={changeOrCancelValue} onClick={(e) => this.enableChangeMaker(e)} >{changeOrCancel}</Button>{' '}
								<Button variant="Link" size="sm" value="0" onClick={(e) => this.enableNewMaker(e)} >Neuen Hersteller anlegen</Button>

								<Button variant="Link" size="sm" type="submit" disabled={readOnlySave} className="float-right">Speichern</Button>
							</Col>
						</Row>
					)}
					<Row>
						<Col>
						</Col>
					</Row>


					{this.state.message && (
						<div className="form-group">
							<div
								className={
									this.state.successful
										? "alert alert-success"
										: "alert alert-danger"
								}
								role="alert"
							>
								{this.state.message}
							</div>
						</div>
					)}
					{!isMakersLoading ? (

						<div>
							<Row>
								<Col>
									<Form.Group as={Col} >
										<Form.Text className="text-muted"><strong>Name</strong></Form.Text>

										<Form.Control className={isError.name.length > 0 ? "is-invalid form-control" : "form-control"} as="input" name="name" value={maker ? maker.name : ""} readOnly={readOnly} onChange={(e) => this.changeField(e)} />

										{isError.name.length > 0 && (
											<span className="invalid-feedback">{isError.name}</span>
										)}
									</Form.Group>
								</Col>
								<Col>
									<Form.Group as={Col} >
										<Form.Text className="text-muted"><strong>Vorname</strong></Form.Text>
										<Form.Control as="input" name="vorname" value={maker.vorname} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col xs={2}>
									<Form.Group as={Col} >
										<Form.Text className="text-muted"><strong>Geburtsdatum (Tag)</strong></Form.Text>
										<Form.Control placeholder="Tag" as="input" name="geboren_tag" value={maker.geboren_tag} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
									</Form.Group>

								</Col>
								<Col xs={2}>
									<Form.Group as={Col} >
										<Form.Text className="text-muted"><strong>Geburtsdatum (Monat)</strong></Form.Text>
										<Form.Control placeholder="Monat" as="input" name="geboren_monat" value={maker.geboren_monat} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
									</Form.Group>

								</Col>
								<Col xs={2}>
									<Form.Group as={Col}>
										<Form.Text className="text-muted"><strong>Geburtsdatum (Jahr)</strong></Form.Text>
										<Form.Control placeholder="Jahr" as="input" name="geboren_jahr" value={maker.geboren_jahr} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
									</Form.Group>

								</Col>
								<Col xs={2}>
									<Form.Group as={Col} >
										<Form.Text className="text-muted"><strong>Sterbedatum (Tag)</strong></Form.Text>
										<Form.Control placeholder="Tag" as="input" name="gestorben_tag" value={maker.gestorben_tag} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
									</Form.Group>

								</Col>
								<Col xs={2}>
									<Form.Group as={Col} >
										<Form.Text className="text-muted"><strong>Sterbedatum (Monat)</strong></Form.Text>
										<Form.Control placeholder="Monat" as="input" name="gestorben_monat" value={maker.gestorben_monat} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
									</Form.Group>

								</Col>
								<Col xs={2}>
									<Form.Group as={Col}>
										<Form.Text className="text-muted"><strong>Sterbedatum (Jahr)</strong></Form.Text>
										<Form.Control placeholder="Jahr" as="input" name="gestorben_jahr" value={maker.gestorben_jahr} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
									</Form.Group>

								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group as={Col} >
										<Form.Text className="text-muted"><strong>Ort</strong></Form.Text>
										<Form.Control as="input" value={maker.ort} name="ort" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
									</Form.Group>
								</Col>
								<Col>
									<Form.Group as={Col} >
										<Form.Text className="text-muted"><strong>Land</strong></Form.Text>
										<Form.Control as="input" value={maker.land} name="land" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group as={Col} >
										<Form.Text className="text-muted"><strong>Adresse</strong></Form.Text>
										<Form.Control as="input" value={maker.adresse} name="adresse" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group as={Col} >
										<Form.Text className="text-muted"><strong>Biografie</strong></Form.Text>
										<Form.Control as="textarea" value={maker.biografie} name="biografie" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
									</Form.Group>
								</Col>
								<Col>
									<Form.Group as={Col} >
										<Form.Text className="text-muted"><strong>Kommentar</strong></Form.Text>
										<Form.Control as="textarea" value={maker.rechte} name="rechte" readOnly={readOnly} onChange={(e) => this.changeField(e)} />
									</Form.Group>
								</Col>
							</Row>
							<hr />
							{ACService.accessValidation(currentUser, Constants.AC_EDIT_MAKER) && (

								<Row>
									<Col>
										<Form.Group as={Col} >
											<Button variant="Link" type="submit" disabled={readOnlySave} className="float-right" size="sm">Speichern</Button>
										</Form.Group>
									</Col>
								</Row>
							)}

							<hr />
							{maker.tombstones && maker.tombstones.length > 0 ?
								<div>
									<Row>
										<Col md="2">
											<strong>Grabsteine/Werke: </strong>
										</Col>

										<Col>
											{maker.tombstones && maker.tombstones.map((tombstone, tsId) => (
												<Figure>
													<Figure.Image
														width={75}
														height={75}
														alt={tombstone.id}
														src={tombstone.bilder && tombstone.bilder[0] ? tombstone.bilder[0].path : ""}
														className="maker-img-space"
													/>
													<Figure.Caption>
														<a href={"/tombstoneDetails/" + tombstone.cementry + "/" + tombstone.id}>{tombstone.id}</a>
													</Figure.Caption>
												</Figure>
											))}

										</Col>
									</Row>
									{this.state.message && (
										<div className="form-group">
											<div
												className={
													this.state.successful
														? "alert alert-success"
														: "alert alert-danger"
												}
												role="alert"
											>
												{this.state.message}
											</div>
										</div>
									)}
								</div>


								: ""}
						</div>

					) : (
						<Row>
							<Col>Loading...</Col>
						</Row>
					)}
				</Form>
			</Container>
		)
	}
}
