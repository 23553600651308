import axios from "axios";
import authHeader from './auth-header';
import * as Constants from "../components/constants";


class AuthService {
	async login(username, password) {
		return await axios.post(Constants.API_URL + "signin", { username, password })
			.then(response => {
				if (response.data.accessToken) {
					localStorage.setItem("user", JSON.stringify(response.data));
				}
				return response.data;
			});
	}

	logout() {
		localStorage.removeItem("user");
	
		window.open('/login', '_self');
		
	}

	register(username, password, firstname, lastname, cementries, active) {
		return axios.post(Constants.API_URL + "signup", { username, password, firstname, lastname, cementries, active }, { headers: authHeader() });
	}

	saveUser(id, username, password, firstname, lastname, cementries, active) {
		return axios.post(Constants.API_URL + "user-management/" + id, {
			username,
			password,
			firstname,
			lastname,
			cementries,
			active
		}, { headers: authHeader() });
	}

	hasAccessToNavigation() {
		let user = this.getCurrentUser();
		
	}

	getCurrentUser() {
		return JSON.parse(localStorage.getItem('user'));
	}

	changePassword(username, password) {
		return axios.post(Constants.API_URL + 'user-management/password', {
			username,
			password
		}, {
			headers: authHeader()
		});
	}

	async loadUsers() {
		const response = await axios.get(Constants.API_URL + 'users', { headers: authHeader() });
		return response;
	}

	async getUser(id) {
		const response = await axios.get(Constants.API_URL + 'users/' + id, { headers: authHeader() });
		return response;
	}
}

export default new AuthService();
