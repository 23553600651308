import React, { useState, useEffect } from "react";

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export default function JfbSlider(props) {
	
	const { readOnly, labelSelected, onChange } = props;
	
	const [value, setValue] = useState(0);
	
	const marks = [{
		value: 0,
    	label: 'Keine Dringlichkeit',
  	},
	{
    	value: 33,
		label: 'mittelfristig Maßnahmen erforderlich',
	},
	{
		value: 66,
		label: 'kurzfristig Maßnahmen erforderlich',
	},
	{
		value: 99,
		label: 'Maßnahmen nicht vor Ort möglich',
	}];

	function valueText(value) {
		let index = marks.findIndex((mark) => mark.value === value);
		
		return marks[index].label;
	}

	function valueLabelFormat(value) {
		return marks.findIndex((mark) => mark.value === value) + 1;
	}
	
	function getRiscNumber(label) {
	
		let index = marks.findIndex((mark) => mark.label === label);
		
		if (index > -1) {
			
			return marks[index].value;
				
		} else {
			
			return 0;
		} 
		
	}
	
	const handleChange = (event, newValue) => {
		if (typeof newValue === 'number') {
			setValue(newValue);
			
			let description = valueText(newValue);
			onChange(description);
		}
	};
	
	useEffect(() => {
			
		let riscNumber = getRiscNumber(labelSelected);
		
		setValue(riscNumber);
			
	}, []);
	
	return (
		<Box sx={{ width: "100%" }}>
			<Slider
				aria-label="Risikobewertung"
				value={value}
				valueLabelFormat={valueLabelFormat}
				getAriaValueText={valueText}
				step={33}
				valueLabelDisplay="auto"
				marks={marks} 
				onChange={(event, value) => handleChange(event, value)} 
				disabled={readOnly} 
			/>
		</Box>
	);
}