import React, { Fragment, useState, useEffect } from "react";

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

export default function AsyncAutocomplete(props) {
	
	const { label, getOptions, onSuccess, onError, ...other } = props;
	
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const loading = open && options.length === 0;

	useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}

		(async () => {
			let optionsOfSuccess = [];
			
			getOptions().then(
				response => {
					optionsOfSuccess = onSuccess(response);
					
					if (active) {
						setOptions([...optionsOfSuccess]);
					}
				},
				error => {
					onError(error);
				}
			);
		})();

		return () => {
			active = false;
		};
	}, [loading]);

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	return (
		<Autocomplete
			{...other} 
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			getOptionLabel={(option) => option !== "" ? option.label : ""}
			options={options}
			loading={loading}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<Fragment>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</Fragment>
        				),
      				}}
    			/>
  			)}
		/>
	);
}