import React, { Fragment, useState, useEffect } from "react";

import { Row, Col } from "react-bootstrap";
import ElasticSearchService from "../../services/elasticSearch.service";
import { getStringValue } from "../../helpers/util";
import FootnoteTextField from "./footnotes/footnoteTextField";
import FootnoteLabel from "./footnotes/footnoteLabel";
import ReferencesList from "./footnotes/referencesList";


export default function Footnotes(props) {
	
	const [isLoading, setLoading] = useState(true);

	const [person, setPerson] = useState({});
	const [allReferences, setAllReferences] = useState([]);
	
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	useEffect(() => {
		if (isLoading) {
			
			ElasticSearchService.loadPerson("lm1tsWkBin4BxRxBDWk5").then(
				response => {	
					
					setPerson(response.data);
					setAllReferences(response.data.references);
					setLoading(false);
					
				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setMessage(resMessage);
					setSuccessful(false);
				}
			);
		}
	}, [isLoading]);
	
		
	function handleUpdateReferences(list) {
		
		let references = [...list];
		
		person.references = references;
		
		setPerson(person);
		setAllReferences(references);
	}
	
	function handleFieldContentChanged(fieldName, content) {
		
		person[fieldName] = content;
		
		setPerson(person);
	}
	
	return (
		<Fragment>
			<Row>
				<Col><h3>Felder</h3></Col>
			</Row>			
			{!isLoading && (
				<Fragment>
					<Row>
						<Col><br/></Col>
					</Row>	
							
					<Row md={12}>
						<Col  md={12} className="form-group">
							<FootnoteTextField 
								globalReferences={allReferences} 
								footnoteLetter="P" 
								fieldName="biografie" 
								onUpdateReferences = {handleUpdateReferences}
								
								fullWidth 
								label="Biografie" 
								name="content-with-footnotes"
								defaultValue={getStringValue(person.biografie, "")} 
								onContentChanged={handleFieldContentChanged}
								multiline
								rows={4} 
							/>
						</Col>
					</Row>
					
					<Row md={12}>
						<Col  md={12} className="form-group">
							<FootnoteLabel 
								globalReferences={allReferences} 
								defaultValue={person.biografie} 
							/>
						</Col>
					</Row>
					
					<Row md={12}>
						<Col  md={12} className="form-group">
							<FootnoteTextField 
								globalReferences={allReferences} 
								footnoteLetter="P" 
								fieldName="offeneFragen" 
								onUpdateReferences = {handleUpdateReferences}
								
								fullWidth 
								label="Offene Fragen" 
								name="content-with-footnotes"
								defaultValue={getStringValue(person.offeneFragen, "")} 
								onContentChanged={handleFieldContentChanged}
								multiline
								rows={4} 
							/>
						</Col>
					</Row>
					
					<Row md={12}>
						<Col  md={12} className="form-group">
							<FootnoteLabel 
								globalReferences={allReferences} 
								defaultValue={person.offeneFragen} 
							/>
						</Col>
					</Row>
					
					<Row>
						<Col><br/></Col>
					</Row>
					
					<Row md={12}>
						<Col  md={12} >
							<ReferencesList 
								references={allReferences}
								onUpdateReferences = {handleUpdateReferences} 
							/>
						</Col>
					</Row>
					<Row md={12}>
						<Col  md={12} >
							<ReferencesList 
								references={allReferences}
							/>
						</Col>
					</Row>
					
				</Fragment>
			)}

			{message && (
				<div className="form-group">
					<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
				</div>
			)}

		</Fragment>

	);
}