import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";

import ElasticSearchService from "../../../../services/elasticSearch.service";

import PDF from "../../../../helpers/pdf.js";

import { getStringDate } from "../../../../helpers/util";

export default function ConservationTombstoneList(props) {
	
	const { projectName, reload } = props;
	
	const [isLoadingTombstonesWithProject, setLoadingTombstonesWithProject] = useState(true);
	const [tombstonesWithProject, setTombstonesWithProject] = useState([]);
	
	const [successful, setSuccessful] = useState(false);	
	const [message, setMessage] = useState("");
  	
	useEffect(() => {
		
		setLoadingTombstonesWithProject(true);
		setTombstonesWithProject([]);
			
		ElasticSearchService.loadTombstonesWithProject(projectName).then(
			response => {
				
				setTombstonesWithProject(response.data);
				
				setLoadingTombstonesWithProject(false);
				setSuccessful(true);
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
				setMessage(resMessage);
				setSuccessful(false);
				setLoadingTombstonesWithProject(true);
			}
		);

	}, [projectName, reload]);
			
	return (
		<Container>
			<Row>
				<Col><b>Maßnahmen</b></Col>
			</Row>
				
		 {(!isLoadingTombstonesWithProject && tombstonesWithProject.length > 0) && (
			
			<Table striped bordered hover>
			  <thead>
			    <tr>		    	
			      	<th>Grabstein Id</th>
			      	<th>Planungsdatum</th>
			      	<th>Planer</th>
			      	<th>Status</th>
			      	<th>Ausführungsdatum</th>
			      	<th>Aktionen</th>      
			    </tr>
			  </thead>
			  <tbody>
			    {tombstonesWithProject.map((tombstone, id) => (					    
			      	tombstone.conservations.filter(conservation => conservation.projectName === projectName).map((conservation, id1) => (
						<tr key={id1}>						
							<td>{tombstone.grabstein.grabsteinnummer}</td>
			      			<td>{getStringDate(new Date(Number(conservation.planningDate)))}</td>
			      			<td>{conservation.planner}</td>
			      			<td>{conservation.status}</td>
				      		<td>{conservation.executionDate&&conservation.executionDate}</td>
			      			<td>
				        		<Button variant="secondary" onClick={() => PDF.createConservationsPdf(tombstone.cementry, tombstone.grabstein.grabsteinnummer, setMessage)} >Drucken</Button>{' '}
			         			<Button variant="secondary" href={"/conservation/"+tombstone.cementry+"/"+tombstone.grabstein.grabsteinnummer}>Bearbeiten</Button>{' '}
			      			</td>
			      		</tr>
					))
				))}
			    
			  </tbody>
			</Table>
		)}
		
		{tombstonesWithProject.length === 0 && (
			<Row>
				{isLoadingTombstonesWithProject ? (<Col>Die Maßnahmen werden geladen. Bitte einen Augenblick Geduld...</Col>) : (<Col><b>Es sind keine Maßnahmen geplant!</b></Col>)}
			</Row>
		)}
		
		
		</Container>
	);
}