import React, { Component } from "react";
import { Container, Breadcrumb, Row, Col, Button, Form } from "react-bootstrap"
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";


import TextField from '@mui/material/TextField';


class Login extends Component {

  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
      resMessage: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();
    this.setState({
      message: "",
      loading: true
    });


  if (this.state.username.length >0 && this.state.password.length > 0) {
      AuthService.login(this.state.username, this.state.password).then(
        () => {
			window.location.href="/home"
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: "Die Anmeldungung war nicht erfolgreich! Die Kombination aus Benutzername und Passwort konnte nicht gefunden werden!",
            resMessage: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }
  
  
 render(){

    return (
		<Container fluid="xxl">	
			<Row>
				<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Login</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
				
				<Row>
					<Col><h4>Als Benutzer anmelden</h4></Col>
				</Row>


          <Form onSubmit={this.handleLogin}>
           <Row>
			<Col >

              <TextField 
                    required
                    label="Benutzername"
              		name="username" 
              		fullWidth 
              		id="outlined-basic-user" 
              		variant="outlined" 
              		value={this.state.username} 
              		onChange={this.onChangeUsername}
              		/>
            </Col>
			</Row>

            <Row>
			<Col>

              <TextField 
                    required
                    type="password"
                    label="Passwort"
              		name="paswword" 
              		fullWidth 
              		id="outlined-basic-password" 
              		variant="outlined" 
					value={this.state.password}
                	onChange={this.onChangePassword}
             		/>

             </Col>
			</Row>
			<Row>
			<Col >

             </Col>
			</Row>
            <Row>
            	<Col className="col-auto me-auto"></Col>
              	<Col className="col-auto">
	            <Button href="/home" className="btn btn-secondary btn-block" disabled={this.state.loading}>
                 <span>Abbrechen</span>
              </Button>  
              {'   '}
              <Button type="submit" className="btn btn-secondary btn-block" disabled={this.state.loading}>
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Anmelden</span>
              </Button>
             </Col>
            
			</Row>

            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                  {this.state.resMessage}
                </div>
              </div>
            )}
           
          </Form>
      </Container>
    );
  }
}


export default function(props) {
  const navigate = useNavigate();
  return <Login {...props} navigate = {navigate}/>;
}

