import React, { useState, useEffect } from "react";

import { Row, Col } from "react-bootstrap";

import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import Autocomplete from '@mui/material/Autocomplete';

import Button from '@mui/material/Button';

import ElasticSearchService from "../../../../services/elasticSearch.service";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ACService from "../../../../helpers/accessValidation.js";
import * as Constants from "../../../../components/constants";
import AuthService from "../../../../services/auth.service";


export default function ConservationProjectDetails(props) {
	const [user] = useState(AuthService.getCurrentUser());
	const { tombstone, conservation, createNewProject, noConservation, onAddProject, onUpdateProject, onEditStarted } = props;
	
	const [userDisplayName, setUserDisplayName] = useState("");
	
	const [isLoadingTombstones, setLoadingTombstones] = useState(true);
	const [tombstones, setTombstones] = useState([]);
	
	const [isLoadingCementries, setLoadingCementries] = useState(true);
	const [cementries, setCementries] = useState({});
	
	const [isLoadingChoices, setLoadingChoices] = useState(true);
	
	const [selectedCemetry, setSelectedCemetry] = useState(null);
	const [selectedTombstone, setSelectedTombstone] = useState(null);
	
	let currentProjectName = (conservation !== undefined && conservation.projectName !== undefined) ? conservation.projectName : null;
	const [projectName, setProjectName] = useState(currentProjectName);
	
	// JavaScript date format: YYYY-MM-DDTHH:mm:ss.sssZ
	let date = (conservation !== undefined && conservation.planningDate !== undefined) ? new Date(Number(conservation.planningDate)) : new Date();
	const [conservationPlanningDate, setConservationPlanningDate] = useState(date);
	
	const [conservationPlanner, setConservationPlanner] = useState("");

	const [companyOptions, setCompanyOptions] = useState([]);
	
	let currentCompany = (conservation !== undefined && conservation.company !== undefined) ? conservation.company : null;
	const [company, setCompany] = useState(currentCompany);
	
	const [edit, setEdit] = useState(false);
	
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	const PROJECTS_CHOICE_GROUP = "Projekte für Restaurierungsmaßnahmen";
	const CHOICE_GROUP_TYPE = "projects";
	
	const getProject = () => {
		
		let project = {
			
			projectName: (projectName !== undefined && projectName !== null && projectName !== "") ? projectName : null,

			planningDate: (conservationPlanningDate !== undefined && conservationPlanningDate !== null && conservationPlanningDate !== "") ? conservationPlanningDate.getTime() : new Date().getTime(),

			planner: (conservationPlanner !== undefined && conservationPlanner !== null && conservationPlanner !== "") ? conservationPlanner : userDisplayName,
			company: (company !== undefined && company !== null && company !== "") ? company : null
		};
		
		if (project.projectName !== null) return project;
		else return null;
	}
	
	function toggleEditProjectData(edit) {
			
		setEdit(edit);
		
		onEditStarted(edit);
	}
	
	function handleSaveProject() {
		
		let project = getProject();
		
		if (createNewProject) {
			
			if (!noConservation) {
				createNewProjectChoice(project.projectName).then(
					response => {	
						
						onAddProject({
							...project,
							cementryId: selectedCemetry.id,
							tombstoneId: selectedTombstone.id
						});
						
					},
					error => {
						const resMessage =
								(error.response &&
									error.response.data &&
									error.response.data.message) ||
								error.message ||
								error.toString();
			
						setMessage(resMessage);
						setSuccessful(false);
					}
				);
				
			} else {
				onUpdateProject({
					...project,
					cementryId: selectedCemetry.id,
					tombstoneId: selectedTombstone.id
				});
			}
			
		} else {
			onUpdateProject(project);
		}
	}
	
	function hanldeDeleteProject() {
		
		// TODO
		// do we delete a project?
		// if yes -> ALL conservations on ALL tombstones with the corresponding project name need to be deleted!
		
		//onDelete(index);
	}
	
	function handleChangeProjectName(newValue) {
		
		setProjectName(newValue);

	}
	
	function handleChangeCompany(newValue) {
		
		let currentCompany = (newValue !== null && newValue !== "") ? newValue.label : null;
		
		setCompany(currentCompany);

	}
	
	
	function handleChangePlanner(newValue) {
		
		setConservationPlanner(newValue);

	}
	
	function getCementryOption(cementryOptions, cementryId) {
					
		let option = null;
		
		let index = cementryOptions.findIndex(cementryOption => cementryOption.id === cementryId);
		if (index > -1) {
			
			option = cementryOptions[index];
		}
		
		return option;
	}
	
	function getTombstoneOption(tombstoneOptions, tombstoneId) {
					
		let option = null;
		
		let index = tombstoneOptions.findIndex(tombstoneOption => tombstoneOption.id === tombstoneId);
		if (index > -1) {
			
			option = tombstoneOptions[index];
		}
		
		return option;
	}
	
	function handleCementryChange(cementryOption) {
		
		let cementryId = (cementryOption.id !== null && cementryOption.id !== "") ? cementryOption.id : null;
		
		if(cementryId === '0') {
			
			return;
			
		} else {
			
			ElasticSearchService.loadTombstoneIds(cementryId).then(
				response => {
					
					setTombstones(response.data);
					setLoadingTombstones(false);
					setSuccessful(true);

					if (tombstone !== undefined) {
						
						let tombstoneOption = getTombstoneOption(response.data, tombstone.id);
						setSelectedTombstone(tombstoneOption);
					}
				},
				error => {
					const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
		
						setMessage(resMessage);
						setSuccessful(false);
						setLoadingTombstones(false);
				}
			);	
			
			setSelectedCemetry(cementryOption);
	  	}		
    }
    
    function handleTombstoneChange(tombstoneOption){
		
		setSelectedTombstone(tombstoneOption);
		
   	}
   
   	function handleChangePlanningDate(newValue) {
		
		setConservationPlanningDate(newValue);
		
		// conservation.executionDate = getStringDate(newValue);

	}
	
	function createNewProjectChoice(projectName) {
			
		let projectChoice = {
			id: PROJECTS_CHOICE_GROUP + "_" + projectName.replace(/ /g, ""),
			type: CHOICE_GROUP_TYPE,
			value: projectName,
			comment: projectName
		};
			
		return ElasticSearchService.saveChoice(projectChoice);
	}
	
	useEffect(() => {

		if (isLoadingChoices) {
			ElasticSearchService.loadChoices().then(
				response => {	
					let data = response.data;
					
					let options = null;
					
					options = data["Ausführende Unternehmen für Restaurierungsmaßnahmen"].map((option) => {
						
						return {
							id: option.id,
							label: option.value
						}
					});
					setCompanyOptions(options);
					
					setLoadingChoices(false);
					
				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
		
					setMessage(resMessage);
					setSuccessful(false);
					setLoadingChoices(true);
				}
			);
		}
		
		let currentCompany = (conservation !== undefined && conservation.company !== undefined) ? conservation.company : null;
		setCompany(currentCompany);
				
		let currentProjectName = (conservation !== undefined && conservation.projectName !== undefined) ? conservation.projectName : "";
		setProjectName(currentProjectName);
				
		// JavaScript date format: YYYY-MM-DDTHH:mm:ss.sssZ

		let planningDate = (conservation !== undefined && conservation.planningDate !== undefined) ? new Date(Number(conservation.planningDate)) : new Date();
		if (planningDate instanceof Date && !isNaN(planningDate)){
			setConservationPlanningDate(planningDate);
		} 
		else {
			setConservationPlanningDate(new Date());
		} 

		
		let currentUser = AuthService.getCurrentUser();
		let currentUserName = currentUser.firstName + " " + currentUser.lastName;
		setUserDisplayName(currentUserName);
		
		let planingPerson = (conservation !== undefined && conservation.planner !== undefined) ? conservation.planner : currentUserName;
		setConservationPlanner(planingPerson);
		
		setSelectedCemetry(null);
		setSelectedTombstone(null);
		
		if(isLoadingCementries) {
			ElasticSearchService.loadCementriesForAutoComplete(0).then(
			response => {
				
				setCementries(response.data);
				setLoadingCementries(false);
				setSuccessful(true);
				
				if (tombstone !== undefined) {
					
					let selectedCementryOption = getCementryOption(response.data, tombstone.cementry);
					
					handleCementryChange(selectedCementryOption);
				}
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
					setMessage(resMessage);
					setSuccessful(false);
					setLoadingCementries(false);
				}
			);
		}
		
		if (createNewProject === true) setEdit(true);
		else setEdit(false);
		 
	}, [conservation]);
			
	return (
		<>
			<Row>
				<Col><br/></Col>
			</Row>
			{(!isLoadingChoices && !isLoadingCementries) ? (
			<>
			<Row md={12}>
				<Col md={4}>Name des Projektes</Col>
				<Col  md={8} className="form-group">
					{(createNewProject) ? (
					<TextField 
						fullWidth 
						label="Name des Projektes" 
						name="project-name"
						value={projectName} 
						onChange={(e) => handleChangeProjectName(e.target.value)} 
						disabled={!edit} 
					/>) : (<h2>{projectName}</h2>)}
				</Col>
			</Row>
			<Row md={12} >
				<Col md={4}>Planungsdatum</Col>
				<Col md={8}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DesktopDatePicker 
				          label="Planungsdatum"
				          inputFormat="dd.MM.yyyy"
				          value={conservationPlanningDate}
				          onChange={handleChangePlanningDate}
				          renderInput={(params) => <TextField fullWidth {...params} disabled={!edit} />}  
				          disabled={!edit} 
				        />
					</LocalizationProvider>	
				</Col>
			</Row>
			<Row>
				<Col md={4}>Bearbeiter</Col>
				<Col md={8}>{}
					<TextField 
						fullWidth 
						label="Bearbeiter" 
						name="planning-person"
						value={conservationPlanner} 
						onChange={(e) => handleChangePlanner(e.target.value)} 
						disabled={!edit} 
						/>
				</Col>
			</Row>
			<Row>
				<Col></Col>
			</Row>
			<Row md={12} >
				<Col md={4}>Ausführendes Unternehmen</Col>
				<Col md={8}>
					<Autocomplete 
						id="companies-list"
						options={companyOptions}
						value={company} 
						filterSelectedOptions 
						isOptionEqualToValue={(option, value) => (value !== null && option.label === value)} 
						onChange={(event, newValue) => handleChangeCompany(newValue)}
						renderInput={(params) => <TextField {...params} label="Wählen Sie das ausführende Unternehmen" />} 
						disabled={!edit}
					/>
				</Col>
			</Row>
			
			<Row md={12}>
				<Col md={4}>Friedhof</Col>
				<Col  md={8} className="form-group">
					<Autocomplete	 
						onChange={(event, newValue) => {handleCementryChange(newValue);}}
      					id="combo-box-cementries-dialog" 
      					value={selectedCemetry}
      					options={cementries} 
      					isOptionEqualToValue={(option, value) => (value != null && option.id === value.id)}
      					disableClearable
      					renderInput={(params) => <TextField {...params} label="Bitte wählen Sie einen Friedhof" />}
      					disabled={!edit || !createNewProject}
    				/>
				</Col>
			</Row>
			
			<Row md={12}>
				<Col md={4}>Grabstein</Col>
				<Col  md={8} className="form-group">
					<Autocomplete 
							onChange={(event, newValue) => {handleTombstoneChange(newValue);}}						
      						id="combo-box-tombstone" 
      						value={selectedTombstone}
      						options={tombstones} 
      						isOptionEqualToValue={(option, value) => (value != null && option.id === value.id)} 
      						disableClearable
      						renderInput={(params) => <TextField {...params} label="Bitte wählen Sie einen Grabstein" />}
      						disabled={(!edit || isLoadingTombstones) || !createNewProject}
    				/>
				</Col>
			</Row>
			{ACService.accessValidation(user, Constants.AC_EDIT_ASSESSMENTS) && (
			<Row >
				<Col className="col-auto me-auto"></Col>
				<Col className="col-auto">
					<Tooltip title={"Projektdaten bearbeiten"} arrow placement="top-start">
						<label htmlFor="edit-project-button">
							<Button variant="contained" sx={{ m: 1 }} component="span" disabled={edit} onClick={(e) => toggleEditProjectData(true)} >Projektdaten bearbeiten</Button>
						</label>
					</Tooltip>
					{edit && (
					<Tooltip title={"Bearbeiten beenden"} arrow placement="top-start">
						<label htmlFor="cancel-button">
							<Button variant="contained" sx={{ m: 1 }} component="span" onClick={() => toggleEditProjectData(false)} >Abbrechen</Button>
						</label>
					</Tooltip>
					)}
					<Tooltip title={projectName === null ? "Wählen Sie einen Projektnamen aus" : (createNewProject && (selectedCemetry === null || selectedTombstone === null)) ? "Wählen Sie ein Friedhof und Grabstein aus" : "Speichern Sie das Projekt"} arrow placement="right">
						<label htmlFor="save-project-button">
							<Button variant="contained" sx={{ m: 1 }} component="span" disabled={!edit || (createNewProject && (projectName === null || selectedCemetry === null || selectedTombstone === null))} onClick={() => handleSaveProject()} >Speichern</Button>
						</label>
					</Tooltip>
				</Col>
				
			</Row>
			)}
			{message && (
				<div className="form-group">
					<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
				</div>
			)}
			</>
			): (
			<Row md={12}>
				<Col md={4}></Col>
				<Col  md={8} className="form-group">
					Einen Augenblick bitte. Projekt wird geladen...
				</Col>
			</Row>
			)}
		</>
	);
}