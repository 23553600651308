import React from 'react'
import { Row, Col } from "react-bootstrap";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import * as Constants from "../../../components/constants";

export default class CementeryMapDetailElement extends React.Component {
	
  	render() {
    	return (
			<div>
	        {this.props.cementeries && this.props.published ? 
	        	<Row>
				<Col>
				
					<LoadScript googleMapsApiKey={Constants.GOOGLE_API_KEY}>
        				<GoogleMap mapContainerStyle={{width: '100%', height: '500px'}} center={Constants.center} zoom={7} options={{streetViewControl: false}}>
          				{this.props.cementeries.flatMap(function(cementery, id) {
	
							if(cementery.coordinates !== undefined && cementery.coordinates !== null && cementery.published) {
								if(cementery.coordinates.features !== undefined && cementery.coordinates.features !== null) {
									let features = cementery.coordinates.features;
									return (<Marker key={id} onClick={() => {window.open('/cemetery/'+cementery.id, '_self')}} position={features[0].geometry.coordinates[0][0]} icon={'jfb-ic-cementry-symbol.png'} />)	
								} else {
									return [];
								}
					   		} else return [];
				   
						})};
        				</GoogleMap>
      				</LoadScript>
					
				</Col>
			</Row>		
			: 
			<Row>
				<Col>
				
					<LoadScript googleMapsApiKey={Constants.GOOGLE_API_KEY}>
        				<GoogleMap mapContainerStyle={{width: '100%', height: '500px'}} center={Constants.center} zoom={7} options={{streetViewControl: false}}>
          				{this.props.cementeries.flatMap(function(cementery, id) {
	
							if(cementery.coordinates !== undefined && cementery.coordinates !== null && cementery.published ) {
								if(cementery.coordinates.features !== undefined && cementery.coordinates.features !== null) {
									let features = cementery.coordinates.features;
									return (
										<Marker key={id} 
										onClick={() => {window.open('/cemetery/'+cementery.id, '_self')}} 
										position={features[0].geometry.coordinates[0][0]} 
										title ={cementery.name}
										icon={'jfb-ic-cementry-symbol.png'}
										onMouseOver={() => {}}
										onMouseOut={() => {}}
										/>);
								} else return [];
					   		} else return [];
					   		
						})};
						
        				</GoogleMap>
      				</LoadScript>
					
				</Col>
			</Row>
			}
			</div>
    )
  }
  
}





