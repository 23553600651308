import React, { useState, useEffect } from "react";

import { Row, Col } from "react-bootstrap";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import { getStringValue } from "../../../helpers/util";

import Dialog from "../../standard/standardDialog";
import ReferencesList from "./referencesList";

import * as Constants from "../../../components/constants";

export default function FootnoteTextField(props) {
	
	const { globalReferences, onUpdateReferences, defaultValue, onContentChanged, footnoteLetter, fieldName, ...other } = props;
	
	const [content, setContent] = useState(defaultValue);

	const [newReferences, setNewReferences] = useState([]);
	const [fieldReferences, setFieldReferences] = useState([]);
	const [removedReferences, setRemovedReferences] = useState([]);

	const [showDialog, setShowDialog] = useState(false);
	
	useEffect(() => {
		
		let currentGlobalReferences = (globalReferences === undefined) ? [] : globalReferences.filter((reference) => reference.fieldReference === fieldName);

		setFieldReferences(currentGlobalReferences);
		
	}, [globalReferences]);

	function getHighestReferenceIndex() {
		
		let sortedReferences = [];
		let currentGlobalReferences = (globalReferences === undefined) ? [] : globalReferences;
		
		currentGlobalReferences.forEach((reference) => {
			
			sortedReferences.push({
				reference: reference,
				footnoteId: parseFootnoteId(reference.id)
			});
		});
		
		if (sortedReferences.length > 0) {
			sortedReferences.sort((a, b) => a.footnoteId.index - b.footnoteId.index);
		
			let lastElement = sortedReferences.pop();
		
			return lastElement.footnoteId.index;
		
		} else {
			
			return 0;	
		}	
	}
	
	function handleChange(changedContent) {
		
		setContent(changedContent);
		
		onContentChanged(fieldName, changedContent);
	}
	
	function getIndex(footnoteIdString) {
		
		let index = 0;
		
		if (footnoteIdString.length > 1) {
		
			index = Number(footnoteIdString.substring(1,  footnoteIdString.length));
			if (index === "NaN") index = 0;	
		}
		
		return index;
	}
	
	function createEmptyFootnoteId() {
		
		return {
			id: null,
			letter: null,
			index: null
		};
	}
	
	function parseFootnoteId(footnoteIdFromTextField) {
		
		let footnoteIdString = getStringValue(footnoteIdFromTextField, null);
		let footnoteId = createEmptyFootnoteId();
		
		if (footnoteIdString != null && footnoteIdString.length > 0) {
				
			let letter = footnoteIdString[0];
				
			if (letter === "F" || footnoteIdString.length > 1) {
				
				footnoteId = {
					id: footnoteIdString,
					letter: footnoteIdString[0],
					index: getIndex(footnoteIdString)
				};
			}
		} 
	
		return footnoteId;
		
	}
	
	function handleFootnotesCheck() {
		
		//let matches = content.match(/\[(.*?)\]/);
		//let matches2 = content.match(/(?<=\[)[^\][]*(?=])/);
		
		let regex = /\[([^\][]*)]/g;
		let currentGlobalReferences = (globalReferences === undefined) ? [] : [...globalReferences];
		let match;
		
		let referenceListIndex = 0;
		
		let highest = getHighestReferenceIndex();
		let newReferencesForDialog = [];
		let currentReferencesInText = [];
		let removedReferencesInText = [];
		
		match = regex.exec(content);
		while (match) {
			
			if (match.length > 1) {
				
				let footnoteId = parseFootnoteId(match[1]);
				
				if (footnoteId.letter === footnoteLetter) {
					
					referenceListIndex = currentGlobalReferences.findIndex((footnote) => footnote.id === footnoteId.id);
					if (referenceListIndex === -1) {
	
						if (footnoteId.index > highest) highest = footnoteId.index;
	
						newReferencesForDialog.push({
							id: footnoteId.id,
							value: "",
							fieldReference: fieldName,
							type: "missingInListOfReferences"
						});
						
						currentReferencesInText.push({
							id: footnoteId.id,
							value: "",
							fieldReference: fieldName
						});
						
					} else {
						
						currentReferencesInText.push(currentGlobalReferences[referenceListIndex]);
					}
					
				} else {
					// reference not relevant for this text field 
				}
			}
			
			match = regex.exec(content);
		}
		
		removedReferencesInText = fieldReferences.filter((fieldReference) => currentReferencesInText.findIndex((inTextReference) => inTextReference.id === fieldReference.id) === -1);
		setRemovedReferences(removedReferencesInText);
		
		regex = /\[F\]/g;
		match = regex.exec(content)
		while (match) {
 
 			highest++;
 
			let id = footnoteLetter + highest;
			let newReference = {
				id: id,
				value: "",
				fieldReference: fieldName,
				letter: footnoteLetter,
				index: highest,
				type: "new"
			};
			
			newReferencesForDialog.push(newReference);
			
			match = regex.exec(content);
		}
		
		setNewReferences(newReferencesForDialog);
		setShowDialog(true);
	}
	
	function handleCancelDialog() {
		
		setNewReferences([]);
		setShowDialog(false);
	}
	
	function handleSaveReferences() {
		
		let results = (globalReferences === undefined) ? [] : [...globalReferences];
		
		let currentContent = content;
		
		let updatedContent = false;
		let updatedNew = false;
		let updatedRemoved = false;

		if (newReferences.length > 0) {
			
			let regex = /\[F\]/;
			for (let i=0; i < newReferences.length; i++) {
	 
	 			if (newReferences[i].type === "new") {
					
					currentContent = currentContent.replace(regex, "[" + newReferences[i].id + "]");
					updatedContent = true;
				}
				
				results.push(newReferences[i]);
			}
			
			if (updatedContent) {
				setContent(currentContent);
				onContentChanged(fieldName, currentContent);
			}
			
			updatedNew = true;
		}
		
		if (removedReferences.length > 0) {
			
			results = results.filter((globalReference) => removedReferences.findIndex((removedReference) => removedReference.id === globalReference.id) === -1);

			updatedRemoved = true;
		}
		
		if (updatedNew || updatedRemoved) {
		
			onUpdateReferences(results);
		}
		
		setShowDialog(false);
	}
	
	function handleUpdateNewReferences(list) {
		
		setNewReferences(list);
	}
	
	return (
		<div>		
			<Row md={12}>
				<Col  md={12} className="form-group">
					<TextField 
						{...other} 
						value={content} 
						onChange={(e) => handleChange(e.target.value)}  
						/>
				</Col>
			</Row>
			{!props.disabled && (
			<Row md={12}>
				<Col  md={12} >
					<Stack direction="row" justifyContent="end">
						<Button variant="contained" onClick={handleFootnotesCheck}>F</Button>
					</Stack>
				</Col>
			</Row>)}
			
			{showDialog && 
				<Dialog key={fieldName} 
					title={"Fußnoten bearbeiten"}
					onCancel={handleCancelDialog} 
		 			actions={[{ title: "Speichern", onAction: handleSaveReferences }]} 
		 			maxWidth={Constants.DialogWidth.LG} >
		 			<div>
		 				<ReferencesList 
		 					title="Neue Fußnoten"
							references={newReferences}
							onUpdateReferences={handleUpdateNewReferences} 
							allowDelete={false}						 
						/>
						<ReferencesList 
							title="Im Text entfernte Fußnoten" 
							references={removedReferences}
						/>
						<ReferencesList 
							title="Alle registrierten Fußnoten"
							references={fieldReferences}
						/>
		 			</div>
		 		</Dialog>
			}
			
		</div>

	);
}