import React from 'react'
import { Row, Col } from "react-bootstrap";

export default class DetailElementMasse extends React.Component {
	
  	render() {
    	return (<div>
	        {this.props.value.hoehe && this.props.value.breite && this.props.value.tiefe ? 
      			<Row >
					<Col md={3} style={{backgroundColor: '#ffffff'}}>
						<strong>{this.props.name}</strong>
       				</Col>
					<Col  md="auto" style={{backgroundColor: '#ffffff'}}></Col>
					<Col md={{ span: 10, offset: 1 }} style={{backgroundColor: '#fbfbfb'}}>
						{this.props.value.hoehe} x {this.props.value.breite} x {this.props.value.tiefe} cm
       				</Col>
      		</Row>
			: ""}</div>
			
    )
  }
}

