import axios from "axios";
import authHeader from './auth-header';
import * as Constants from "../components/constants";


class SchedulerService {
	
	async createJobForCementryPicturesReportPdfGeneration(cementryId) {
		const response = await axios.get(Constants.JOBS_API_URL + 'pdf/cemetery/pictures/report/' + cementryId, { headers: authHeader() });
		return response;
  	}
  	
  	async getJobStatusCementryPicturesReportPdf(cementryId) {
		const response = await axios.get(Constants.JOBS_API_URL + 'pdf/cemetery/pictures/report/' + cementryId + "/status", { headers: authHeader() });
		return response;
  	}
  	
  	async downloadCementryPicturesReportPdf(cementryId) {
		const response = await axios.get(Constants.JOBS_API_URL + 'pdf/cemetery/pictures/report/download/' + cementryId, {headers: authHeader(), responseType: 'blob' });
		return response;
  	}
  	
}
export default new SchedulerService();