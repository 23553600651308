import React from 'react'
import { Row, Col, Table } from "react-bootstrap";
import { checkArray } from '../helpers/util';


const InscriptionRow = ({linenumber, text, u1, u0}) => (
    <tr>
        <td  style={{width: "50%"}}>
            <div dir='auto' align='right'>{text[linenumber]}</div>
            
        </td>
        <td style={{backgroundColor: '#ffffff'}}>{(linenumber + 1) % 5 === 0 ? linenumber + 1 : ""}</td>
        <td style={{width: "50%"}}>
			{checkArray(u1)? <div>{u1[linenumber]}</div> : <div>{u0[linenumber]}</div>}
        </td>
    </tr>
)

export default class DetailElementInscription extends React.Component {

  	render() {
	 let linenumber_text = 0;
	 let linenumber_back = 0;
	 let linenumber_foot = 0;
    	return (<div>
			{ checkArray(this.props.value.vorderseite.text) || checkArray(this.props.value.rueckseite.text) || checkArray(this.props.value.sockel.text)?
				<div>
      			<Row >
					<Col style={{backgroundColor: '#ffffff'}}>
						<strong>{this.props.name}</strong>
						{this.props.rights? this.props.rights.map((entry, id)=>( entry.reference === "inscription"?<div key={id}>{entry.rightChoices.map((right)=>(<div key={entry.id}>{right.value}{right.comment?" | "+right.comment:""}</div>))}</div>:"" )):""}
					</Col>
      			</Row>
      			
				</div>
			:""} 
	        { checkArray(this.props.value.vorderseite.text)? 
				<div>
				<Row >
					<Col md={{ span: 11, offset: 1 }} style={{backgroundColor: '#ffffff'}}>
						<strong>Vorderseite</strong>
					</Col>
      			</Row>
				<Row >

					<Col md={{ span: 11, offset: 1 }} style={{backgroundColor: '#fbfbfb'}}>	
					 <Table>
                        <thead>
                            <tr>
                                <th>
                                    Inschrift
                                    
                                </th>
                                <th></th>
                                <th>
                                    Übersetzung
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.value.vorderseite.text.map((line, i) => <InscriptionRow 
																					
																					linenumber={linenumber_text++} 
																					text={this.props.value.vorderseite.text} 
																					u1={this.props.value.vorderseite.uebersetzung_1}
																					u0={this.props.value.vorderseite.uebersetzung_0}  
																					key={i}/>)}
                        </tbody>
                    </Table>
       				</Col>
      			</Row> 
				</div>
			: ""}
			 {checkArray(this.props.value.sockel.text) ? 
				<div>
      			<Row >
					<Col md={{ span: 11, offset: 1 }} style={{backgroundColor: '#ffffff'}}>
						<strong>Postament</strong>
					</Col>
      			</Row>
				<Row >
					<Col md={{ span: 11, offset: 1 }} style={{backgroundColor: '#fbfbfb'}}>	
					 <Table>
                         <tbody>
                            {this.props.value.sockel.text.map((line, i) => <InscriptionRow 
																					linenumber={linenumber_foot++} 
																					text={this.props.value.sockel.text} 
																					u1={this.props.value.sockel.uebersetzung_1}
																					u0={this.props.value.sockel.uebersetzung_0}  
																					key={i}/>)}
                        </tbody>
                    </Table>
       				</Col>
      			</Row> 
				</div>
			: ""}
			
			 {checkArray(this.props.value.rueckseite.text) ? 
				<div>
      			<Row >
					<Col md={{ span: 11, offset: 1 }} style={{backgroundColor: '#ffffff'}}>
						<strong>Rückseite</strong>
					</Col>
      			</Row>
				<Row >
					<Col md={{ span: 11, offset: 1 }} style={{backgroundColor: '#fbfbfb'}}>	
					 <Table>
                         <tbody>
                            {this.props.value.rueckseite.text.map((line, i) => <InscriptionRow 
																					linenumber={linenumber_back++} 
																					text={this.props.value.rueckseite.text} 
																					u1={this.props.value.rueckseite.uebersetzung_1} 
																					u0={this.props.value.rueckseite.uebersetzung_0} 
																					key={i}/>)}
                        </tbody>
                    </Table>
       				</Col>
      			</Row> 
				</div>
			: ""}
			
			
			</div>
			
    )
  }
}
