import React, { useState, useEffect } from "react";

import PropTypes from 'prop-types';
import { Row, Col } from "react-bootstrap";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { green, blue } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';

export default function ProgressIndicator(props) {

	const { cementryId, labelGenerationButton, labelGenerationFinishedButton, labelDownloadButton, getStatus, onGenerate, onDownload, registerTimerId } = props;
		
	const [jobExecuting, setJobExecuting] = useState(false);
	const [fileAvailable, setFileAvailable] = useState(false);
	
	const [progress, setProgress] = useState(0);

	const [success, setSuccess] = useState(false);
	const [message, setMessage] = useState("");
	
	//const [timerId, setTimerId] = useState(null);
	
	var timerId = null;
	
	function getTimerId() {
		
		return timerId;
	}
	
	function setTimerId(timer) {
		
		timerId = timer;
		
		registerTimerId(timerId);
	}
	
	const buttonSx = {
		...(success && {
			bgcolor: green[500],
			'&:hover': {
				bgcolor: green[700]
			},
		}),
	};
	
	const linkSx = {
		
		color: blue[500],
		'&:hover': {
			bgcolor: blue[100]
		},
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		typography: 'body1',
		'& > :not(style) + :not(style)': {
			ml: 2,
		}
	};
	
	
	
	CircularProgressWithLabel.propTypes = {
		/**
		 * The value of the progress indicator for the determinate variant.
		 * Value between 0 and 100.
		 * @default 0
		*/
		value: PropTypes.number.isRequired,
	};
	
	const updateStatus = () => {
		
		getStatus().then(
			response => {
				
				if (!response.data.jobExecuting) {
						
					clearUpdateInterval();

					setSuccess(response.data.fileAvailable);
				}
				setJobExecuting(response.data.jobExecuting);
				setFileAvailable(response.data.fileAvailable);
				setProgress(response.data.progressInPercent);

			},
			error => {
				const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
							
				setJobExecuting(false);
				setFileAvailable(false);
				setProgress(0);
				setMessage(resMessage);
				clearUpdateInterval();
		
				setSuccess(false);
			}
		);
	};
	
	const handleButtonClick = () => {
		
		if (!jobExecuting) {
			
			setSuccess(false);
			
			onGenerate().then(
				response => {
					
					clearUpdateInterval();
					
					setJobExecuting(true);
					
					setUpdateInterval();
	
				},
				error => {
					const resMessage =
								(error.response &&
									error.response.data &&
									error.response.data.message) ||
								error.message ||
								error.toString();
								
					setJobExecuting(false);
					setFileAvailable(false);
					setProgress(0);
					setMessage(resMessage);

				}
			);
		}
    };
    
    const handleDownload = (event) => {
		
		event.preventDefault();
		
		onDownload();
    };
    
    function clearUpdateInterval() {
		
		console.info(getTimerId());
		
		if (getTimerId() !== null) {
			
			clearInterval(getTimerId());
			
			setTimerId(null);
		}		
	}
	
	function setUpdateInterval() {
		
		console.info(getTimerId());
		
		var newTimerId = setInterval(() => { updateStatus(); }, 800);
		setTimerId(newTimerId);
		
		console.info(getTimerId());
	}
	
	function CircularProgressWithLabel(props) {
		return (
			<Box sx={{  m: 1, position: 'relative' }}>
				<CircularProgress variant="determinate" value={props.progress} size={52}
					sx={{
						color: green[500],
						top: -20,
						left: 0,
						zIndex: 1,
					}}
				 />
				 
				<Box sx={{
					top: -6,
					left: 2,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
					<Typography variant="caption" component="div" color="text.secondary">{`${Math.round(props.progress)}%`}</Typography>
				</Box>
			</Box>
		);
	};
		
	useEffect(() => {
			
		console.info(timerId);
		
		clearUpdateInterval();

		getStatus().then(
			response => {
				
				if (response.data.jobExecuting) {
					
					setUpdateInterval();
				}
				
				setJobExecuting(response.data.jobExecuting);
				
				setFileAvailable(response.data.fileAvailable);
				setProgress(response.data.progressInPercent);
		
				setSuccess(false);

			},
			error => {
				const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
							
				setJobExecuting(false);
				setFileAvailable(false);
				setProgress(0);
				setMessage(resMessage);		
				setSuccess(false);
			}
		);
			
	}, [cementryId]);

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			{!jobExecuting ? (<Box sx={{ m: 1, position: 'relative' }}>
				<Fab
					aria-label="save"
					color="primary"
					sx={buttonSx}
					{...(!success ? {onClick: handleButtonClick} : {})}
				>
					{success ? <CheckIcon /> : <DownloadIcon />}
				</Fab>
			</Box>) : (<CircularProgressWithLabel progress={progress} />)}
      
			<Box sx={{ m: 1, position: 'relative' }}>
				<Button
					variant="contained"
					sx={buttonSx}
					disabled={jobExecuting}
					{...(!success ? {onClick: handleButtonClick} : {})}
				>
					{success ? labelGenerationFinishedButton : labelGenerationButton }
				</Button>
				
				{jobExecuting && (
		          <CircularProgress
		            size={24}
		            sx={{
		              color: green[500],
		              position: 'absolute',
		              top: '50%',
		              left: '50%',
		              marginTop: '-12px',
		              marginLeft: '-12px',
		            }}
		          />
		        )}
			</Box>
			
			{fileAvailable && 
				(<Box
		      		sx={linkSx}
		      		onClick={handleDownload}
		    	>
		    		<Link href="#" underline="always">{labelDownloadButton}</Link>
    			</Box>)
    		}
    		{message && (
				<Row className="form-group">
					<Col className={success ? "alert alert-success": "alert alert-danger"} role="alert">{message}</Col>
				</Row>
			)}
		</Box>
		
	);
}