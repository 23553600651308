import React, { Fragment, useState, useEffect } from "react";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

export default function FootnoteLabel(props) {
	
	const { globalReferences, defaultValue } = props;

	const [textParts, setTextParts] = useState([]);
	
	function getReferenceIndex(foundFootnoteId) {
		
		return globalReferences.findIndex((footnote) => footnote.id === foundFootnoteId);
	}
	
	useEffect(() => {
		
		let regex = /\[([^\][]*)]/g;
		let match;
		
		let text = defaultValue;
		
		let currentTextParts = [];
		
		let foundFootnoteIdWithBrackets = null;
		let foundFootnoteId = null;
		
		let referenceListIndex = 0;
		
		match = regex.exec(defaultValue);
		while (match) {
			
			if (match.length > 1) {
				
				foundFootnoteIdWithBrackets = match[0];
				foundFootnoteId = match[1];
				
				referenceListIndex = getReferenceIndex(foundFootnoteId);
				if (referenceListIndex > -1) {
				
					let findRferenceRegex = new RegExp("\\\[" + foundFootnoteId + "\\\]"); //eslint-disable-line
					let referenceInTextIndex = text.search(findRferenceRegex); 
					
					let textPart = text.substring(0, referenceInTextIndex);
					let referencePart = foundFootnoteIdWithBrackets;
					
					let globalReference = globalReferences[referenceListIndex];
					
					currentTextParts.push({
						textPart: textPart,
						referencePart: {
							short: referencePart,
							id: globalReference.id,
							value: globalReference.value
						}	
					});
					
					text = text.substring((referenceInTextIndex) + foundFootnoteIdWithBrackets.length);
				}
			}
			
			match = regex.exec(defaultValue);
		}
		
		if (text !== "") {
			currentTextParts.push({
				textPart: text,
				referencePart: null	
			});
		}
		
		setTextParts(currentTextParts);
		
	}, [globalReferences]);

	const PopoverLink = ((props) => {
		
		const { id, link, text } = props;
		
		const [anchorEl, setAnchorEl] = React.useState(null);
		
		const handlePopoverOpen = (event) => {
			setAnchorEl(event.currentTarget);
		};
	
		const handlePopoverClose = () => {
			setAnchorEl(null);
		};
	
		const open = Boolean(anchorEl);
	
		return (
			<Fragment>
				<Link to={'#' + id} underline="always" aria-owns={open ? 'mouse-over-popover' : undefined}
					aria-haspopup="true"
					onMouseEnter={handlePopoverOpen}
					onMouseLeave={handlePopoverClose} 
					sx={{ fontSize: 10 }}>{link}</Link>
					
				<Popover
					id="mouse-over-popover"
					sx={{
						pointerEvents: 'none',
					}}
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					onClose={handlePopoverClose}
					disableRestoreFocus
				>
					<Card sx={{ maxWidth: 345 }}>
						
						<CardContent>
					
							<Typography variant="body2" color="text.secondary" >{text}</Typography>
  						</CardContent>
  					</Card>
		      </Popover>
			</Fragment>
		);
	});
	
	return (
		<Fragment>
			{textParts.map((part, index) => (
				<Fragment key={index} >
					<Box component="span">{part.textPart}</Box>
					{part.referencePart ? (
						<sup>
							{part.referencePart && <PopoverLink 
								id={part.referencePart.id}
								link={part.referencePart.short} 
								text={part.referencePart.value} />
							}
						</sup>)
					:""}
				</Fragment>
			))}	
		</Fragment>	
	);
}