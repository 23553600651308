import React, { Fragment, useState, useEffect } from "react";

import ElasticSearchService from "../../../../services/elasticSearch.service";

import { Row, Col } from "react-bootstrap";

import Stack from '@mui/material/Stack';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import Tooltip from '@mui/material/Tooltip';

import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function ConservationForMultipleTombstones(props) {
	
	const { readOnly, cementeryId, assignedTombstones, onChange } = props;
	
	const [allTombstones, setAllTombstones] = useState([]);
	const [tombstoneCounter, setTombstoneCounter] = useState(0);
	
	const [selectedTombstone, setSelectedTombstone] = useState(null);
	const [tombstonesForConservation, setTombstonesForConservation] = useState([]);
	
	const [loadingTombstones, setLoadingTombstones] = useState(false);
	
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	function handleChangeTombstone(newValue) {
		
		let currentTombstoneId = newValue !== null ? newValue.label : null;
		
		setSelectedTombstone(currentTombstoneId);
	}
	
	function handleAddTombstoneToConservation() {
		
		var currentTombstonesForConservation = [...tombstonesForConservation];
		
		if (selectedTombstone !== null) {
			let index = currentTombstonesForConservation.findIndex((tombstoneForConservation) => tombstoneForConservation.label === selectedTombstone);
			if (index === -1) {
				
				currentTombstonesForConservation.push({
					id: selectedTombstone,
					label: selectedTombstone
				});
				
				setTombstonesForConservation(currentTombstonesForConservation);
				
				onChange(currentTombstonesForConservation.map((item) => {
					return item.id
				}));
			}
		}
	}
	
	function handleRemoveTombstoneFromConservation() {
		
		// ToDo
	}
	
	function handleChangeAssignedTombstones(label, status) {
		
		var currentTombstonesForConservation = [...tombstonesForConservation];
		
		if (!status) {
		
			let index = currentTombstonesForConservation.findIndex((tombstoneForConservation) => tombstoneForConservation.label === label);
			if (index > -1) {
				
				currentTombstonesForConservation.splice(index, 1);
				
				setTombstonesForConservation(currentTombstonesForConservation);
				
				onChange(currentTombstonesForConservation.map((item) => {
					return item.id
				}));
			}
		}
	}
	
	useEffect(() => {
	
		console.info("conservation for multiple tombstones rendered");
		
		setTombstonesForConservation([]);
		
		ElasticSearchService.loadTombstones(cementeryId).then(
			response => {
				
				let options = response.data.tombstones.map((option) => {
					
					return {
						id: option.id,
						label: option.grabstein.grabsteinnummer
					}
				});
				
				setAllTombstones(options);
				setTombstoneCounter(response.data.numberOfTombstones);

				setLoadingTombstones(false);
				setSuccessful(true);

			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setMessage(resMessage);
				setSuccessful(false);
				setLoadingTombstones(false);
			}
		);
		 
	}, [assignedTombstones]);
	
	const CheckboxListItem = ((props) => {
		
		const { name, onChange } = props;
  
		const [checked, setChecked] = useState(true);

		const handleToggle = () => () => {
					
		    setChecked(!checked);
		    
		    onChange(name, !checked);
		};
		
		const labelId = `checkbox-list-label-${name}`;

		return (

			<ListItem
				disablePadding
      		>
				<ListItemButton role={undefined} onClick={handleToggle()} dense>
					<ListItemIcon>
						<Checkbox
							edge="start"
							checked={checked}
							tabIndex={-1}
							disableRipple
							inputProps={{ 'aria-labelledby': labelId }}
						/>
					</ListItemIcon>
					<ListItemText id={labelId} primary={name} />
				</ListItemButton>
			</ListItem>
	        	
		);
	});
	
	return (
		<>
			{allTombstones.length > 0 ? 
						
				(<>
					<Row>
						<Col md={11}>
							<Autocomplete sx={{ mt: 2, ml: 2 }} 
								id="actions-list"
								options={allTombstones}
								value={selectedTombstone} 
								filterSelectedOptions 
								isOptionEqualToValue={(option, value) => (value !== null && option.label === value)} 
								onChange={(event, newValue) => handleChangeTombstone(newValue)}
								renderInput={(params) => <TextField {...params} label="Wählen Sie den Grabstein" />} 
								disabled={readOnly}
							/>
						</Col>
						<Col md={1}>
							<Stack sx={{ mt: 2 }} direction="row" justifyContent="space-between" alignItems="center">							
								<Tooltip title={"Grabstein zur Maßnahme hinzufügen"} arrow placement="right">
									<span>
										<IconButton onClick={handleAddTombstoneToConservation} ><AddCircleIcon /></IconButton>
									</span>
								</Tooltip>
							</Stack>
						</Col>
					</Row>
					<Row md={12}>
						<Col md={12}>
							<Grid sx={{ maxHeight: 500, overflowX : "hidden", ml: 2 }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
								{tombstonesForConservation.map((tombstone, tombstoneIndex) => (
									<Grid sx={{ xs: 6, maxHeight: 100 }} key={tombstoneIndex}>
    									<Item>
											<CheckboxListItem 
												name={tombstone.label} 
												onChange={handleChangeAssignedTombstones} 
											/>
										</Item>
  									</Grid>
								))}
							</Grid>
						</Col>
					</Row>
					<Row>
						<Col></Col>
					</Row>
				</>)
				:
				(
					<Box sx={{ boxShadow: 3 }} key={-1}>
						<Row>
							<Col md={12}></Col>
						</Row>
						<Row md={12}>
							<Col md={12}>
								<Stack direction="row" justifyContent="center" alignItems="center">
									<Box><strong>Keine Grabsteine gefunden</strong></Box>
								</Stack>
							</Col>
						</Row>
					</Box>
				)}
		   	<Row>
				<Col md={12}></Col>
			</Row>
			{message && (
				<div className="form-group">
					<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
				</div>
			)}
		</>
	);
}