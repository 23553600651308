import React, { useState, useEffect} from "react";

import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { handleStorage, setStorage } from '../../helpers/jfb'


export default function ConsentDialog(props) {
    
  	const { onOpen, onCancel, onAproval } = props;
  	  	
  	const [openConsentDialog, setOpenConsentDialog] = useState(false);
  	
  	const [googleMapConsent, setGoogleMapConsent] = useState(handleStorage("googleMapConsent").length!==0 ? Boolean(handleStorage("googleMapConsent")) : false);
  	const [requiredConsent, setRequiredConsent] = useState(handleStorage("requiredConsent").length!==0 ? Boolean(handleStorage("requiredConsent")) : true);
    
    
	const handleClose = () => {
		
		setStorage("googleMapConsent", true);
		setStorage("requiredConsent", true);	
		setConsentConfirmitionExpire();
		if (onCancel) onCancel();
	};
	
	const handleApproval = () => {
		setOpenConsentDialog(false);
		setRequiredConsent(true)
		setStorage("requiredConsent", true);
		setConsentConfirmitionExpire();
		if (onCancel) onCancel();
	};
	
	
	const handleGoogleMapsConsentChange = (e) => {
		let googleMapConsent = e.target.checked;
		setGoogleMapConsent(googleMapConsent)
		setStorage("googleMapConsent", googleMapConsent)
		setConsentConfirmitionExpire();
		
	};
	
	const handleRequiredConsentChange = (e) => {
	
		let requiredConsentValue = e.target.checked;
		setRequiredConsent(requiredConsentValue)
		setConsentConfirmitionExpire();	
	};
	
	const setConsentConfirmitionExpire = (e) => {
		
		const today = new Date();
		today.setDate(today.getDate() + 90);	
		setStorage("lastConsentConfirmation", today )
	};
	
	useEffect(() => {
		
		
		
		setStorage("googleMapConsent", googleMapConsent);
		setStorage("requiredConsent", requiredConsent);	
		
		setOpenConsentDialog(onOpen);
		
		

	});

	return (
	  
	  <Dialog open={openConsentDialog} onClose={handleApproval} className="dialog-style" maxWidth={"md"}>
			<DialogTitle>Datenschutz</DialogTitle>
			<DialogContent >
				<DialogContentText>				
				Diese Seite nutzt neben technisch notwendigen Cookies den Dienst und das Kartenmaterial von Google Maps. 
				Indem Sie die Nutzung von Google Maps aktivieren können Sie die interaktive Kartenfunktion nutzen. 
				Dafür gelten die Datenschutzerklärungen von Google. Weitere Informationen zum Datenschutz finden Sie in unseren	<a href="/impress"><strong> Datenschutzhinweisen</strong></a>.
				</DialogContentText>
				<br/>
				<FormGroup>
  					<FormControlLabel control={<Switch checked={requiredConsent}/>} label="Technisch Notwendige Daten" onChange={handleRequiredConsentChange}/>
  					<FormControlLabel required control={<Switch checked={googleMapConsent}/>} label="Nutzung von Google Maps" onChange={(e) => handleGoogleMapsConsentChange(e)}/>
				</FormGroup>
			</DialogContent>
	
	
			<DialogActions>
				<Button onClick={handleClose} >Alles Akzeptieren & Speichern</Button>{' '}
				<Button onClick={handleApproval} >Auswahl Akzeptieren, Ablehnen & Speichern</Button>{' '}
			</DialogActions>
		</Dialog>
	);
}
