import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";

import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import * as Constants from "../../../components/constants";
import ACService from "../../../helpers/accessValidation.js"
import AuthService from "../../../services/auth.service";

export default function ReferencesList(props) {
	
	const [user] = useState(AuthService.getCurrentUser());
	
	const { title, references, onUpdateReferences, allowDelete, hideNoResults, isReadOnly } = props;
	
	const [deleteReference, setDeleteReference] = useState(true);
	const [readOnly, setReadOnly] = useState(isReadOnly ? true : false);
	
	useEffect(() => {
		
		setReadOnly(isReadOnly || !ACService.accessValidation(user, Constants.AC_EDIT_REFERENCES_LIST));
		
	}, [user, isReadOnly]);
	
	useEffect(() => {
		
		setDeleteReference(allowDelete === undefined ? true : allowDelete);
		
	}, [references]);
	
	function handleDelete(footnoteId) {
	
		let list = [...references];
		let footnoteIndex = list.findIndex((footnote) => footnote.id === footnoteId);
		if (footnoteIndex > -1) {
			list.splice(footnoteIndex, 1);
		}
		
		onUpdateReferences(list);
	}
	
	function handleFootnoteDescriptionChange(footnoteId, description) {
	
		let list = [...references];
		
		let footnoteIndex = list.findIndex((footnote) => footnote.id === footnoteId);
		if (footnoteIndex > -1) {
			list[footnoteIndex].value = description;
		}
		
		onUpdateReferences(list);
	}
	
	if (references.length > 0) {
	
		return (
			<Container>
				{title ? (
					<Row>
						<Col><h3>{title}</h3></Col>
					</Row>
				) : ""}
				{(onUpdateReferences ? references.map((reference) => (
						
					<Row md={12} key={reference.id}>
						<Col md={1}>
							<TextField label="Kürzel" 
									name="footnote-short"
									defaultValue={reference.id}
									disabled />
						</Col>
						<Col md={9}>
							<TextField 
								fullWidth 
								{...((onUpdateReferences === undefined || readOnly) ? {disabled: true}:{})} 
								label="Beschreibung" 
								name="footnote-description"
								defaultValue={reference.value} 
								{...((onUpdateReferences !== undefined && !readOnly) ? {onChange: (e) => handleFootnoteDescriptionChange(reference.id, e.target.value)}:{})}
								 />
						</Col>
						<Col md={1}>
							{deleteReference && onUpdateReferences && !readOnly && (
								<Tooltip title="Fußnote entfernen" arrow placement="right">
									<IconButton onClick={() => handleDelete(reference.id)} ><DeleteIcon /></IconButton>
								</Tooltip>
							)}
						</Col>
					</Row>	
					)) : references.map((reference) => (
						<section key={reference.id} id={reference.id}>
							<Row md={12} >
								<Col md={1}>
									<span>{reference.id}:</span>
								</Col>
								<Col md={10}>
									<span>{reference.value}</span> 
								</Col>
							</Row>
						</section>
					)) 
				)}
			</Container>
		);
	} else {
		
		return (
			!hideNoResults && 
				(<Container>
					<Row>
						<Col><h3>{title ? title : ""}</h3></Col>
					</Row>
					<Row md={12} key={0}>
						<Col md={1}></Col>
						<Col md={9}>Keine Ergebnisse</Col>
						<Col md={1}></Col>
					</Row>
				</Container>)
		)
	}
}