import React, { useState } from "react";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
        <CloseIcon />  
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function StandardDialog(props) {
    
  	const [maxWidth] = useState(props.maxWidth ? props.maxWidth : 'sm');
    const [open, setOpen] = useState(true);
    
	const { title, actions, onCancel, cancelTitle, children } = props;

	const handleClose = (cancelAction) => {
		setOpen(false);
		
		if (cancelAction) cancelAction();
	};
  
	function handleAction(onAction) {
		
		setOpen(false);
		
		onAction();
	};

  return (
	  <BootstrapDialog 
	    onClose={onCancel ? () => handleClose(onCancel) : undefined}
	    aria-labelledby="customized-dialog-title" 
	    fullWidth={true}
        maxWidth={maxWidth} 
	    open={open}
	  >
	    <BootstrapDialogTitle id="customized-dialog-title" onClose={() => handleClose(onCancel)} >
	      {title}
	    </BootstrapDialogTitle>
	    <DialogContent dividers>
	      {children}
	    </DialogContent>
	    <DialogActions>
	    	{onCancel && <Button key="Abbrechen" autoFocus onClick={() => handleClose(onCancel)}>{cancelTitle ? cancelTitle : "Abbrechen"}</Button>}
	    	{actions.map((action) => (
				<Button key={action.title} autoFocus onClick={() => handleAction(action.onAction)}>{action.title}</Button>
			))}
	    </DialogActions>
	  </BootstrapDialog>
  );
}
