import React, { useState, useEffect} from "react";
import { Container, Row, Col, Breadcrumb, Button } from 'react-bootstrap'

import { Link } from "react-router-dom";
import * as Constants from "../../components/constants";

import AuthService from "../../services/auth.service";
import ACService from "../../helpers/accessValidation.js";
import ElasticSearchService from "../../services/elasticSearch.service";

import { disableForms } from "../../helpers/jfb.js";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Grid from '@mui/material/Unstable_Grid2';
import DashboardLink from "../standard/dashboardLink";

import LockIcon from '@mui/icons-material/Lock';

import PasswordIcon from '@mui/icons-material/Password';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import HouseIcon from '@mui/icons-material/House';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BalconyIcon from '@mui/icons-material/Balcony';
import SearchIcon from '@mui/icons-material/Search';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ContactsIcon from '@mui/icons-material/Contacts';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import FactoryIcon from '@mui/icons-material/Factory';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';

export default function Dashboard() {

	const [user] = useState(AuthService.getCurrentUser());
	
	const [open, setOpen] = useState(false);
	const [cementriesForPersons, setCementriesForPersons] = useState([]);
	const [selectedCemetryDialogForPersons, setSelectedCemetryDialogForPersons] = useState('');
	const [isLoadingCementries, setLoadingCementries] = useState(true);
	
	const [openTombstoneDialog, setOpenTombstoneDialog] = useState(false);
	const [cementriesForTombstones, setCementriesForTombstones] = useState([]);
	const [selectedCemetryDialogForTombstones, setSelectedCemetryDialogForTombstones] = useState('');
	
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	const handleOpenDialog = () => {
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setOpen(false);
	};

	const handleCancelDialog = () => {
		setOpen(false);
		setSelectedCemetryDialogForPersons('');
	};

	function handleCementryChangeDialogForPersons(e) {
		let id = e.id;

		setSelectedCemetryDialogForPersons(id);
	}
	
	function handleCementryChangeDialogForTombstones(e) {
		let id = e.id;

		setSelectedCemetryDialogForTombstones(id);
	}
	
	const handleOpenTombstoneDialog = () => {

		setOpenTombstoneDialog(true);
	};

	const handleCloseTombstoneDialog = () => {
		setOpenTombstoneDialog(false);
	};
	
	
	useEffect(() => {

		if (isLoadingCementries) {
			ElasticSearchService.loadCementriesForAutoComplete(-1).then(
				response => {
					
					let cementries = response.data;
					let cementriesForTombstones = [...cementries];
					
					cementriesForTombstones.shift();
					
					setCementriesForPersons(cementries);
					setCementriesForTombstones(cementriesForTombstones);
					
					setLoadingCementries(false);
					setSuccessful(true);
				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setMessage(resMessage);
					setSuccessful(false);
					setLoadingCementries(false);
				}
			);
		}

	}, [isLoadingCementries]);
	
	return (
		<Container fluid="xxl">
			<Row className="top-spacer">
				<Col>
					<Breadcrumb className="breadcrumb-style">
						<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
						<Breadcrumb.Item active>Übersicht</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			{user === null && (
			<Row>
				<Col>
					<h4>Anmelden</h4>
					<Grid sx={{ ml: 2 }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid key="1">
	    					<DashboardLink AvatarIcon={LockIcon} title="Anmelden" subheader="Anmelden" linkDescription="Bitte melden Sie sich an." linkPath="/login" />
	  					</Grid>
					</Grid>
				</Col>
			</Row>
			)}
			{user !== null && (
			<Row>
				<Col>
					<h4>Profil ({user.username})</h4>
					<Grid sx={{ ml: 2 }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid key="1">
	    					<DashboardLink AvatarIcon={PasswordIcon} title="Passwort Änderung" subheader="Password Änderung" linkDescription="Sie können Ihr Passwort jeder Zeit ändern. Es wird empfohlen dies regelmäßig durchzuführen." linkPath="/password" />
	  					</Grid>
	  					<Grid key="2">
	    					<DashboardLink AvatarIcon={LockOpenIcon} title="Abmelden" subheader="Abmelden" linkDescription="Hier können Sie sich von diesem Dienst abmelden." linkPath="/home" onClick={AuthService.logout} />
	  					</Grid>
					</Grid>
				</Col>
			</Row>
			)}
			<Row>
				<Col>
					<h4>Navigation</h4>
					<Grid sx={{ ml: 2 }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid key="1">
	    					<DashboardLink AvatarIcon={HouseIcon} title="Home" subheader="Startseite" linkDescription="Startseite mit allen Informationen, um mit der Verwendung des Dienstes zu beginnen." linkPath="/home" />
	  					</Grid>
	  					<Grid key="2">
	    					<DashboardLink AvatarIcon={AccountBalanceIcon} title="Friedhöfe" subheader="Liste der Friedhöfe" linkDescription="Eine Liste aller verfügbaren Friedhöfe mit einer Such-Funktion und der Möglichkeit die Auswahl über eine Karte zu treffen." linkPath="/cemeteries" />
	  					</Grid>
	  					<Grid key="3">
	    					<DashboardLink AvatarIcon={BalconyIcon} title="Grabsteine" subheader="Liste der Grabsteine" linkDescription="Eine Liste aller verfügbaren Grabsteine mit einer Such-Funktion und einer Übersicht auf der Karte." linkPath="/tombstones" />
	  					</Grid>
	  					<Grid key="4">
	    					<DashboardLink AvatarIcon={SearchIcon} title="Suche" subheader="Suche über Grabsteine und Personen" linkDescription="Die Suche wird über den gewünschten Friedhof oder alle Friedhöfe durchgeführt. Sie kann Suchbegriffe auf Grabsteine oder Personen einschränken." linkPath="/search" />
	  					</Grid>
	  					<Grid key="5">
	    					<DashboardLink AvatarIcon={QueryStatsIcon} title="Auswertungen" subheader="Individuelle Auswertungen" linkDescription="Diesen Bereich können Sie nutzen, um auf einem Friedhof nach bestimmten Grabsteinen oder Personen zu suchen, die eine oder mehrere Bedingungen erfüllen." linkPath="/listGenerator" />
	  					</Grid>
	  					<Grid key="6">
	    					<DashboardLink AvatarIcon={EmojiObjectsIcon} title="Hintergründe" subheader="Verein Bet Olam" linkDescription="Im September 2017 wurde der Verein Bet Olam gegründet, der es sich zum Ziel gesetzt hat, das gefährdete Baudenkmal im Rahmen eines LEADER-Projekts zu erforschen." linkPath="/betolam" />
	  					</Grid>
	  					<Grid key="7">
	    					<DashboardLink AvatarIcon={ContactsIcon} title="Kontakt" subheader="Kontakt" linkDescription="Bet Olam c/o Fränkisches Museum Feuchtwangen." linkPath="/contact" />
	  					</Grid>
	  					<Grid key="8">
	    					<DashboardLink AvatarIcon={ImportContactsIcon} title="Impressum" subheader="Impressum" linkDescription="Anbieter, Geschäftsstelle und Kontakt." linkPath="/impress" />
	  					</Grid>
					</Grid>	
				</Col>
			</Row>
			
			{user !== null && ACService.accessValidationWithoutCementery(user, Constants.AC_ADMIN_NAVIGATION) && (
			<Row>
				<Col>
					<h4>Administration</h4>
					<Grid sx={{ ml: 2 }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid key="1">
	    					<DashboardLink AvatarIcon={HouseIcon} title="Home" subheader="Startseite" linkDescription="Startseite mit allen Informationen, um mit der Verwendung des Dienstes zu beginnen." linkPath="/home" />
	  					</Grid>
	  					<Grid key="2">
	    					<DashboardLink AvatarIcon={PeopleIcon} title="Benutzerübersicht" subheader="Benutzerübersicht" linkDescription="Die Übersicht aller im System vorhandenen Benutzer." linkPath="/userManagement" />
	  					</Grid>
	  					<Grid key="3">
	    					<DashboardLink AvatarIcon={PersonAddIcon} title="Neuen Benutzer anlegen" subheader="Neuen Benutzer anlegen" linkDescription="Neuer Bneutzer wird über diese Maske im System angelegt." linkPath="/register" />
	  					</Grid>
	  					<Grid key="4">
	    					<DashboardLink AvatarIcon={AutoFixHighIcon} title="Vermessung" subheader="Vermessungsdatenmanagement" linkDescription="Vermessungsdaten zu einem bestimmten Friedhof können hier hochgeladen werden." linkPath="/measurementManagement" />
	  					</Grid>
	  					<Grid key="5">
	    					<DashboardLink AvatarIcon={AddAPhotoIcon} title="Bilddatenmanagement" subheader="Bilddatenmanagement" linkDescription="Das Bildmaterial zu den Grabsteinen wird hier hochgeladen." linkPath="/photoUpload" />
	  					</Grid>
					</Grid>	
				</Col>
			</Row>
			)}
			{user !== null && (ACService.accessValidationWithoutCementery(user, Constants.AC_ADMIN_NAVIGATION) || ACService.accessValidationWithoutCementery(user, Constants.AC_MANAGER_NAVIGATION)) && (
			<Row>
				<Col>
					<h4>Management</h4>
					<Grid sx={{ ml: 2 }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid key="1">
	    					<DashboardLink AvatarIcon={AccountBalanceIcon} title="Friedhöfe" subheader="Friedhofsverwaltung" linkDescription="Es können Details der Friedhöfe eingesehen und geändert werden." linkPath="/cemeteryOverview" />
	  					</Grid>
	  					<Grid key="2">
	    					<DashboardLink AvatarIcon={FactoryIcon} title="Hersteller" subheader="Liste der Hersteller" linkDescription="Eine Liste aller Hersteller samt Beispielen ihrer Werke." linkPath="/makers" />
	  					</Grid>
	  					<Grid key="3">
	    					<DashboardLink AvatarIcon={FormatListBulletedIcon} title="Auswahllisten" subheader="Listen von Auswahlmöglichkeiten" linkDescription="Die im System vorhandenen Auswahllisten können hier gepflegt und angelegt werden." linkPath="/choices" />
	  					</Grid>
	  					<Grid key="4">
	    					<DashboardLink AvatarIcon={AssessmentIcon} title="Auswertungen" subheader="Reportverwaltung" linkDescription="Alle erfassten Auswertungen werden hier dargestellt." linkPath="/reports" />
	  					</Grid>
	  					<Grid key="5">
	    					<DashboardLink AvatarIcon={HealthAndSafetyIcon} title="Konservierungen" subheader="Liste der Konservierungen" linkDescription="Die Konservierungen werden pro Friedhof festgehalten und können hier bearbeitet, gedruckt und neue Maßnahmen geplant werden." linkPath="/conservationList" />
	  					</Grid>
	  					<Grid key="6">
	    					<DashboardLink AvatarIcon={BalconyIcon} title="Grabstein erfassen" subheader="Grabstein erfassen" linkDescription="Details zum Grabstein können hier eingegeben werden." linkPath="/home" onClick={handleOpenTombstoneDialog} />
	  					</Grid>
	  					<Grid key="7">
	    					<DashboardLink AvatarIcon={PersonAddIcon} title="Person erfassen" subheader="Person erfassen" linkDescription="Details zuPersonen können hier eingegeben werden." linkPath="/home" onClick={handleOpenDialog} />
	  					</Grid>
	  					<Grid key="8">
	    					<DashboardLink AvatarIcon={PrecisionManufacturingIcon} title="Hersteller erfassen" subheader="Hersteller erfassen" linkDescription="Details zu Herstellern können hier eingegeben werden." linkPath="/makerDetails/new" />
	  					</Grid>
					</Grid>						
				</Col>
			</Row>
			)}
			{user !== null && (ACService.accessValidationWithoutCementery(user, Constants.AC_ADMIN_NAVIGATION) || ACService.accessValidationWithoutCementery(user, Constants.AC_MANAGER_NAVIGATION) || ACService.accessValidationWithoutCementery(user, Constants.AC_REPORTING)) && (
			<Row>
				<Col>
					<h4>Analysen</h4>
					<Grid sx={{ ml: 2 }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid key="1">
	    					<DashboardLink AvatarIcon={QueryStatsIcon} title="Auswertungen" subheader="Individuelle Auswertungen" linkDescription="Diesen Bereich können Sie nutzen, um auf einem Friedhof nach bestimmten Grabsteinen oder Personen zu suchen, die eine oder mehrere Bedingungen erfüllen." linkPath="/listGenerator" />
	  					</Grid>
	  					<Grid key="2">
	    					<DashboardLink AvatarIcon={PeopleIcon} title="Kontaktabzug erstellen" subheader="Kontaktabzug erstellen" linkDescription="Die Übersicht aller Bilder kann hier heruntergeladen werden." linkPath="/cemeteryReport" />
	  					</Grid>
					</Grid>	
				</Col>
			</Row>
			)}	
			{user !== null && (ACService.accessValidationWithoutCementery(user, Constants.AC_ADMIN_NAVIGATION) || ACService.accessValidationWithoutCementery(user, Constants.AC_MANAGER_NAVIGATION) || ACService.accessValidationWithoutCementery(user, Constants.AC_EDIT_ASSESSMENTS)) && (
			<Row>
				<Col>
					<h4>Grabsteinbewertungen</h4>
					<Grid sx={{ ml: 2 }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid key="1">
	    					<DashboardLink AvatarIcon={NetworkCheckIcon} title="Zustandsbewertungen" subheader="Zustandsbewertungen" linkDescription="Der Zustand eines Grabmals wird hier erfasst und dokumentiert." linkPath="/assessments" />
	  					</Grid>
					</Grid>	
					<br/>
					<Link to="/"></Link>
				</Col>
			</Row>
			)}			
			<Row>
				<Col><hr /></Col>
			</Row>
			{message && (
			<Row>
				<Col className="form-group">
					<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
				</Col>
			</Row>
			)}
			
			<Row>
				<Col>
				<Dialog open={open} onClose={handleCloseDialog} >
					<DialogTitle>Neue Person anlegen</DialogTitle>
					<DialogContent >
						<DialogContentText>Bitte wählen Sie einen Friedhof, um eine neue Person anzulegen</DialogContentText>
						<br/>
						<Autocomplete
							onChange={(event, newValue) => { handleCementryChangeDialogForPersons(newValue); }}
							id="combo-box-cementries-dialog"
							options={cementriesForPersons}
							disableClearable
							renderInput={(params) => <TextField {...params} label="Bitte wählen Sie einen Friedhof" />}
						/>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleCancelDialog} variant="secondary">Abbrechen</Button>{' '}
						<Button disabled={disableForms(!selectedCemetryDialogForPersons)} onClick={handleCloseDialog} variant="secondary" href={"/management/person/" + selectedCemetryDialogForPersons + "/" + Date.now()+"/new"}>Person Anlegen</Button>
					</DialogActions>
				</Dialog>
				
				<Dialog open={openTombstoneDialog} onClose={handleCloseTombstoneDialog} >
					<DialogTitle>Neuen Grabstein anlegen</DialogTitle>
					<DialogContent >
						<DialogContentText>Bitte wählen Sie einen Friedhof, um eine neuen Grabstein anzulegen</DialogContentText>
						<br/>
						<Autocomplete
							onChange={(event, newValue) => { handleCementryChangeDialogForTombstones(newValue); }}
							id="combo-box-cementries-dialog"
							options={cementriesForTombstones}
							disableClearable
							renderInput={(params) => <TextField {...params} label="Bitte wählen Sie einen Friedhof" />}
						/>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleCloseTombstoneDialog} variant="secondary">Abbrechen</Button>{' '}
						<Button disabled={disableForms(!selectedCemetryDialogForTombstones)} onClick={handleCloseTombstoneDialog} variant="secondary" href={"/management/tombstone/" + selectedCemetryDialogForTombstones + "/-1/new"}>Grabstein anlegen</Button>
					</DialogActions>
				</Dialog>
				</Col>
			</Row>
		</Container>
	);
}
