import React, { Component } from 'react';
import { Container, Row, Col, Table, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

import AuthService from "../../services/auth.service";
import ElasticSearchService from "../../services/elasticSearch.service";

import * as Constants from "../../components/constants";

export default class UserManagement extends Component {
	
	constructor(props) {
		super(props);
		
		this.onSelect = this.onSelect.bind(this);
		this.onRemove = this.onRemove.bind(this);
		
		this.multiselectRef = React.createRef();

		this.state = {
			users: [],
			isLoading: true,
    		errors: null,
			successful: false,
			message: null,
			cementries: [],
			isLoadingCementries: true
		};
	}
	
	componentDidMount() {
		AuthService.loadUsers().then(
			response => {
			
				this.setState({
					users:  response.data,
					isLoading: false,
					
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);
		
		ElasticSearchService.loadCementries().then(
			response => {
			
				this.setState({
					cementries:  response.data,
					isLoadingCementries: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);
	}
	
	onSelect(e) {
		
	}
	
	onRemove(e) {
		
	}
	
	
	
	render() {
		const { users, isLoading, errors} = this.state;
		
		const Role = (({cementryName, cementryRoles}) => {
			
			if (cementryRoles.findIndex((roleName) => roleName === Constants.ROLE_ADMIN) > -1) {
				
				return (
					<div key={cementryName}>
						<div key={Constants.ROLE_ADMIN}>Administrator</div>
					</div>
				);
				
			} else {
			
				return (
					<Container>
						<Row md={12}>
							<Col md={12}>{cementryName === Constants.CEMENTERY_ALL ? "Alle" : cementryName}</Col>
						</Row>
						{cementryRoles.map((roleName) => {
							const roleInfo = Constants.ROLES.roles.find((roleInfo) => roleInfo.name === roleName);
							
							return (
								<Row md={12} key={roleName + "#" + cementryName}>
									<Col  md={2} className="form-group">
									</Col>
								
									<Col md={9}>{roleInfo.displayName}</Col>
									<Col md={1}></Col>
								</Row>
							)}
						)}
					</Container>
				);
			}
		});
		
		const TableErrorsMessage = (() => {
			
			return (
				<tr>
					<td colSpan="5">
						<div className="form-group">
							<div className={this.state.successful ? "alert alert-success" : "alert alert-danger"} role="alert">{this.state.message}</div>
						</div>
					</td>
				</tr>
			)
		});
		
		const TableErrors = (() => {
			
			return (
				<tr>
					<td colSpan="5">
						<div className="form-group">
							<div className="alert alert-danger" role="alert">{errors}</div>
						</div>
					</td>
				</tr>
			)
		});
		
		const TableItem = ((user) => {
			
			return (
				<tr key={user.userName}>
					<td>{user.userName}</td>
					<td>{user.active ? "Aktiv" : "Inaktiv"}</td>
					<td>{user.cementries.map((cementry) => {
						
						let cementeryName = cementry.name === Constants.CEMENTERY_ALL ? "Alle" : cementry.name;
							
						return (
						<div key={cementry.name}>{cementeryName}</div>
						)
					}
					)}
					</td>
					<td>
						{user.cementries.map((cementry) => (
						<Role key={cementry.name} 
							cementryName={cementry.name}
							cementryRoles={cementry.roles.map((role) => role.name)}
						/>
						))}
						
					</td>
					<td><Link to={"/userDetails/" + user.id}>Details</Link></td>
				</tr>
			)
		});
		
		const TableItems = (() => {
			
			return (
				<tbody>
					 {!isLoading ? (
						users.map((user) => (
							<TableItem 
								key={user.username} 
								id={user.id}
								userName={user.username}
								active={user.active}
								cementries={user.cementries}
								/>
						))
		        	 ) : (
			          <tr>
						<td colSpan="5">Loading...</td>
					  </tr>
		        	)}
	
					{this.state.errors && <TableErrors />}
					{this.state.message && <TableErrorsMessage />}
				</tbody>
			)
		});
		
		return (
			<Container fluid="xxl">
				<Row>
				<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Administration</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Benutzerübersicht</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" href="/register">Neuen Benutzer anlegen</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
				<Row>
					<Col><h3>Benutzerübersicht</h3></Col>
				</Row>
				
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>Benutzer</th>
							<th>Aktiv</th>
							<th>Friedhof</th>
							<th>Rechte</th>
							<th>Details</th>
						</tr>
					</thead>
					<TableItems />
				</Table>	
			</Container>			
		);
	}
}
