import * as Constants from "../components/constants";

var componentsRolesMatrix = [
	{ name: Constants.AC_ADMIN_NAVIGATION, admin: true, manager: false, cementryRoles: [] },
	{ name: Constants.AC_MANAGER_NAVIGATION, admin: false, manager: false, cementryRoles: ["MANAGER", "TOMBSTONE_MANAGER", "TOMBSTONE_INSCRIPTIONS", "TOMBSTONE_IMAGES", "TOMBSTONE_COMMENT", "TOMBSTONE_CITATION", "TOMBSTONE_STIL", "TOMBSTONE_STYLISTIC", 
	"TOMBSTONE_DIMENSION", "TOMBSTONE_TYPE", "TOMBSTONE_DESCRIPTION", "TOMBSTONE_SYMBOLS", "TOMBSTONE_ORNAMENT", "TOMBSTONE_MAKER", "TOMBSTONE_MATERIAL", "TOMBSTONE_CONDITIONS", "TOMBSTONE_RESTAURATION", "TOMBSTONE_PERSONS"] },
	{ name: Constants.AC_MANAGER, admin: false, manager: true, cementryRoles: ["MANAGER"] },
	{ name: Constants.AC_TOMBSTONE_MANAGER, admin: false, manager: false, cementryRoles: ["TOMBSTONE_MANAGER"] },
	
	{ name: Constants.AC_TOMBSTONE_INSCRIPTIONS, admin: false, manager: false, cementryRoles: ["TOMBSTONE_INSCRIPTIONS"] },
	{ name: Constants.AC_TOMBSTONE_IMAGES, admin: false, manager: false, cementryRoles: ["TOMBSTONE_IMAGES"] },
	{ name: Constants.AC_TOMBSTONE_COMMENT, admin: false, manager: false, cementryRoles: ["TOMBSTONE_COMMENT"] },
	{ name: Constants.AC_TOMBSTONE_CITATION, admin: false, manager: false, cementryRoles: ["TOMBSTONE_CITATION"] },
	{ name: Constants.AC_TOMBSTONE_STIL, admin: false, manager: false, cementryRoles: ["TOMBSTONE_STIL"] },
	{ name: Constants.AC_TOMBSTONE_STYLISTIC, admin: false, manager: false, cementryRoles: ["TOMBSTONE_STYLISTIC"] },
	{ name: Constants.AC_TOMBSTONE_DIMENSION, admin: false, manager: false, cementryRoles: ["TOMBSTONE_DIMENSION"] },
	{ name: Constants.AC_TOMBSTONE_TYPE, admin: false, manager: false, cementryRoles: ["TOMBSTONE_TYPE"] },
	{ name: Constants.AC_TOMBSTONE_DESCRIPTION, admin: false, manager: false, cementryRoles: ["TOMBSTONE_DESCRIPTION"] },
	{ name: Constants.AC_TOMBSTONE_SYMBOLS, admin: false, manager: false, cementryRoles: ["TOMBSTONE_SYMBOLS"] },
	{ name: Constants.AC_TOMBSTONE_ORNAMENT, admin: false, manager: false, cementryRoles: ["TOMBSTONE_ORNAMENT"] },
	{ name: Constants.AC_TOMBSTONE_MAKER, admin: false, manager: false, cementryRoles: ["TOMBSTONE_MAKER"] },
	{ name: Constants.AC_TOMBSTONE_MATERIAL, admin: false, manager: false, cementryRoles: ["TOMBSTONE_MATERIAL"] },
	{ name: Constants.AC_TOMBSTONE_CONDITIONS, admin: false, manager: false, cementryRoles: ["TOMBSTONE_CONDITIONS"] },
	{ name: Constants.AC_TOMBSTONE_RESTAURATION, admin: false, manager: false, cementryRoles: ["TOMBSTONE_RESTAURATION"] },
	{ name: Constants.AC_TOMBSTONE_PERSONS, admin: false, manager: false, cementryRoles: ["TOMBSTONE_PERSONS"] },
	
	{ name: Constants.AC_PERSON_MANAGER, admin: false, manager: false, cementryRoles: ["PERSON_MANAGER" ] },
	{ name: Constants.AC_EDIT_CEMENTERY, admin: true, manager: true, cementryRoles: [ "MANAGER", "CEMENTRY_MANAGER" ] },
	{ name: Constants.AC_EDIT_MAKER, admin: true, manager: true, cementryRoles: [ "MANAGER", "MAKER" ] },
	{ name: Constants.AC_EDIT_SELECTIONS, admin: true, manager: true, cementryRoles: [ "MANAGER", "SELECTIONS" ] },
	{ name: Constants.AC_REPORTING, admin: true, manager: true, cementryRoles: [ "MANAGER", "REPORTS" ] },
	{ name: Constants.AC_EDIT_REFERENCES_LIST, admin: true, manager: true, cementryRoles: [ "MANAGER", "TOMBSTONE_MANAGER", "TOMBSTONE_COMMENT", "TOMBSTONE_DESCRIPTION", "TOMBSTONE_REFERENCES", "PERSON_MANAGER", "PERSON_BIOGRAPHY", "PERONS_REFERENCES" ] },
	{ name: Constants.AC_EDIT_FOOTNOTES, admin: true, manager: true, cementryRoles: [ "MANAGER", "TOMBSTONE_MANAGER", "TOMBSTONE_COMMENT", "TOMBSTONE_DESCRIPTION", "TOMBSTONE_REFERENCES", "PERSON_MANAGER", "PERSON_BIOGRAPHY", "PERONS_REFERENCES" ] },
	{ name: Constants.AC_EDIT_ASSESSMENTS, admin: true, manager: true, cementryRoles: [ "MANAGER", "TOMBSTONE_MANAGER", "TOMBSTONE_CONDITIONS" ] },
	{ name: Constants.AC_COPYRIGHTS, admin: true, manager: true, cementryRoles: [ "MANAGER", "TOMBSTONE_MANAGER", "TOMBSTONE_CONDITIONS" ] },
	{ name: Constants.AC_REPORT_CEMENTRY_PICTURES, admin: true, manager: true, cementryRoles: [ "MANAGER" ] },
];

const ROLE_PREFIX = "ROLE_";
const SEPERATOR = "#";		
			
class ACService {
	
	isAdmin(role) {
		return role === ROLE_PREFIX + Constants.ROLES.roles[1].name ? true : false;
	}
	
	isManager(role) {

		if (role === ROLE_PREFIX + Constants.ROLES.roles[2].name) {
			return true;
			
		} else if (role.startsWith(ROLE_PREFIX + Constants.ROLES.roles[2].name)) {
			return true;
		}
		else {
			return false;
		}
	}
	
	isValidUser(user) {
		
		if(user === undefined){
			return false;
		}		
		
		if(user === null || user.roles === null || user.roles === undefined || user.roles.length === 0 ){
			return false;
		}
		
		return true;
	}
	
	accessValidation(user, componentsToValidate, cementery){

				
		if (!this.isValidUser(user)) return false;
		
		if (Array.isArray(componentsToValidate)) {
			let componentHasAccess = false;
			
			for (let i = 0; i < componentsToValidate.length; i++) {
			
				let component = componentsToValidate[i];
				
			
				
				componentHasAccess = this.accessValidationByComponent(user, component, cementery);
				
				
				if (componentHasAccess) return true;
	
			}
			
			return false;
			
		} else {
				return this.accessValidationByComponent(user, componentsToValidate, cementery);	
		}
		
	}
	

	
	accessValidationByComponent(user, componentToValidate, cementery) {
		
		
		let userRights = user.roles;
		let roleHasAccess = false;
		let j=0;
		
		for ( j; j < userRights.length; j++) {
			
			let role = userRights[j];
			
			let isAdmin = this.isAdmin(role);
			let isManager = this.isManager(role);
			
			roleHasAccess = componentsRolesMatrix.filter(componentPermission => componentPermission.name === componentToValidate 
						&& this.isAllowedForCementery(cementery, componentPermission, isAdmin, isManager, role)
			).length > 0;
			
			if(!roleHasAccess){
				if (componentToValidate ==="AC_MANAGER_NAVIGATION"){
					roleHasAccess = true;
				}
			}
			
			if (roleHasAccess){
			 return true;	
			}

		}
		
		return false;
	}
	
	/*
		componentPermission = { name: Constants.AC_ADMIN_NAVIGATION, admin: true, manager: false, cementryRoles: [] }
	*/
	isAllowedForCementery(cementery, componentPermission, isAdmin, isManager, role) {



		if(componentPermission.admin && isAdmin){
			return true;
		}
		

		if (componentPermission.manager && isManager && componentPermission.cementryRoles.filter(permissionRole => (ROLE_PREFIX + permissionRole + SEPERATOR + cementery) === role).length > 0){
		
			return true;
		}

		
		if (componentPermission.cementryRoles.filter(permissionRole => (ROLE_PREFIX + permissionRole + SEPERATOR + cementery) === role).length > 0){
			
			return true;
		}
		
		if (componentPermission.cementryRoles.filter(permissionRole => (ROLE_PREFIX + permissionRole + SEPERATOR + "--- alle Friedhöfe ---") === role).length > 0){
			
			return true;
		}
	
		return false;

	}
	
	/*
		Validates the access for a user to a component without taking the particular cementery into account.
		
		This is important for cases where it suffies for the user to have the role for ANY of the cementeries to be allowed access, 
		like it is the case e.g. on the dashboard to decide if a user should "see" a certain link or not
		
	*/
	accessValidationWithoutCementery(user, componentToValidate){
				
		if (!this.isValidUser(user)) return false;
		
		let userRights = user.roles;
		
		let roleHasAccess = false;
		
		for (let i = 0; i < userRights.length; i++) {
			
			let role = userRights[i];
			
			let isAdmin = this.isAdmin(role);
			let isManager = this.isManager(role);
			
			roleHasAccess = componentsRolesMatrix.filter(componentPermission => componentPermission.name === componentToValidate 
						&& this.isAllowedWithoutCementery(componentPermission, isAdmin, isManager, role)
			).length > 0;
			
			if (roleHasAccess) return true;

		}
		
		return false;
	}
	
	isAllowedWithoutCementery(componentPermission, isAdmin, isManager, role) {
		
		return ((componentPermission.admin && isAdmin) 
					|| (componentPermission.manager && isManager) 
					|| componentPermission.cementryRoles.filter(permissionRole => this.isCementeryRole(role, permissionRole)).length > 0);
	}
	
	/*
		checks if the role of a user is a role for a cementery - it is ignored to which cementery this role applies...
	*/
	isCementeryRole(userRole, permissionRole) {
		
		let roleNameWithoutCementery = ROLE_PREFIX + permissionRole + SEPERATOR;
		
		return userRole.startsWith(roleNameWithoutCementery);
	}
}
export default new ACService();
