import React from 'react'
import ElasticSearchService from "../../services/elasticSearch.service";
import StorageService from "../../services/storage.service";
import { Container, Row, Col, Breadcrumb, Form, Button, Table } from 'react-bootstrap'
import AuthService from "../../services/auth.service";
import FileSaver from 'file-saver';
import TombstoneMapDetailElement from "../public/details/tombstoneMapDetailElement2";
import { handleStorage } from '../../helpers/jfb'
import * as Constants from "../../components/constants";

class ListGenerator extends React.Component {
    constructor(props) {
        super(props)

		this.state = {
			cementries: [],
			isLoading: true,
    		errors: null,
			successful: false,
			message: "",
			selectedCementry: "1",
			currentCementry: {},
			currentUser: undefined,
						
			selectedField: "0",
			selectedFields: [],
			availableFields: ["Bild", "Grabstein","Geburtsdatum", "Sterbedatum", "Wohnort", "Geburtsort", "Sterbeort", "Name", "Stellung", "Beruf",  "Material", "Zustand Grabstein", "Zustand Inschrift", "Schadensbild", "Symbol", "Hersteller", "Schmuck", "Stilmittel" ],
			grabsteinnummerValue: "",
			
			headerList: [],
			contentList: {},
			tombstones: [],
			allTombstones: [],
			isAllTombstonesLoading: true,
			isListCreated: false,
			listNameValue: "",
			reports: [],
			isReportLoading: true,
			googleMapConsent:false,
		};
		
		
		this.handleCementryChange = this.handleCementryChange.bind(this);
		this.handleReportChange = this.handleReportChange.bind(this);

    }
    


	createCsvList(e){
	
		this.setState({
			message: ""
		});
		
		let selectedFields = this.state.selectedFields;
		
		ElasticSearchService.createCsvList(selectedFields).then(
			response => {
			
				let fileName = "csvReport.csv"
				
				FileSaver.saveAs(new Blob([response.data], {type: "text/plain;charset=utf-8"}), fileName);
			},
			error => {
				this.setState({
					error:(error.response && error.response.data) ||error.message ||error.toString(),
					successful: false,
					message: error.toString()
				});
			}
		);
	}
	
	saveList(e){
		let selectedFields = this.state.selectedFields;
		let listName = this.state.listNameValue;
		let user = AuthService.getCurrentUser(); 
		
		if(selectedFields.length===0){
			this.setState({
					message:"Es wurden keine Felder für die Liste ausgewählt",
			});
			return;
		}
		
		ElasticSearchService.saveList(selectedFields, listName, user.username).then(
			response => {	
			
				this.setState({
					name: response.data,
					message: "Liste wurde gespeichert.",
					successful: true
				});		
			},
			error => {
				this.setState({
					error:(error.response && error.response.data) ||error.message ||error.toString() || error.data.message,
					successful: false,
					message: error.toString()
				});
			}
			
		)
		
	}
	
	createList(e){
		let selectedFields = this.state.selectedFields;
		
	
		
		if(selectedFields.length===0){
			this.setState({
					message:"Es wurden keine Felder für die Liste ausgewählt",
			});
			return;
		}
		
		ElasticSearchService.createList(selectedFields).then(
			response => {	
			
				this.setState({
					headerList: response.data.headerList,
					contentList: response.data.contentList,
					tombstones: response.data.tombstones,
					isListCreated: true,
					message: "",
					successful: true
				});	
			},
			error => {
				
				this.setState({
					error:(error.response && error.response.data) ||error.message ||error.toString() || error.data.message,
					successful: false,
					message: error.toString()
				});
			}
			
		)



		ElasticSearchService.loadTombstones(this.state.currentCementry.id).then(
			response => {
				
				this.setState({
					allTombstones: response.data.tombstones,
					isAllTombstonesLoading: false,
				});
			},
			error => {
				this.setState({
					error:(error.response && error.response.data) ||error.message ||error.toString() || error.data.message,
					successful: false,
					message: error.toString()
				});
			}
		);

		
	}

	handleOperatorChange(e, id){
		
		let selectedFields = this.state.selectedFields;
		let selectedField = selectedFields[id];
		selectedField.operator = e.target.value;
		selectedFields[id] = selectedField
		this.setState({
				selectedFields: selectedFields,
				message:"",
		});
		localStorage.setItem("selectedFields", JSON.stringify(selectedFields));		
		
	}
	
	changeValue(e, id){
		let selectedFields = this.state.selectedFields;
		let selectedField = selectedFields[id];
		selectedField.value = e.target.value;
		selectedFields[id] = selectedField
		this.setState({
				selectedFields: selectedFields,
				message:"",
		});
		localStorage.setItem("selectedFields", JSON.stringify(selectedFields));		
	}

	onAddField(e){
		
		
		if(this.state.selectedField === "0"){
			this.setState({
				message:"Bitte wählen Sie zuerst ein Feld aus",
			});
			return;
		}
		
		let selectedField = this.state.selectedField
		let selectedFields = this.state.selectedFields;
		
		selectedFields.push({name: selectedField, operator: "", value: "", cementry: this.state.selectedCementry})
		
		this.setState({
			selectedFields: selectedFields,
			selectedField: "0",
			message:"",
		});

		
		localStorage.setItem("selectedFields", JSON.stringify(selectedFields));		
	}
	
	onRemoveField(e, field){
		

		let selectedFields = this.state.selectedFields;
		var index = selectedFields.indexOf(field);
		selectedFields.splice(index, 1);
		
		this.setState({
			selectedFields: selectedFields,
			selectedField: "0",
			message:"",
		});
		
		localStorage.setItem("selectedFields", JSON.stringify(selectedFields));	

	}
	
	handleFieldChange(e) {
		let value = e.target.value;
		this.setState({
			selectedField: value,
			message:"",
		});
	}

	handleCementryChange(e) {
		
		let id = e.target.value;
		
		
		
		this.setState({
					selectedCementry: id,
					result:  {},
					query: "",
					message: "",
					isSearching: false,
					resultSize: 0,
					selectedFields: [],
					selectedReport: 0,
		});
		
		localStorage.setItem("cementery", JSON.stringify(id));
		
		let currentCementry = this.state.cementries.find(cementery => cementery.id === id);
		this.setState({
			currentCementry: currentCementry
		});
			
	}
	
	handleReportChange(e) {
		
		let id = e.target.value;
		
		
		this.setState({
					selectedReport: id,
					message: "",
					selectedFields: [],
					headerList: [],
					contentList: [],
					tombstones: [],
					isListCreated: false,
		});

		
		let report = this.state.reports.find(report => report.id === id);
		let currentCementry = this.state.cementries.find(cementery => cementery.id === report.selectedFields[0].cementry);
		localStorage.setItem("cementery", JSON.stringify(currentCementry.id));
		localStorage.setItem("selectedFields", JSON.stringify(report.selectedFields));	
		this.setState({
			currentCementry: currentCementry,
			selectedCementry: currentCementry.id,
			selectedReport: report.id,
			selectedFields: report.selectedFields,
		});
		
			
	}


	componentDidMount() {
		
		let googleMapConsent = handleStorage("googleMapConsent");
		this.setState({
				googleMapConsent: googleMapConsent,
			});
		
		const user = AuthService.getCurrentUser();	
		if (user) {
			this.setState({
				currentUser: user,
			});
		}
		
		let selectedFields = StorageService.handleStorage("selectedFields");
		let cementeryId = "";
		if(selectedFields.length>0){
			cementeryId = selectedFields[0].cementry;
		}

		this.setState({
					selectedFields:  selectedFields,	
					selectedCementry: cementeryId,			
		});

		let onlyPublished = user ? false : true;
		
		let callLoadCementries = onlyPublished ? ElasticSearchService.loadPublicCementries : ElasticSearchService.loadCementries;
		
		callLoadCementries().then(
			response => {
				
				let cementeries = response.data
				let currentCementry = cementeries.find(cementery => cementery.id === cementeryId);
				this.setState({
					currentCementry: currentCementry,
					cementries:  cementeries,
					isLoading: false					
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);	
		
		
		
		ElasticSearchService.loadReports().then(
			response => {
				this.setState({
					reports: response.data,
					isReportLoading: false,					
				});
				
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
			);
	}

    render() {
   			const { 
				cementries, 
   				isLoading, 
   				isReportLoading, 
   				selectedField, 
   				selectedFields, 
   				availableFields, 
   				isListCreated, 
   				headerList, 
   				contentList,  
   				listNameValue, 
   				reports,
   				currentUser,
   				isAllTombstonesLoading,
   				tombstones,
   				allTombstones,
   				googleMapConsent
   				} = this.state;
        return (
			<Container>
  			<Row>
						<Col>
							<Breadcrumb className="breadcrumb-style">
								<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
								<Breadcrumb.Item href="/cemeteries/">Historische Friedhöfe</Breadcrumb.Item>
								<Breadcrumb.Item active>Individuelle Auswertungen</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>
            <Row>
             	<Col><h3>Individuelle Auswertungen</h3></Col>
            </Row>
              <Row>
             	<Col>
             	Diesen Bereich können Sie nutzen, um auf einem Friedhof nach bestimmten Grabsteinen oder Personen zu suchen, 
             	die eine oder mehrere Bedingungen erfüllen.
             	<br/>
             	Wählen Sie zunächst einen Friedhof aus. Anschließend wählen Sie aus der Dropdown-Liste ein Feld aus, das Sie 
             	im Ergebnis und für das Sie ggf. eine Bedingung definieren möchten.
             	<br/><br/>
             	<strong>Beispiel:</strong> Sie möchten wissen, wie viele Grabsteine auf dem Friedhof Schopfloch vor dem 01.01.1600 exisitert haben.
             	<br/>
             	<ul>
	             	<li>Wählen Sie das Feld "Grabstein" und klicken Sie auf "Feld Hinzufügen".</li>
	             	<li>Wählen Sie das Feld "Bild" und klicken Sie auf "Feld Hinzufügen"</li>
	             	<li>Wählen Sie das Feld "Sterbedatum" und klicken Sie auf "Feld Hinzufügen". Anschließend wählen Sie im Feld Operator "kleiner gleich" aus und geben als Wert das gewünschte Datum in der Form 1600-01-01 an.</li>
	             	<li>Mit einem Klick auf den Button "Liste erstellen", werden die ermittelten Grabsteine angezeigt und in der Karte dargestellt, wo sich die Steine ungefähr befinden.</li>
             	</ul>
             	
             	Mit einem Klick auf den Link "Entfernen" können Sie ein hinzugefügten Feld aus der Auswertung entfernen. 
             	</Col>
            </Row>
			
			
			
			 {!isLoading  && !isReportLoading ? (
				<div>
				{currentUser &&(
				<Row>
					<Col >
						<Form.Group as={Col} controlId="ReportChoice">
							<Form.Text className="text-muted">Bitte wählen Sie einen Report aus...</Form.Text>
      						<Form.Control as="select" value={this.state.selectedReport} onChange={(e) => this.handleReportChange(e)}>	
      						    <option key={0} value={0}>kein Report ausgewählt</option>		
        						{reports.map((report) =>(<option key={report.id} value={report.id}>{report.id}</option>))}
      						</Form.Control>
						</Form.Group>
					</Col>
				</Row>
				)}
            	<Row>
					<Col >
						<Form.Group as={Col} controlId="CementryChoice">
							<Form.Text className="text-muted">Bitte wählen Sie einen Friedhof aus...</Form.Text>
      						<Form.Control as="select" value={this.state.selectedCementry} onChange={(e) => this.handleCementryChange(e)}>			
        						{cementries.map((cementry) =>(<option key={cementry.id} value={cementry.id}>{cementry.name}</option>))}
      						</Form.Control>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group as={Col} controlId="FieldList">
							<Form.Text className="text-muted">Bitte wählen Sie ein Feld aus...</Form.Text>
							<Form.Control as="select" onChange={(e)=> this.handleFieldChange(e)} value={selectedField}>
							     <option key="0" value="0">Felder zur Auswahl</option>
								 {availableFields.map((field, id) =>(<option key={id} value={field}>{field}</option>))}
							</Form.Control>
						</Form.Group>
					</Col>	
				</Row>
				{this.state.message && (
				<div className="form-group">
					<div className={this.state.successful?"alert alert-success":"alert alert-danger"} role="alert">
						{this.state.message}
					</div>
				</div>
			)}
				<Row>
					<Col >
						<Button variant="secondary" onClick={(e) => this.onAddField(e)}  size="lg" block>Feld Hinzufügen</Button>
					</Col>
				</Row>
				{selectedFields.map((field, id) =>(
					<Row key={id}>
						<Col>
						  <Form.Group as={Col} controlId={field}>
   							<Form.Label>Feld</Form.Label>			
							<Form.Control key={id} disabled as="input" name={field.name} value={field.name}/>			
  						  </Form.Group>
						</Col>
						<Col xs={2}>
						  <Form.Group as={Col} controlId={field}>
   							<Form.Label>Operator</Form.Label>
							<Form.Control as="select" name={field.name + "-"+id +"-operator"} onChange={(e)=> this.handleOperatorChange(e, id)} value={field.operator}>
							     <option key="0" value="0">keine Auswahl getroffen</option>
								 <option key="1" value="1">ist gleich</option>
								 <option key="2" value="2">kleiner gleich</option>
								 <option key="3" value="3">größer gleich</option>
							</Form.Control>
  						  </Form.Group>
						</Col>
						<Col>
						  <Form.Group as={Col}>
   							<Form.Label>Wert</Form.Label>
							<Form.Control placeholder='kein Wert angegeben, es wird nur das Feld im Ergebnis angezeigt' as="input" name={field+"-"+id}  id={field+"-"+id} value={field.value}  onChange={(e) => this.changeValue(e, id)}/>
  						  </Form.Group>
						</Col>
						<Col xs={1}>
						  <Form.Group as={Col} controlId={field}>
   							<Button variant="Link" onClick={(e) => this.onRemoveField(e, field)}>Entfernen</Button>
  						  </Form.Group>
						</Col>
					</Row>
				))}
				<Row>
            		<Col>
						<Button variant="secondary" onClick={(e) => this.createList(e)} size="lg" block>Liste erstellen</Button>
					</Col>
            	</Row>
				<Row>
            		<Col>
						<hr/>
					</Col>
            	</Row>
				{isListCreated && !isAllTombstonesLoading ? (
					<div>
					
		
					<Row>
					<Col>
					{googleMapConsent ? (
								<TombstoneMapDetailElement cementery={this.state.currentCementry} tombstones={allTombstones} zoom={19} tombstoneMarker={tombstones}/>
							):(
								<div className="consent-info" >{Constants.consentText}<br/>
							<strong>Den Button finden Sie unten auf dieser Seite!</strong></div>
							)}
												
					</Col>
				</Row>	
			
					
					<Table striped bordered hover size="sm">
  						<thead>
    						<tr>
								{headerList.map((field, id) =>(
									<th key={id}>{field}</th>
								))}
    						</tr>
  						</thead>
  						<tbody>
							{contentList?contentList.map((dataSet, id) =>(
							<tr key={id}>
								  {dataSet.map((data, id2) =>(
									data.startsWith("http")?<td key={id2}>
									<img width={64} height={64} className="mr-3" src={data}  alt="kein Bild vorhanden"/></td>:<td key={id2}>{data}</td>
								  
								))}
							</tr>
							)):"keine Ergebnisse"}
							
  						</tbody>
					</Table>
					{currentUser &&(
						<div>
					<Row>
            			<Col>
							<Button variant="secondary" onClick={(e) => this.createCsvList(e)} size="lg" block>Liste als CSV Datei speichern</Button>
						</Col>
            		</Row>
            		
            		
            		<Row>
            			<Col>
							<Form.Group as={Col}>
   							<Form.Label>Name der Liste</Form.Label>
							<Form.Control placeholder='geben Sie den Namen der Liste vor dem Speichern an' as="input" name='listName'  id='listName' value={listNameValue}  onChange={(e) => this.setState({listNameValue: e.target.value}) }/>
  						  </Form.Group>
						</Col>
						<Col>
							<Button variant="secondary" onClick={(e) => this.saveList(e)} size="lg" block>Liste in Datenbank speichern</Button>
						</Col>
            		</Row>
            		</div>
            		)}
					</div>
				):""}
				{this.state.message && (
				<div className="form-group">
					<div className={this.state.successful?"alert alert-success":"alert alert-danger"} role="alert">
						{this.state.message}
					</div>
				</div>
			)}
				
				</div>		
			
        ) : (
          <Row>
			<Col>Loading...</Col>
			
		  </Row>
        )}
     
			
        </Container>)
        
    }
}
export default ListGenerator
