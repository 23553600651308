import React, { useState, useEffect } from "react";
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import parse from 'html-react-parser'
import * as Constants from "../../components/constants";

export default function Impressum() {

	const [html, setHtml] = useState("");

	useEffect(() => {
	
		
		fetch(Constants.DATA_PROTECTION_URL).then((response) => response.text()).then((html) => setHtml(html));
	}, []);

	return (
		<Container fluid="xxl">
			<Row>
				<Col>
					<Breadcrumb className="breadcrumb-style">
						<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
						<Breadcrumb.Item className="breadcrumb-item-style" active>Impressum</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col><h3>Impressum</h3></Col>
			</Row>
			<Row>
				<Col>

					<strong>Herausgeber</strong><br />
					Der Beauftragte der Bayerischen Staatsregierung für jüdisches Leben und gegen Antisemitismus, für Erinnerungsarbeit und geschichtliches Erbe<br /><br />

Franz-Josef-Strauß-Ring 1<br />
80539 München<br />
Telefon: +49 (0) 89 2165 2380<br />
E-Mail: antisemitismusbeauftragter@stk.bayern.de<br />
Vertretungsberechtigter: Dr. Ludwig Spaenle<br />


					<br />
					<strong>Fachverantwortung für das Projekt „Erfassung jüdischer Grabmäler in Bayern“ </strong><br />
					Die unter https://www.bet-olam-bayern.de/ abrufbare Informationsplattform und Datenbank für jüdische Grabmäler in Bayern wurde unter der Projektleitung des Bayerischen Landesamts für Denkmalpflege konzipiert und entwickelt. Das Bayerische Landesamt für Denkmalpflege (Referat Z II), Hofgraben 4, 80539 München, Telefon +49 (0) 89 2114 0, E-Mail poststelle@blfd.bayern.de trägt die Fach- und Redaktionsverantwortung für die Inhalte auf dieser Website. Design und Programmierung: Ralf Kaczich, Rostock.


				</Col>
			</Row>

			<Row>
				<Col>

					<h4>Rechtliche Hinweise</h4>
					<strong>Haftung für Inhalte </strong><br />
					<p>
						Das Bayerische Landesamt für Denkmalpflege, als Fach- und Redaktionsverantwortlicher, ist um Richtigkeit und Aktualität der Informationen und Angebote abrufbar unter https://www.bet-olam-bayern.de/ bemüht. Die Inhalte wurden mit größter Sorgfalt erstellt, dennoch können Fehler und Unklarheiten nicht vollständig ausgeschlossen werden. Das Bayerische Landesamt für Denkmalpflege, als Fach- und Redaktionsverantwortlicher, übernimmt daher keine Gewähr für die Aktualität, Richtigkeit, Vollständigkeit oder Qualität der bereitgestellten Informationen.
					</p>

					<strong>Haftung für Links </strong><br />
					<p>
						Von den eigenen Websiteinhalten sind Querverweise („Links“) auf die Websites anderer Anbieter zu unterscheiden. Durch die gesetzten Links wird lediglich der Zugang zur Nutzung fremder Inhalte nach § 8 Telemediengesetz (TMG) ermöglicht. Bei der erstmaligen Verknüpfung mit diesen Internetangeboten wurden diese fremden Inhalte daraufhin überprüft, ob durch sie eine mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst wird. Es ist jedoch nicht möglich diese fremden Inhalte ständig auf Veränderungen zu überprüfen. Daher wird auch keine Verantwortung dafür übernommen. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung von Informationen Dritter entstehen, haftet allein der jeweilige Anbieter der verlinkten Seite.
					</p>
					<strong>Nutzungsbedingungen</strong><br />
					<p>
						Texte, Bilder, Grafiken sowie die Gestaltung dieser Internetseiten unterliegen dem Urheberrecht. Bei den Datenbankinhalten, die dem Urheberrecht unterliegen, sind die jeweils angegebenen Creative Commons Urheberrechtslizenz (CC-Lizenzen) zu beachten. Die in der Datenbank veröffentlichten Metadaten sind gemeinfrei (CC0). Wenn nicht anders gekennzeichnet, sind die Inhalte dieser Internetpräsenz unter CC BY-NC-SA 4.0 lizensiert.
						Jede vom Urheberrechtsgesetz und den verwendeten Lizenzen nicht zugelassene Verwertung bedarf vorheriger ausdrücklicher Zustimmung des Bayerischen Landesamts für Denkmalpflege, als Fach- und Redaktionsverantwortlicher. Dies gilt insbesondere für die Vervielfältigung, Bearbeitung, Übersetzung sowie für die Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien. Für den privaten, nichtkommerziellen und wissenschaftlichen Gebrauch dürfen Kopien und Downloads hergestellt werden. Das Zitieren von Inhalten aus dem Angebot dieser Website und das Setzen von Links auf unsere Webseite ist erlaubt und erwünscht.
						Weiterhin können Texte, Bilder, Grafiken und sonstige Dateien ganz oder teilweise dem Urheberrecht Dritter unterliegen. Auch über das Bestehen möglicher Rechte Dritter geben Ihnen die für den Inhalt Verantwortlichen nähere Auskünfte.
						Die Bildrechte wurden sorgfältig recherchiert. Sollten Sie trotzdem ein Bildrecht verletzt sehen, wenden Sie sich bitte an das Bayerische Landesamt für Denkmalpflege, als Fach- und Redaktionsverantwortlichen unter info@bet-olam-bayern.de.
					</p>
					<strong>Haftungsausschluss </strong><br />
					<p>
						Alle auf dieser Internetseite bereitgestellten Informationen wurden nach bestem Wissen und Gewissen erarbeitet und geprüft. Eine Gewähr für die jederzeitige Aktualität, Richtigkeit, Vollständigkeit und Verfügbarkeit der bereitgestellten Informationen kann nicht übernommen werden. Ein Vertragsverhältnis mit den Nutzenden des Internetangebots kommt nicht zustande.
						Für Schäden, die durch die Nutzung dieses Internetangebots entstehen wird nicht gehaftet. Dieser Haftungsausschluss gilt nicht, soweit die Vorschriften des § 839 BGB (Haftung bei Amtspflichtverletzung) einschlägig sind. Für etwaige Schäden, die beim Aufrufen oder Herunterladen von Daten durch Schadsoftware oder der Installation oder Nutzung von Software verursacht werden, wird nicht gehaftet.

					</p>

				</Col>
			</Row>

			<Row>
				<Col><h3>Datenschutzhinweise</h3></Col>
			</Row>
			{parse(html)}
		</Container>
	);
}

