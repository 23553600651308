import React, { useState, useEffect } from "react";

import { Row, Col } from "react-bootstrap";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Tooltip from '@mui/material/Tooltip';

import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Stack from '@mui/material/Stack';
import { red } from '@mui/material/colors';

import { ConditionDamageType } from "../../../../components/constants";

import AutocompleteHelper from "../../../../helpers/autocompleteHelper";

export default function AssessmentDetails(props) {
	
	const { readOnly, damageType, details, index, choices, onDelete, onAddNew, onChange } = props;
	
	const [loading, setLoading] = useState(true);

	//const [damageArea, setDamageArea] = useState(details.component);
	
	const [damageImageLabels, setDamageImageLabels] = useState([]);
	const [comment, setComment] = useState(details.comment);
	
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	const [damageImageOptions, setDamageImageOptions] = useState([]);
	
	const damageTypes = [
		{ label: ConditionDamageType.StoneDamage, choicesName: "Schadensbild" },
		{ label: ConditionDamageType.ConstructionDamage, choicesName: "Konstruktive Schäden" }, 
		{ label: ConditionDamageType.DesacrationsDamage, choicesName: "Schäden im Kontext von Schändungen" }
	];
	
	const createAssessmentDetails = (damageImageLabels, detailsComment) => {
		
		let currentAssessmentDetails = {};
     	currentAssessmentDetails.component = damageType;
		currentAssessmentDetails.damagePatterns = damageImageLabels.map((pattern) => {
				
			return {
				id: pattern.id,
				value: pattern.label
			};
		});;
		currentAssessmentDetails.comment = detailsComment;
		
		return currentAssessmentDetails;
	}
	
	function hanldeDeleteAssessmentDetails() {
		
		onDelete(index);
	}
	
	function handleAddNewAssessmentDetails() {
		
		onAddNew({
			component: damageType,  
			damagePatterns:[],
			comment: ""
     	});
	}
	
	/*function handleChangeAssessmentDetailsDamageArea(newValue) {
		
		setDamageArea(newValue);
		
		let currentAssessmentDetails = createAssessmentDetails(newValue, damageImageLabels, comment);
		
		onChange(index, currentAssessmentDetails);
	}*/
	
	function handleChangeAssessmentDetailsDamageImage(newValue) {
		
		let currentDamageImageLabels = [...newValue];
		
		setDamageImageLabels(currentDamageImageLabels);
		
		let currentAssessmentDetails = createAssessmentDetails(currentDamageImageLabels, comment);
		
		onChange(index, currentAssessmentDetails);
	}
	
	function handleChangeAssessmentDetailsComment(newValue) {

		setComment(newValue);
		
		let currentAssessmentDetails = createAssessmentDetails(damageImageLabels, newValue);
		
		onChange(index, currentAssessmentDetails);
	}
	
	useEffect(() => {

		if (loading) {
			//setDamageArea(details.component);
			setComment(details.comment);
	
			let currentDamageImageLabels = [];
			if (details.damagePatterns !== undefined && details.damagePatterns.length > 0) {
				currentDamageImageLabels = details.damagePatterns.map((pattern) => {
					
					return {
						id: pattern.id,
						label: pattern.value
					};
				});
			}
			setDamageImageLabels(currentDamageImageLabels);
		
			let nameOfChoicesForDamageType = damageTypes.find(damageTypeItem => damageTypeItem.label === damageType);
			let removeDuplicates = true;
			let options = AutocompleteHelper.getOptionsFromChoices(choices[nameOfChoicesForDamageType.choicesName], removeDuplicates);
					
			setDamageImageOptions(options);
					
			setMessage("");
			setSuccessful(true);
			setLoading(false);

		}
		
		return () => {
			
			setLoading(true);
			setDamageImageLabels();
			setComment("");
	
			setSuccessful(false);
			setMessage("");
	
			setDamageImageOptions([]);
      	};
		 
	}, [details]);
			
	return (
		
		<Box sx={{ boxShadow: 0 }} >
			<Row>
				<Col></Col>
			</Row>
			<Row md={12} >
				
				<Col md={6}>
					<Autocomplete 
						multiple
						id="damage-image"
						options={damageImageOptions}
						value={damageImageLabels} 
						isOptionEqualToValue={(option, value) => (value != null && option.id === value.id)} 
						onChange={(event, newValue) => handleChangeAssessmentDetailsDamageImage(newValue)}
						renderInput={(params) => <TextField {...params} label="Wählen Sie das Schadensbild" />} 
						disabled={readOnly}
						sx={{
						  '& .MuiOutlinedInput-root': {
						    padding: 0
						  }
						}}
					/>
					
				</Col>
				<Col  md={5} className="form-group">
					<TextField 
						fullWidth 
						label="Kommentar" 
						name="cementry-tombstone-assessment-description"
						value={comment} 
						onChange={(e) => handleChangeAssessmentDetailsComment(e.target.value)} 
						multiline
						rows={2} 
						disabled={readOnly} 
						/>
				</Col>
				{(!readOnly && onDelete) && (
				<Col  md={1} className="form-group">
					<Stack direction="row" justifyContent="end" alignItems="center">
						<Tooltip title={"Bewertungsdetails entfernen"} arrow placement="left">
							<span>
								<IconButton onClick={hanldeDeleteAssessmentDetails} ><DeleteIcon sx={{ color: red[500] }}/></IconButton>
							</span>
						</Tooltip>

					</Stack>
				</Col>
				)}
			</Row>
			
			{message && (
					<div className="form-group">
						<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
					</div>
			)}
		</Box>
	);
}