import React, { useState, useEffect } from "react";

import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';

export default function HoverRating(props) {

	const { readOnly, labels, labelSelected, onChange } = props;
		
	const [value, setValue] = useState(0);
	const [hover, setHover] = useState(-1);
	
	function getRatingNumber(labelValue) {
	
		let rating = Object.entries(labels).find((entry) => {
			return entry[1] === labelValue;
		});
			
		if (rating !== undefined) {
				
			return parseInt(rating[0], 10);
			
		} else {
			
			return 0;
		}
	}
		
	function getLabelText(value) {
		return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
	}
		
	useEffect(() => {
			
		let ratingNumber = getRatingNumber(labelSelected);
		
		setValue(ratingNumber);
			
	}, []);

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center'
			}}
		>
			<Rating
				name="hover-feedback"
				value={value}
				precision={1}
				getLabelText={getLabelText}
				onChange={(event, newValue) => {
					
					setValue(newValue);
					
					if (onChange) onChange(labels[newValue]);
				}}
				onChangeActive={(event, newHover) => {
					setHover(newHover);
				}}
				emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} 
				max={4} 
				readOnly={readOnly}
			/>
			
			{value !== null && (
				<Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
			)}
		</Box>
	);
}