import React, { useState, useEffect } from "react";

import {  Row, Col, Form } from "react-bootstrap";
import ElasticSearchService from "../../../../services/elasticSearch.service";

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import AssessmentItem from "./assessmentItem2";
import AsyncAutocomplete from "../../../standard/asyncAutocomplete";

import { getStringDateNow } from "../../../../helpers/util";
import { handleStorage, setStorage } from '../../../../helpers/jfb'

export default function TombstoneConditionAssessment(props) {
		
	const { conditions, tombstoneNumber, canSelectTombstone, showTombstoneName, readOnly, onTombstoneSelection, onUpdateAssessments } = props;

	const STORAGE_CEMENTRY_KEY = "conservation-cementry";
	
	const [isLoadingCementries, setLoadingCementries] = useState(true);
	const [isLoadingTombstones, setLoadingTombstones] = useState(true);

	const [cementrySelection, setCementrySelection] = useState(null);
	const [cementries, setCementries] = useState([]);
	//const [assessedTombstone, setTombstone] = useState(tombstone !== undefined ? tombstone : "");
	const [assessments, setAssessments] = useState([]);
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	function addAssessment() {
		
		let newAssessmentsList = [...assessments];
		
		newAssessmentsList.push({
			date: getStringDateNow(),
			classificationTombstone: "gut",
			classificationInscription: "gut",
  			comment: "Sonstiges",
      	  	details: []
     	});
     	
     	setAssessments(newAssessmentsList);
     	
     	onUpdateAssessments(newAssessmentsList);
	}
	
	function handleChangeCementrySelection(cementryId) {
		
		let cementryOption = getCementryOption(cementries, cementryId);
		
		setCementrySelection(cementryOption);
		//localStorage.setItem("cementery-assessment", JSON.stringify(cementryId));

		setStorage(STORAGE_CEMENTRY_KEY, cementryId);
	}
	
	function handleChangeTombstoneSelection(tombstoneId) {
		
		let cemeteryId = cementrySelection.id;

		ElasticSearchService.loadTombstone(cemeteryId, tombstoneId).then(
			response => {
				
				let currentTombstone = response.data;
				
				setAssessments(currentTombstone.conditions);
				setSuccessful(true);
				onTombstoneSelection(currentTombstone);
					
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
					setMessage(resMessage);
					setSuccessful(false);
					isLoadingTombstones(true);
			}
		);	
	}

	function handleLoadingTombstonesSuccess(response) {
		
		if (response.data.tombstones.length > 0) {
		
			return response.data.tombstones.map((currentTombstone) => {
					
				return {	
					id: currentTombstone.id,
					label: currentTombstone.id
				}
			});
			
		} else {
		
			return [];
		}
		
	}
	
	function handleLoadingTombstonesError(error) {
		const resMessage =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

		setMessage(resMessage);
		setSuccessful(false);
		isLoadingTombstones(true);
	}
	
	function handleAssessmentItemChanged(assessmentIndex, assessment) {
		
		//let currentAssessments = [...assessments];
		
		assessments[assessmentIndex] = assessment;
		
		setAssessments(assessments);
		
		onUpdateAssessments(assessments);
	}
	
	function handleAssessmentItemDeleted(assessmentIndex) {
		
		let currentAssessments = [...assessments];
		
		currentAssessments.splice(assessmentIndex, 1);
		
		setAssessments(currentAssessments);
		
		onUpdateAssessments(currentAssessments);
	}
	
	function getCementryOption(cementryOptions, cementryId) {
					
		let option = null;
		
		let index = cementryOptions.findIndex(cementry => cementry.id === cementryId);
		if (index > -1) {
			
			option = cementryOptions[index];
		}
		
		return option;
	}
	
	useEffect(() => {
		
		let showCementriesSelection = true;
		
		if (canSelectTombstone === undefined) showCementriesSelection = true;
		else showCementriesSelection = canSelectTombstone;
		
		if (showCementriesSelection) {
			
			if (isLoadingCementries) {
				ElasticSearchService.loadCementriesForAutoComplete(0).then(
					response => {
						
						setCementries(response.data);
						setLoadingCementries(false);
						setSuccessful(true);
						
						let cementryIdInStorage = handleStorage(STORAGE_CEMENTRY_KEY, null); //localStorage.getItem("cementery-assessment");
						let selectedCementryOption = getCementryOption(response.data, cementryIdInStorage);
						
						setCementrySelection(selectedCementryOption);
						
					},
					error => {
						const resMessage =
								(error.response &&
									error.response.data &&
									error.response.data.message) ||
								error.message ||
								error.toString();
			
						setMessage(resMessage);
						setSuccessful(false);
						setLoadingCementries(true);
					}
				);
			}
			
		} else {
			if (conditions !== undefined) {
				
				setAssessments(conditions);
				setSuccessful(true);
				
			} else {
				
				setAssessments([]);
				setCementrySelection(null);
			}
		} 
		 
	}, [isLoadingCementries, canSelectTombstone, conditions]);
	
	return (
		
		<>	
			{(!isLoadingCementries && (canSelectTombstone === undefined || canSelectTombstone)) && (
				<>
			<Row md={12}>
				<Col  md={12} className="form-group">
												
					<Form.Group as={Col} controlId="CementryChoice">
      					
    					<Autocomplete
						  disablePortal
						  id="combo-box-cementries"
						  options={cementries}
						  value={cementrySelection}
						  isOptionEqualToValue={(option, value) => (value != null && option.id === value.id)}
						  onChange={(event, cementry) => handleChangeCementrySelection(cementry.id)}
						  renderInput={(params) => <TextField {...params} label="Bitte wählen Sie den gewünschten Friedhof aus" />} 
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row md={12}>
				<Col  md={12} className="form-group">
												
					<Form.Group as={Col} controlId="TombstoneChoice">
      					<AsyncAutocomplete 
      						label="Bitte wählen Sie den gewünschten Grabstein aus"
							onChange={(event, tombstone) => handleChangeTombstoneSelection(tombstone.id)}
      					    disableClearable = {true} 
      						id="combo-box-tombstones" 
      						disabled={cementrySelection === null ? true : false}
      						
      						getOptions={() => ElasticSearchService.loadTombstones(cementrySelection.id)} 
      						onSuccess={handleLoadingTombstonesSuccess} 
      						onError={handleLoadingTombstonesError}
    					/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col><br/></Col>
			</Row>

			</>
			)}
			{successful && (
			<>
			<Row>
				<Col>
					{assessments.length===0?"Keine Zustandsbewertung vorhanden, Klicken Sie auf Bearbeiten, um eine Bewertung hinzuzufügen":""}
				</Col>
			</Row>
				{showTombstoneName && (
				<Row>
					<Col>Grabstein: {tombstoneNumber}</Col>
				</Row>
				)}			
				<Row md={12}>
					<Col md={12}>
						{assessments.map((assessment, index) => (
							<AssessmentItem key={index} 
								readOnly={readOnly}
								index={index} 
								tombstoneName={tombstoneNumber}
								assessment={assessment} 
								onChange={handleAssessmentItemChanged}
								onDelete={handleAssessmentItemDeleted}
							/>
						))}
					</Col>
				</Row>
				{!readOnly && (
				<Row md={12}>
					<Col md={12}>
						<Stack direction="row" justifyContent="end">
							<Tooltip title={((canSelectTombstone === undefined || canSelectTombstone) && (cementrySelection === null || cementrySelection.length === 0 || tombstoneNumber.length === 0)) ? "Bitte wählen Sie Friedhof und Grabstein aus" : ""} arrow placement="left">
								<span>
									<IconButton onClick={() => addAssessment()} disabled={((canSelectTombstone === undefined || canSelectTombstone) && cementrySelection === null) ? true : false} ><AddCircleIcon /></IconButton>
								</span>
							</Tooltip>
						</Stack>
					</Col>
				</Row>
				)}
			</>)}
			{message && (
				<div className="form-group">
					<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
				</div>
			)}
		</>
	);
}