import React, { useState, useEffect} from "react";
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'

import { Navigate } from "react-router-dom";

import { resetCookieConsentValue } from "react-cookie-consent";

import Button from '@mui/material/Button';

export default function ConsentRejected() {

	const [navigateHome, setNavigateHome] = useState(false);

  	function handleResetConsent() {
	
		resetCookieConsentValue("betolam-consent-cookie");
		
    	setNavigateHome(true);
  	}
  	
  	useEffect(() => {

	}, []);
	
	if (navigateHome === true) {
		return <Navigate to="/home" />;
	}

	return (
		<Container fluid="xxl">
			<Row className="top-spacer">
				<Col>
					<Breadcrumb className="breadcrumb-style">
						<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
						<Breadcrumb.Item active>Cookies abgelehnt</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col>
					<center>
					<h4>Sie haben die Cookies Vereinbarung für diesen Service abgelehnt...</h4>
					<br/>
					<p>Möchten Sie Ihre Entscheidung revidieren?</p>
					<Button sx={{ m: 5 }} variant="contained" onClick={(e) => handleResetConsent()} >Ja</Button>
					<Button sx={{ m: 5 }} variant="contained" color="error">Nein</Button>
					<p>Andernfalls wird die Nutzung des Service nicht möglich sein.</p>
					</center>
				</Col>
			</Row>
			
			<Row>
				<Col><hr /></Col>
			</Row>
		</Container>
	);
}
