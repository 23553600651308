
class AutocompleteHelper {

	getMaterialOptionsByChoice(tombstone, materialChoiceFieldName) {
		
		let currentTombstoneLabels = [];
		if (tombstone.material[materialChoiceFieldName] !== undefined && tombstone.material[materialChoiceFieldName].length > 0) {
			currentTombstoneLabels = tombstone.material[materialChoiceFieldName].map((pattern) => {
				
				return {
					id: pattern.id,
					label: pattern.value
				};
			});
		}
		
		return currentTombstoneLabels;
	}
	
	getChoicesFromOptions(options) {
		
		return options.map(option => {
			
			return {
				id: option.id,
				value: option.label
			};
		});
	}
	
	getOptionsFromChoices(choices, removeDuplicates) {
		
		if (choices !== undefined && choices.length > 0) {
		
			let currentChoices = choices;
		
			if (removeDuplicates) {
				
				let choiceIds = choices.map(e => e.id.toLowerCase());
				
				currentChoices = choices.filter((element, index) => {
	    			return choiceIds.indexOf(element.id.toLowerCase()) === index;
				});
			}

			return currentChoices.map(choice => {
				
				return {
					id: choice.id,
					label: choice.value
				};
			});
			
		} else return [];
	}
}

export default new AutocompleteHelper();