import React, { useState, useEffect } from "react";

import { Container, Row, Col, Form, Breadcrumb } from "react-bootstrap";

import ElasticSearchService from "../../services/elasticSearch.service";

import Dialog from "../standard/standardDialog";

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';

export default function MeasurementManagement(props) {
	
	const [isLoading, setLoading] = useState(true);

	const [cementrySelection, setCementrySelection] = useState("");
	const [allCementries, setAllCementries] = useState([]);
	
	const [cementryMeasurementDataFile, setCementryMeasurementDataFile] = useState("");
	const [tombstoneMeasurementDataFile, setTombstoneMeasurementDataFile] = useState("");
	
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	const [showDialog, setShowDialog] = useState(false);
	
	const Input = styled('input')({
	  display: 'none',
	});
	
	
	function handleChangeCementrySelection(newValue) {
		
		setCementrySelection(allCementries.find(cementry => cementry.id === newValue.id));
	}	
	
	function handleUploadCementryMeasurementFile(e) {
		e.preventDefault();

		setMessage("");

		let file = e.target.files[0];
		

		let formData = new FormData();
		formData.append('file', file, file.name);
		formData.append('cementryId', cementrySelection.id);
		
		setCementryMeasurementDataFile(file.name);
		
		ElasticSearchService.uploadCementryMeasurementsDataFile(formData).then(
			response => {
				
				setMessage(response.data.message);
				setSuccessful(true);
				setShowDialog(true);
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setMessage(resMessage);
				setSuccessful(false);
				setShowDialog(true);
			}			
		)
	}
	
	function handleUploadTombstoneMeasurementFile(e) {
		e.preventDefault();

		setMessage("");

		let file = e.target.files[0];

		let formData = new FormData();
		formData.append('file', file, file.name);
		formData.append('cementryId', cementrySelection.id);
		
		setTombstoneMeasurementDataFile(file.name);
		
		ElasticSearchService.uploadTombstonesMeasurementsDataFile(formData).then(
			response => {
				
				setMessage(response.data.message);
				setSuccessful(true);
				setShowDialog(true);
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setMessage(resMessage);
				setSuccessful(false);
				setShowDialog(true);
			}			
		)
	}
	
	useEffect(() => {
		if (isLoading) {
			
			ElasticSearchService.loadCementriesForAutoComplete().then(
			response => {
				setAllCementries(response.data);
				setLoading(false);
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
					setMessage(resMessage);
					setSuccessful(false);
					setLoading(false);
				}
			);
		 }
	}, [isLoading]);
			
	return (
		<Container fluid="xxl">
		<Row>
				<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>			
					  <Breadcrumb.Item >Administration</Breadcrumb.Item>
					  <Breadcrumb.Item active>Vermessungsdaten</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col><h3>Vermessungsdatenmanagement</h3></Col>
			</Row>			
			{!isLoading && (
				<div>
					<Row>
						<Col><br/></Col>
					</Row>
					<Row md={12}>
						<Col  md={12} className="form-group">
														
							<Form.Group as={Col} controlId="CementryChoice">
		      					<Autocomplete 
									value= {allCementries.find(cementry => cementry.id === cementrySelection.id)}
									onChange={(event, newValue) => handleChangeCementrySelection(newValue)}
		      					    disableClearable = {true} 
		      						id="combo-box-cementries"
		      						options={allCementries}
		      						renderInput={(params) => <TextField {...params} label="Bitte wählen Sie den gewünschten Friedhof aus" />}
		    					/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col><br/></Col>
					</Row>
					<Row md={12}>
						<Col md={12}><h2>Upload - Vermessungsdaten des Friedhofs</h2></Col>
					</Row>
					<Row md={12}>
						<Col  md={8} className="form-group">
							<TextField fullWidth sx={{ m: 1 }} label="Bitte wählen Sie die Datei mit den Vermessungsdaten des Friedhofs aus" 
								name="cementry-measurement-data-file"
								value={cementryMeasurementDataFile} 
								disabled={true} />
						</Col>
						<Col md={4}>
							<Tooltip title={cementrySelection === "" ? "Bitte wählen Sie zuerst einen Friedhof aus" : ""} arrow placement="right">
								<label htmlFor="cementery-upload-button">
									{cementrySelection !== "" && (<Input accept=".geojson,.json" id="cementery-upload-button" multiple={false} type="file" onChange={(e) => handleUploadCementryMeasurementFile(e)} />)}
									<Button variant="contained" sx={{ m: 1 }} component="span" disabled={cementrySelection === "" ? true : false} >Upload</Button>
								</label>
							</Tooltip>
						</Col>
					</Row>
					<Row>
						<Col><br/></Col>
					</Row>
					<Row md={12}>
						<Col md={12}><h2>Upload - Vermessungsdaten der Grabsteine</h2></Col>
					</Row>
					<Row md={12}>
						<Col  md={8} className="form-group">
							<TextField fullWidth sx={{ m: 1 }} label="Bitte wählen Sie die Datei mit den Vermessungsdaten der Grabsteine aus" 
								name="tombstone-measurement-data-file"
								value={tombstoneMeasurementDataFile} 
								disabled={true} />
						</Col>
						<Col md={4}>
							<Tooltip title={cementrySelection === "" ? "Bitte wählen Sie zuerst einen Friedhof aus" : ""} arrow placement="right">
								<label htmlFor="tombstone-upload-button">
									{cementrySelection !== "" && (<Input accept=".geojson,.json" id="tombstone-upload-button" multiple={false} type="file" onChange={(e) => handleUploadTombstoneMeasurementFile(e)} />)}
									<Button variant="contained" sx={{ m: 1 }} component="span" disabled={cementrySelection === "" ? true : false} >Upload</Button>
								</label>
							</Tooltip>
						</Col>
					</Row>
					<Row>
						<Col><br/></Col>
					</Row>
				</div>
			)}

			{message && (
				<div className="form-group">
					<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
				</div>
			)}
						
			{showDialog && successful && 
				<Dialog 
					title={"Prüfung der Vermessungsdaten abgeschlossen!"}
		 			actions={[]} 
		 			onCancel={() => setShowDialog(false)} >
		 			<div>
		 				<div>
		 					<span style={{ color: "green" }}>Die Prüfung wurde vollständig und erfolgreich abgeschlossen!</span>
		 				</div>
		 				<div>
		 					<span style={{ color: "green" }}>Die Daten wurden erfolgreich gespeichert!</span>
		 				</div>
		 			</div>
		 		</Dialog>
			}
			
			{showDialog && !successful && 
				<Dialog 
					title={"Prüfung der Vermessungsdaten abgeschlossen!"}
		 			actions={[]}
		 			cancelTitle={"Beenden"}  
		 			onCancel={() => setShowDialog(false)} >
		 			<div>
		 				<div>
		 					<span>Die Prüfung der Daten ist fehlgeschlagen:</span>
		 				</div>
		 				<div style={{ 
								color: "red",
								paddingTop: 10,
								paddingBottom: 10
						}}>{message}</div>
		 				<div>
		 					<span>Die Daten wurden nicht gespeichert. Bitte korrigieren Sie den Fehler.</span>
		 				</div>
		 			</div>
		 		</Dialog>
			}
		</Container>

	);
}