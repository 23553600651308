import ElasticSearchService from "../services/elasticSearch.service";
import SchedulerService from "../services/scheduler.service";

import FileSaver from 'file-saver';

class PDF {
	
	createConservationsPdf(cementryId, tombstoneId, onError) {

		ElasticSearchService.createTombstoneConservationsPdf(cementryId, tombstoneId).then(
			response => {
	
				let fileName = cementryId + "-" + tombstoneId + "_conservations.pdf"
	
				FileSaver.saveAs(new Blob([response.data], { type: 'application/pdf' }), fileName);
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
	
				onError(resMessage);
			}
		);
	}
	
	createCementryPicturesReportPdf(cementryId, onError) {

		ElasticSearchService.createCementryPicturesReportPdf(cementryId).then(
			response => {
	
				let fileName = cementryId + "_pictures.pdf"
	
				FileSaver.saveAs(new Blob([response.data], { type: 'application/pdf' }), fileName);
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
	
				onError(resMessage);
			}
		);
	}
	
	downloadCementryPicturesReportPdf(cementryId, onError) {

		SchedulerService.downloadCementryPicturesReportPdf(cementryId).then(
			response => {
	
				let fileName = cementryId + "_pictures.pdf"
	
				FileSaver.saveAs(new Blob([response.data], { type: 'application/pdf' }), fileName);
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
	
				onError(resMessage);
			}
		);
	}
}

export default new PDF();
