import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Container, Row, Col, Button, Breadcrumb } from "react-bootstrap";

import AuthService from "../../services/auth.service";

const required = value => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		);
	}
};

const vpassword = value => {
	if (value.length < 6 || value.length > 40) {
		return (
			<div className="alert alert-danger" role="alert">
				The password must be between 6 and 40 characters.
			</div>
		);
	}
};

const vconfirmation = (value, props, components) => {
	if (components.new_password[0].value !== components.confirmation[0].value) {
		return (
			<div className="alert alert-danger" role="alert">
				Die Passwörter stimmen nicht überein.
			</div>
		);
	}
};


export default class Password extends Component {

	constructor(props) {
		super(props);
		this.handleCredentials = this.handleCredentials.bind(this);
		this.onChangeConfirmation = this.onChangeConfirmation.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);

		this.state = {
			new_password: "",
			confirmation: "",
			passwordEqualsConfirmation: false,
			successful: false,
			message: "",
			authenticated: false
		};
	}

	onChangePassword(e) {
		this.setState({
			new_password: e.target.value,
			passwordEqualsConfirmation: e.target.value !== this.state.confirmation
		});
	}

	onChangeConfirmation(e) {
		this.setState({
			confirmation: e.target.value,
			passwordEqualsConfirmation: e.target.value !== this.state.new_password
		});
	}

	handleCredentials(e) {
		e.preventDefault();

		this.setState({
			message: "",
			successful: false,
		});

		this.form.validateAll();

		if (this.checkBtn.context._errors.length === 0) {
			let user = AuthService.getCurrentUser();
			AuthService.changePassword(
				user.username,
				this.state.new_password
			).then(
				response => {
					this.setState({
						message: response.data.message,
						successful: true
					});
				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					this.setState({
						successful: false,
						message: resMessage
					});
				}
			);
		}
	}
	
	componentDidMount() {
		
		const user = AuthService.getCurrentUser();	
		if (user===null) {
			this.setState({
				authenticated: false,
				message: "Sie sind nicht angemeldet und können das Passwort nicht ändern!"
			});
		}
		else{
			this.setState({
				authenticated: true				
			});
		}
	}


	render() {
		return (
			<Container fluid="xxl" >
			<Row>
				<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Passwortänderung</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			
				<Row>
					<Col><h3>Passwort Änderung</h3></Col>
				</Row>
				
			{this.state.authenticated ? (
				<Form onSubmit={this.handleCredentials} ref={c => { this.form = c; }} >
					{!this.state.successful && (
						<div>
						<Row>
							<Col>Sie sind angemeldet als: <strong>{AuthService.getCurrentUser().username}</strong></Col>
						</Row>
						<Row>
							<Col>Vergeben Sie ein neues Passwort in beide Felder ein und klicken Sie auf "Passwort ändern"!</Col>
						</Row>
						<Row>
							<Col className="form-group">
								<label htmlFor="password"><strong>Passwort</strong></label>
								<Input
										type="password"
										className="form-control"
										name="new_password"
										value={this.state.new_password}
										onChange={this.onChangePassword}
										validations={[required, vpassword, vconfirmation]}
									/>
								</Col>
							</Row>
							<Row>
								<Col className="form-group">
									<label htmlFor="password"><strong>Passwordbestätigung</strong></label>
									<Input
										type="password"
										className="form-control"
										name="confirmation"
										value={this.state.confirmation}
										onChange={this.onChangeConfirmation}
										validations={[required, vpassword, vconfirmation]}
									/>
								</Col>
							</Row>
							<Row>
								<Col className="col-auto me-auto"></Col>
								<Col className="col-auto">
									<Button href="/home">Abbrechen</Button>
									{' '}
									<Button type='submit'><strong>Passwort ändern</strong></Button>
								</Col>
							</Row>
						</div>
					)}

					{this.state.message && (
						<div className="form-group">
							<div className={this.state.successful ? "alert alert-success": "alert alert-danger"	} role="alert">{this.state.message}	</div>
						</div>
					)}
					<CheckButton
						style={{ display: "none" }}
						ref={c => {
							this.checkBtn = c;
						}}
					/>
				</Form>
				):(
				
						<div className="form-group">
							<div className={this.state.successful ? "alert alert-success": "alert alert-danger"	} role="alert">{this.state.message}	</div>
						</div>
					
				)}
			</Container>

		);
	}
}
