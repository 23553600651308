import React from 'react'
import { Row, Col } from "react-bootstrap";

export default class DetailElementLiteraturListValues extends React.Component {

  	render() {
    	return (<div>
	        {this.props.value && this.props.value.length >0 &&  this.props.value[0] !== ""? 
      			<Row >
					<Col md={3} style={{backgroundColor: '#ffffff'}}>
						<strong>{this.props.name}</strong>
       				</Col>
					<Col  md="auto" style={{backgroundColor: '#ffffff'}}></Col>
					<Col md={{ span: 10, offset: 1 }} style={{backgroundColor: '#fbfbfb'}}>
						{this.props.value.map((entry, id) =>(	
							<div key={id}>{entry.page?entry.literaturEntry.value.substring(0,entry.literaturEntry.value.length-1 ) +", "+ entry.page:entry.literaturEntry.value}</div>))}
       				</Col>
      		</Row>
			: ""}</div>
			
    )
  }
}

