import React from 'react'
import { Button} from "react-bootstrap";

export default class NavigationButton extends React.Component {
	
  	render() {
    	return (
      		<Button disabled={this.props.disabled} onClick={this.props.onClick} name={this.props.name} variant="link" href={"/"+this.props.linkBase+"/"+this.props.cementryId+"/"+this.props.tombstoneId} className={this.props.myStyle}>{this.props.title}</Button>
	
    )
  }
}

