import React, { Fragment, useState, useEffect } from "react";

import { Row, Col } from "react-bootstrap";

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Stack from '@mui/material/Stack';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';

import ImageIcon from '@mui/icons-material/Image';

import ElasticSearchService from "../../../../services/elasticSearch.service";
import AuthService from "../../../../services/auth.service";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { getStringDate } from "../../../../helpers/util";

import AutocompleteHelper from "../../../../helpers/autocompleteHelper";
import ConservationForMultipleTombstones from "./conservationForMultipleTombstones2";


export default function ConservationPlanItem(props) {
	
	const { readOnly, cementryId, tombstoneId, conservation, index, onDelete, onAddNew, onChange, onChangeAssignedTombstones } = props;
	
	const [userDisplayName, setUserDisplayName] = useState("");
	
	const [setLoading] = useState(true);
	
	// JavaScript date format: YYYY-MM-DDTHH:mm:ss.sssZ
	let date = conservation.planningDate !== undefined ? conservation.planningDate : new Date().getTime();
	const [conservationPlanningDate, setConservationPlanningDate] = useState(date);
	
	let planingPerson = conservation.planner !== undefined ? conservation.planner : "";
	const [conservationPlanner, setConservationPlanner] = useState(planingPerson);

	const [actionLabels, setActionLabels] = useState([]);
	const [mappingLabels, setMappingLabels] = useState([]);
	const [inspectionLabels, setInspectionLabels] = useState([]);
	
	const [projectNameOptions, setProjectNameOptions] = useState([]);
	const [companyOptions, setCompanyOptions] = useState([]);
	
	const [actionOptions, setActionOptions] = useState([]);
	const [mappingOptions, setMappingOptions] = useState([]);
	const [inspectionOptions, setInspectionOptions] = useState([]);
	
	let cost = conservation.costEstimate !== undefined ? conservation.costEstimate : "";
	const [conservationCostEstimate, setConservationCostEstimate] = useState(cost);
	
	let currentStatus = conservation.status !== undefined ? conservation.status : null;
	const [conservationStatus, setConservationStatus] = useState(currentStatus);
	
	let currentProjectName = conservation.projectName !== undefined ? conservation.projectName : null;
	const [projectName, setProjectName] = useState(currentProjectName);
	
	let currentCompany = conservation.company !== undefined ? conservation.company : null;
	const [company, setCompany] = useState(currentCompany);
	
	// JavaScript date format: YYYY-MM-DDTHH:mm:ss.sssZ
	let executionDate = conservation.executionDate !== undefined ? new Date(conservation.executionDate) : new Date();
	const [conservationExecutionDate, setConservationExecutionDate] = useState(executionDate);
	
	let documentation = conservation.documentation !== undefined ? conservation.documentation : "";
	const [conservationDocumentation, setConservationDocumentation] = useState(documentation);
	
	const [documentationFile, setDocumentationFile] = useState("");
	
	let pdfDocumentations = conservation.pdfDocumentations !== undefined ? conservation.pdfDocumentations : [];
	const [conservationPdfDocumentations, setConservationPdfDocumentations] = useState(pdfDocumentations);
	
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	const Input = styled('input')({
	  display: 'none',
	});
	
	const statusOptions = [
		{ label: "Planung" },
		{ label: "Ausführung" }, 
		{ label: "Abgeschlossen" }
	];
	
	const createConservation = (projectName, company, actions, mappings, inspections, costEstimate, status, executionDate, documentation, pdfDocumentations) => {
		
		let currentConservation = {};
		
     	currentConservation.planningDate = (new Date()).getTime();
		currentConservation.planner = userDisplayName;
		
		currentConservation.projectName = (projectName !== undefined && projectName !== "") ? projectName : null;
		currentConservation.company = (company !== undefined && company !== "") ? company : null;
		
		currentConservation.actionsChoice = AutocompleteHelper.getChoicesFromOptions(actions);
		currentConservation.mappingsChoice = AutocompleteHelper.getChoicesFromOptions(mappings);
		currentConservation.inspectionsChoice = AutocompleteHelper.getChoicesFromOptions(inspections);
		currentConservation.costEstimate = costEstimate;
		
		currentConservation.status = (status !== undefined && status !== "") ? status : null;
		currentConservation.executionDate = getStringDate(executionDate);
		currentConservation.documentation = documentation;
		currentConservation.pdfDocumentations = pdfDocumentations;
		
		return currentConservation;
	}
	
	function handleChangeAssignedTombstones(assignedTombstones) {
			
		onChangeAssignedTombstones(index, assignedTombstones);
	}
	
	function handleAddNewConservation() {
		
		let newConservation = createConservation(null, null, [], [], [], "", "Planung", new Date(), "", []);
		
		onAddNew(newConservation);
	}
	
	function hanldeDeleteConservation() {
		
		onDelete(index);
	}
	
	function handleChangeProjectName(newValue) {
		
		let currentProjectName = (newValue !== null && newValue !== "") ? newValue.label : null;
		
		setProjectName(currentProjectName);
		
		conservation.projectName = currentProjectName;
		
		onChange(index, conservation);
	}
	
	function handleChangeCompany(newValue) {
		
		let currentCompany = (newValue !== null && newValue !== "") ? newValue.label : null;
		
		setCompany(currentCompany);
		
		conservation.company = currentCompany;
		
		onChange(index, conservation);
	}
	
	function handleChangeActions(newValue) {
		
		let currentActionLabels = [...newValue];
		
		setActionLabels(currentActionLabels);
		
		conservation.actionsChoice = AutocompleteHelper.getChoicesFromOptions(currentActionLabels);
		
		onChange(index, conservation);
	}
	
	function handleChangeMappings(newValue) {
		
		let currentLabels = [...newValue];
		
		setMappingLabels(currentLabels);
		
		conservation.mappingsChoice = AutocompleteHelper.getChoicesFromOptions(currentLabels);
		
		onChange(index, conservation);
	}
	
	function handleChangeInspections(newValue) {
		
		let currentLabels = [...newValue];
		
		setInspectionLabels(currentLabels);
		
		conservation.inspectionsChoice = AutocompleteHelper.getChoicesFromOptions(currentLabels);
		
		onChange(index, conservation);
	}
	
	function handleChangeCostEstimate(newValue) {
		
		setConservationCostEstimate(newValue);
		
		conservation.costEstimate = newValue;
		
		onChange(index, conservation);
	}
	
	function handleChangeStatus(newValue) {
		
		let currentConservationStatus = (newValue !== null && newValue !== "") ? newValue.label : null;
		
		setConservationStatus(currentConservationStatus);
		
		conservation.status = currentConservationStatus;
		
		onChange(index, conservation);
	}
	
	function handleChangeExecutionDate(newValue) {
		
		setConservationExecutionDate(newValue);
		
		conservation.executionDate = getStringDate(newValue);
		
		onChange(index, conservation);
	}
	
	function handleChangeDocumentation(newValue) {
		
		setConservationDocumentation(newValue);
		
		conservation.documentation = newValue;
		
		onChange(index, conservation);
	}
	
	function handleUploadFile(e) {
		e.preventDefault();

		setMessage("");

		let file = e.target.files[0];

		let formData = new FormData();
		formData.append('file', file, file.name);
		formData.append('cementryId', cementryId);
		formData.append('tombstoneId', tombstoneId);
		formData.append('conservationId', conservation.planningDate);
		
		setDocumentationFile(file.name);
		
		ElasticSearchService.uploadConservationDocumentationFile(formData).then(
			response => {
				
				let files = [...conservationPdfDocumentations];
				
				let documentationFile = {
					
					name: file.name,
					url: response.data.url
				}
				
				files.push(documentationFile);
				setConservationPdfDocumentations(files);
				
				conservation.pdfDocumentations = files;
		
				onChange(index, conservation);
				
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setMessage(resMessage);
				setSuccessful(false);
				
			}			
		)
	}
	
	function getConservationLabelsBySelectedValue(selectedValues, availableOptions) {
		
	
		let currentLabels = [];
		if (selectedValues !== undefined &&  selectedValues != null && selectedValues.length > 0) {
			
			currentLabels = selectedValues.map((selectedValue) => {
				
				// translate all id values into choices
				let choiceFound = availableOptions.find((choice) => selectedValue === choice.id);
				
				return choiceFound;
				
			}).filter((choice) => choice !== undefined); // remove all entries where there was no choices for a given id (value of find() was undefined)
		}
		
		return currentLabels;
	}
	
	useEffect(() => {

		ElasticSearchService.loadChoices().then(
			response => {	
				let data = response.data;
				
				let options = null;
				
				options = data["Ausführende Unternehmen für Restaurierungsmaßnahmen"].map((option) => {
					
					return {
						id: option.id,
						label: option.value
					}
				});
				setCompanyOptions(options);
				
				let currentCompany = conservation.company !== undefined ? conservation.company : null;
				setCompany(currentCompany);

				options = data["Projekte für Restaurierungsmaßnahmen"].map((option) => {
					
					return {
						id: option.id,
						label: option.value
					}
				});
				setProjectNameOptions(options);
				
				let currentProjectName = conservation.projectName !== undefined ? conservation.projectName : null;
				setProjectName(currentProjectName);
				
				options = AutocompleteHelper.getOptionsFromChoices(data["Maßnahmen zur Konservierung"]);
				setActionOptions(options);
				let actionLabels = getConservationLabelsBySelectedValue(conservation.actions, options);
				setActionLabels(actionLabels);
				
				options = AutocompleteHelper.getOptionsFromChoices(data["Schadens- und Maßnahmenkartierung"]);
				setMappingOptions(options);
				let selectedMappingLabels = getConservationLabelsBySelectedValue(conservation.mappings, options);
				setMappingLabels(selectedMappingLabels);
				
				options = AutocompleteHelper.getOptionsFromChoices(data["Untersuchungen durch Naturwissenschaftler"]);
				setInspectionOptions(options);
				let selectedInspectionLabels = getConservationLabelsBySelectedValue(conservation.inspections, options);
				setInspectionLabels(selectedInspectionLabels);
				
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
	
				setMessage(resMessage);
				setSuccessful(false);
				setLoading(false);
			}
		);
		
		// JavaScript date format: YYYY-MM-DDTHH:mm:ss.sssZ
		let planningDate = conservation.planningDate !== undefined ? conservation.planningDate : new Date().getTime();
		setConservationPlanningDate(planningDate);
		
		let planingPerson = conservation.planner !== undefined ? conservation.planner : "";
		setConservationPlanner(planingPerson);

		let cost = conservation.costEstimate !== undefined ? conservation.costEstimate : "";
		setConservationCostEstimate(cost);
	
		let currentStatus = (conservation.status !== undefined && conservation.status !== "") ? conservation.status : null;
		setConservationStatus(currentStatus);
	
		// JavaScript date format: YYYY-MM-DDTHH:mm:ss.sssZ
		let executionDate = conservation.executionDate !== undefined ? new Date(conservation.executionDate) : new Date();
		setConservationExecutionDate(executionDate);
	
		let documentation = conservation.documentation !== undefined ? conservation.documentation : "";
		setConservationDocumentation(documentation);
	
		let pdfDocumentations = conservation.pdfDocumentations !== undefined ? conservation.pdfDocumentations : [];
		setConservationPdfDocumentations(pdfDocumentations);
		
		setUserDisplayName(conservation.planner);
		 
	}, [conservation]);
			
	return (
		<>
		<Box sx={{ boxShadow: 3 }} >
			<Row>
				<Col></Col>
			</Row>
			<Row>
				<Col><h2>Maßnahmenplan</h2></Col>
			</Row>
			<Row>
				<Col md={1}></Col>
				<Col md={3}><h4>Planungsdatum</h4></Col>
				<Col md={7}>{getStringDate(new Date(Number(conservationPlanningDate)))}</Col>
				<Col md={1}></Col>
			</Row>
			<Row>
				<Col md={1}></Col>
				<Col md={3}><h4>Planer</h4></Col>
				<Col md={7}>{conservationPlanner}</Col>
				<Col md={1}></Col>
			</Row>
			<Row>
				<Col></Col>
			</Row>
			<Row md={12} >
				<Col md={1}></Col>
				<Col md={3}>Ausführendes Unternehmen</Col>
				<Col md={7}>
					<Autocomplete 
						id="companies-list"
						options={companyOptions}
						value={company} 
						filterSelectedOptions 
						isOptionEqualToValue={(option, value) => (value !== null && option.label === value)} 
						onChange={(event, newValue) => handleChangeCompany(newValue)}
						renderInput={(params) => <TextField {...params} label="Wählen Sie das ausführende Unternehmen" />} 
						disabled={readOnly}
					/>
					
				</Col>
				<Col md={1}></Col>
			</Row>
			<Row md={12} >
				<Col md={1}></Col>
				<Col md={3}>Projekt</Col>
				<Col md={7}>
					<Autocomplete 
						id="projects-list"
						options={projectNameOptions}
						value={projectName} 
						filterSelectedOptions 
						isOptionEqualToValue={(option, value) => (value !== null && option.label === value)} 
						onChange={(event, newValue) => handleChangeProjectName(newValue)}
						renderInput={(params) => <TextField {...params} label="Wählen Sie das Projekt" />} 
						disabled={readOnly}
					/>
					
				</Col>
				<Col md={1}></Col>
			</Row>
			<Row md={12} >
				<Col md={1}></Col>
				<Col md={3}>Maßnahmen</Col>
				<Col md={7}>
					<Autocomplete 
						multiple
						id="actions-list"
						options={actionOptions}
						value={actionLabels} 
						filterSelectedOptions 
						isOptionEqualToValue={(option, value) => (value !== null && option.id === value.id)} 
						onChange={(event, newValue) => handleChangeActions(newValue)}
						renderInput={(params) => <TextField {...params} label="Wählen Sie die Maßnahme" />} 
						disabled={readOnly}
					/>
					
				</Col>
				<Col md={1}></Col>
			</Row>
			<Row md={12} >
				
				<Col md={1}></Col>
				<Col md={3}>Kartierung</Col>
				<Col md={7}>
					<Autocomplete 
						multiple
						id="mappings-list"
						options={mappingOptions}
						value={mappingLabels} 
						filterSelectedOptions 
						isOptionEqualToValue={(option, value) => (value !== null && option.id === value.id)} 
						onChange={(event, newValue) => handleChangeMappings(newValue)}
						renderInput={(params) => <TextField {...params} label="Wählen Sie die Kartierung" />} 
						disabled={readOnly}
					/>
					
				</Col>
				<Col md={1}></Col>
			</Row>
			<Row md={12} >
				
				<Col md={1}></Col>
				<Col md={3}>Untersuchungen</Col>
				<Col md={7}>
					<Autocomplete 
						multiple
						id="inspections-list"
						options={inspectionOptions}
						value={inspectionLabels} 
						filterSelectedOptions 
						isOptionEqualToValue={(option, value) => (value !== null && option.id === value.id)} 
						onChange={(event, newValue) => handleChangeInspections(newValue)}
						renderInput={(params) => <TextField {...params} label="Wählen Sie die Untersuchungen" />} 
						disabled={readOnly}
					/>
					
				</Col>
				<Col md={1}></Col>
			</Row>
			<Row md={12}>
				<Col md={1}></Col>
				<Col md={3}>Kostenprognose</Col>
				<Col  md={7} className="form-group">
					<TextField 
						fullWidth 
						label="Kostenprognose" 
						name="conservation-cost-estimate"
						value={conservationCostEstimate} 
						onChange={(e) => handleChangeCostEstimate(e.target.value)} 
						multiline
						rows={4} 
						disabled={readOnly} 
						/>
				</Col>
				<Col md={1}></Col>
			</Row>
		</Box>
		<br/>
		<Box  sx={{ boxShadow: 3 }}>
			<Row>
				<Col></Col>
			</Row>
			<Row>
				<Col><h2>Maßnahmendurchführung</h2></Col>
			</Row>
			<Row>
				<Col></Col>
			</Row>
			<Row md={12} >
				
				<Col md={1}></Col>
				<Col md={3}>Status</Col>
				<Col md={7}>
					<Autocomplete 
						id="actions-list"
						options={statusOptions}
						value={conservationStatus} 
						filterSelectedOptions 
						isOptionEqualToValue={(option, value) => (value !== null && value !== "" && option.label === value)} 
						onChange={(event, newValue) => handleChangeStatus(newValue)}
						renderInput={(params) => <TextField {...params} label="Wählen Sie die Maßnahme" />} 
						disabled={readOnly}
					/>
					
				</Col>
				<Col md={1}></Col>
			</Row>
			<Row md={12} >
				
				<Col md={1}></Col>
				<Col md={3}>Ausführungsdatum</Col>
				<Col md={7}>
				
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DesktopDatePicker 
				          label="Datum"
				          inputFormat="dd.MM.yyyy"
				          value={conservationExecutionDate}
				          onChange={handleChangeExecutionDate}
				          renderInput={(params) => <TextField fullWidth {...params} disabled={readOnly} />}  
				          disabled={readOnly} 
				        />
					</LocalizationProvider>	
				</Col>
				<Col md={1}></Col>
			</Row>
			<Row md={12}>
				<Col md={1}></Col>
				<Col md={3}>Dokumentation</Col>
				<Col  md={7} className="form-group">
					<TextField 
						fullWidth 
						label="Dokumentation" 
						name="conservation-documentation"
						value={conservationDocumentation} 
						onChange={(e) => handleChangeDocumentation(e.target.value)} 
						multiline
						rows={4} 
						disabled={readOnly} 
						/>
				</Col>
				<Col md={1}></Col>
			</Row>
			<Row md={12}>
				<Col md={1}></Col>
				<Col md={3}>Dokumentation- und Bild-Upload</Col>
				<Col  md={5} className="form-group">
					<TextField fullWidth label="Bitte wählen Sie die Dateien für den Upload aus" 
						name="documentation-file"
						value={documentationFile} 
						disabled={readOnly} />
				</Col>
				<Col md={2}>
					<Tooltip title={"Laden Sie die Datei hoch"} arrow placement="right">
						<label htmlFor="documentation-file-upload-button">
							{!readOnly && (<Input accept=".jpg,.png,.git,.jpeg" id="documentation-file-upload-button" multiple={false} type="file" onChange={(e) => handleUploadFile(e)} />)}
							<Button variant="contained" sx={{ m: 1 }} component="span" disabled={readOnly} >Upload</Button>
						</label>
					</Tooltip>
				</Col>
				<Col md={1}></Col>
			</Row>
			<Row md={12}>
				<Col md={4}></Col>
				<Col  md={7} className="form-group">
					Wählen Sie eine Datei aus. Die Dateigröße sollte 250 MB nicht überschreiten. Die Dauer des Uploads ist abhängig von der Dateigröße.
				</Col>
				<Col md={1}></Col>
			</Row>
			{conservationPdfDocumentations && (
			<Row md={12}>
				<Col md={1}></Col>
				<Col md={3}>Hochgeladen</Col>
				<Col  md={5} className="form-group">
					<Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
				        <List>
				        	{conservationPdfDocumentations.map((conservationPdfDocumentation, index) => (
					          <ListItem key={index} component={Link} href={conservationPdfDocumentation.url} target="_blank" disablePadding>
					            <ListItemButton>
					              <ListItemIcon>
					                <ImageIcon />
					              </ListItemIcon>
					              <ListItemText primary={conservationPdfDocumentation.name} />
					            </ListItemButton>
					          </ListItem>
					          ))}
				        </List>
		    		</Box>
				</Col>
				<Col md={3}></Col>
			</Row>
			)}
			{!readOnly && (
			<Row md={12}>
				<Col md={1}></Col>
				<Col  md={10} className="form-group">
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Tooltip title={"Maßnahme entfernen"} arrow placement="right">
							<span>
								<IconButton onClick={hanldeDeleteConservation} ><DeleteIcon /></IconButton>
							</span>
						</Tooltip>
					
						<Tooltip title={"Maßnahme hinzufügen"} arrow placement="left">
							<span>
								<IconButton onClick={handleAddNewConservation} ><AddCircleIcon /></IconButton>
							</span>
						</Tooltip>
					</Stack>
				</Col>
				<Col md={1}></Col>
			</Row>
			)}
			
			{message && (
					<div className="form-group">
						<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
					</div>
			)}
		</Box>
		<br/>
		
		</>
	);
}