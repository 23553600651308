import React, { useState, useEffect } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";

import ElasticSearchService from "../../../../services/elasticSearch.service";

import ConservationProjectDetails from "./conservationProjectDetails";

import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import styled from "styled-components";

import ConservationForMultipleTombstones from "./conservationForMultipleTombstones2";

import ConservationTombstoneList from "./conservationTombstoneList";
import { handleStorage, setStorage } from '../../../../helpers/jfb'
import ACService from "../../../../helpers/accessValidation.js";
import * as Constants from "../../../../components/constants";
import AuthService from "../../../../services/auth.service";

const PdfAnchor = styled.a`
	cursor: pointer;
`;	
	
export default function ConservationProjects() {
	const [user] = useState(AuthService.getCurrentUser());
	const [projectName, setProjectName] = useState(handleStorage('selectedConversationProject', null));
	const [projectNameOptions, setProjectNameOptions] = useState([]);
	
	const [conservationIndex, setConservationIndex] = useState(-1);
	
	const [tombstone, setTombstone] = useState({});
	
	const [isLoadingChoices, setLoadingChoices] = useState(true);
	const [isLoadingTombstoneWithProject, setLoadingTombstoneWithProject] = useState({});
	
	const [loadConservationTombstoneList, setLoadConservationTombstoneList] = useState(1);
	
	const [saveConservationsOnTombstones, setSaveConservationsOnTombstones] = useState(false);
	
	const [viewProjectDetails, setViewProjectDetails] = useState(false);
	const [isProjectEdited, setProjectEdited] = useState(false);
	const [createNewProjectMode, setCreateNewProjectMode] = useState(false);
	const [noConservationProjectMode, setNoConservationProjectMode] = useState(false);
	
	const [successful, setSuccessful] = useState(false);	
	const [message, setMessage] = useState("");
	
	const PROJECTS_CHOICE_GROUP = "Projekte für Restaurierungsmaßnahmen";
	
	function handleTombstoneSelection(tombstone) {
		
		setTombstone(tombstone);
	}
	
	function handleUpdateConservation(updatedConservation) {
		
		if (createNewProjectMode) {
			
			handleSaveNewProject(updatedConservation);

		} else {
			
			handleSaveProject(updatedConservation);
		}
		
		setStorage("selectedConversationProject", updatedConservation.projectName);
		
		setViewProjectDetails(false);
		setCreateNewProjectMode(false);
		setProjectName(updatedConservation.projectName);
	}
	
	function getConservationIndex(tombstoneWithConservations, conservationProjectName) {
		
		
		if(tombstoneWithConservations.conservations !== undefined){
			return tombstoneWithConservations.conservations.findIndex(conservation => conservation.projectName === conservationProjectName);
		} else {
			return -1;
		}
	}
	
	function getConservation(tombstoneWithConservations, index) {
		
		if (index > -1) return tombstoneWithConservations.conservations[index];
		return null;
		
	}
	
	function handleChangeProjectName(newValue) {
		
		setViewProjectDetails(false);
		
		setLoadingTombstoneWithProject(true);
		setNoConservationProjectMode(false);
		
		let currentProjectName = (newValue !== null && newValue !== "") ? newValue.label : null;
		
		ElasticSearchService.loadTombstoneWithProject(currentProjectName).then(
			response => {
				
				if (response.data !== "") {
					setTombstone(response.data);
					
					let index = getConservationIndex(response.data, currentProjectName);
					setConservationIndex(index);
					
					setProjectName(currentProjectName);
					setViewProjectDetails(true);
					setStorage("selectedConversationProject", currentProjectName);
					
					setLoadingTombstoneWithProject(false);
				} else {
					
					setCreateNewProjectMode(true);
					
					setProjectName(currentProjectName);
					setViewProjectDetails(true);
					
					setNoConservationProjectMode(true);
					setLoadingTombstoneWithProject(false);
				}
				
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
					setMessage(resMessage);
					setSuccessful(false);
					setViewProjectDetails(false);
			}
		);
	}
	
	function enterCreateNewProjectMode() {
			
		setCreateNewProjectMode(true);
		
		setViewProjectDetails(true);
	}
	
	function handleSaveNewProject(newProject) {
		
		setViewProjectDetails(false);
						
		ElasticSearchService.saveNewProject(newProject).then(
			response => {	
				
				ElasticSearchService.loadChoices().then(
					response => {
						
						let data = response.data;
						
						let options = data[PROJECTS_CHOICE_GROUP].map((option) => {
						
							return {
								id: option.id,
								label: option.value
							}
						});
						setProjectNameOptions(options);
						
						setLoadingChoices(false);
						
						handleChangeProjectName({
							label: newProject.projectName
						});
					},
					error => {
						const resMessage =
								(error.response &&
									error.response.data &&
									error.response.data.message) ||
								error.message ||
								error.toString();
			
						setMessage(resMessage);
						setSuccessful(false);
						setLoadingChoices(true);
					}
				);

			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
		
				setMessage(resMessage);
				setSuccessful(false);
			}
		);
	}
	
	function handleSaveProject(project) {
						
		ElasticSearchService.saveProject(project).then(
			response => {	
				
				setSuccessful(true);
				setMessage(response.data.message);
				
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
		
				setMessage(resMessage);
				setSuccessful(false);
			}
		);
	}
	
	function handleSaveAssignedTombstones() {
		
		let assignedTombstones = tombstone.conservations[conservationIndex].assignedTombstones;
		if (assignedTombstones !== null && typeof assignedTombstones !== 'undefined' && assignedTombstones.length > 0) {
				
			setSaveConservationsOnTombstones(true);
			
			ElasticSearchService.assignConservationToTombstones(tombstone.cementry, tombstone.conservations[conservationIndex], assignedTombstones).then(
				response => {
					
					setSuccessful(true);
					setMessage(response.data.message);
					
					setLoadConservationTombstoneList(Math.random());
					
					setSaveConservationsOnTombstones(false);
					
				},
				error => {
					const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
			
					setMessage(resMessage);
					setSuccessful(false);
					
					setSaveConservationsOnTombstones(false);
				}
			);
		} 
	}
	
	function handleChangeAssignedTombstones(assignedTombstones) {
		
		tombstone.conservations[conservationIndex].assignedTombstones = assignedTombstones;
				
		setTombstone(tombstone);
	}
	
	function handleEdit(edit) {
		
		if (createNewProjectMode && !edit) {
			
			setViewProjectDetails(false);
			setCreateNewProjectMode(false);
			setProjectName(null);
		}
		
		setProjectEdited(edit);
	}

	useEffect(() => {
		
		if (isLoadingChoices) {

			ElasticSearchService.loadChoices().then(
				response => {
					
					let data = response.data;
					
					let options = data[PROJECTS_CHOICE_GROUP].map((option) => {
					
						return {
							id: option.id,
							label: option.value
						}
					});
					setProjectNameOptions(options);
					
					setLoadingChoices(false);
				},
				error => {
					const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
		
					setMessage(resMessage);
					setSuccessful(false);
					setLoadingChoices(true);
				}
			);
		}
		
		if (projectName != null) {
		
			setLoadingTombstoneWithProject(true);
			setNoConservationProjectMode(false);
			
			ElasticSearchService.loadTombstoneWithProject(projectName).then(
				response => {
					
					if (response.data !== "") {
						setTombstone(response.data);
						
						let index = getConservationIndex(response.data, projectName);
						setConservationIndex(index);
						
						setProjectName(projectName);
						setViewProjectDetails(true);
						setStorage("selectedConversationProject", projectName);
						
						setLoadingTombstoneWithProject(false);
					} else {
						
						setCreateNewProjectMode(true);
						
						setProjectName(projectName);
						setViewProjectDetails(true);
						
						setNoConservationProjectMode(true);
						setLoadingTombstoneWithProject(false);
					}
					
				},
				error => {
					const resMessage =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
		
						setMessage(resMessage);
						setSuccessful(false);
						setViewProjectDetails(false);
				}
			);
		}

	}, []);

	return (
		<Container>
			<Row>
				<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item className="breadcrumb-item-style" href="/dashboard">Management</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active >Konservierungsprojekte</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			
			{!isLoadingChoices && (
				<>
					{message && (
						<div className="form-group">
							<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
						</div>
					)}
			
					<div className="areaBorder">
					   	<Row>
							<Col><h2><b>Konservierung</b></h2></Col>
						</Row>
						
						<Row>
							<Col>Übersicht aller geplanten und abgeschlossenen Projekte</Col>
						</Row>
						{ACService.accessValidation(user, Constants.AC_EDIT_ASSESSMENTS) && (
						<Row>
							<Col className="col-auto me-auto"></Col>
							<Col className="col-auto">
								<Tooltip title={"Erstellen Sie ein neues Projekt"} arrow placement="right">
									<label htmlFor="create-project-button">
										<Button variant="contained" sx={{ m: 1 }} component="span" disabled={createNewProjectMode || (viewProjectDetails && isProjectEdited)} onClick={(e) => enterCreateNewProjectMode(e)} >Neues Projekt</Button>
									</label>
								</Tooltip>
							</Col>
						</Row>
						)}
						<Row >
							<Col >Projekt</Col>
						</Row>
						
						<Row  >
							<Col >
								<Autocomplete 
									id="projects-list"
									options={projectNameOptions}
									value={projectName} 
									filterSelectedOptions 
									isOptionEqualToValue={(option, value) => (value !== null && option.label === value)} 
									onChange={(event, newValue) => handleChangeProjectName(newValue)}
									renderInput={(params) => <TextField {...params} label="Wählen Sie ein vorhandenes Projekt aus" />} 
									disabled={createNewProjectMode || (viewProjectDetails && isProjectEdited)}
								/>
								
							</Col>
						</Row>
						{!isLoadingTombstoneWithProject && !(createNewProjectMode || (viewProjectDetails && isProjectEdited)) && (
						<Row>
							<Col>Wählen Sie ein vorhandenes Projekt aus</Col>
						</Row>
						)}
						
						{isLoadingTombstoneWithProject && (
						<Row>
							<Col>Projektdetails werden geladen. Bitte einen Augenblick Geduld...</Col>
						</Row>
						)}
					</div>
				</>
			)}
		
		   {(viewProjectDetails) && (
			<>
				<div className="areaBorder">
					
					<Row md={12}>
						<Col md={12}>
							<ConservationProjectDetails 
								{...(createNewProjectMode ? {
									tombstone: undefined,
									conservation: noConservationProjectMode ? {projectName: projectName} : undefined,
									createNewProject: true,
									noConservation: noConservationProjectMode,
									onTombstoneSelection: handleTombstoneSelection,
									onAddProject: handleUpdateConservation, 
									onUpdateProject: handleUpdateConservation 
								}:{
									tombstone: tombstone,
									conservation: (conservationIndex > -1) ? tombstone.conservations[conservationIndex] : undefined,
									createNewProject: false,
									onUpdateProject: handleUpdateConservation  
								})} 
								onEditStarted={handleEdit}
							 />
						</Col>
					</Row>
					<Row>
						<Col>
						</Col>
					</Row>
				</div>
				{!createNewProjectMode && ACService.accessValidation(user, Constants.AC_EDIT_ASSESSMENTS) && (	
				<div className="areaBorder">
					<Row>
						<Col></Col>
					</Row>
					<Row>
						<Col><h2>Auswahl weiterer Grabsteine</h2></Col>
					</Row>
					<Row>
						<Col></Col>
					</Row>
					<Row md={12} >
						
						<Col md={1}></Col>
						<Col md={10}>
							<ConservationForMultipleTombstones 
								cementeryId={tombstone.cementry} 
								assignedTombstones={tombstone.conservations[conservationIndex].assignedTombstones} 
								onChange={handleChangeAssignedTombstones} 
							/>					
							
						</Col>
						<Col md={1}></Col>
					</Row>
					{!saveConservationsOnTombstones ? (
					<Row md={12} >
						<Col md={9}></Col>
						<Col md={2}>
							<Tooltip title={"Fügen die Grabsteine dem Projekt hinzu"} arrow placement="right">
								<label htmlFor="assign-tombstones-button">
									<Button variant="contained" sx={{ m: 1 }} component="span" disabled={createNewProjectMode || (viewProjectDetails && isProjectEdited)} onClick={() => handleSaveAssignedTombstones()} >Zuordnen</Button>
								</label>
							</Tooltip>
						</Col>
					</Row>
					) : (
					<Row md={12} >
						<Col md={7}></Col>
						<Col md={4}>
							Grabsteine werden dem Projekt zugeordnet. Bitte einen Augenblick Geduld...
						</Col>
					</Row>
					)}
					
					<Row>
						<Col></Col>
					</Row>
					<Row>
						<Col><h2>Liste der Maßmahmen</h2></Col>
					</Row>
					<Row>
						<Col></Col>
					</Row>
					<Row md={12} >
						
						<Col md={1}></Col>
						<Col md={10}>
							<ConservationTombstoneList 
								projectName={projectName} 
								reload={loadConservationTombstoneList}
							/>					
							
						</Col>
						<Col md={1}></Col>
					</Row>
				</div>
				)}
			</>
			)}
		</Container>
	);
}