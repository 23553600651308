export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.accessToken) {
    return { Authorization: 'Bearer ' + user.accessToken, 'Access-Control-Allow-Origin': '*'}; 
  } else {
    return {};
  }
}

//return { 'x-access-token': user.accessToken, 'Access-Control-Allow-Origin': '*', };       // for Node.js Express back-end