import React, { useState } from "react";

import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import * as Constants from "../../components/constants";
import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";
import ACService from "../../helpers/accessValidation.js";

import TombstoneConditionAssessment from "./tombstoneConditionAssessment";
import ActionsBar from "../standard/actionsBar";

export default function Assessments(props) {
	
	const [user] = useState(AuthService.getCurrentUser());
	
	const [setLoading] = useState(true);
	
	const [tombstone, setTombstone] = useState({});
	const [conditions, setConditions] = useState([]);
	
	const [edit, setEdit] = useState(false);
	
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	function handleTombstoneSelection(tombstone) {
		
		setTombstone(tombstone);
	}
	
	function handleUpdateAssessments(updatedAssessments) {
		
		let updatedList = [...updatedAssessments];
		
		setConditions(updatedList);
	}
	
	function handleSaveAssessments() {
		
		tombstone.conditions = conditions;
		
		ElasticSearchService.saveTombstone(tombstone).then(
			response => {	
				
				setSuccessful(true);
				setMessage(response.data.message);
				
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
		
				setMessage(resMessage);
				setSuccessful(false);
				setLoading(false);
			}
		);
	}
	
	function handleChangeEdit(value) {
			
		setEdit(value);
	}
	
	return (
		<Container>
			<Row md={12}>
				<Col  md={12} >
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Grabsteinbewertungen</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row md={12}>
				<Col  md={12} ><h3>Zustandsbewertungen</h3></Col>
			</Row>	
			{ACService.accessValidation(user, Constants.AC_EDIT_ASSESSMENTS) && (<Row md={12}>
				<Col  md={12} >
					<ActionsBar 
						isEditable={edit} 
						onSave={handleSaveAssessments} 
						onChange={handleChangeEdit} 
						labelInit={Constants.ASSESSMENT_CHANGE_BUTTON_INIT}
						labelEdit={Constants.ASSESSMENT_CHANGE_BUTTON_ACTIV}
					/>
				</Col>
			</Row>)}
			<Row md={12}>
				<Col  md={12} className="form-group">
					<TombstoneConditionAssessment 
						readOnly={!edit} 
						onTombstoneSelection={handleTombstoneSelection}
						onUpdateAssessments={handleUpdateAssessments}
					/>
				</Col>
			</Row>
			{ACService.accessValidation(user, Constants.AC_EDIT_ASSESSMENTS) && (<Row md={12}>
				<Col  md={12} >
					<ActionsBar 
						isEditable={edit} 
						onSave={handleSaveAssessments} 
						onChange={handleChangeEdit} 
						labelInit={Constants.ASSESSMENT_CHANGE_BUTTON_INIT}
						labelEdit={Constants.ASSESSMENT_CHANGE_BUTTON_ACTIV} 
					/>
				</Col>
			</Row>)}
			{message && (
				<Row className="form-group">
					<Col className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</Col>
				</Row>
			)}
			
		</Container>
		
	);
}