import { isEmpty } from "./util";

export function getPolygonCoordinatesFromCementery(cementery) {
	
	let cementeryCoordinates = [];
	if (!isEmpty(cementery.coordinates) && !isEmpty(cementery.coordinates.features)) {	
		 cementery.coordinates.features.forEach(feature => {	
			cementeryCoordinates.push(...feature.geometry.coordinates[0]);
		});
	}
	
	return cementeryCoordinates;
}

export function getCoordinatesFromCementery(cementery) {
	
	let cementeryCoordinates = null;
	if (!isEmpty(cementery.coordinates) && !isEmpty(cementery.coordinates.features)) {	
		 
		 cementeryCoordinates = cementery.coordinates.features[0].geometry.coordinates[0][0];
	}
	
	return cementeryCoordinates;
}

export function getCoordinatesFromTombstone(tombstone) {
	
	let tombstoneCoordinates = null;
	if (tombstone.weltkoordinaten !== null && tombstone.weltkoordinaten.features !== null) {
		if (tombstone.weltkoordinaten.features.length > 0) {
			
			tombstoneCoordinates = tombstone.weltkoordinaten.features[0].geometry.coordinates[0];
		}
	}
	
	return 	tombstoneCoordinates;
}

