
export function handleStorage(itemKey, defaultValue) {

	let itemValues = getStorage(itemKey, defaultValue);
	
	// if itemKey not yet available in the store, set it	
	if (itemValues === defaultValue) {
		
		setStorage(itemKey, itemValues);
	} 
	
	return itemValues;
}

export function getStorage(itemKey, defaultValue) {

	let valuesInStore = localStorage.getItem(itemKey);
	let itemValues = null;
		
	if (valuesInStore === null && !valuesInStore) {
		
		if (defaultValue !== undefined) itemValues = defaultValue;
		else itemValues = [];
	
	} else {
		itemValues = JSON.parse(valuesInStore);
	}
	
	return itemValues;
}

export function setStorage(itemKey, itemValues) {

	localStorage.setItem(itemKey, JSON.stringify(itemValues));
}
	
export function disableForms(e){
	
	if (e === undefined || e === false){
		return false;
	}
	else{
		return true;
	}
}
	
	

	
