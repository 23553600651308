import React, { useState, useEffect,  } from "react";

import 'bootstrap/dist/css/bootstrap.css';
import '../jfb-styles-3.0.css';
import { Container, Row, Col, Button} from "react-bootstrap";
import ElasticSearchService from "../services/elasticSearch.service";
import CementeryListDetailElement from "./public/details/cementeryListDetailElement";
import CementeryMapDetailElement from "./public/details/cementeryMapDetailElement";
import { handleStorage } from '../helpers/jfb'
import * as Constants from "../components/constants";


export default function Home() {
	
	const [isLoadingCementeries, setLoadingCementeries] = useState(true);	
	const [cementeries, setCementeries] = useState([{}]);
	const [successful, setSuccessful] = useState(false);	
	const [message, setMessage] = useState("");
	const [googleMapConsent] = useState(handleStorage("googleMapConsent").length!==0 ? Boolean(handleStorage("googleMapConsent")) : false);



	useEffect(() => {	
			
		if(isLoadingCementeries){
			ElasticSearchService.loadCementries().then(
			response => {
				setCementeries(response.data);
				
				setSuccessful(true);
				setLoadingCementeries(false);
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
					setMessage(resMessage);
					setSuccessful(false);
					setLoadingCementeries(false);
				}
			);
		}
		
	});


	return (
			<Container fluid="xxl">
			{successful ? (	
			<div className="top-spacer">
			
				<Row className="text-spacer">
					<Col md={6}>
					Dokumentation und Datenbank<br/>
					<h4>Jüdische Friedhöfe in Bayern</h4>
					
					Jüdische Friedhöfe sind bedeutende Zeugnisse unserer Geschichte. 
					Sie belegen die Jahrhunderte zurückreichende Existenz einer großen Zahl jüdischer Gemeinden in Deutschland. 
					Durch Pogrome und Schändungen wurden unzählige Gräber verwüstet und zahlreiche Friedhöfe völlig zerstört. 
					Zudem ist der heutige Bestand an Grabmälern durch fortschreitende Verwitterung bedroht, einhergehend mit einem Verlust der Grabsteininschriften. 

					<br/>
					</Col>
					<Col md><p align="center">
					<a href="/cemeteries"><img className="img-fluid"  src={'jfb-ic-directory.png'} alt="Logo Friedhosverzeichnis" /></a><br/>
					<strong>FRIEDHÖFE</strong><br/>
					Eine Übersicht		
					</p>
					<p align="center">
						<a href="/betolam"><img className="img-fluid"  src={'jfb-ic-backgroundinfo.png'} alt="Logo" /></a><br/>
						<strong>PARTNER</strong><br/>
						Unsere Arbeit, Partner
						</p>
					</Col>
			
					<Col md>
					
						<p align="center">
						
						<a href="/tombstones"><img className="img-fluid"  src={'jfb-ic-tombstone.png'} alt="Logo" /></a><br/>
						<strong>GRABSTEINE</strong><br/>
						Verzeichnis
						</p>
						
						<p align="center">
						<a href="/search"><img className="img-fluid"  src={'jfb-ic-search.png'} alt="Logo" /></a><br/>
						<strong>SUCHE</strong><br/>
						Erweiterte Optionen
						</p>
						
					</Col>
				</Row>
				<Row className="top-spacer text-spacer">
					<Col>
					<p>Das Bayerische Landesamt für Denkmalpflege verfolgt mit dem 2020 gestarteten Projekt „Erfassung jüdischer Grabmäler in Bayern“ die Zielsetzung, die jüdischen Grabmäler in Bayern umfassend zu inventarisieren und die Ergebnisse in der vorliegenden Datenbank zur veröffentlichen. Das Projekt ist interdisziplinär angelegt: Wissenschaftler und Fachleute unterschiedlichster Disziplinen arbeiten darin Hand in Hand zusammen. </p>
					<p>Die Dokumentation umfasst nicht nur alle wichtigen Merkmale eines Grabsteins, sondern auch biografische Daten zu den bestatteten Personen. Jüdische Friedhöfe sind steinerne Archive. Ihre Erforschung und Dokumentation bereichert die jeweilige jüdische Orts- und Familiengeschichte und stellt zugleich wichtiges Quellenmaterial zur Geschichte der Juden in Bayern bereit. Das Landesamt für Denkmalpflege greift hier auf umfangreiche Vorarbeiten zurück, die in den vergangenen Jahrzehnten durch Ortshistoriker und Ehrenamtliche geleistet wurden. Valide Daten werden nach und nach in die Datenbank eingepflegt.</p> 

                    <p>Die in der Datenbank präsentierte Dokumentation basiert auf einer Tiefenerfassung mit mehreren, aufeinander folgenden Arbeitsschritten: Vermessungstechniker fertigen georeferenzierte Friedhofspläne an, so dass die Grabmäler eindeutig im Gelände verortet werden können. Anschließend reinigen Steinrestauratoren von Moos und Flechten überwucherte Grabsteine vorsichtig, um die Inschriften wieder sichtbar zu machen. Die Dokumentarfotografie der Grabmäler erfolgt unter Streiflicht, wodurch sich die Lesbarkeit der Grabsteininschriften verbessert. Erst dann können die Inschriften entziffert und übersetzt werden. Die kunsthistorische Beschreibung, die Bestimmung der Denkmalgesteine sowie die Dokumentation des Zustands und der Schadensbilder sind weitere Maßnahmen. Die umfassende Dokumentation eines größeren Friedhofs dauert in der Regel mehrere Jahre.</p> 

					</Col>	
				</Row>

				{googleMapConsent ? (
					<CementeryMapDetailElement cementeries={cementeries} published={false}/>
				):(
					<Row className="top-spacer text-spacer">
						<Col>
						
								<div className="consent-info" >{Constants.consentText}<br/>
							<strong>Den Button finden Sie unten auf dieser Seite!</strong></div>

						</Col>	
					</Row>
				)};



				
				
				
				<Row className="top-spacer text-spacer">
					<Col><h4>Verzeichnis der veröffentlichten jüdischen Friedhöfe in Bayern</h4></Col>	
				</Row>

				<CementeryListDetailElement cementeries={cementeries} published={true}/>
				<Row className="text-spacer">
					<Col>Die vorstehende Liste enthält nur die Friedhöfe, deren Daten der Öffentlichkeit zur Verfügung stehen. Friedhöfe, deren Daten noch nicht ausreichend qualifiziert wurden, sind hier nicht aufgeführt. Die vollständige Liste der Friedhöfe finden Sie <a href="/cemeteries">hier</a>.</Col>	
				</Row>

			</div>):(
				<div>
					{message}
				</div>
			)}
			
			
			</Container>
		);
}
