import React from "react";

import * as Constants from "../../components/constants";

import Button from '@mui/material/Button';

export default function ActionsBar(props) {
	
	const { isEditable, onSave, onChange, labelEdit, labelInit } = props;
	
	function handleChange(value) {
		
		onChange(value === "1" ? false : true);
	}
	
	function handleSave() {
	
		onSave();
	}

	return (
		<>
			<Button variant="Link" value={isEditable ? 1 : 0} onClick={(e) => handleChange(e.target.value)}>
					{isEditable ? 
						labelEdit !== undefined ? labelEdit : Constants.CHANGE_BUTTON_ACTIV : labelInit !== undefined ? labelInit : Constants.CHANGE_BUTTON_INIT}
			</Button>
			<Button variant="Link" onClick={handleSave} disabled={!isEditable} className="float-right">Speichern</Button>
		</>
	);
}