import React, { useState, useEffect } from "react";

import { Container, Badge, Row, Col, Form } from "react-bootstrap";

import ElasticSearchService from "../../services/elasticSearch.service";

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

export default function MeasurementManagement(props) {
	
	const [isLoading, setLoading] = useState(true);

	const [cementrySelection, setCementrySelection] = useState("");
	const [allCementries, setAllCementries] = useState([]);
	
	const [zippedCementryImagesFile, setZippedCementryImagesFile] = useState("");
	const [override, setOverride] = useState(true);
	const [cementryFileUploadOutput, setCementryFileUploadOutput] = useState("");
	
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	
	const Input = styled('input')({
	  display: 'none',
	});
	
	
	function handleChangeCementrySelection(newValue) {
		
		setCementrySelection(allCementries.find(cementry => cementry.id === newValue.id).id);
	}	
	
	function handleUploadZippedCementryImagesFile(e) {
		e.preventDefault();

		setMessage("");

		let file = e.target.files[0];

		let formData = new FormData();
		formData.append('file', file, file.name);
		formData.append('cementryId', cementrySelection);
		formData.append('override', override);
		
		setZippedCementryImagesFile(file.name);
		
		ElasticSearchService.uploadZippedTombstoneImagesFile(formData).then(
			response => {
				
				let output = "";
				response.data.urls.forEach(uploadResult => {
					
					output += uploadResult.urlorg + " - " + uploadResult.message + "\n";
				});
				setCementryFileUploadOutput(output);
				
				setMessage("Das ZIP-Archiv wurde hochgeladen");
				setSuccessful(true);
				
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setMessage(resMessage);
				setSuccessful(false);
				
			}			
		)
	}
	
	useEffect(() => {
		if (isLoading) {
			
			ElasticSearchService.loadCementriesForAutoComplete().then(
			response => {
				setAllCementries(response.data);
				setLoading(false);
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
					setMessage(resMessage);
					setSuccessful(false);
					setLoading(false);
				}
			);
		 }
	}, [isLoading]);
			
	return (
		<Container>
			<Row>
				<Col><h3><Badge variant="flat">Foto Upload</Badge></h3></Col>
			</Row>			
			{!isLoading && (
				<div>
					<Row>
						<Col><br/></Col>
					</Row>
					<Row md={12}>
						<Col  md={12} className="form-group">
														
							<Form.Group as={Col} controlId="CementryChoice">
		      					<Autocomplete 
									value= {allCementries.find(cementry => cementry.id === cementrySelection)}
									onChange={(event, newValue) => handleChangeCementrySelection(newValue)}
		      					    disableClearable = {true} 
		      						id="combo-box-cementries"
		      						options={allCementries}
		      						renderInput={(params) => <TextField {...params} label="Bitte wählen Sie den gewünschten Friedhof aus" />}
		    					/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col><br/></Col>
					</Row>
					<Row md={12}>
						<Col md={12}><h2>Upload - Foto Zip-File</h2></Col>
					</Row>
					<Row md={12}>
						<Col  md={8} className="form-group">
							<TextField fullWidth label="Bitte wählen Sie die Zip-Datei mit den Bilddaten des Friedhofs aus" 
								name="zipped-cementry-images-file"
								value={zippedCementryImagesFile} 
								disabled={true} />
						</Col>
						<Col md={4}>
							<Tooltip title={cementrySelection === "" ? "Bitte wählen Sie zuerst einen Friedhof aus" : ""} arrow placement="right">
								<label htmlFor="zipped-cementry-images-upload-button">
									{cementrySelection !== "" && (<Input accept=".zip" id="zipped-cementry-images-upload-button" multiple={false} type="file" onChange={(e) => handleUploadZippedCementryImagesFile(e)} />)}
									<Button variant="contained" sx={{ m: 1 }} component="span" disabled={cementrySelection === "" ? true : false} >Upload</Button>
								</label>
							</Tooltip>
						</Col>
					</Row>
					<Row md={12}>
						<Col  md={8} className="form-group">
							Wählen Sie eine ZIP-Datei mit Bilddaten aus. Die Dateigröße sollte 250 MB nicht überschreiten. Die Dauer des Uploads ist abhängig von der Dateigröße
						</Col>
						<Col md={4}>
						</Col>
					</Row>
					<Row>
						<Col><br/></Col>
					</Row>
					<Row md={12}>
						<Col  md={12} className="form-group">
							<FormGroup>
								<FormControlLabel control={<Checkbox defaultChecked={true} value={override} onChange={(e) => setOverride(e.target.value)} />} label="Vorhandene Bilder überschreiben" />
							</FormGroup>
						</Col>
					</Row>					
					<Row md={12}>
						<Col  md={12} className="form-group">
							<TextField 
								fullWidth 
								label="Ausgabe von Verarbeitungsinformationen" 
								name="cementry-file-upload-output"
								value={cementryFileUploadOutput} 
								multiline
								rows={4}
								/>
						</Col>
					</Row>
					<Row>
						<Col><br/></Col>
					</Row>
				</div>
			)}

			{message && (
				<div className="form-group">
					<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
				</div>
			)}

		</Container>

	);
}