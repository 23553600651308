import axios from "axios";
import authHeader from './auth-header';
import * as Constants from "../components/constants";


class ElasticSearchService {
	
	async deleteReport(listName) {
    	return await axios.post(Constants.ES_API_URL + "deleteReport",  { id: listName}, {headers: authHeader()});
  	}
	
	async loadReports() {
		const response = await axios.get(Constants.ES_API_URL + 'loadReports/', { headers: authHeader() });
		return response;
  	}
	
	async saveList(selectedFields, listName, userName) {
    	return await axios.post(Constants.ES_API_URL + "saveList",  { selectedFields: selectedFields, id: listName, userName: userName }, {headers: authHeader()});
  	}
	
	async loadTombstoneIds(cementryId) {
		const response = await axios.get(Constants.ES_API_URL + 'tombstoneIds/'+cementryId, { headers: authHeader() });
		return response;
  	}
  	
  	async getCementry(cementryId) {
        const response = axios.get(Constants.ES_API_URL + 'cementries/' + cementryId, { headers: authHeader() });
	    return response;
  	}

	
	async loadTombstonesWithConservation(cementryId) {
		const response = await axios.get(Constants.ES_API_URL + 'tombstonesWithConservations/'+cementryId, { headers: authHeader() });
		return response;
  	}
  	
  	async loadTombstoneWithProject(projectName) {
		const response = await axios.get(Constants.ES_API_URL + 'tombstone/conservations/projects/' + projectName, { headers: authHeader() });
		return response;
  	}
  	
  	async loadTombstonesWithProject(projectName) {
		const response = await axios.get(Constants.ES_API_URL + 'tombstone/conservations/projects/' + projectName + "/all", { headers: authHeader() });
		return response;
  	}
	
	async loadCementriesForAutoComplete(all) {
        const response = axios.get(Constants.ES_API_URL + 'cementriesForAutoComplete/'+all, { headers: authHeader() });
	    return response;
  	}
	
	async loadCementries() {
        const response = axios.get(Constants.ES_API_URL + 'cementries', { headers: authHeader() });
	    return response;
  	}
  	
  	async loadPublicCementries() {
        const response = axios.get(Constants.ES_API_URL + 'cementries/public', { headers: authHeader() });
	    return response;
  	}
  	
	async loadTombstones(cementryId) {
		const response = await axios.get(Constants.ES_API_URL + 'tombstones/'+cementryId, { headers: authHeader() });
		return response;
  	}

	async filterTombstones(cementryId, filteredTombstoneIds) {
		const response = axios.put(Constants.ES_API_URL + 'filter/tombstones/'+cementryId, { filteredTombstoneIds: filteredTombstoneIds}, {headers: authHeader()});
		return response;
  	}

	async saveCementries(cementries) {
    	return await axios.post(Constants.ES_API_URL + "saveCementries", { cementries }, {headers: authHeader()});
  	}
  	
  	async saveCementry(cementry) {
    	return await axios.post(Constants.ES_API_URL + "saveCementry", { cementry }, {headers: authHeader()});
  	}
  	
  	async deleteCementry(cementryId) {
    	return await axios.delete(Constants.ES_API_URL + "cementries/" + cementryId, {headers: authHeader()});
  	}

	async saveChoices(choices) {
    	return await axios.post(Constants.ES_API_URL + "saveChoices", { choices: choices }, {headers: authHeader()});
  	}
  	
  	async saveChoice(choice) {
    	return await axios.post(Constants.ES_API_URL + "saveChoice", { choice: choice }, {headers: authHeader()});
  	}

	async deleteChoice(choice) {
    	return await axios.post(Constants.ES_API_URL + "deleteChoice", { choice: choice }, {headers: authHeader()});
  	}

	async loadTombstone(cementryId, tombstoneId) {
		const response = axios.get(Constants.ES_API_URL + 'tombstone/'+cementryId+"/"+tombstoneId, {headers: authHeader()});
		return response;
  	}

	tombstoneExist(cementryId, tombstoneId) {
		const response = axios.get(Constants.ES_API_URL + 'tombstone/exist/'+cementryId+"/"+tombstoneId, {headers: authHeader()});
		return response;
  	}

	async loadTombstoneNeighbors(cementryId, tombstoneId) {
		const response = await axios.get(Constants.ES_API_URL + 'tombstone/neighbors/'+cementryId+"/"+tombstoneId, {headers: authHeader()});
		return response;
  	}

	async search(cementryId, searchRequest, from, size, restriction) {
		const response = await axios.put(Constants.ES_API_URL + 'search/'+cementryId, {request: searchRequest, from: from, size: size, restriction: restriction }, {headers: authHeader()});
		return response;
  	}

	async loadChoices() {
		const response = await axios.get(Constants.ES_API_URL + 'choices', { headers: authHeader() });
		return response;
  	}

	async loadMakers() {
		const response = await axios.get(Constants.ES_API_URL + 'makers', { headers: authHeader() });
		return response;
  	}

	async loadMaker(makerId) {
		const response = await axios.get(Constants.ES_API_URL + 'maker/'+makerId, { headers: authHeader() });
		return response;
  	}

	async saveMaker(maker) {
    	return await axios.post(Constants.ES_API_URL + "saveMaker", { maker: maker }, { headers: authHeader() });
  	}

	async loadPerson(personId) {		
		const response = await axios.get(Constants.ES_API_URL + 'person/'+personId, { headers: authHeader() });
		return response;
  	}

	async loadAllPersons() {
		const response = await axios.get(Constants.ES_API_URL + 'persons', { headers: authHeader() });
		return response;
  	}

	async savePerson(person) {
    	return await axios.post(Constants.ES_API_URL + "savePerson", { person: person }, { headers: authHeader() });
  	}

	async saveTombstone(tombstone) {
    	return await axios.post(Constants.ES_API_URL + "saveTombstone", { tombstone: tombstone }, { headers: authHeader() });
  	}

	async uploadImageFile(formData) {
    	const response = await axios.post(Constants.ES_API_URL + "uploadImage", formData, {headers: authHeader()});	
		return response;
  	}
  	
  	async createTombstonePdf(cementryId, tombstoneId) {
		const response = await axios.get(Constants.ES_API_URL + 'tombstone/pdf/'+cementryId+"/"+tombstoneId, {headers: authHeader(), responseType: 'blob', timeout: 30000,});
		return response;
  	}

	async createTombstoneConservationsPdf(cementryId, tombstoneId) {
		const response = await axios.get(Constants.ES_API_URL + 'tombstone/pdf/conservations/'+cementryId+"/"+tombstoneId, {headers: authHeader(), responseType: 'blob', timeout: 30000,});
		return response;
  	}
  	
  	async createCementryPicturesReportPdf(cementryId) {
		const response = await axios.get(Constants.ES_API_URL + 'tombstone/pdf/reports/pictures/'+cementryId, {headers: authHeader(), responseType: 'blob', timeout: 180000,});
		return response;
  	}

	async createList(selectedFields) {
		
		const response = axios.put(Constants.ES_API_URL + 'createList', { fieldList: selectedFields },  {headers: authHeader()});
		return response;
  	}

	async createCsvList(selectedFields) {
		const response = await axios.post(Constants.ES_API_URL + 'csv/listReport', { fieldList: selectedFields },  {headers: authHeader()});
		return response;
  	}
  	
  	async uploadCementryMeasurementsDataFile(formData) {
    	const response = await axios.post(Constants.ES_API_URL + "uploadCementryMeasurementsData", formData, {headers: authHeader()});	
		return response;
  	}
  	
  	async uploadTombstonesMeasurementsDataFile(formData) {
    	const response = await axios.post(Constants.ES_API_URL + "uploadTombstonesMeasurementsData", formData, {headers: authHeader()});	
		return response;
  	}
  	
  	async uploadZippedCementryImagesFile(formData) {
    	const response = await axios.post(Constants.ES_API_URL + "uploadCementryImages", formData, {headers: authHeader()});	
		return response;
  	}
  	
  	async uploadZippedTombstoneImagesFile(formData) {
    	const response = await axios.post(Constants.ES_API_URL + "uploadTombstoneImages", formData, {headers: authHeader()});	
		return response;
  	}
  	
  	async uploadConservationDocumentationFile(formData) {
    	const response = await axios.post(Constants.ES_API_URL + "uploadConservationDocumentationFile", formData, {headers: authHeader()});	
		return response;
  	}
  	
  	async loadLicenses() {
		const response = await axios.get(Constants.ES_API_URL + 'licenses', { headers: authHeader() });
		return response;
  	}

	async loadLicense(licenseId) {
		const response = await axios.get(Constants.ES_API_URL + 'licenses', licenseId, { headers: authHeader() });
		return response;
  	}

	async saveLicense(license) {
    	return await axios.post(Constants.ES_API_URL + "saveLicense", { license: license }, { headers: authHeader() });
  	}
  	
  	async deleteLicense(licenseId) {
    	return await axios.delete(Constants.ES_API_URL + "licenses", licenseId, { headers: authHeader() });
  	}
  	
  	async assignConservationToTombstones(cementeryId, conservation, assignedTombstones) {
    	return await axios.post(Constants.ES_API_URL + "assignConservationToTombstones", {
			cementeryId: cementeryId,
			conservation: conservation,
			assignedTombstones: assignedTombstones 
		}, { headers: authHeader() });
  	}
  	
  	async saveProject(project) {
    	return await axios.put(Constants.ES_API_URL + "tombstone/conservations/projects/" + project.projectName, project, { headers: authHeader() });
  	}
  	
  	async saveNewProject(project) {
    	return await axios.post(Constants.ES_API_URL + "tombstone/conservations/projects/" + project.projectName, project, { headers: authHeader() });
  	}
}
export default new ElasticSearchService();