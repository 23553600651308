import React, { Fragment, useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";

//import HoverRating from "../../../standard/hoverRating";
import JFBRadioRating from "../../../standard/jfbRadioRating";

import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Stack from '@mui/material/Stack';
import { red } from '@mui/material/colors';
import { pink } from '@mui/material/colors';

import AssessmentDetails from "./assessmentDetails2";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import ElasticSearchService from "../../../../services/elasticSearch.service";
import { getStringDate } from "../../../../helpers/util";

import { ConditionDamageType } from "../../../../components/constants";

export default function AssessmentItem(props) {
	
	const { readOnly, tombstoneName, assessment, index, onChange, onDelete } = props;
	
	const tombstoneConditions = {
		1: 'gut',
		2: 'gefährdet',
		3: 'stark gefährdet',
		4: 'Fragment'
	};
	
	const inscriptionConditions = {
		1: 'gut',
		2: 'überwiegend lesbar',
		3: 'überwiegend verwittert',
		4: 'abgängig'
	};

	let details = assessment.details !== undefined && assessment.details !== null ? assessment.details : [];
	const [assessmentDetails, setAssessmentDetails] = useState([...details]);
	
	// JavaScript date format: YYYY-MM-DDTHH:mm:ss.sssZ
	let date = assessment.date !== undefined ? new Date(assessment.date) : new Date();
	const [assessementDate, setAssessementDate] = useState(date);
	
	let classification1 = assessment.classificationTombstone !== undefined ? assessment.classificationTombstone : "";
	const [classificationTombstone, setClassificationTombstone] = useState(classification1);
	
	let classification2 = assessment.classificationInscription !== undefined ? assessment.classificationInscription : "";
	const [classificationInscription, setClassificationInscription] = useState(classification2);
	
	let currentComment = assessment.comment !== undefined ? assessment.comment : "";
	const [comment, setComment] = useState(currentComment);
	
	const [choicesLoaded, setChoicesLoaded] = useState(false);
	const [choices, setChoices] = useState([]);
	
	const createAssessment = (details, date, classTombstone, classInscription, assessmentComment) => {
		
		let currentAssessment = {};
     	currentAssessment.details = details;
		currentAssessment.date = getStringDate(date);
		currentAssessment.classificationTombstone = classTombstone;
		currentAssessment.classificationInscription = classInscription;
		currentAssessment.comment = assessmentComment;
		
		return currentAssessment;
	}
	
	const handleDateChange = (newValue) => {
		
		setAssessementDate(newValue);
		
		let currentAssessment = createAssessment(assessmentDetails, newValue, 
									classificationTombstone, classificationInscription, comment);
     	
     	onChange(index, currentAssessment);
	};
	
	const handleClassificationTombstone = (newValue) => {
		
		setClassificationTombstone(newValue);
		
		let currentAssessment = createAssessment(assessmentDetails, assessementDate, 
									newValue, classificationInscription, comment);
     	
     	onChange(index, currentAssessment);
	};
	
	const handleClassificationInscription = (newValue) => {
		
		setClassificationInscription(newValue);
		
		let currentAssessment = createAssessment(assessmentDetails, assessementDate, 
									classificationTombstone, newValue, comment);
     	
     	onChange(index, currentAssessment);
	};
	
	function handleCommentChange(newValue) {
		
		setComment(newValue);
		
		let currentAssessment = createAssessment(assessmentDetails, assessementDate, 
									classificationTombstone, classificationInscription, newValue);
     	
     	onChange(index, currentAssessment);
	};
	
	function hanldeDeleteAssessment() {
		
		onDelete(index);
	};
	
	function handleAddDefaultNewAssessmentDetails() {

		let newAssessmentsDetails = [...assessmentDetails];
		
		let stoneDamageDetails = {
			component: ConditionDamageType.StoneDamage,  
			damagePatterns:[],
			comment: ""
     	}
     	newAssessmentsDetails.push(stoneDamageDetails);
     	
     	let constructionDamageDetails = {
			component: ConditionDamageType.ConstructionDamage,  
			damagePatterns:[],
			comment: ""
     	}
     	newAssessmentsDetails.push(constructionDamageDetails);
     	
     	let desacrationsDamageDetails = {
			component: ConditionDamageType.DesacrationsDamage,  
			damagePatterns:[],
			comment: ""
     	}
     	newAssessmentsDetails.push(desacrationsDamageDetails);
		
     	setAssessmentDetails(newAssessmentsDetails);
     	/*
     	let currentAssessment = createAssessment(newAssessmentsDetails, assessementDate, 
									classificationTombstone, classificationInscription, comment);
									
		onChange(index, currentAssessment);*/
	}
	
	function handleAddNewAssessmentDetails(details) {
		
		addNewAssessmentDetails(details);
		
	}
	
	function addNewAssessmentDetails(details) {

		let newAssessmentsDetails = [...assessmentDetails];
		
		newAssessmentsDetails.push(details);
     	
     	setAssessmentDetails(newAssessmentsDetails);
     	
     	/*
     	let currentAssessment = createAssessment(newAssessmentsDetails, assessementDate, 
									classificationTombstone, classificationInscription, comment);
     	
     	onChange(index, currentAssessment);*/
	}
	
	function deleteAssessmentDetails(detailsIndex) {
		
		let newAssessmentsDetails = [...assessmentDetails];
		
		newAssessmentsDetails.splice(detailsIndex, 1);
		
		setAssessmentDetails(newAssessmentsDetails);
		
		// TODO: 
		// wollen wir dass "leere" (ohne Werte) Details am Grabstein gespeichert werden?
		// wenn nein -> bitte die folgende Zeile einkommentieren und die Variable im createAssessment() anpassen
		//let changedAssessmentsDetails =  [...newAssessmentsDetails].filter((filterDetails => filterDetails.comment !== "" && filterDetails.damagePatterns.length > 0));
		
		let currentAssessment = createAssessment(newAssessmentsDetails, assessementDate, 
									classificationTombstone, classificationInscription, comment);
     	
     	onChange(index, currentAssessment);
	}
	
	function handleDetailsChange(detailsIndex, detailsItem) {
		
		assessmentDetails[detailsIndex] = detailsItem;
		
		// TODO: 
		// wollen wir dass "leere" (ohne Werte) Details am Grabstein gespeichert werden?
		// wenn nein -> bitte die folgende Zeile einkommentieren und die Variable im createAssessment() anpassen
		//let changedAssessmentsDetails = [...assessmentDetails].filter((filterDetails => filterDetails.comment !== "" && filterDetails.damagePatterns.length > 0));
		
		let currentAssessment = createAssessment(assessmentDetails, assessementDate, 
									classificationTombstone, classificationInscription, comment);
     	
     	onChange(index, currentAssessment);
     	
	};
	
	useEffect(() => {
	
		let details = assessment.details !== undefined && assessment.details !== null ? assessment.details : [];
		setAssessmentDetails([...details]);
		
		// JavaScript date format: YYYY-MM-DDTHH:mm:ss.sssZ
		let date = assessment.date !== undefined ? new Date(assessment.date) : new Date();
		setAssessementDate(date);
		
		let classification1 = assessment.classificationTombstone !== undefined ? assessment.classificationTombstone : "";
		setClassificationTombstone(classification1);
		
		let classification2 = assessment.classificationInscription !== undefined ? assessment.classificationInscription : "";
		setClassificationInscription(classification2);
		
		let currentComment = assessment.comment !== undefined ? assessment.comment : "";
		setComment(currentComment);
		
		ElasticSearchService.loadChoices().then(
			response => {	
				let data = response.data;
				
				setChoices(data);
				setChoicesLoaded(true);
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
					setChoicesLoaded(false);
			}
		);
	
		console.info("assessmentItem rendered");
		 
	}, [assessment]);
	
	const DamageDetails = ((props) => {
		
		const { readOnly, title, damageType, damageDetails, choices, onDelete, onAddNew, onChange } = props;
		
		function addNewAssessmentDamageDetails() {

			onAddNew({
				component: damageType,  
				damagePatterns:[],
				comment: ""
     		});

		}
	
		function deleteAssessmentDamageDetails(detailsIndex) {
			
			onDelete(detailsIndex);
		}
		
		function handleDamageDetailsChange(detailsIndex, detailsItem) {
	     	
	     	onChange(detailsIndex, detailsItem);
		};
		
		function hasDetailsForDamageType(damageType, details) {
		
			return details.filter(detail => detail.component === damageType).length > 0;
		};
		
		useEffect(() => {

			return () => {
      		};
		 
		}, [damageDetails]);
		
		return (

			<>
				<Row md={12}>
					<Col md={12}><Box><strong>{title}</strong></Box></Col>
				</Row>
				<Row md={12}>
					<Col  md={12} className="form-group">
						{damageDetails.map((assessmentDetailItem, detailsIndex) => (
							<Fragment key={detailsIndex}>
								{(assessmentDetailItem.component === damageType) && (
									<Row md={12}>
										<Col  md={12} className="form-group">
											<AssessmentDetails 
												readOnly={readOnly}
												damageType={damageType}
												details={assessmentDetailItem} 
												index={detailsIndex}
												choices={choices} 
												onDelete={deleteAssessmentDamageDetails} 
												onChange={handleDamageDetailsChange}
											 />
										</Col>
									</Row>
								)}
							</Fragment>
						))}
						{(!readOnly && onDelete && onAddNew) && (
						<Row md={12}>
							<Col  md={11} className="form-group">{!hasDetailsForDamageType(damageType, damageDetails) && (<center>Keine Einträge für {damageType} vorhanden</center>)}</Col>
							<Col  md={1} className="form-group">
								<Stack direction="row" justifyContent="end" alignItems="center">
									<Tooltip title={damageType + " - Bewertungsdetails hinzufügen"} arrow placement="left">
										<span>
											<IconButton onClick={addNewAssessmentDamageDetails} ><AddCircleIcon /></IconButton>
										</span>
									</Tooltip>
								</Stack>
							</Col>
							
						</Row>
						)}
					</Col>
				</Row>
			</>	
		);
	});
	
	return (
		<Container>
			<>
				<Row md={12}>
					<Col md={12}>
						<Box sx={{ boxShadow: 0 }} >
							<Row>
								<Col><hr/></Col>
							</Row>
							<Row md={12} >

								<Col  md={3} className="form-group">
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DesktopDatePicker 
								          label="Datum"
								          inputFormat="dd.MM.yyyy"
								          value={assessementDate}
								          onChange={handleDateChange}
								          renderInput={(params) => <TextField fullWidth {...params} disabled={readOnly} sx={{'& .MuiOutlinedInput-input': {
																																  paddingTop: "7.5px",
																																  paddingRight: "4px",
																																  paddingBottom: "7.5px",
																																  paddingLeft: "6px"
																																}
																															  }}/>}  
								          disabled={readOnly} 
								          
								        />
									</LocalizationProvider>									     
								</Col>
								<Col md={9}>
									<Box><strong>Zustand des Grabmals</strong></Box>
									<JFBRadioRating 
										readOnly={readOnly}
										labels={tombstoneConditions}
										labelSelected={classificationTombstone} 
										onChange={handleClassificationTombstone} 
									/>
								</Col>
							</Row>
							<Row md={12} >
								
								<Col md={3} className="form-group">
									{!readOnly && (							
										<Row md={12}>
											<Col md={12}>
												<Stack direction="row" justifyContent="start">
													<Tooltip title="Bericht löschen" arrow placement="left">
														<span>
															<IconButton onClick={() => hanldeDeleteAssessment()} ><DeleteIcon sx={{ color: red[500]}} /> </IconButton>
														</span>
													</Tooltip>
													<Box>Den Bericht für dieses Datum löschen</Box>
												</Stack>
											</Col>
										</Row>
									)}
								</Col>
								<Col md={9}>
									<Box><strong>Zustand der Inschrift</strong></Box>
									<JFBRadioRating 
										readOnly={readOnly} 
										labels={inscriptionConditions} 
										labelSelected={classificationInscription} 
										onChange={handleClassificationInscription} 
									/>
								</Col>
							</Row>
							<Row>
								<Col><br/></Col>
							</Row>
							
							
							{assessmentDetails.length > 0 && choicesLoaded ? (
								<>
									<DamageDetails 
										readOnly={readOnly}
										title="Verwitterungsschäden" 
										damageType={ConditionDamageType.StoneDamage} 
										damageDetails={assessmentDetails} 
										choices={choices} 
										onDelete={deleteAssessmentDetails} 
										onAddNew={handleAddNewAssessmentDetails} 
										onChange={handleDetailsChange}
									 />
									 <DamageDetails 
										readOnly={readOnly}
										title="Konstruktive Schäden" 
										damageType={ConditionDamageType.ConstructionDamage} 
										damageDetails={assessmentDetails} 
										choices={choices} 
										onDelete={deleteAssessmentDetails} 
										onAddNew={handleAddNewAssessmentDetails} 
										onChange={handleDetailsChange}
									 />
									 <DamageDetails 
										readOnly={readOnly}
										title="Schäden im Kontext von Schändungen" 
										damageType={ConditionDamageType.DesacrationsDamage} 
										damageDetails={assessmentDetails} 
										choices={choices} 
										onDelete={deleteAssessmentDetails} 
										onAddNew={handleAddNewAssessmentDetails} 
										onChange={handleDetailsChange}
									 />
								</>)
							 :
							(
								<Fragment key={-1}>
									<Row md={12}>
										<Col md={12}>
											<Stack direction="row" justifyContent="center" alignItems="center">
												<Box><strong>Keine Details zur Bewertung</strong></Box>
											</Stack>
										</Col>
									</Row>
									{!readOnly && (
									<Row md={12}>
										<Col  md={12} className="form-group">
											<Stack direction="row" justifyContent="end" alignItems="center">
												<Tooltip title={"Bewertungsdetails hinzufügen"} arrow placement="left">
													<span>
														<IconButton onClick={(e) => handleAddDefaultNewAssessmentDetails()} ><AddCircleIcon /></IconButton>
													</span>
												</Tooltip>
											</Stack>
										</Col>
									</Row>
									)}
								</Fragment>
							)}
						
							<Row md={12}>
					<Col md={12}><Box><strong>Sonstiges</strong></Box></Col>
				</Row>
							<Row md={12}>
								<Col  md={12} className="form-group">
									<TextField 
										fullWidth 
										name="cementry-tombstone-assessment-item-description"
										value={comment} 
										onChange={(e) => handleCommentChange(e.target.value)} 
										multiline
										rows={3} 
										disabled={readOnly} 
									/>
								</Col>
							</Row>
							
						</Box>
					</Col>
				</Row>
			</>

		</Container>

	);
}